<template>
	<main class="flex flex-col">
		<CvDesignSelector
			class="flex-1"
			show-download-button
			@change-cv-design="changeCvDesign($event)"
		/>
	</main>
</template>

<script lang="ts">
import type { IAPILoginUser, IAPIUserExtra } from '@hokify/common';
import CvDesignSelector from '@hokify/pwa-core-nuxt3/components/hokifycv/CvDesignSelector.vue';
import { mapStores } from 'pinia';
import { defineComponent, nextTick } from 'vue';
import { useUserProfileStore } from '@/stores/user-profile';
import { definePageMeta } from '#imports';

export default defineComponent({
	setup() {
		definePageMeta({
			layout: 'user',
			middleware: ['auth']
		});
	},
	components: { CvDesignSelector },
	emits: ['done'],
	computed: {
		user(): IAPILoginUser | undefined {
			return this.userProfileStore.obj;
		},
		visibleCv(): IAPIUserExtra | undefined {
			return (
				this.userProfileStore.obj?.extras?.find(file => file.type === 'cv' && file.visible) ||
				undefined
			);
		},
		activeHokifyCv(): boolean {
			return this.visibleCv?.details === 'hokify' || false;
		},
		cvUrl(): string | undefined {
			return this.visibleCv?.url;
		},
		...mapStores(useUserProfileStore)
	},
	methods: {
		async changeCvDesign($event) {
			try {
				await this.userProfileStore.useHokifyCv($event);
				await this.$trackUserEvent?.('cv_configuration', {
					design: $event.design,
					color: $event.color,
					lineSpacing: $event.lineSpacing,
					disableProfilePic: $event.disableProfilePic
				});
				if ($event.download && this.activeHokifyCv && this.cvUrl) {
					nextTick(() => {
						window.open(this.cvUrl, '_blank');
					});
				} else {
					this.$page.goBack();
				}

				this.$snack.success({
					text: 'Dein Lebenslaufdesign wurde erfolgreich angepasst.'
				});
			} catch (err) {
				if (
					this.$isHokFetchResponseError(err) &&
					err.response?.data?.code === 'INCOMPLETE_HOKIFY_CV'
				) {
					this.$snack.danger({
						text: 'Um den hokify Lebenslauf verwenden zu können, muss dieser vollständiger sein!'
					});
					return;
				}
				this.$nuxt.$errorHandler(
					err,
					'Es ist ein Fehler aufgetreten, beim Versuch das Design deines hokify Lebenslaufes zu ändern.'
				);
			}
		}
	}
});
</script>
