<template>
	<div :class="{ 'm-auto': center }" style="max-width: 185px">
		<Spinner v-show="socialLoginSpinner" :fixed="false" />
		<div v-show="!socialLoginSpinner" class="flex space-x-2 justify-center">
			<a ref="googleLogin" @click="doLoginGoogle">
				<HokIcon :size="10" name="icon:google" pointer />
			</a>
			<a v-if="cordova !== 'android' && showAppleLogin" ref="appleLogin" @click="doLoginApple">
				<HokIcon :size="10" name="icon:apple" pointer />
			</a>
			<a v-if="showFacebookLogin" ref="fbLogin" @click="doLoginFB">
				<HokIcon :size="10" name="icon:facebook-social" pointer />
			</a>
			<a v-if="showLinkedinLogin" @click="doLoginLinkedIn">
				<HokIcon :size="10" name="icon:linkedin-social" pointer />
			</a>
		</div>
	</div>
</template>
<script lang="ts">
import type { IAPIReAuthResult } from '@hokify/common';
import { errorCodes } from '@hokify/shared-components-nuxt3/lib/data/errorCodes';
import { defineComponent } from 'vue';
import type { PropType } from 'vue';
import { SupportedCompanyCase } from '../../types/supported-company-case';
import type { SupportedUserCase } from '../../types/supported-user-case';
import { GoogleLogin, initGoogleSDK } from '../../helpers/google-sdk';
import { FbLogin } from '../../helpers/facebook-sdk';
import { AppleLogin, initAppleSDK } from '../../helpers/apple-sdk';

export default defineComponent({
	name: 'SocialLogin',
	emits: ['error', 'login-result'],
	data() {
		const cordova: string | null = process.env.cordova || null;

		return {
			cordova,
			socialLoginSpinner: false,
			errorCodes
		};
	},
	mounted() {
		initGoogleSDK();
		initAppleSDK();
	},
	methods: {
		async doLoginGoogle() {
			this.$trackGenericEvent?.('register_started', { loginMethod: 'google' });
			try {
				this.$emit('error', false);
				this.socialLoginSpinner = true;
				const response = await GoogleLogin(useRuntimeConfig().public.env);
				this.loginSuccess('google', response);
			} catch (err: any) {
				let message;
				switch (err) {
					case 'popup_failed_to_open':
						message = 'Fenster konnte nicht geöffnet werden.';
						break;
					case 'popup_closed':
						message = 'Fenster wurde frühzeitig geschlossen.';
						break;
					default:
						message = err;
				}
				this.loginFail('google', message);
			} finally {
				this.socialLoginSpinner = false;
			}
		},
		async doLoginLinkedIn() {
			this.$trackGenericEvent?.('register_started', { loginMethod: 'linkedIn' });
			const host = this.$nuxt?.$userRootStore?.host;
			this.openSignInWindow(
				`https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=771ujqi29x2ota&redirect_uri=${host}/linkedInCallBack&state=linkedIn&scope=r_liteprofile%20r_emailaddress`,
				'LinkedIn'
			);
		},
		openSignInWindow(url: string, name: string) {
			// remove any existing event listeners
			window.removeEventListener('message', this.receiveMessage);

			// window features
			const strWindowFeatures =
				'popup=yes,toolbar=no,menubar=no,scrollbars=no,resizable=no,top=100,left=100,width=500,height=700';

			window.open(url, name, strWindowFeatures);

			// add the listener for receiving a message from the popup
			window.addEventListener('message', event => this.receiveMessage(event), false);
		},
		receiveMessage(event) {
			// only go on if we trust the source of the event
			if (event.origin !== this.$nuxt?.$userRootStore?.host) {
				this.loginFail('linkedIn', event);
				return;
			}

			if (typeof event.data === 'string') {
				this.loginSuccess('linkedIn', event.data);
			}
		},
		async doLoginFB() {
			this.$trackGenericEvent?.('register_started', { loginMethod: 'fb' });
			try {
				this.$emit('error', false);
				this.socialLoginSpinner = true;
				const response = await FbLogin();
				this.loginSuccess('fb', response);
			} catch (err: any) {
				this.loginFail('fb', err);
			} finally {
				this.socialLoginSpinner = false;
			}
		},
		async doLoginApple() {
			this.$trackGenericEvent?.('register_started', { loginMethod: 'apple' });
			try {
				this.$emit('error', false);
				this.socialLoginSpinner = true;
				const response = await AppleLogin();
				this.loginSuccess('apple', response);
			} catch (err: any) {
				this.loginFail('apple', err);
			} finally {
				this.socialLoginSpinner = false;
			}
		},
		async loginSuccess(authProvider, responseObj) {
			try {
				let result: IAPIReAuthResult;
				let payload;
				const cleanedUserCase =
					!this.usercase && this.companycase === SupportedCompanyCase.DigitalContent
						? undefined
						: this.usercase;
				switch (authProvider) {
					case 'fb':
						payload = {
							method: 'facebook-token',
							parameters: {
								companycase: this.companycase,
								usercase: cleanedUserCase,
								access_token: responseObj.authResponse.accessToken
							}
						};
						result = await this.$nuxt?.$loginStore?.doLogin(payload);
						break;
					case 'linkedIn': {
						const host = this.$nuxt?.$userRootStore?.host;
						payload = {
							method: 'linkedin',
							parameters: {
								companycase: this.companycase,
								usercase: cleanedUserCase,
								code: responseObj,
								redirect_uri: `${host}/linkedInCallBack`
							}
						};
						result = await this.$nuxt?.$loginStore?.doLogin(payload);
						break;
					}
					case 'apple':
						payload = {
							method: process.env.cordova ? 'apple-token' : 'apple-token-web',
							parameters: {
								companycase: this.companycase,
								usercase: cleanedUserCase,
								access_token: responseObj.authorization?.id_token || responseObj.identityToken
							}
						};
						result = await this.$nuxt?.$loginStore?.doLogin(payload);
						break;
					case 'google':
						if (responseObj.idToken) {
							payload = {
								method: 'google-token',
								parameters: {
									companycase: this.companycase,
									usercase: cleanedUserCase,
									access_token: responseObj.idToken
								}
							};
							result = await this.$nuxt?.$loginStore?.doLogin(payload);
						} else {
							payload = {
								method: 'google',
								parameters: {
									companycase: this.companycase,
									usercase: cleanedUserCase,
									code: responseObj.code,
									redirect_uri: window.location.origin
								}
							};
							result = await this.$nuxt?.$loginStore?.doLogin(payload);
						}
						break;
					default:
						throw new Error('invalid authProvider');
				}
				const { loggedin } = result;
				if (!loggedin) {
					throw new Error(`not logged in: ${JSON.stringify(result)}`);
				}

				if (result.newUser) {
					if (this.companycase) {
						this.$trackRecruiterEvent?.({ item: 'social-login' }, 'register_completed');
					}

					this.$trackGenericEvent?.('register_completed', {
						case: this.companycase || this.usercase,
						mode: result.user?.mode || (this.companycase && 'company') || 'jobseeker',
						loginMethod: authProvider
					});
				} else {
					if (this.companycase) {
						this.$trackRecruiterEvent?.({ item: 'social-login' }, 'login');
					}

					this.$trackGenericEvent?.('login', {
						case: this.companycase || this.usercase,
						mode: result.user?.mode || (this.companycase && 'company') || 'jobseeker',
						loginMethod: authProvider
					});
				}

				return this.$emit('login-result', {
					loginMethod: authProvider,
					...result
				});
			} catch (err: any) {
				if (err.response?.status >= 400 && err.response?.status < 500) {
					this.$emit('error', this.getError(err));
				} else {
					this.$nuxt.$errorHandler(err);
				}
			}
		},
		loginFail(authProvider, error) {
			console.error('loginFail', authProvider, error);
			this.$emit('error', {
				text:
					(typeof error === 'string' && error) ||
					'Der Login ist mit dieser Methode fehlgeschlagen, probier es noch einmal oder wähle eine andere Loginmethode.'
			});
		},
		getError(err: any) {
			const code = this.$nuxt.$getErrorCode(err) || '';
			return {
				title: this.errorCodes.genericErrors[`${code}_TITLE`] || undefined,
				text: this.errorCodes.genericErrors[code] || code || err
			};
		}
	},
	props: {
		socialLogin: { type: String, required: false, default: () => {} },
		showAppleLogin: { type: Boolean, default: true },
		showFacebookLogin: { type: Boolean, default: true },
		showLinkedinLogin: { type: Boolean, default: true },
		center: { type: Boolean, default: true },
		usercase: {
			type: String as PropType<SupportedUserCase>,
			default: () => {}
		},
		companycase: { type: String, default: () => undefined }
	}
});
</script>
