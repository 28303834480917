export const appCdnURL = ""

export const appBuildAssetsDir = "/pwa/_assets/"

export const appHead = {"meta":[{"name":"nuxt-version","content":"3"},{"hid":"charset","charset":"utf-8"},{"name":"viewport","content":"user-scalable=no,initial-scale=1,maximum-scale=1,minimum-scale=1,width=device-width,height=device-height"},{"hid":"description","name":"description","content":"hokify"},{"hid":"robots","name":"robots","content":"noindex, nofollow"},{"hid":"fb:app_id","name":"fb:app_id","content":"1031146320229277"}],"link":[{"rel":"icon","type":"image/x-icon","href":"/pwa/public/icon.png"}],"style":[],"script":[],"noscript":[],"title":"hokify App - deine mobile Job-Plattform"}

export const appBaseURL = "/"

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appRootAttrs = {"id":"__nuxt"}

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const appTeleportAttrs = {"id":"teleports"}

export const appSpaLoaderTag = "div"

export const appSpaLoaderAttrs = {"id":"__nuxt-loader"}

export const renderJsonPayloads = false

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = true

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink","prefetch":true,"prefetchOn":{"visibility":true}}

export const asyncDataDefaults = {"value":null,"errorValue":null,"deep":true}

export const resetAsyncDataToUndefined = true

export const nuxtDefaultErrorValue = null

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false

export const appId = "nuxt-app"

export const outdatedBuildInterval = 3600000

export const multiApp = false

export const chunkErrorEvent = "vite:preloadError"

export const crawlLinks = false

export const spaLoadingTemplateOutside = false