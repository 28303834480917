<template>
	<div
		:class="[
			fullwidth ? 'max-w-full' : 'max-w-400',
			{ 'value-required': valueRequired && useOverlay, 'opacity-50': disabled }
		]"
		class="autocomplete-container mb-4 relative"
	>
		<!-- on mobile do not use the overlay becasue we are already in an inside page -->
		<div v-if="!$isMobile.any" class="fixed overlay"></div>
		<div :class="myClasses" class="input input--sae">
			<label v-if="$slots.default" :for="id" class="input__label input__label--sae">
				<span class="input__label-content input__label-content--sae">
					<slot />
					<span v-if="required" :class="{ required: !value }">*</span>
				</span>
			</label>
			<!-- if value id, then use the internal value id in a hidden html field for sending, and ignore the other value -->
			<input v-if="valueLabel" :name="name" :value="internalValueId" type="hidden" />
			<input
				:id="id"
				ref="input"
				v-model="value"
				:name="valueLabel ? `${name || id}-label` : name"
				maxlength="2048"
				aria-autocomplete="both"
				aria-haspopup="false"
				autocomplete="off"
				autocapitalize="off"
				autocorrect="off"
				:autofocus="autofocus"
				role="combobox"
				spellcheck="false"
				:required="required"
				:disabled="disabled"
				:placeholder="placeholder"
				class="input__field input__field--sae pl-2"
				:class="{ 'cursor-not-allowed': disabled }"
				:type="mustSelect ? 'search' : 'text'"
				@click="open"
				@focus="focus"
				@blur="blur"
				@change.stop="input"
				@keyup="keyup"
				@keydown.enter="enter"
				@keydown.down="down"
				@keydown.up="up"
			/>
			<LazySpinner v-if="loading" :fixed="false" size="small" />
		</div>
		<ul
			v-if="openSuggestion || valueRequired"
			:class="[resultFullWidth ? 'max-w-full' : 'max-w-400', { initial: positionInitial }]"
			class="autocomplete shadow-lg rounded-b-lg overflow-y-auto max-h-188 max-w-400 w-full"
		>
			<li
				v-for="(suggestion, index) in suggestions"
				:key="index"
				:class="`${useSelection && isActive(index) ? 'active' : ''}`"
				class="py-1 px-2 w-full hover:bg-color-grey-lightest cursor-pointer"
				@click.prevent.stop="suggestionClick(suggestion, index)"
			>
				<slot :suggestion="suggestion" :value="values[index]" name="suggestion">{{
					suggestion
				}}</slot>
			</li>
			<li
				v-if="
					$slots &&
					entriesFetched &&
					!loading &&
					$slots.addCustomValue &&
					value.length > 0 &&
					(allowDuplicateCustomValues ||
						!suggestions.some(suggestion => suggestion.toLowerCase() === value.toLowerCase()))
				"
				class="cursor-pointer"
				@click.prevent.stop="doAddCustomValue"
			>
				<p class="text-color-blue underline m-0 p-0">
					<slot :value="value" name="addCustomValue"> "{{ value }}" </slot>
				</p>
			</li>
		</ul>
		<LazyErrorBox v-if="invalidValue" class="mt-4">
			Wähle einen Eintrag aus den Vorschlägen!
		</LazyErrorBox>
		<LazyErrorBox v-if="error" class="mt-4">
			Vorschläge können zurzeit nicht geladen werden. Bitte versuch es später noch einmal!
			{{ error }}
		</LazyErrorBox>
	</div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { delay, newDeferredPromise } from '../helpers/promise';
import LazyErrorBox from './ErrorBox.vue';
import LazySpinner from './Spinner.vue';

export default defineComponent({
	name: 'Autocomplete',
	components: {
		LazyErrorBox,
		LazySpinner
	},
	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	// eslint-disable-next-line no-undef
	async fetch(this: Autocomplete) {
		/*
		 * valueId & valueLabel
		 * if set, we return the property valueId (e.g. "val")
		 * for the valueLabel (e.g. "label") as result.
		 * e.g. { val: "1", label: 'x', name: 'h' }
		 * will return as value "1" if the search term (=valueLabel) is "x".
		 *
		 * to get this value for the initial loading state,
		 * we need to execute a search for the current
		 * search term, and find the valueId property of it.
		 *
		 * beware: this is ALSO executed on server side!
		 */
		if (this.value && this.valueId && this.valueLabel) {
			await this.loadEntries();
			this.values.some(testValue => {
				if (this.value === testValue[this.valueLabel]) {
					this.value = testValue[this.valueLabel];
					this.internalValueId = testValue[this.valueId];
					return true;
				}
				return false;
			});
		}
	},
	emits: ['enter', 'blur', 'click', 'focus', 'input', 'update:modelValue'],
	data() {
		const tt = undefined as any;
		const waitForClick = undefined as any;

		return {
			error: false,
			isOpen: false,
			internalValueId: null,
			current: -1,
			loading: false,
			entriesFetched: false,
			useSelection: false,
			values: [],
			invalidValue: false,
			selectedSuggestion: false,
			tt,
			waitForClick
		};
	},
	computed: {
		currentNumberOfSuggestions(): number {
			return this.values.length < this.maxNumberOfSuggestions
				? this.values.length
				: this.maxNumberOfSuggestions;
		},
		mustSelectComputed() {
			return this.mustSelect === true || this.mustSelect === false
				? this.mustSelect
				: !!this.valueLabel;
		},
		suggestions(): string[] {
			const values =
				(this.values?.length && this.values.slice(0, this.maxNumberOfSuggestions)) || [];

			// show all values, if user must select one of these
			if (this.valueLabel) {
				return values.map(v => v[this.valueLabel]);
			}
			return values;
		},
		valueRequired() {
			return this.$slots?.addCustomValue && this.isOpen && this.value.length > 0;
		},
		openSuggestion() {
			if (
				(this.minLength > 0 && !this.value) ||
				(this.value && this.value.length < this.minLength)
			) {
				return false;
			}
			return this.isOpen && (this.loading || (this.values && this.values.length !== 0));
		},
		myClasses() {
			const classes: string[] = [];
			if (this.isOpen || (this.value && this.value.length > 0)) {
				classes.push('input--filled');
			}

			if (this.isOpen) {
				classes.push('input--active');
			}

			if (this.invalidValue) {
				classes.push('has-error');
			}

			if (this.fullwidth) {
				classes.push('max-w-full');
			}

			return classes;
		},
		value: {
			get() {
				return this.modelValue;
			},
			set(value) {
				this.$emit('update:modelValue', value);
			}
		}
	},
	created() {
		if (this.id === 'autocomplete') {
			console.warn('Autocomplete without explicit id', this);
		}
	},
	methods: {
		input($event) {
			this.value = $event.target.value;
			if (!this.isOpen) {
				this.useSelection = true;
				this.isOpen = true;
			} else {
				const found = this.suggestions.indexOf(this.value);
				if (found !== -1 && !this.useSelection) {
					this.useSelection = true;
					this.current = found;
				} else {
					this.useSelection = false;
					this.current = -1;
				}
				if (this.$slots?.addCustomValue) {
					this.isOpen = true;
				}
			}
			this.selectedSuggestion = false;
			if (!this.mustSelectComputed) {
				this.processEvent($event); //  $emit('input', $event.target.value);
			}
		},
		async matches() {
			if (this.minLength > 0 && (!this.value || this.value.length < this.minLength)) {
				return [];
			}

			this.entriesFetched = true;
			return this.valuePromise(this.value);
		},
		async loadEntries() {
			this.loading = true;
			try {
				this.values = await this.matches();
				this.error = false;
			} catch (err: any) {
				console.error(err);
				this.error = err;
			} finally {
				this.loading = false;
			}
		},
		keyup(event) {
			if (!event.code || !event?.code?.includes('Arrow')) {
				let timeout = 100;
				if (this.tt) {
					clearTimeout(this.tt);
					timeout = 500;
				}
				this.tt = setTimeout(this.loadEntries, timeout);
			}
			// this.selectedSuggestion = false;
			this.invalidValue = false;
		},
		up() {
			if (this.current === -1) {
				this.current = this.currentNumberOfSuggestions - 1;
				this.useSelection = true;
			} else if (this.current === 0) {
				this.current -= 1;
				this.useSelection = false;
			} else {
				this.current -= 1;
				this.useSelection = true;
			}
		},
		down() {
			if (!this.values) {
				return;
			}
			if (this.current === this.currentNumberOfSuggestions - 1) {
				this.current = -1;
				this.useSelection = false;
			} else {
				this.current += 1;
				this.useSelection = true;
			}
		},
		isActive(index) {
			return index === this.current;
		},
		enter() {
			if (!this.values[this.current]) {
				this.current = 0;
			}

			if (!this.openSuggestion) {
				return;
			}

			if (this.useSelection && this.valueLabel && this.values[this.current]) {
				this.value = this.values[this.current][this.valueLabel];
				this.internalValueId = this.valueId
					? this.values[this.current][this.valueId]
					: this.values[this.current];
			} else if (
				(this.useSelection || this.mustSelectComputed) &&
				this.values &&
				this.values[this.current]
			) {
				this.value = this.values[this.current];
			}

			this.isOpen = false;
			this.selectedSuggestion = true;
			this.processEvent(); // $event);
			this.$emit(
				'enter',
				this.value,
				this.valueId ? { id: this.internalValueId } : this.internalValueId
			);

			setTimeout(() => this.$emit('blur'), 300);
			this.useSelection = false;
		},
		suggestionClick(value, index?: number) {
			if (this.valueLabel) {
				const val =
					index !== undefined
						? this.values[index]
						: this.values.find(entry => entry[this.valueLabel] === value);
				if (val) {
					this.value = val[this.valueLabel];
					this.internalValueId = this.valueId ? val[this.valueId] : val;
				} else if (this.$slots?.addCustomValue) {
					this.internalValueId = null;
					this.value = value;
				} else {
					// eslint-disable-next-line no-console
					console.log('value not found', value);
				}
			} else {
				this.value = value;
			}
			this.selectedSuggestion = true;
			this.isOpen = false;
			this.processEvent(undefined, value);
			this.$emit(
				'enter',
				this.value,
				this.valueId ? { id: this.internalValueId } : this.internalValueId
			);
		},
		open() {
			this.$emit('click');
			if (!this.isOpen) {
				this.useSelection = true;
				this.isOpen = true;
			}
			if (!this.values || this.values.length === 0) {
				this.loadEntries();
			}
		},
		focus() {
			if (!this.isOpen) {
				this.open();
			}
			this.$emit('focus');
		},
		async blur($event) {
			this.waitForClick = newDeferredPromise();
			await Promise.race([this.waitForClick?.promise, delay(300)]);

			// reset invalidValue if user "just looking"
			if (
				((!this.value || this.value.length === 0) && (!this.value || this.value.length === 0)) ||
				this.value === this.modelValue
			) {
				this.invalidValue = false;
			}

			if (!this.mustSelectComputed) {
				this.processEvent($event);
			} else if (
				this.mustSelectComputed &&
				!this.selectedSuggestion &&
				!this.$slots?.addCustomValue
			) {
				this.loadEntries().then(() => {
					const valid = this.values.some(testValue => {
						if (this.valueLabel && this.value === testValue[this.valueLabel]) {
							this.value = testValue[this.valueLabel];
							this.internalValueId = this.valueId ? testValue[this.valueId] : testValue;
							return true;
						}

						if (this.values && this.value === testValue) {
							this.value = testValue;
							return true;
						}

						return false;
					});

					if (!valid && !this.$slots?.addCustomValue && this.mustSelect) {
						this.invalidValue = true;
					}
				});
			}

			if (!this.$slots?.addCustomValue || (this.$slots?.addCustomValue && !this.value)) {
				this.isOpen = false;
			}

			this.useSelection = false;
			this.$emit('blur', $event);
		},
		processEvent($event?: Event, setValue?: string) {
			if (this.waitForClick) {
				this.waitForClick.resolve();
				this.waitForClick = undefined;
			}

			this.invalidValue = false;
			const params = this.valueId ? { id: this.internalValueId } : this.internalValueId;

			let value = '';
			if (setValue) {
				value = setValue;
			} else if (
				!this.mustSelectComputed &&
				!this.useSelection &&
				$event &&
				($event.target as any).value
			) {
				value = ($event.target as any).value;
			} else {
				value = this.value;
			}

			this.$emit('input', value, $event, params);
		},
		doAddCustomValue() {
			this.suggestionClick(this.value);
		},
		onValueChanged(newVal) {
			this.entriesFetched = false;
			this.value = newVal;
			if (newVal && newVal.length === 0) {
				this.isOpen = false;
			}
		}
	},
	props: {
		id: { type: String, default: 'autocomplete', required: true },
		valuePromise: { type: Function, required: true },
		required: { type: Boolean, default: false },
		modelValue: { type: String, default: '' },
		valueId: { type: String, default: '' },
		valueLabel: { type: String, default: '' },
		name: { type: String, default: '' },
		mustSelect: { type: Boolean, default: false },
		allowDuplicateCustomValues: { type: Boolean, default: true },
		fullwidth: {
			type: [String, Boolean],
			default: false,
			validator: (value: string | boolean) => [false, 'mobile', 'always'].includes(value)
		},
		resultFullWidth: { type: Boolean, default: false },
		autofocus: { type: Boolean, default: false },
		minLength: { type: Number, default: 2 },
		useOverlay: { type: Boolean, default: true },
		positionInitial: { type: Boolean, default: false },
		maxNumberOfSuggestions: { type: Number, default: 4 },
		disabled: { type: Boolean, default: false },
		placeholder: { type: String, default: '' }
	},
	watch: {
		value: [
			{
				handler: 'onValueChanged'
			}
		]
	}
});
</script>

<style scoped src="../styles/input.scss" />
<style lang="scss" scoped>
// eslint-disable-next-line vue-scoped-css/no-unused-selector
body.desktop {
	.autocomplete {
		width: 100%;
		position: absolute;
		background: white;
		z-index: 100;
		&.initial {
			position: initial;
		}
	}
}

.autocomplete-container {
	.max-w-400 {
		max-width: 400px;
	}

	.max-h-188 {
		/* exact height of 4 items */
		max-height: 188px;
	}

	ul {
		background: #ffffff;
		list-style: none;
		padding: 0;
	}

	li {
		border-bottom: 1px solid $color-grey-lightest;
		color: $color-text;
		padding: 0.75rem;
		margin: 0;

		&.active {
			background-color: $color-grey-lightest;
		}
	}
}

.value-required {
	.input__label,
	div.input {
		z-index: 4;
	}
	input {
		background: #ffffff;
	}
	.overlay {
		z-index: 2;
		background: rgba(0, 0, 0, 0.2);
		/* inset-0 not working */
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
	}
}
</style>
