<template>
	<div
		v-show="showDayView"
		:class="[calendarClass, 'vdp-datepicker__calendar']"
		:style="calendarStyle"
		@mousedown.prevent
	>
		<slot name="beforeCalendarHeader"></slot>
		<header>
			<span :class="{ disabled: isLeftNavDisabled }" class="prev" @click="previousMonth()"
				>&lt;</span
			>
			<span
				:class="allowedToShowView('month') ? 'up' : ''"
				class="day__month_btn"
				@click="showMonthCalendar"
				>{{ isYmd ? currYearName : currMonthName }} {{ isYmd ? currMonthName : currYearName }}</span
			>
			<span :class="{ disabled: isRightNavDisabled }" class="next" @click="nextMonth()">&gt;</span>
		</header>
		<div>
			<span v-for="d in daysOfWeek" :key="d.timestamp" class="cell day-header">{{ d }}</span>
			<template v-if="blankDays > 0">
				<span v-for="d in blankDays" :key="d.timestamp" class="cell day blank"></span> </template
			><!--
      --><span
				v-for="day in days"
				:key="day.timestamp"
				:class="dayClasses(day)"
				class="cell day"
				@click="selectDate(day)"
				v-html="dayCellContent(day)"
			></span>
		</div>
	</div>
</template>
<script>
import { makeDateUtils, langYearSuffix, ymdLangs } from './DateUtils';

export default {
	props: {
		showDayView: Boolean,
		selectedDate: Date,
		pageDate: Date,
		fullMonthName: Boolean,
		allowedToShowView: Function,
		dayCellContent: {
			type: Function,
			default: day => day.date
		},
		disabledDates: Object,
		highlighted: Object,
		calendarClass: [String, Object, Array],
		calendarStyle: Object,
		language: String,
		mondayFirst: Boolean,
		useUtc: Boolean
	},
	data() {
		const constructedDateUtils = makeDateUtils(this.useUtc, this.language);
		return {
			utils: constructedDateUtils
		};
	},
	watch: {
		language(newLanguage) {
			this.utils = makeDateUtils(this.useUtc, newLanguage);
		},
		useUtc(newUtc) {
			this.utils = makeDateUtils(newUtc, this.language);
		}
	},
	computed: {
		/**
		 * Returns an array of day names
		 *
		 * @returns {String[]}
		 */
		daysOfWeek() {
			return this.utils.getDaysOfWeek(this.mondayFirst);
		},
		/**
		 * Returns the day number of the week less one for the first of the current month Used to show
		 * amount of empty cells before the first in the day calendar layout
		 *
		 * @returns {Number}
		 */
		blankDays() {
			const d = this.pageDate;
			const dObj = this.useUtc
				? new Date(Date.UTC(d.getUTCFullYear(), d.getUTCMonth(), 1))
				: new Date(d.getFullYear(), d.getMonth(), 1, d.getHours(), d.getMinutes());
			if (this.mondayFirst) {
				return this.utils.getDay(dObj) > 0 ? this.utils.getDay(dObj) - 1 : 6;
			}
			return this.utils.getDay(dObj);
		},
		/**
		 * @returns {Object[]}
		 */
		days() {
			const d = this.pageDate;
			const days = [];
			// set up a new date object to the beginning of the current 'page'
			const dObj = this.useUtc
				? new Date(Date.UTC(d.getUTCFullYear(), d.getUTCMonth(), 1))
				: new Date(d.getFullYear(), d.getMonth(), 1, d.getHours(), d.getMinutes());
			const daysInMonth = this.utils.daysInMonth(
				this.utils.getFullYear(dObj),
				this.utils.getMonth(dObj)
			);
			for (let i = 0; i < daysInMonth; i++) {
				days.push({
					date: this.utils.getDate(dObj),
					timestamp: dObj.getTime(),
					isSelected: this.isSelectedDate(dObj),
					isDisabled: this.isDisabledDate(dObj),
					isHighlighted: this.isHighlightedDate(dObj),
					isHighlightStart: this.isHighlightStart(dObj),
					isHighlightEnd: this.isHighlightEnd(dObj),
					isToday: this.utils.compareDates(dObj, new Date()),
					isWeekend: this.utils.getDay(dObj) === 0 || this.utils.getDay(dObj) === 6,
					isSaturday: this.utils.getDay(dObj) === 6,
					isSunday: this.utils.getDay(dObj) === 0
				});
				this.utils.setDate(dObj, this.utils.getDate(dObj) + 1);
			}
			return days;
		},
		/**
		 * Gets the name of the month the current page is on
		 *
		 * @returns {String}
		 */
		currMonthName() {
			return this.utils.getMonthNameAbbr(this.pageDate);
		},
		/**
		 * Gets the name of the year that current page is on
		 *
		 * @returns {Number}
		 */
		currYearName() {
			const yearSuffix = langYearSuffix[this.language] || '';
			return `${this.utils.getFullYear(this.pageDate)}${yearSuffix}`;
		},
		/**
		 * Is this language using year/month/day format?
		 *
		 * @returns {Boolean}
		 */
		isYmd() {
			return ymdLangs.includes(this.language);
		},
		/**
		 * Is the left hand navigation button disabled?
		 *
		 * @returns {Boolean}
		 */
		isLeftNavDisabled() {
			return this.isPreviousMonthDisabled();
		},
		/**
		 * Is the right hand navigation button disabled?
		 *
		 * @returns {Boolean}
		 */
		isRightNavDisabled() {
			return this.isNextMonthDisabled();
		}
	},
	methods: {
		// eslint-disable-next-line consistent-return
		selectDate(date) {
			if (date.isDisabled) {
				this.$emit('selected-disabled', date);
				return false;
			}
			this.$emit('select-date', date);
		},
		/**
		 * @returns {Number}
		 */
		getPageMonth() {
			return this.utils.getMonth(this.pageDate);
		},
		/**
		 * Emit an event to show the month picker
		 */
		showMonthCalendar() {
			this.$emit('show-month-calendar');
		},
		/**
		 * Change the page month
		 *
		 * @param {Number} incrementBy
		 */
		changeMonth(incrementBy) {
			const date = this.pageDate;
			this.utils.setMonth(date, this.utils.getMonth(date) + incrementBy);
			this.$emit('changed-month', date);
		},
		/**
		 * Decrement the page month
		 */
		previousMonth() {
			if (!this.isPreviousMonthDisabled()) {
				this.changeMonth(-1);
			}
		},
		/**
		 * Is the previous month disabled?
		 *
		 * @returns {Boolean}
		 */
		isPreviousMonthDisabled() {
			if (!this.disabledDates || !this.disabledDates.to) {
				return false;
			}
			const d = this.pageDate;
			return (
				this.utils.getMonth(this.disabledDates.to) >= this.utils.getMonth(d) &&
				this.utils.getFullYear(this.disabledDates.to) >= this.utils.getFullYear(d)
			);
		},
		/**
		 * Increment the current page month
		 */
		nextMonth() {
			if (!this.isNextMonthDisabled()) {
				this.changeMonth(+1);
			}
		},
		/**
		 * Is the next month disabled?
		 *
		 * @returns {Boolean}
		 */
		isNextMonthDisabled() {
			if (!this.disabledDates || !this.disabledDates.from) {
				return false;
			}
			const d = this.pageDate;
			return (
				this.utils.getMonth(this.disabledDates.from) <= this.utils.getMonth(d) &&
				this.utils.getFullYear(this.disabledDates.from) <= this.utils.getFullYear(d)
			);
		},
		/**
		 * Whether a day is selected
		 *
		 * @param {Date}
		 * @returns {Boolean}
		 */
		isSelectedDate(dObj) {
			return this.selectedDate && this.utils.compareDates(this.selectedDate, dObj);
		},
		/**
		 * Whether a day is disabled
		 *
		 * @param {Date}
		 * @returns {Boolean}
		 */
		isDisabledDate(date) {
			let disabledDates = false;

			if (typeof this.disabledDates === 'undefined') {
				return false;
			}

			if (typeof this.disabledDates.dates !== 'undefined') {
				// eslint-disable-next-line consistent-return
				this.disabledDates.dates.forEach(d => {
					if (this.utils.compareDates(date, d)) {
						disabledDates = true;
						return true;
					}
				});
			}
			if (
				typeof this.disabledDates.to !== 'undefined' &&
				this.disabledDates.to &&
				date < this.disabledDates.to
			) {
				disabledDates = true;
			}
			if (
				typeof this.disabledDates.from !== 'undefined' &&
				this.disabledDates.from &&
				date > this.disabledDates.from
			) {
				disabledDates = true;
			}
			if (typeof this.disabledDates.ranges !== 'undefined') {
				// eslint-disable-next-line consistent-return
				this.disabledDates.ranges.forEach(range => {
					if (
						typeof range.from !== 'undefined' &&
						range.from &&
						typeof range.to !== 'undefined' &&
						range.to
					) {
						if (date < range.to && date > range.from) {
							disabledDates = true;
							return true;
						}
					}
				});
			}
			if (
				typeof this.disabledDates.days !== 'undefined' &&
				this.disabledDates.days.includes(this.utils.getDay(date))
			) {
				disabledDates = true;
			}
			if (
				typeof this.disabledDates.daysOfMonth !== 'undefined' &&
				this.disabledDates.daysOfMonth.includes(this.utils.getDate(date))
			) {
				disabledDates = true;
			}
			if (
				typeof this.disabledDates.customPredictor === 'function' &&
				this.disabledDates.customPredictor(date)
			) {
				disabledDates = true;
			}
			return disabledDates;
		},
		/**
		 * Whether a day is highlighted (only if it is not disabled already except when
		 * highlighted.includeDisabled is true)
		 *
		 * @param {Date}
		 * @returns {Boolean}
		 */
		isHighlightedDate(date) {
			if (!(this.highlighted && this.highlighted.includeDisabled) && this.isDisabledDate(date)) {
				return false;
			}

			let highlighted = false;

			if (typeof this.highlighted === 'undefined') {
				return false;
			}

			if (typeof this.highlighted.dates !== 'undefined') {
				// eslint-disable-next-line consistent-return
				this.highlighted.dates.forEach(d => {
					if (this.utils.compareDates(date, d)) {
						highlighted = true;
						return true;
					}
				});
			}

			if (this.isDefined(this.highlighted.from) && this.isDefined(this.highlighted.to)) {
				highlighted = date >= this.highlighted.from && date <= this.highlighted.to;
			}

			if (
				typeof this.highlighted.days !== 'undefined' &&
				this.highlighted.days.includes(this.utils.getDay(date))
			) {
				highlighted = true;
			}

			if (
				typeof this.highlighted.daysOfMonth !== 'undefined' &&
				this.highlighted.daysOfMonth.includes(this.utils.getDate(date))
			) {
				highlighted = true;
			}

			if (
				typeof this.highlighted.customPredictor === 'function' &&
				this.highlighted.customPredictor(date)
			) {
				highlighted = true;
			}

			return highlighted;
		},
		dayClasses(day) {
			return {
				selected: day.isSelected,
				disabled: day.isDisabled,
				highlighted: day.isHighlighted,
				today: day.isToday,
				weekend: day.isWeekend,
				sat: day.isSaturday,
				sun: day.isSunday,
				'highlight-start': day.isHighlightStart,
				'highlight-end': day.isHighlightEnd
			};
		},
		/**
		 * Whether a day is highlighted and it is the first date in the highlighted range of dates
		 *
		 * @param {Date}
		 * @returns {Boolean}
		 */
		isHighlightStart(date) {
			return (
				this.isHighlightedDate(date) &&
				this.highlighted.from &&
				this.highlighted.from instanceof Date &&
				this.utils.getFullYear(this.highlighted.from) === this.utils.getFullYear(date) &&
				this.utils.getMonth(this.highlighted.from) === this.utils.getMonth(date) &&
				this.utils.getDate(this.highlighted.from) === this.utils.getDate(date)
			);
		},
		/**
		 * Whether a day is highlighted and it is the first date in the highlighted range of dates
		 *
		 * @param {Date}
		 * @returns {Boolean}
		 */
		isHighlightEnd(date) {
			return (
				this.isHighlightedDate(date) &&
				this.highlighted.to &&
				this.highlighted.to instanceof Date &&
				this.utils.getFullYear(this.highlighted.to) === this.utils.getFullYear(date) &&
				this.utils.getMonth(this.highlighted.to) === this.utils.getMonth(date) &&
				this.utils.getDate(this.highlighted.to) === this.utils.getDate(date)
			);
		},
		/**
		 * Helper
		 *
		 * @param {mixed} prop
		 * @returns {Boolean}
		 */
		isDefined(prop) {
			return typeof prop !== 'undefined' && prop;
		}
	}
};
// eslint-disable-next-line
</script>
