<template>
	<main class="match-detail">
		<div
			v-if="
				(match && match.likeuser === 'saved') ||
				(match && match.likeuser === 'needreview') ||
				(match && match.likecompany && match.likeuser !== 'true')
			"
			class="tabs"
		>
			<Tabs
				v-if="match && match.relation.type === 'job'"
				:options="{ defaultTabHash: showTab }"
				:name="`match-${match.relation.obj._id}`"
				:reset-tabs="true"
				:align-center="true"
				@changed="activateCompanyPage"
			>
				<Tab id="job" name="Job">
					<JobDetail
						class="container max-w-5xl px-0"
						:job="match.relation.obj"
						single-job-view
						show-delete-button
						is-pwa
						:logged-in="loggedIn"
						:gnc-calculation="gncCalculation"
						:match="match"
						:user-image="userImage"
						@delete-job="deleteMatchMethod"
						@send-job="shareJob(match.relation.obj)"
						@setup-jobalarm="openJobAlarmPage"
						@send-gnc="calcGnc"
						@go-to-jobsearch="openJobsearch"
						@acts-invite="activeSourcingInvite"
						@show-map-dropdown="trackCommute('dropdown')"
						@show-map-image="trackCommute('image')"
					/>
				</Tab>
				<Tab id="company-scroll-container" name="Unternehmen">
					<CompanyPageWrapper
						v-if="companyId"
						:company-id="companyId"
						:load-instantly="showTab === 'company-scroll-container'"
						@activate-jobalarm="activateJobAlarm"
					/>
				</Tab>
			</Tabs>
			<CompanyPageWrapper
				v-else-if="companyId"
				:company-id="companyId"
				@activate-jobalarm="activateJobAlarm"
			/>
		</div>
		<MatchTabs
			v-else-if="match"
			:match="match"
			:company-id="companyId"
			:show-tab="showTab"
			:logged-in="loggedIn"
			:active-sourcing-mode="activeSourcingMode"
			@setup-company-jobalarm="activateJobAlarm"
			@setup-jobalarm="openJobAlarmPage"
		/>
		<!--confirm privacy-->
		<HokModal :adaptive="!!$isMobile.any" name="accept-privacy-application">
			<div class="mb-4">
				<h3 class="text-center">Datenschutzbestimmungen</h3>
				<HokCheckbox
					id="privacy_user_application_modal"
					v-model="acceptedPrivacy"
					name="privacy_user_application"
				>
					Hiermit stimme ich dem Versand meiner Daten an Firmen, bei denen ich mich bewerbe, zu.
					<template #more>
						<HokLink to="/privacy#user-application" target="_blank" class="text-sm block">
							Datenschutzerklärung & Widerrufsrecht
						</HokLink>
					</template>
				</HokCheckbox>
			</div>
			<div class="flex justify-center">
				<HokButton
					fullwidth="mobile"
					class="mb-4"
					color="main"
					:disabled="!acceptedPrivacy"
					@click="applyDSGVO"
				>
					Fortfahren
				</HokButton>
			</div>
		</HokModal>

		<HokModal name="more-info-modal">
			<h4 class="container">Mehr Informationen anfordern</h4>
			<MoreInfoModal
				@request-more-info="
					(messagingTag, message) => {
						if (match) {
							requestMoreInfo(messagingTag, message, match.relation, match._id);
						}
					}
				"
			/>
		</HokModal>
	</main>
</template>

<script lang="ts">
import Tabs from '@hokify/shared-components-nuxt3/lib/components/Tabs.vue';
import Tab from '@hokify/shared-components-nuxt3/lib/components/Tab.vue';
import JobDetail from '@hokify/shared-components-nuxt3/lib/components/JobDetail.vue';
import { EventBus } from '@hokify/shared-components-nuxt3/lib/eventbus';
import type {
	IAPIMatchForJobSeeker,
	APITypeObjectId,
	APIObjectType,
	IAPIJobFilter,
	IAPILoginUser,
	IAPIJobForUser,
	MessagingTags,
	APIMatchRelation,
	IAPIGrossNetCalculation
} from '@hokify/common';
import { isNotAuthenticatedError } from '@hokify/pwa-core-nuxt3/errors/NotAuthenticatedError';
import { shareJob } from '@hokify/pwa-core-nuxt3/helpers/sharejob';
import { PrivacyType } from '@hokify/shared-components-nuxt3/lib/types/privacyType';
import HokCheckbox from '@hokify/shared-components-nuxt3/lib/components/HokCheckbox.vue';
import { mapStores } from 'pinia';
import { markRaw, defineAsyncComponent } from 'vue';
import { defineNuxtComponent, definePageMeta, useRoute } from '#imports';
import CompanyPageWrapper from '@/components/user/CompanyPageWrapper.vue';
import MatchTabs from '@/components/user/match/MatchTabs.vue';
import MoreInfoModal from '@/components/MoreInfoModal.vue';
import { useGncStore } from '@/stores/gnc';
import { useMatchesStore } from '@/stores/matches';
import { useMessagesStore } from '@/stores/messages';
import { useUserProfileStore } from '@/stores/user-profile';
import { useRelationsStore } from '@/stores/relations';
import { useUserJobFilterStore } from '@/stores/user-job-filter';
import { useUserJobAlarmStore } from '@/stores/user-job-alarm';
import { useValuesStore } from '@/stores/values';
import { useLoginStore } from '@/stores/login';

export default defineNuxtComponent({
	setup() {
		definePageMeta({
			layout: 'user',
			path: '/pwa/match/:id?',
			middleware: ['auth']
		});
	},
	components: {
		MatchTabs,
		JobDetail,
		CompanyPageWrapper,
		Tab,
		Tabs,
		MoreInfoModal,
		HokCheckbox
	},
	async asyncData({ $pinia }) {
		const { params, query } = useRoute();

		let matchId;

		try {
			const matchesStore = useMatchesStore($pinia);
			const valuesStore = useValuesStore($pinia);
			const relationsStore = useRelationsStore($pinia);

			if (params?.id) {
				matchId = params.id;
			} else if (relationsStore?.numberOrId?.idOrNr) {
				matchId = relationsStore?.numberOrId.idOrNr;
			}

			const match: IAPIMatchForJobSeeker | undefined = await matchesStore.getUserMatch(matchId);
			const jobFields = await valuesStore.getJobFields();
			let companyId: APITypeObjectId<APIObjectType.Company> | undefined;

			if (match?.relation.type === 'job') {
				companyId = match.relation.obj.company._id;
			} else if (match?.relation.type === 'company') {
				companyId = match?.relation.obj._id;
			}
			return {
				match,
				companyId,
				showTab: query.showTab,
				jobFields
			};
		} catch (err: any) {
			if (err.response) {
				throw createError({
					statusCode: err.response.status,
					message:
						err.response.data && err.response.data.code
							? `${err.response.data.code}: ${err.response.data.msg || err.response.data.message}`
							: `Es ist ein Fehler aufgetreten${
									err.response.data ? ` (${JSON.stringify(err.response.data)})` : ''
								}`
				});
			} else {
				throw createError(err);
			}
		}
	},
	data() {
		const gncCalculation = undefined as IAPIGrossNetCalculation | undefined;
		const match = undefined as undefined | IAPIMatchForJobSeeker;
		const jobFields = undefined as any;
		const jobTrackingObject = undefined as any;
		const privacyError = false;
		const acceptedPrivacy = false;
		const showTab = undefined as string | undefined;
		const companyId = '';

		return {
			gncCalculation,
			match,
			jobFields,
			jobTrackingObject,
			privacyError,
			acceptedPrivacy,
			shareJob,
			showTab,
			companyId,
			EventBus
		};
	},
	computed: {
		user(): IAPILoginUser | undefined {
			return this.userProfileStore?.obj;
		},
		loggedIn() {
			return this.loginStore.loggedIn;
		},
		acceptedPrivacyApplication(): boolean {
			return !!this.userProfileStore.obj?.privacy?.user_application;
		},
		userImage(): string | undefined {
			return this.userProfileStore.obj?.images?.small;
		},
		activeSourcingMode(): boolean {
			return !!(this.loggedIn && this.match?.likecompany);
		},
		getJobFilter(): IAPIJobFilter | undefined {
			return this.userProfileStore?.obj?.jobFilter;
		},
		...mapStores(
			useGncStore,
			useMatchesStore,
			useMessagesStore,
			useRelationsStore,
			useUserProfileStore,
			useUserJobFilterStore,
			useUserJobAlarmStore,
			useLoginStore,
			useValuesStore
		)
	},
	/*	mounted() {
		// send application-related tracking events when user
		if (
			this.$route.query?.reverseApply === 'accepted-deeplink' &&
			this.match?.relation?.type === 'job'
		) {
			const job = this.match.relation.obj;
			const trackingObject = {
				matchId: this.match._id,
				job: {
					name: job?.name,
					jobNr: job?.jobNr,
					_id: job?._id,
					jobfields: job?.fields.map(f => f.name),
					type: job?.type,
					priorize: job?.internal.trackingValue || 1,
					country: job?.location?.countryCode?.replace(/\W/g, '').toUpperCase(),
					city: job?.location?.city || undefined
				},
				category: 'website-jobdetail',
				relationId: job._id,
				item_type: 'job',
				application_type: 'active-sourcing'
			};
			this.$trackUserEvent?.('start_application', {
				...trackingObject,
				is_logged_in: this.loginStore.loggedIn ? 1 : 0
			});
			this.$trackUserEvent?.('send_application', trackingObject); *!/
		}
	}, */
	beforeUnmount() {
		this.EventBus.$off('set-job-filter');
		this.EventBus.$off('request-more-info');
	},
	methods: {
		activateCompanyPage({ tab }) {
			if (tab.id === 'company-scroll-container') {
				// make sure to update carousel when switching to a company page, otherwise width calculation would be wrong
				this.EventBus.$emit('page-close-observed');
				// make sure to load company details
				this.EventBus.$emit('load-company-details');
			}
		},
		async activateJobAlarm() {
			let company;
			if (this.match) {
				if (this.match.relation.type === 'job') {
					company = this.match.relation.obj.company;
				} else if (this.match.relation.type === 'company') {
					company = this.match.relation.obj;
				}
			}

			try {
				await this.userJobAlarmStore.setJobAlarm({ filters: [`company-${company.alias}`] });

				this.$snack.success({
					text: `Jobalarm für ${company.name} erstellt.`,
					button: 'Bearbeiten',
					action: () => {
						this.$router.push({ path: '/pwa/joblist/jobalarm' });
					}
				});
				this.$trackUserEvent?.('activate_jobalarm_completed', { pageElement: 'job-saved' });
			} catch (err: any) {
				if (isNotAuthenticatedError(err)) {
					this.$snack.danger({
						text: 'Du musst angemeldet sein, um einen Jobalarm anlegen zu können.',
						button: 'Anmelden',
						action: () => {
							this.$router.push({ path: '/pwa/login' });
						}
					});
					return;
				}
				this.$nuxt.$errorHandler(
					err,
					'Beim setzen deines Jobalarms ist ein Fehler aufgetreten. Bitte versuch es später noch einmal.'
				);
			}
		},
		async deleteMatchMethod() {
			// case saved jobs
			try {
				await this.matchesStore.deleteMatch({ match: this.match });
				this.$router.go(-1);
				this.$snack.danger({
					text: `Der gespeicherte Job "${this.match?.relation.obj.name}" wurde gelöscht!`,
					button: 'Rückgängig',
					action: () => {
						this.relationsStore.saveRelation(this.match?.relation);
					}
				});
			} catch (err: any) {
				this.$nuxt.$errorHandler(
					err,
					'Deine Änderungen konnten nicht übernommen werden. Bitte probiere es etwas später erneut.'
				);
			}
		},
		async openJobAlarmPage() {
			if (!this.jobFields.length) {
				this.jobFields = await this.valuesStore.getJobFields();
			}
			const jobAlarmPage = markRaw(
				defineAsyncComponent(
					() => import('@hokify/shared-components-nuxt3/lib/pages/jobAlarmPage.vue')
				)
			);
			try {
				await this.$page.push(
					jobAlarmPage,
					{
						selected: this.getJobFilter,
						jobFields: this.jobFields,
						getPossibleFilter: this.userJobFilterStore.getPossibleFilter,
						user: this.user
					},
					{
						pageTitle: 'Jobalarm einstellen',
						name: 'set-job-alarm',
						done: () => {
							this.EventBus.$off('set-job-filter');
						}
					}
				);
				this.EventBus.$on('set-job-filter', async jobFilterObj => {
					try {
						await this.userJobAlarmStore.setJobAlarm(jobFilterObj);
						this.$router.push({ path: '/pwa/joblist/jobalarm#jobalarm' });
						this.$snack.success({
							title: 'Jobalarm erstellt!',
							text: 'Du bekommst ab jetzt eine Mitteilung, wenn wir einen neuen Job für dich haben, der zu deinem Filter passt.'
						});
						this.$trackUserEvent?.('activate_jobalarm_completed', {
							pageElement: 'job-alarm-list'
						});
					} catch (err: any) {
						console.error('setJobalarm failed', err);
						this.$nuxt.$errorHandler(
							err,
							'Beim setzen deines Jobalarms ist ein Fehler aufgetreten. Bitte versuch es später noch einmal.'
						);
					}
				});
			} catch (err) {
				this.$nuxt.$errorHandler(err);
			}
		},
		async calcGnc(gnc) {
			try {
				this.gncCalculation = await this.gncStore.sendGnc({
					...gnc,
					locationUrl: 'job-detail-inside-saved-match'
				});
			} catch (err: any) {
				this.$nuxt.$errorHandler(err);
			}
		},
		openJobsearch() {
			this.$router.push('/pwa');
			this.$trackUserEvent?.('click_redirect', { url: '/pwa' });
		},
		async activeSourcingInvite(interaction: 'accepted' | 'declined' | 'more-info') {
			if (!this.acceptedPrivacyApplication) {
				this.$modal.show('accept-privacy-application');
				return;
			}
			try {
				await this.processInviteInteraction(
					interaction,
					this.match?._id,
					this.match?.relation.obj as IAPIJobForUser
				);
			} catch (err: any) {
				this.$nuxt.$errorHandler(err);
			}
		},
		trackCommute(type: 'dropdown' | 'image') {
			this.$trackUserEvent?.('click_commute_element', {
				pageElement: type
			});
		},
		async applyDSGVO() {
			try {
				await this.userProfileStore.acceptPrivacy(PrivacyType.UserApplication);
				this.$modal.hide('accept-privacy-application');
			} catch (err: any) {
				this.$nuxt.$errorHandler(err);
			}
		},
		async processInviteInteraction(
			interaction: 'accepted' | 'declined' | 'more-info',
			matchId: APITypeObjectId<APIObjectType.Match> | undefined,
			job: IAPIJobForUser
		) {
			if (!matchId) {
				throw new Error('no match id provided');
			}
			this.jobTrackingObject = {
				name: job?.name,
				jobNr: job?.jobNr,
				_id: job?._id,
				jobfields: job?.fields?.map(f => f.name),
				type: job?.type,
				priorize: job?.internal.trackingValue || 1,
				country: job?.location?.countryCode?.replace(/\W/g, '').toUpperCase(),
				city: job?.location?.city || undefined
			};

			if (interaction !== 'declined' && job.activeSourcingApplicationTarget === 'chat') {
				this.$trackUserEvent?.('start_application', {
					matchId,
					job: this.jobTrackingObject,
					category: 'app-jobdetail',
					relationId: job._id,
					item_type: 'job',
					is_logged_in: this.loginStore.loggedIn ? 1 : 0,
					application_type: 'active-sourcing'
				});
			}

			this.$trackUserEvent?.('active_sourcing_reverse_apply', { interaction });
			if (interaction === 'accepted') {
				try {
					switch (job.activeSourcingApplicationTarget) {
						case 'chat':
							await this.relationsStore.applyToRelation({ relation: { type: 'job', obj: job } });
							this.$trackUserEvent?.('send_application', {
								matchId,
								job: this.jobTrackingObject,
								relationId: job?._id,
								item_type: 'job',
								category: 'app-jobdetail',
								application_type: 'active-sourcing'
							});
							this.$router.push(`/pwa/match/${matchId}?reverseApply=accepted`);
							break;
						case 'application':
							this.$router.push(`/apply/${job.jobNr}`);
							break;
						default:
							throw new Error(
								`invalid activeSourcingApplicationTarget: ${job.activeSourcingApplicationTarget}`
							);
					}
				} catch (err: any) {
					if (err.response?.data?.code === 'PRIVACY_NOT_ACCEPTED') {
						this.$modal.show('accept-privacy-application');
					}
				}
			} else if (interaction === 'declined') {
				try {
					await this.relationsStore.discardRelation({
						type: 'matchrelation',
						relation: { type: 'job', obj: job }
					});
					this.$snack.success({
						text: 'Das Unternehmen wurde über dein Nicht-Interesse informiert.'
					});
					this.matchesStore.removeMatch({ _id: matchId });
					this.$page.goBack();
				} catch (err) {
					throw new Error('Entscheidung konnte nicht gespeichert werden!');
				}
			} else if (interaction === 'more-info') {
				if (this.$isMobile.any) {
					const modal = markRaw(
						defineAsyncComponent(() => import('@/components/MoreInfoModal.vue'))
					);
					try {
						await this.$page.push(modal, this.$props, {
							modalHeading: 'Mehr Informationen anfordern',
							mode: 'modal',
							name: 'hok-moreinfo-modal',
							done: () => {
								this.EventBus.$emit('done-more-info');
								this.EventBus.$off('request-more-info');
							}
						});
						this.EventBus.$on(
							'request-more-info',
							async (obj: { req: MessagingTags; msg: string }) => {
								await this.requestMoreInfo(obj.req, obj.msg, { type: 'job', obj: job }, matchId);
							}
						);
					} catch (err) {
						this.$nuxt.$errorHandler(err);
					}
				} else {
					this.$modal.show('more-info-modal');
				}
			} else {
				throw new Error('interaction type missing');
			}
		},
		async requestMoreInfo(
			messagingTag: MessagingTags,
			message: string,
			relation: APIMatchRelation,
			matchId: APITypeObjectId<APIObjectType.Match>
		) {
			try {
				// we cannot Promise.all these requests because there needs to be a specific order in chat messages
				await this.relationsStore.applyToRelation({ relation });
				await this.messagesStore.sendMsg({
					conversationTypeId: matchId,
					conversationType: 0,
					message,
					messagingTag
				});
				this.$trackUserEvent?.('send_application', {
					matchId,
					job: this.jobTrackingObject,
					relationId: this.jobTrackingObject._id,
					item_type: 'job',
					category: 'app-jobdetail',
					application_type: 'active-sourcing'
				});
				this.$router.push(`/pwa/match/${matchId}?reverseApply=moreInfo`);
			} catch (err: any) {
				this.$nuxt.$errorHandler(err, 'Anfrage konnte nicht versendet werden');
			}
		}
	}
});
</script>
