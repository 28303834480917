<template>
	<div class="flex flex-col overflow-y-auto h-full"><slot /></div>
</template>
<script lang="ts">
import { defineComponent } from 'vue';
import type { PropType } from 'vue';
import { EventBus } from '../../eventbus';

export default defineComponent({
	name: 'InsidePage',
	data() {
		const oldTitle = '';
		const internalIndex = 0;
		return { oldTitle, internalIndex, EventBus };
	},
	mounted() {
		this.internalIndex = this.currentIndex;
		this.oldTitle = document.title;
		document.title = this.pageTitles[this.internalIndex];
		this.EventBus.$on('inside-page-closed', this.insidePageClosedEvent);
	},
	beforeUnmount() {
		this.EventBus.$off('inside-page-closed');
	},
	methods: {
		insidePageClosedEvent() {
			this.internalIndex -= 1;
			document.title = this.pageTitles[this.internalIndex];
		}
	},
	props: {
		pageTitle: { type: String, default: 'hokify - deine mobile Job-Plattform' },
		currentIndex: { type: Number, required: true, default: 0 },
		pageTitles: { type: Array as PropType<string[]>, required: true, default: () => [] }
	}
});
</script>
