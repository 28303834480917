import validate from "/home/runner/work/hokify/hokify/node_modules/.pnpm/nuxt@3.15.1_@types+node@20.17.23_eslint@8.57.1_sass-embedded@1.85.1_tsx@4.19.3_typescript@5.7.3/node_modules/nuxt/dist/pages/runtime/validate.js";
import _01_45domain_45global from "/home/runner/work/hokify/hokify/apps/user-app/src/middleware/01.domain.global.ts";
import _02_45public_45images_45global from "/home/runner/work/hokify/hokify/apps/user-app/src/middleware/02.public-images.global.ts";
import manifest_45route_45rule from "/home/runner/work/hokify/hokify/node_modules/.pnpm/nuxt@3.15.1_@types+node@20.17.23_eslint@8.57.1_sass-embedded@1.85.1_tsx@4.19.3_typescript@5.7.3/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  _01_45domain_45global,
  _02_45public_45images_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  auth: () => import("/home/runner/work/hokify/hokify/apps/user-app/src/middleware/auth.ts"),
  "update-ostats": () => import("/home/runner/work/hokify/hokify/apps/user-app/src/middleware/updateOstats.ts")
}