import { joinURL } from 'ufo';
import type { ProviderGetImage } from '@nuxt/image';

const webAssetRegex = /^https?:\/\/.*$/;

export const getImage: ProviderGetImage = src => {
	const {
		public: { publicPath }
	} = useRuntimeConfig();

	// TODO optimize images on demand here with $img or own optimizer
	// check modifiers and add to request

	const calculatedSrc = src.match(webAssetRegex) ? src : joinURL(publicPath, src);

	return {
		url: calculatedSrc
	};
};
