import type { APITypeObjectId } from '@hokify/common';
import { isObjectId } from '@hokify/shared-components-nuxt3/lib/helpers/utility';

const numberRegEx = /^\d+$/;

function isValidIntegerNumber(number: any): boolean {
	return parseInt(number, 10) === number || numberRegEx.test(number);
}

export function setPropByPath(obj, propertyPath, value) {
	if (!propertyPath) {
		return;
	}

	const props = propertyPath.split('.');
	let candidate;
	let parentObj = obj;

	for (let i = 0; i < props.length; i += 1) {
		let prop = props[i];

		let indexValue;
		let found = -1;
		let index: number | APITypeObjectId<any> | undefined;

		// cut away array brackets
		let elementType: 'object' | 'array' = 'object';
		if (prop.includes('[') && prop.includes(']')) {
			elementType = 'array';
			indexValue = prop.substr(prop.indexOf('[') + 1, prop.indexOf(']') - prop.indexOf('[') - 1);

			index =
				(isValidIntegerNumber(indexValue) && parseInt(indexValue, 10)) ||
				(isObjectId(indexValue) && indexValue) ||
				undefined;

			if (indexValue && index === undefined) {
				throw new Error(`invalid index value for ${prop}`);
			}
			prop = prop.substr(0, prop.indexOf('['));
		}

		if (!obj) {
			break;
		}

		// create object path if it does not exists
		if (!obj[prop] && i < props.length) {
			obj[prop] = elementType === 'array' ? [] : {};
		}

		parentObj = obj;
		candidate = obj[prop];

		if (candidate !== undefined) {
			if (index === undefined) {
				obj = candidate;
			} else if (typeof index === 'number') {
				obj = candidate[index];
			} else if (index) {
				obj = candidate.find(c => index && c._id.toString() === index.toString());
				found = candidate.indexOf(obj);
			}
		}
		// we are at the end
		if (i === props.length - 1) {
			if (value && elementType === 'array') {
				const completeValue =
					value && typeof value === 'object' ? { _id: indexValue, ...value } : value;
				if (found >= 0) {
					candidate[found] = completeValue;
				} else {
					candidate?.push(completeValue);
				}
			} else if (found >= 0) {
				if (candidate !== null) {
					candidate.splice(found, 1);
				}
			} else if (value === null) {
				parentObj[prop] = undefined;
			} else {
				parentObj[prop] = value;
			}
		}
	}
}

export const updateElements = (obj, userUpdate): void => {
	Object.keys(userUpdate).forEach(path => {
		setPropByPath(obj, path, userUpdate[path]);
	});
};
