<template>
	<div :class="myClasses" class="input input--sae mb-4">
		<label v-if="$slots.default" :for="id" class="input__label input__label--sae">
			<span class="input__label-content input__label-content--sae">
				<slot />
				<span v-if="required" :class="{ required: !value }">*</span>
			</span>
		</label>
		<!-- auto format seems buggy, needs to be set to 'undefined' instead of 'false' to work correctly -->
		<VueTelInput
			v-model="value"
			mode="international"
			:auto-format="isPhone(value) || phoneOnly || undefined"
			:input-options="inputOptions"
			:preferred-countries="preferredCountries"
			:default-country="myTLD"
			:disabled="disabled"
			:style-classes="[
				'hok-tel-input',
				{ empty: !value || (value && value.length <= 0) },
				{ 'is-phone': isPhone(value) || phoneOnly }
			]"
			@blur.stop="
				focused = false;
				$emit('blur', $event);
			"
			@focus.stop="
				focused = true;
				$emit('focus', $event);
			"
			@click.stop="$emit('click', $event)"
		></VueTelInput>
	</div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { VueTelInput } from 'vue-tel-input';
import 'vue-tel-input/vue-tel-input.css';

// TODO v-focus would work again like this but we want to check if we still need it and otherwise just remove it.
// set autofocus via JS for browsers which doesn't support HTML5 attr. focus or when page is not loaded
/* const focus = {
	mounted: (el, binding, vnode) => {
		const $iPhone = vnode?.context?.$isMobile?.apple?.device;
		if (!binding || $iPhone) {
			return;
		}
		if (vnode?.context?.autofocus && !el.gotFocused) {
			vnode.context.$emit('focus');

			setTimeout(() => {
				el.gotFocused = true;
				vnode.context.$children?.[0]?.$el?.querySelector('input')?.focus();
				vnode.context.$children?.[0]?.$emit('focus');
			}, 300); // by default wait 300ms, in case there is any transition ongoing (max 300 ms)
		}
	}
}; */

export default defineComponent({
	name: 'EmailOrPhone',
	components: { VueTelInput },
	emits: ['focus', 'click', 'blur', 'update:modelValue'],
	/* directives: { focus }, */
	data() {
		let myTLD = 'at';

		const topLevelDomain = this.$nuxt?.$userRootStore?.topLevelDomain || 'at';

		// if TLD is available in store, use it and overwrite the country fetched via IP
		if (topLevelDomain) {
			myTLD = topLevelDomain;
		}

		const inputOptions = {
			name: this.name,
			type: this.phoneOnly ? 'tel' : 'text',
			autocomplete: !this.browserAutocomplete ? 'off' : this.browserAutocomplete,
			placeholder: '',
			id: this.id,
			maxlength: this.maxLength,
			required: this.required,
			styleClasses: {
				'typeform-input': this.styling === 'typeform',
				'text-center': this.styling === 'text-center',
				'cursor-not-allowed': this.disabled
			}
		};

		return {
			inputOptions,
			myTLD,
			focused: false,
			preferredCountries: [
				'at',
				'de',
				'ch',
				'by',
				'be',
				'ba',
				'io',
				'vg',
				'bg',
				'hr',
				'cy',
				'cz',
				'dk',
				'ee',
				'fi',
				'fr',
				'gr',
				'hu',
				'ie',
				'it',
				'lv',
				'li',
				'lt',
				'lu',
				'mk',
				'mt',
				'md',
				'mc',
				'me',
				'nl',
				'no',
				'pl',
				'pt',
				'ro',
				'ru',
				'mf',
				'vc',
				'sm',
				'rs',
				'sk',
				'si',
				'es',
				'sw',
				'tr',
				'ua',
				'gb',
				'us',
				'va'
			]
		};
	},
	computed: {
		myClasses() {
			const classes: string[] = [];
			if (this.focused || (this.value && this.value.length > 0)) {
				classes.push('input--filled');
			}
			if (this.focused) {
				classes.push('input--active');
			}
			if (this.fullwidth) {
				classes.push('max-w-full');
			}
			if (this.phoneOnly || this.isPhone(this.value)) {
				classes.push('force-overflow');
			}
			return classes;
		},
		value: {
			get() {
				return this.modelValue;
			},
			set(value) {
				this.$emit('update:modelValue', value);
			}
		}
	},
	created() {
		if (this.id === 'EmailOrPhone') {
			console.warn('EmailOrPhone without explicit id', this);
		}
	},
	methods: {
		isPhone(value) {
			return (
				value &&
				typeof value === 'string' &&
				!value.includes('@') &&
				value.length >= 3 &&
				/^\+?([0-9]| |\/)+$/.test(value)
			);
		}
	},
	props: {
		modelValue: { type: String, default: '' },
		phoneOnly: { type: Boolean, default: false },
		name: { type: String, default: '' },
		maxLength: { type: Number, default: 150 },
		styling: { type: String, default: '' },
		id: { type: String, default: 'EmailOrPhone', required: true },
		browserAutocomplete: { type: [Boolean, String], default: 'on' },
		required: { type: Boolean, default: false },
		autofocus: { type: Boolean, default: false },
		disabled: { type: Boolean, default: false },
		fullwidth: {
			type: [String, Boolean],
			default: false,
			validator: (value: string | boolean) => [false, 'mobile', 'always'].includes(value)
		}
	}
});
</script>
<style scoped src="../styles/input.scss" />
<style lang="scss" scoped>
// make sure countries list is visible
.input--sae {
	&.force-overflow {
		overflow: visible;
		z-index: 10;
	}
}
// style vue-tel-input like its a HokInput
::v-deep(.hok-tel-input) {
	height: 52px;
	width: 100%;
	max-width: 400px;
	border: 1px solid $color-blue-grey;
	border-radius: 3px;
	transition: border 0.3s ease;

	.vti__input {
		padding: 0.8em;
		border-radius: 3px;
		transition: border 0.3s ease;
	}
	&.empty {
		border: 1px solid $color-grey-light;
		border-radius: 3px;
		transition: border 0.3s ease;
		input {
			font-weight: bold;
		}
	}
	::placeholder {
		color: $color-grey-medium;
		opacity: 1;
	}
	// deactivate box shadow & and set outline color
	&.vue-tel-input:focus-within {
		box-shadow: none;
		border-color: #0fb1af;
	}
	// adjust overlay styling
	.vti__dropdown {
		position: initial;
		display: none;
	}
	&:not(.empty) {
		&.is-phone {
			.vti__dropdown {
				display: flex;
			}
		}
	}
	.vti__dropdown-list {
		z-index: 70;
		left: 0;
		width: 100%;
		max-width: 400px;
		&.below {
			top: 67px;
		}
	}
}
</style>
