<template>
	<div :class="[wrapperClass]" class="vdp-datepicker">
		<DateInput
			:id="id"
			:selected-date="selectedDate"
			:reset-typed-date="resetTypedDate"
			:format="format"
			:language="language"
			:inline="inline"
			:name="name"
			:ref-name="refName"
			:open-date="openDate"
			:placeholder="placeholder"
			:input-class="inputClass"
			:typeable="typeable"
			:parse-typed-date="parseTypedDate"
			:clear-button="clearButton"
			:clear-button-icon="clearButtonIcon"
			:calendar-button="calendarButton"
			:calendar-button-icon="calendarButtonIcon"
			:calendar-button-icon-content="calendarButtonIconContent"
			:disabled="disabled"
			:required="required"
			:bootstrap-styling="bootstrapStyling"
			:use-utc="useUtc"
			:show-calendar-on-focus="showCalendarOnFocus"
			@show-calendar="showCalendar"
			@close-calendar="close"
			@typed-date="setTypedDate"
			@clear-date="clearDate"
		>
			<template #beforeDateInput>
				<slot name="beforeDateInput"></slot>
			</template>
			<template #afterDateInput>
				<slot name="afterDateInput"></slot>
			</template>
		</DateInput>

		<!-- Day View -->
		<PickerDay
			v-if="allowedToShowView('day') && showDayView"
			:page-date="pageDate"
			:selected-date="selectedDate"
			:show-day-view="showDayView"
			:full-month-name="fullMonthName"
			:allowed-to-show-view="allowedToShowView"
			:disabled-dates="disabledDates"
			:highlighted="highlighted"
			:calendar-class="calendarClass"
			:calendar-style="calendarStyle"
			:language="language"
			:monday-first="mondayFirst"
			:day-cell-content="dayCellContent"
			:use-utc="useUtc"
			@changed-month="handleChangedMonthFromDayPicker"
			@select-date="selectDate"
			@show-month-calendar="showMonthCalendar"
			@selected-disabled="selectDisabledDate"
		>
			<template #beforeCalendarHeader>
				<slot name="beforeCalendarHeader"></slot>
			</template>
		</PickerDay>

		<!-- Month View -->
		<PickerMonth
			v-if="allowedToShowView('month') && showMonthView"
			:page-date="pageDate"
			:selected-date="selectedDate"
			:show-month-view="showMonthView"
			:allowed-to-show-view="allowedToShowView"
			:disabled-dates="disabledDates"
			:calendar-class="calendarClass"
			:calendar-style="calendarStyle"
			:language="language"
			:use-utc="useUtc"
			@select-month="selectMonth"
			@show-year-calendar="showYearCalendar"
			@changed-year="setPageDate"
		>
			<template #beforeCalendarHeader>
				<slot name="beforeCalendarHeader"></slot>
			</template>
		</PickerMonth>

		<!-- Year View -->
		<PickerYear
			v-if="allowedToShowView('year') && showYearView"
			:page-date="pageDate"
			:selected-date="selectedDate"
			:show-year-view="showYearView"
			:allowed-to-show-view="allowedToShowView"
			:disabled-dates="disabledDates"
			:calendar-class="calendarClass"
			:calendar-style="calendarStyle"
			:language="language"
			:use-utc="useUtc"
			@select-year="selectYear"
			@changed-decade="setPageDate"
		>
			<template #beforeCalendarHeader>
				<slot name="beforeCalendarHeader"></slot>
			</template>
		</PickerYear>
	</div>
</template>
<script>
import DateInput from './DateInput.vue';
import PickerDay from './PickerDay.vue';
import PickerMonth from './PickerMonth.vue';
import PickerYear from './PickerYear.vue';
import utils, { makeDateUtils } from './DateUtils';
import { getAsDate } from '../../helpers/datehelpers/get-as-date';

export default {
	components: {
		DateInput,
		PickerDay,
		PickerMonth,
		PickerYear
	},
	props: {
		value: {
			validator: val => utils.validateDateInput(val)
		},
		name: String,
		refName: String,
		id: String,
		format: {
			type: [String, Function],
			default: 'DD MMM yyyy'
		},
		language: {
			type: String,
			default: 'en'
		},
		openDate: {
			validator: val => utils.validateDateInput(val)
		},
		dayCellContent: Function,
		fullMonthName: Boolean,
		disabledDates: Object,
		highlighted: Object,
		placeholder: String,
		inline: Boolean,
		calendarClass: [String, Object, Array],
		inputClass: [String, Object, Array],
		wrapperClass: [String, Object, Array],
		mondayFirst: Boolean,
		clearButton: Boolean,
		clearButtonIcon: String,
		calendarButton: Boolean,
		calendarButtonIcon: String,
		calendarButtonIconContent: String,
		bootstrapStyling: Boolean,
		initialView: String,
		disabled: Boolean,
		required: Boolean,
		typeable: Boolean,
		parseTypedDate: Function,
		useUtc: Boolean,
		minimumView: {
			type: String,
			default: 'day'
		},
		maximumView: {
			type: String,
			default: 'year'
		},
		showCalendarOnFocus: Boolean
	},
	emits: [
		'opened',
		'selected',
		'input',
		'cleared',
		'selected-disabled',
		'changed-month',
		'changed-year',
		'closed'
	],
	data() {
		const startDate = new Date( // make a copy of startdate
			(this.value && getAsDate(this.value)) ||
				(this.openDate && getAsDate(this.openDate)) ||
				new Date()
		);
		const constructedDateUtils = makeDateUtils(this.useUtc, this.language);
		const pageDate = constructedDateUtils.setDate(startDate, 1);

		return {
			pageDate,
			/*
			 * Selected Date
			 * {Date}
			 */
			selectedDate: (this.value && getAsDate(this.value)) || null,
			/*
			 * Flags to show calendar views
			 * {Boolean}
			 */
			showDayView: false,
			showMonthView: false,
			showYearView: false,
			/*
			 * Positioning
			 */
			calendarHeight: 0,
			resetTypedDate: new Date(),
			utils: constructedDateUtils
		};
	},
	watch: {
		language(newLanguage) {
			this.utils = makeDateUtils(this.useUtc, newLanguage);
		},
		useUtc(newUtc) {
			this.utils = makeDateUtils(newUtc, this.language);
		},
		value(value) {
			this.setValue(value);
		},
		openDate() {
			this.setPageDate();
		},
		initialView() {
			this.setInitialView();
		}
	},
	computed: {
		computedInitialView() {
			if (!this.initialView) {
				return this.minimumView;
			}

			return this.initialView;
		},

		calendarStyle() {
			return {
				position: this.isInline ? 'static' : undefined
			};
		},
		isOpen() {
			return this.showDayView || this.showMonthView || this.showYearView;
		},
		isInline() {
			return !!this.inline;
		}
	},
	methods: {
		/**
		 * Called in the event that the user navigates to date pages and closes the picker without
		 * selecting a date.
		 */
		resetDefaultPageDate() {
			if (this.selectedDate === null) {
				this.setPageDate();
				return;
			}
			this.setPageDate(this.selectedDate);
		},
		/**
		 * Effectively a toggle to show/hide the calendar
		 *
		 * @returns {mixed}
		 */
		// eslint-disable-next-line consistent-return
		showCalendar() {
			if (this.disabled || this.isInline) {
				return false;
			}
			if (this.isOpen) {
				return this.close(true);
			}
			this.setInitialView();
			this.$emit('opened');
		},
		/**
		 * Sets the initial picker page view: day, month or year
		 */
		setInitialView() {
			const initialView = this.computedInitialView;
			if (!this.allowedToShowView(initialView)) {
				throw new Error(
					`initialView '${this.initialView}' cannot be rendered based on minimum '${this.minimumView}' and maximum '${this.maximumView}'`
				);
			}
			switch (initialView) {
				case 'year':
					this.showYearCalendar();
					break;
				case 'month':
					this.showMonthCalendar();
					break;
				default:
					this.showDayCalendar();
					break;
			}
		},
		/**
		 * Are we allowed to show a specific picker view?
		 *
		 * @param {String} view
		 * @returns {Boolean}
		 */
		allowedToShowView(view) {
			const views = ['day', 'month', 'year'];
			const minimumViewIndex = views.indexOf(this.minimumView);
			const maximumViewIndex = views.indexOf(this.maximumView);
			const viewIndex = views.indexOf(view);

			return viewIndex >= minimumViewIndex && viewIndex <= maximumViewIndex;
		},
		/**
		 * Show the day picker
		 *
		 * @returns {Boolean}
		 */
		showDayCalendar() {
			if (!this.allowedToShowView('day')) {
				return false;
			}
			this.close();
			this.showDayView = true;
			return true;
		},
		/**
		 * Show the month picker
		 *
		 * @returns {Boolean}
		 */
		showMonthCalendar() {
			if (!this.allowedToShowView('month')) {
				return false;
			}
			this.close();
			this.showMonthView = true;
			return true;
		},
		/**
		 * Show the year picker
		 *
		 * @returns {Boolean}
		 */
		showYearCalendar() {
			if (!this.allowedToShowView('year')) {
				return false;
			}
			this.close();
			this.showYearView = true;
			return true;
		},
		/**
		 * Set the selected date
		 *
		 * @param {Number} timestamp
		 */
		setDate(timestamp) {
			const date = new Date(timestamp);
			this.selectedDate = date;
			this.setPageDate(date);
			this.$emit('selected', date);
			this.$emit('input', date);
		},
		/**
		 * Clear the selected date
		 */
		clearDate() {
			this.selectedDate = null;
			this.setPageDate();
			this.$emit('selected', null);
			this.$emit('input', null);
			this.$emit('cleared');
		},
		/**
		 * @param {Object} date
		 */
		selectDate(date) {
			this.setDate(date.timestamp);
			if (!this.isInline) {
				this.close(true);
			}
			this.resetTypedDate = new Date();
		},
		/**
		 * @param {Object} date
		 */
		selectDisabledDate(date) {
			this.$emit('selected-disabled', date);
		},
		/**
		 * @param {Object} month
		 */
		selectMonth(month) {
			const date = new Date(month.timestamp);
			if (this.allowedToShowView('day')) {
				this.setPageDate(date);
				this.$emit('changed-month', month);
				this.showDayCalendar();
			} else {
				this.selectDate(month);
			}
		},
		/**
		 * @param {Object} year
		 */
		selectYear(year) {
			const date = new Date(year.timestamp);
			if (this.allowedToShowView('month')) {
				this.setPageDate(date);
				this.$emit('changed-year', year);
				this.showMonthCalendar();
			} else {
				this.selectDate(year);
			}
		},
		/**
		 * Set the datepicker value
		 *
		 * @param {Date | String | Number | null} date
		 */
		setValue(date) {
			if (typeof date === 'string' || typeof date === 'number') {
				const parsed = this.utils.parseDate(date);
				// eslint-disable-next-line no-restricted-globals
				date = isNaN(parsed.valueOf()) ? null : parsed;
			}
			if (!date) {
				this.setPageDate();
				this.selectedDate = null;
				return;
			}
			this.selectedDate = date;
			this.setPageDate(date);
		},
		/**
		 * Sets the date that the calendar should open on
		 */
		setPageDate(date) {
			if (!date) {
				if (this.value) {
					date = getAsDate(this.value);
				} else if (this.openDate) {
					date = getAsDate(this.openDate);
				} else {
					date = new Date();
				}
			}
			this.pageDate = this.utils.setDate(new Date(date), 1);
		},
		/**
		 * Handles a month change from the day picker
		 */
		handleChangedMonthFromDayPicker(date) {
			this.setPageDate(date);
			this.$emit('changed-month', date);
		},
		/**
		 * Set the date from a typedDate event
		 */
		setTypedDate(date) {
			this.setDate(date.getTime());
		},
		/**
		 * Close all calendar layers
		 *
		 * @param {Boolean} emitEvent - emit close event
		 */
		close(emitEvent) {
			// eslint-disable-next-line no-multi-assign
			this.showDayView = this.showMonthView = this.showYearView = false;
			if (!this.isInline) {
				if (emitEvent) {
					this.$emit('closed');
				}
				document.removeEventListener('click', this.clickOutside, false);
			}
		},
		/**
		 * Initiate the component
		 */
		init() {
			if (this.value) {
				this.setValue(this.value);
			}
			if (this.isInline) {
				this.setInitialView();
			}
		}
	},
	mounted() {
		this.init();
	}
};
// eslint-disable-next-line
</script>
