// do we need this?
export const completeObj = (obj, type) => {
	if (!obj) {
		// console.warn("empty completeCvObj", type);
		obj = {};
	} else {
		obj = { ...obj }; // copy
	}

	switch (type) {
		case 'education':
			if (!obj.level) {
				obj.level = {};
			} else {
				obj.level = { ...obj.level };
			}

			if (!obj.sublevel) {
				obj.sublevel = {};
			} else {
				obj.sublevel = { ...obj.sublevel };
			}

			if (!obj.school) {
				obj.school = {};
			} else {
				obj.school = { ...obj.school };
			}

			if (!obj.degree) {
				obj.degree = {};
			} else {
				obj.degree = { ...obj.degree };
			}
			break;
		case 'experience':
			if (!obj.company) {
				obj.company = {};
			} else {
				obj.company = { ...obj.company };
			}

			// if( typeof obj.isCurrent === 'undefined' ) obj.isCurrent = false;
			break;
		case 'reference':
			if (!obj.company) {
				obj.company = {};
			} else {
				obj.company = { ...obj.company };
			}

			if (!obj.relation) {
				obj.relation = {};
			} else {
				obj.relation = { ...obj.relation };
			}

			if (!obj.position) {
				obj.position = {};
			} else {
				obj.position = { ...obj.position };
			}

			if (!obj.address) {
				obj.address = {};
			} else {
				obj.address = { ...obj.address };
			}

			break;
		case 'user':
			if (!obj.general) {
				obj.general = {};
			} else {
				obj.general = { ...obj.general };
			}

			if (!obj.general.address) {
				obj.general.address = {};
			} else {
				obj.general.address = { ...obj.general.address };
			} // copy

			if (!obj.extras) {
				obj.extras = [];
			}

			if (!obj.cvInfo) {
				obj.cvInfo = {};
			} else {
				obj.cvInfo = { ...obj.cvInfo };
			}

			break;
		default:
			break;
	}
	return obj;
};
