import { EventBus } from '@hokify/shared-components-nuxt3/lib/eventbus';
import { lsTest } from '@hokify/shared-components-nuxt3/lib/helpers/localstorage';
import type { IRedisMessage, IAPILoginUser } from '@hokify/common';
import { diff } from '@hokify/shared-components-nuxt3/lib/helpers/datehelpers/diff';
import { connectSocket } from '../helpers/socket';

function urlB64ToUint8Array(base64String) {
	const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
	const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/');

	const rawData = window.atob(base64);
	const outputArray = new Uint8Array(rawData.length);

	for (let i = 0; i < rawData.length; i += 1) {
		outputArray[i] = rawData.charCodeAt(i);
	}
	return outputArray;
}

function notificationHandler(data: IRedisMessage, nuxtApp: NuxtApp, env: string) {
	try {
		if (env !== 'production') {
			console.log('notificationHandler', data);
		}

		const userObjUpdate =
			(typeof data.userObjUpdate === 'string' && JSON.parse(data.userObjUpdate)) ||
			data.userObjUpdate;
		const additional = data.additional && JSON.parse(data.additional);

		if (userObjUpdate && nuxtApp.$userProfileStore) {
			// when cv-parsing is faster than upload skip userObj update in the store
			if (
				nuxtApp.$userProfileStore?.cvParsingDone?.done &&
				additional?.type === 'newfile' &&
				nuxtApp.$userProfileStore?.cvParsingDone?.parsingDate &&
				diff(nuxtApp.$userProfileStore.cvParsingDone.parsingDate, 'seconds', new Date()) < 30
			) {
				return;
			}

			nuxtApp.$userProfileStore?.setUpdatedElements(userObjUpdate);
		}

		// got new chat message
		if (additional?.type === 'conversation') {
			const { conversationId, conversationType, conversation } = additional;
			nuxtApp.$messagesStore?.processMessage({
				conversationId: conversation,
				conversationType,
				conversationTypeId: conversationId
			});
		}

		if (additional?.type === 'newfile') {
			// if a new file is uploaded
			// e.g. this will be received as soon as processing of video application has finished
			EventBus.$emit('new-file', additional);
		}

		if (nuxtApp.$userProfileStore) {
			if (data?.type === 'user_data_update') {
				nuxtApp.$userProfileStore.updateCvParsingDone({ done: true, parsingDate: new Date() });
			}
			if (data?.type === 'cv-parsing-error-not-a-cv') {
				if (nuxtApp.$userProfileStore.cvParsingError.notCvErrorSeen === true) {
					nuxtApp.$userProfileStore.updateCvParsingError({
						error: 'cv-parsing-error-general',
						notCvErrorSeen: false
					});
				} else {
					nuxtApp.$userProfileStore.updateCvParsingError({
						error: data?.type,
						notCvErrorSeen: false
					});
				}
			}
			if (data?.type === 'cv-parsing-error-general') {
				nuxtApp.$userProfileStore.updateCvParsingError({
					error: data?.type,
					notCvErrorSeen: false
				});
			}
		}

		if (data?.type === 'user_data_update') {
			nuxtApp.$userProfileStore?.updateCvParsingDone({ done: true, parsingDate: new Date() });
		}
	} catch (err: any) {
		console.log('failed receiving socket data', data);
		console.error(err);
	}
}

function suggestionsHandler(
	data:
		| { type: 'token'; token: string }
		| { type: 'finish'; finish: boolean }
		| { type: 'error'; error: string },
	env: string
) {
	if (env !== 'production') {
		console.log('suggestionsHandler', data);
	}
	// got a new suggestion triggered by /app-api/jobseeker/cv/experience-summary-suggestion
	switch (data.type) {
		case 'token':
			EventBus.$emit('experience-summary', data.token);
			break;
		case 'finish':
			// suggestions are finished
			EventBus.$emit('finish-experience-summary');
			break;
		case 'error':
			console.error(data.error);
			// suggestion failed - also finish! (update UI)
			EventBus.$emit('finish-experience-summary');
			break;
		default:
			throw new Error('unknown event for suggestion Handler', data);
	}
}

function jobDescriptionSuggestionHandler(
	data:
		| { type: 'token'; token: string }
		| { type: 'finish'; finish: boolean }
		| { type: 'error'; error: string },
	env: string
) {
	if (env !== 'production') {
		console.log('jobDescriptionSuggestionHandler', data);
	}
	// got a new suggestion triggered by /app-api/recruiter/job/job-description-suggestion
	switch (data.type) {
		case 'token':
			EventBus.$emit('job-description-suggestion', data.token);
			break;
		case 'finish':
			// suggestions are finished
			EventBus.$emit('finish-job-description-suggestion');
			break;
		case 'error':
			console.error(data.error);
			// suggestion failed - also finish! (update UI)
			EventBus.$emit('finish-job-description-suggestion');
			break;
		default:
			throw new Error('unknown event for suggestion Handler', data);
	}
}

async function requestPermission() {
	return new Promise((resolve, reject) => {
		const permissionResult = Notification.requestPermission(result => {
			resolve(result);
		});

		if (permissionResult) {
			permissionResult.then(resolve, reject);
		}
	});
}

function isAbortError(error: unknown): error is { name: 'AbortError' } {
	return (error as { name: string })?.name === 'AbortError';
}

function isNoPermissionErrorMessage(
	error: unknown
): error is { message: "We weren't granted permission." } {
	return (error as { message: string })?.message === "We weren't granted permission.";
}

const pwaClientPlugin: ReturnType<typeof defineNuxtPlugin> = defineNuxtPlugin({
	async setup() {
		const nuxt = useNuxtApp();
		let socket: ReturnType<typeof connectSocket> | undefined;
		const {
			public: { env, websocketHost }
		} = useRuntimeConfig();

		/** WEB SOCKET * */
		const doSocketLogin = async (user: IAPILoginUser) => {
			await socket?.logout();

			socket = connectSocket(env, websocketHost);
			await socket.login(user, {
				onNotification: data => notificationHandler(data, nuxt, env),
				onExperienceSummarySuggestion: data => suggestionsHandler(data, env),
				onJobDescriptionSuggestion: data => jobDescriptionSuggestionHandler(data, env)
			});
		};

		if (nuxt.$loginStore?.loggedIn && nuxt.$userProfileStore?.obj) {
			doSocketLogin(nuxt.$userProfileStore?.obj);
		}
		EventBus.$on('logged-in', async ({ user }) => {
			await doSocketLogin(user);
		});

		EventBus.$on('logged-out', () => {
			if (socket) {
				socket.logout();
				socket = undefined;
			}
		});

		EventBus.$on('show-cookie-banner', () => {
			if (nuxt.$cookieBanner) {
				nuxt.$cookieBanner.toggle(true);
			}
		});

		/* the load event is only triggered when built - it does not trigger in dev mode */
		/* ONLINE / OFFLINE */
		window.addEventListener('load', () => {
			function updateOnlineStatus() {
				nuxt.deviceOffline = !navigator.onLine;
				if (!navigator.onLine) {
					nuxt.$snack?.show({
						title: 'Keine Verbindung',
						text: 'Bitte überprüfe deine Internetverbindung!'
					});
				}
			}

			window.addEventListener('online', updateOnlineStatus);
			window.addEventListener('offline', updateOnlineStatus);
		});

		/** - ADD TO HOMESCREEN ** */
		let deferredAddToHomeScreenPrompt;
		// based on https://developers.google.com/web/fundamentals/app-install-banners/
		window.addEventListener('beforeinstallprompt', e => {
			// Prevent Chrome 67 and earlier from automatically showing the prompt
			e.preventDefault();

			const addToHomeScreenDecision = lsTest() && localStorage.getItem('addToHomeScreenDecision');
			switch (addToHomeScreenDecision) {
				case 'enabled':
				case 'notinterested':
					// cancel here
					return;
				default:
					// Stash the event so it can be triggered later.
					deferredAddToHomeScreenPrompt = e;
					nuxt.$userRootStore?.doAddToHomeScreen(true);
			}
		});

		const addToHomeScreen = async activate => {
			if (!activate) {
				if (lsTest()) {
					localStorage.setItem('addToHomeScreenDecision', 'notinterested');
				}
				nuxt.$userRootStore?.doAddToHomeScreen(false);
				return false;
			}

			if (!deferredAddToHomeScreenPrompt) {
				// alert('autsch, add to home screen promise is null?');
				return false;
			}

			// Show the prompt
			deferredAddToHomeScreenPrompt.prompt();

			// Wait for the user to respond to the prompt

			const timeoutError = new Error('user did not response');
			// Create a promise that rejects in <ms> milliseconds
			const timeout = new Promise((_resolve, reject) => {
				const id = setTimeout(() => {
					clearTimeout(id);

					reject(timeoutError);
				}, 35000);
			});

			return Promise.race([
				timeout,
				deferredAddToHomeScreenPrompt.userChoice.then(choiceResult => {
					if (choiceResult.outcome === 'accepted') {
						if (lsTest()) {
							localStorage.setItem('addToHomeScreenDecision', 'enabled');
						}
						nuxt.$userRootStore?.doAddToHomeScreen(false);
						return true;
					}

					nuxt.$userRootStore?.doAddToHomeScreen(false);
					return false;
				})
			]).catch(err => {
				nuxt.$userRootStore?.doAddToHomeScreen(false);
				if (nuxt.$sentry) {
					nuxt.$sentry.captureException(err);
				}
				console.error(err);
				return false;
			});
		};

		// PUSH
		// Check the current Notification permission.
		// If its denied, it's a permanent block until the
		if (!('Notification' in window)) {
			console.log('This browser does not support desktop notification');
			return {};
		}

		// user changes the permission
		if (Notification.permission === 'denied') {
			console.log("The user has blocked notifications or it's not available");
			return {};
		}

		// Check if push messaging is supported
		if (!('PushManager' in window)) {
			console.log("Push messaging isn't supported.");
			return {};
		}

		let askPushPermission: ((enable: any) => Promise<boolean>) | undefined;

		if ('serviceWorker' in navigator) {
			askPushPermission = async enable => {
				try {
					if (!enable) {
						if (lsTest()) {
							// @todo: this is not checked right now (on purpose, we would like to have the permission if possible)
							localStorage.setItem('askPushPermissionsDecision', 'notinterested');
						}

						nuxt.$userRootStore?.doSetupPushNotification(false);
						return false;
					}

					const permissionResult = await requestPermission();
					if (permissionResult !== 'granted') {
						const error = new Error("We weren't granted permission.");
						// error.permissionResult = permissionResult;
						throw error;
					}

					const serviceWorkerRegistration = await navigator.serviceWorker.ready;

					const subscription = await serviceWorkerRegistration.pushManager.subscribe({
						userVisibleOnly: true,
						applicationServerKey: urlB64ToUint8Array(
							'BJk34uQGF5g0wkbD7xK65NejmRt7LTBW1vgVaoHo4vm938ENx_mTGArPZLtWFkQdWGppC5Siu8zBLTx4BCuD4Zo'
						)
					});

					const subscriptionObject = JSON.parse(JSON.stringify(subscription)); // indeed, we need this.. otherwise keys are not exported.. and no chance to get them (05/04/16 - chrome 50)

					nuxt.$userRootStore?.refreshPushOnServer(subscriptionObject);

					nuxt.$userRootStore?.doSetupPushNotification(false);
					return true;
				} catch (err) {
					nuxt.$userRootStore?.doSetupPushNotification(false);

					if (Notification.permission === 'denied') {
						// The user denied the notification permission which
						// means we failed to subscribe and the user will need
						// to manually change the notification permission to
						// subscribe to push messages
						console.warn('Permission for Notifications was denied');
					} else if (err && !isAbortError(err) && !isNoPermissionErrorMessage(err)) {
						console.error('Unable to subscribe to push.', err);
						if (nuxt.$sentry) {
							nuxt.$sentry.captureMessage('subscription failed', { extra: { error: err } });
						}
					}
					return false;
				}
			};

			// Handler for messages coming from the service worker
			navigator.serviceWorker.addEventListener('message', evt => {
				switch (evt.data.action) {
					case 'deeplink':
						nuxt.$deepLinkRouter.handleUrl(evt.data.url);
						break;
					case 'trackPushOpen':
						nuxt.$userRootStore?.trackPushOpen(evt.data.oid);
						break;
					case 'trackPushReceived':
						// nothing is tracked in this case right now
						break;
					case 'reload':
						console.log('SW forcing reload...');
						if (process.env.pathForReload) {
							window.location.href = process.env.pathForReload;
						} else {
							window.location.reload();
						}
						break;
					default:
						break;
				}
				if (evt.ports && evt.ports[0]) {
					evt.ports[0].postMessage('got it');
				}
			});

			// check for new version
			navigator.serviceWorker.ready.then(async serviceWorkerRegistration => {
				serviceWorkerRegistration.addEventListener('updatefound', () => {
					const newWorker = serviceWorkerRegistration.installing;

					if (!newWorker) {
						return;
					}
					newWorker.addEventListener('statechange', () => {
						// Has network.state changed?
						switch (newWorker.state) {
							case 'installed':
								if (navigator.serviceWorker.controller) {
									// new update available
									nuxt.$snack?.success({
										text: 'Eine neue Version von hokify ist verfügbar!',
										button: 'Neu laden',
										action: () => {
											newWorker.postMessage({ action: 'skipWaiting' });
										}
									});
								}
								// No update available
								break;
							default:
							// nothing happening
						}
					});
				});

				// Are Notifications supported in the service worker?
				if (!('showNotification' in serviceWorkerRegistration)) {
					console.log("Notifications aren't supported.");
					return;
				}

				const subscription = await serviceWorkerRegistration.pushManager.getSubscription();
				// keep server in sync
				if (subscription) {
					const subscriptionObject = JSON.parse(JSON.stringify(subscription)); // indeed, we need this.. otherwise keys are not exported.. and no chance to get them (05/04/16 - chrome 50)
					const keepServerInSync = () => {
						nuxt.$userRootStore?.refreshPushOnServer(subscriptionObject);
					};

					if (nuxt.$loginStore?.loggedIn) {
						keepServerInSync();
					}
					EventBus.$on('logged-in', () => {
						keepServerInSync();
					});

					nuxt.$userRootStore?.doSetupPushNotification(false);
					return true;
				}
			});

			if (Notification.permission === 'granted' && nuxt.$loginStore?.loggedIn) {
				askPushPermission(true);
			}

			nuxt.$userRootStore?.doSetupPushNotification(true);
		}

		return {
			provide: { addToHomeScreen, askPushPermission }
		};
	}
});

export default pwaClientPlugin;
