// eslint-disable-next-line import/no-cycle
import { defineStore, storeToRefs } from 'pinia';
import { useUserProfileStore } from './user-profile';
import { useUiStateStore } from './uistate';

export const useUserProfileGeneralStore = defineStore('userProfileGeneral', {
	state: () => ({}),
	actions: {
		async updatePicture({ postData }) {
			const res = await this.$nuxt.$hokFetch('/app-api/general-profile', {
				method: 'POST',
				body: postData
			});
			if (res && res.userUpdate) {
				const userProfileStore = useUserProfileStore();
				userProfileStore.updateElements(res.userUpdate);
			}
			return res;
		},
		async deleteProfileImage() {
			try {
				const res = await this.$nuxt.$hokFetch('/app-api/profile-image', {
					method: 'DELETE'
				});
				if (res?.userUpdate) {
					const userProfileStore = useUserProfileStore();
					userProfileStore.updateElements(res.userUpdate);
				}
				return res;
			} catch (error: any) {
				console.error('deleteProfileImage failed', error);
				throw (error.response && error.response.data) || error.response || error;
			}
		},
		async updateGeneral(update) {
			const userProfileStore = useUserProfileStore();
			const user = userProfileStore.obj;
			if (!update || Object.keys(update).length === 0) {
				console.log('skipping empty user obj update');
				return false;
			}

			const general = { ...(user?.general || {}), ...update };

			if (Object.keys(update).length > 0) {
				if (general && general.email) {
					// reset invalid email flag
					userProfileStore.resetInvalidEmail();
				}
			}
			return this.syncGeneralToDb(update);
		},
		async syncGeneralToDb(update) {
			if (!update || Object.keys(update).length === 0) {
				console.info('syncGeneralToDb: nothing todo');
				// nothing to save
				return {};
			}

			const uiStateStore = useUiStateStore();
			const userProfileStore = useUserProfileStore();
			const { doNotDisturbMode } = storeToRefs(uiStateStore);

			const res = await this.$nuxt
				.$hokFetch('/app-api/general-profile', {
					method: 'POST',
					body: update,
					params: {
						triggerVerificationMessage: doNotDisturbMode ? false : undefined
					}
				})
				.catch(err => {
					console.log('no promise!');
					throw err;
				});

			if (!res) {
				return {};
			}

			// update user obj
			if (res && res.userUpdate) {
				userProfileStore.updateElements(res.userUpdate);
			}
			return res;
		}
	}
});
