<template>
	<div :class="checkboxClasses" class="checkbox group relative w-auto">
		<div
			:class="containerClasses"
			class="checkbox-container float-left inline-flex w-5 h-5 relative rounded-[3px] border border-color-text"
			@click="toggleCheck($event)"
		>
			<input
				:id="id"
				:name="name"
				:disabled="disabled"
				:required="required"
				:checked="isChecked"
				:value="modelValue"
				type="checkbox"
				:class="cursorClass"
			/>
		</div>

		<label
			v-if="$slots.anchor"
			:for="id"
			data-cy="checkboxLabel-anchor"
			:class="labelClasses"
			class="block leading-5 checkbox-label"
		>
			<slot name="anchor" />
		</label>

		<label
			v-if="$slots.default"
			:for="id"
			data-cy="checkboxLabel-default"
			:class="labelClasses"
			class="block leading-5 checkbox-label"
		>
			<slot />
			<slot name="agb" />
		</label>

		<label
			v-if="$slots.more"
			:class="labelClasses"
			data-cy="checkboxLabel-more"
			class="block leading-5 checkbox-label"
		>
			<slot name="more" />
		</label>
	</div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
	name: 'HokCheckbox',
	emits: ['change', 'input', 'update:modelValue'],
	data() {
		return {
			isChecked: false
		};
	},
	computed: {
		cursorClass() {
			return { 'cursor-not-allowed': this.disabled, 'cursor-pointer': !this.disabled };
		},
		checkboxClasses() {
			return {
				...this.cursorClass,
				checked: this.isChecked,
				disabled: this.disabled,
				'mb-3 block w-full typeform-checkbox': this.styling === 'typeform',
				'mb-1 inline-block': this.styling === 'white',
				'text-xs mb-2 inline-block': this.styling === 'desktop',
				'mb-4 inline-block': this.styling === 'default'
			};
		},
		containerClasses() {
			return {
				...this.cursorClass,
				'group-[.checked]:after:border group-[.checked]:after:border-color-white text-color-white border-color-white':
					this.styling === 'white',
				'group-[.checked]:after:border group-[.checked]:border-color-main after:text-color-main top-[10px] left-[12px]':
					this.styling === 'typeform',
				'group-[.checked]:after:border group-[.checked]:after:border-color-text':
					this.styling === 'desktop' || this.styling === 'default'
			};
		},
		labelClasses() {
			return {
				...this.cursorClass,
				'ml-8 text-color-white': this.styling === 'white',
				'ml-8 group-[.checked]:text-color-main group-hover:text-color-main':
					this.styling === 'desktop' || this.styling === 'default',
				'w-full min-w-[250px] group-hover:text-color-main cursor-pointer rounded-[3px] pt-[9px] pr-4 pb-[9px] pl-[42px] border border-color-text group-[.checked]:border-color-main group-[.checked]:text-color-main':
					this.styling === 'typeform'
			};
		}
	},
	created() {
		if (this.id === 'hokcheckbox') {
			console.warn('HokCheckbox without explicit id', this);
		}
		// if checked is provided, start with it
		if (this.checked !== undefined) {
			this.isChecked = this.checked;
		}
		// using modelValue for initial state
		else {
			this.isChecked = !!this.modelValue;
		}
	},
	methods: {
		toggleCheck($event) {
			if (!this.disabled) {
				this.isChecked = !this.isChecked;
				this.$emit('change', this.isChecked, $event, this.modelValue);
				this.$emit('input', this.isChecked, $event, this.modelValue);
				if (typeof this.modelValue === 'boolean') {
					this.$emit('update:modelValue', !this.modelValue); // "normal" case where we want to emit the opposite of the "old" value (=toggle it)
				} else {
					this.$emit('update:modelValue', this.modelValue); // "hacky case" where value is some string, e.g. in jobfilter
				}
			}
			return false;
		}
	},
	props: {
		id: { type: String, default: 'hokcheckbox', required: true },
		name: { type: String, default: undefined },
		styling: {
			type: String,
			default: 'default',
			validator: (value: string) =>
				['default', 'typeform', 'cv', 'desktop', 'white'].includes(value)
		},
		modelValue: { type: [Boolean, String], default: false },
		disabled: { type: Boolean, default: false },
		required: { type: Boolean, default: false },
		checked: { type: Boolean, default: undefined }
	}
});
</script>

<style lang="scss" scoped>
$swift-ease-out-duration: 0.4s !default;
$swift-ease-out-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1) !default;
$swift-ease-out: all $swift-ease-out-duration $swift-ease-out-timing-function !default;

$swift-ease-in-duration: 0.3s !default;
$swift-ease-in-timing-function: cubic-bezier(0.55, 0, 0.55, 0.2) !default;
$swift-ease-in: all $swift-ease-in-duration $swift-ease-in-timing-function !default;

$checkbox-size: 20px;
$checkbox-ripple-size: 48px;

.checkbox {
	transform: translate3D(0, 0, 0);

	.checkbox-container {
		transition: $swift-ease-out;

		&:after {
			width: 6px;
			height: 13px;
			position: absolute;
			top: 1px;
			left: 6px;
			border-top: 0;
			border-left: 0;
			opacity: 0;
			transform: rotate(45deg) scale3D(0.15, 0.15, 1);
			transition: $swift-ease-in;
			content: ' ';
		}

		input {
			position: absolute;
			opacity: 0;
		}
	}
}

.disabled {
	.checkbox-container {
		border-color: darkgrey !important; // also disable active state
	}

	.checkbox-label {
		color: darkgray !important; // also disable active state
	}
}

.checkbox.checked {
	.checkbox-container {
		&:after {
			opacity: 1;
			transform: rotate(45deg) scale3D(1, 1, 1);
			transition: $swift-ease-out;
		}
	}
}
</style>
