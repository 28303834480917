<template>
	<form @submit.stop.prevent="setupPayload">
		<h4 class="mb-6 font-normal"><slot name="instructions" /></h4>
		<HokInput
			id="login-user-username"
			v-model.trim="email"
			:browser-autocomplete="$isMobile.any ? 'tel' : 'email'"
			required
			:submit-button="submitButton"
			:disabled="disabled"
			type="text"
			name="username"
			:question="`Bitte ${
				companyMode ? 'geben Sie Ihre' : 'gib deine'
			} Telefonnummer oder E-Mail-Adresse ein`"
			@blur="$loginStore.setLoginIdentifier(email)"
		>
			E-Mail/Telefonnummer
		</HokInput>
		<HokInput
			id="login-user-pwd"
			v-model.trim="password"
			required
			:submit-button="submitButton"
			browser-autocomplete="current-password"
			type="password"
			name="password"
			class="mb-4"
			:question="`Bitte ${companyMode ? 'geben Sie Ihr' : 'gib dein'} Passwort ein.`"
		>
			Passwort
		</HokInput>
		<HokButton
			outline
			:color="companyMode ? 'business' : 'main'"
			is-text
			font-weight="light"
			class="text-sm text-left mb-4"
			@click="$emit('abort')"
		>
			Passwort vergessen?
		</HokButton>
		<HokCheckbox
			v-if="privacy.includes('user_register')"
			id="privacy-user-register"
			v-model="user_register"
			class="text-xs"
			name="privacy-user-register"
		>
			Hokify darf meine Daten speichern, um den vollen Funktionsumfang zu gewährleisten.
			<template #more>
				<HokLink to="/privacy#user-register" target="_blank" class="underline">
					Datenschutzerklärung &amp; Widerrufsrecht </HokLink
				><br />Mit dem Login werden die
				<HokLink to="/disclaimer" target="_blank" class="underline"> AGB </HokLink>
				akzeptiert.
			</template>
		</HokCheckbox>
		<HokCheckbox
			v-if="privacy.includes('company_register')"
			id="privacy-company-register"
			v-model="company_register"
			class="text-xs"
			name="privacy-company-register"
		>
			Um ein kostenloses hokify Firmenkonto zu erstellen, bestätige ich, dass hokify meine Daten
			verarbeiten darf. Weiters nehme ich zur Kenntnis, dass ich für die Daten eingehender Bewerber
			im Sinne der Datenschutz-Grundverordnung als Verantwortlicher zu betrachten bin und alle
			dementsprechenden Pflichten zu erfüllen habe.
			<template #more>
				<HokLink to="/privacy#company-register" target="_blank" class="underline">
					Datenschutzerklärung &amp; Widerrufsrecht </HokLink
				><br />Mit dem Login werden die
				<HokLink to="/disclaimer" target="_blank" class="underline"> AGB </HokLink>
				akzeptiert.
			</template>
		</HokCheckbox>
		<HokCheckbox
			v-if="privacy.includes('company_digital_content')"
			id="privacy-company-digital-content"
			v-model="company_digital_content"
			required
			name="privacy-company-digital-content"
			class="text-xs max-w-md"
		>
			Um digitale Inhalte von hokify zu erhalten, bestätige ich, dass hokify meine Daten verarbeiten
			darf.
			<HokLink to="/privacy#company-digital-content" target="_blank" class="underline">
				Datenschutzerklärung &amp; Widerrufsrecht
			</HokLink>
		</HokCheckbox>
		<HokCheckbox
			v-if="privacy.includes('user_digital_content')"
			id="privacy-user-digital-content"
			v-model="user_digital_content"
			required
			name="privacy-user-digital-content"
			class="text-xs max-w-md"
		>
			Um digitale Inhalte von hokify zu erhalten, bestätige ich, dass hokify meine Daten verarbeiten
			darf.
			<HokLink to="/privacy#company-digital-content" target="_blank" class="underline">
				Datenschutzerklärung &amp; Widerrufsrecht
			</HokLink>
		</HokCheckbox>
		<ErrorBox v-if="errorOnRequest || error" :fullwidth="false" class="max-w-[400px]">
			<template v-if="errorTitle" #title>
				{{ errorTitle }}
			</template>
			{{ errorText }}
		</ErrorBox>
		<HokButton
			v-if="showSubmitButton"
			id="login-user-pwd-submit"
			submit
			:color="companyMode ? 'business' : 'main'"
			fullwidth="always"
		>
			Login
		</HokButton>
		<div class="max-w-[400px]" :class="{ 'text-center': !$isMobile.any }">
			<InfoBox
				v-if="currentLoginState === LoginState.reserved && !(errorOnRequest || error)"
				class="mt-4"
			>
				<template #title> Passwort eingeben </template>
				<template>
					Wir haben Ihnen zur einmaligen Verifizierung ein Passwort per E-Mail geschickt. Bitte
					geben Sie dieses ein, um fortzufahren.
				</template>
			</InfoBox>
		</div>
	</form>
</template>

<script lang="ts">
import ErrorBox from '@hokify/shared-components-nuxt3/lib/components/ErrorBox.vue';
import InfoBox from '@hokify/shared-components-nuxt3/lib/components/InfoBox.vue';
import HokCheckbox from '@hokify/shared-components-nuxt3/lib/components/HokCheckbox.vue';
import HokInput from '@hokify/shared-components-nuxt3/lib/components/HokInput.vue';
import { defineComponent } from 'vue';
import type { PropType } from 'vue';
import type { ILoginPayload } from '../../../types/login';
import { LoginState } from '../../../types/enums';
import type { ILocalLoginParameters } from '../../../types/ilocal-login-parameters';

const defaultLoginError = {
	title: 'Login fehlgeschlagen',
	text: 'Bitte versuche es erneut'
};

export default defineComponent({
	name: 'HokLoginForm',
	components: {
		ErrorBox,
		InfoBox,
		HokCheckbox,
		HokInput
	},
	emits: ['abort', 'send', 'action', 'reset'],
	computed: {
		errorTitle(): string | undefined {
			const error = this.errorOnRequest || this.error;
			if (!error) {
				return undefined;
			}

			return error.title;
		},
		errorText(): string | undefined {
			const error = this.errorOnRequest || this.error;
			if (!error) {
				return undefined;
			}

			return error.text;
		},
		errorCode(): string | undefined {
			const error = this.errorOnRequest || this.error;
			if (!error) {
				return undefined;
			}

			if (this.$nuxt.$isHokFetchResponseError(error)) {
				return error.code ?? error.response.data.code;
			}

			if (
				'data' in error &&
				typeof error.data === 'object' &&
				error.data !== null &&
				'code' in error.data &&
				typeof error.data.code === 'string'
			) {
				return error.data.code;
			}

			return error.code;
		}
	},
	data() {
		const error = null as null | { title?: string; text?: string };
		const {
			public: { development }
		} = useRuntimeConfig();

		return {
			LoginState,
			defaultLoginError,
			error,
			email: this.$loginStore.loginIdentifier,
			password: '',
			user_register: false,
			company_register: false,
			company_digital_content: false,
			user_digital_content: false,
			submitButton: { label: 'weiter' },
			development
		};
	},
	methods: {
		setupPayload() {
			if (!this.email?.trim?.().length || !this.password?.trim?.().length) {
				this.error = defaultLoginError;
			} else if (this.privacy.includes('user_register') && !this.user_register) {
				this.error = {
					title: 'Zustimmung notwendig',
					text: 'Bitte Datenschutz akzeptieren um fortzufahren.'
				};
			} else if (this.privacy.includes('company_register') && !this.company_register) {
				this.error = {
					title: 'Zustimmung notwendig',
					text: 'Bitte Datenschutz akzeptieren um fortzufahren.'
				};
			} else if (
				this.privacy.includes('company_digital_content') &&
				!this.company_digital_content
			) {
				this.error = {
					title: 'Zustimmung notwendig',
					text: 'Bitte Datenschutz akzeptieren um fortzufahren.'
				};
			} else if (this.privacy.includes('user_digital_content') && !this.user_digital_content) {
				this.error = {
					title: 'Zustimmung notwendig',
					text: 'Bitte Datenschutz akzeptieren um fortzufahren.'
				};
			} else {
				this.error = null;

				const data: ILocalLoginParameters = {
					email: this.email,
					password: this.password
				};
				if (this.privacy.includes('user_register')) {
					data.privacy_user_register = this.user_register as any;
				}
				if (this.privacy.includes('company_register')) {
					data.privacy_company_register = this.company_register as any;
				}
				if (this.privacy.includes('company_digital_content')) {
					data.privacy_company_digital_content = this.company_digital_content as any;
				}
				if (this.privacy.includes('user_digital_content')) {
					data.privacy_user_digital_content = this.user_digital_content as any;
				}

				const payload: ILoginPayload = {
					state: this.currentLoginState,
					data
				};
				if (this.development) {
					console.log('PAYLOAD', payload);
				}
				this.$nextTick(() => {
					this.$emit('send', payload);
				});
			}
		}
	},
	props: {
		privacy: { type: Array, default: () => [] },
		disabled: { type: Boolean, default: false },
		showSubmitButton: { type: Boolean, default: true },
		currentLoginState: {
			type: String as PropType<LoginState.login | LoginState.reserved | LoginState.pwdReset>,
			default: () => {}
		},
		companyMode: { type: Boolean, default: false },
		errorOnRequest: {
			type: Object,
			default: undefined
		}
	}
});
</script>

<style lang="scss" scoped>
::v-deep(.input--sae:has(#login-user-pwd)) {
	margin-bottom: $s1;
}
</style>
