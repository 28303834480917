// tslint:disable
/**
 * app-api No description provided (generated by Openapi Generator
 * https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.11.26
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech Do not edit the class manually.
 */

/**
 * @export
 * @enum {string}
 */
export enum SupportedCompanyCase {
	Contacted = 'contacted',
	B2b = 'b2b',
	B2bLehrling = 'b2b-lehrling',
	B2bSocialmedia = 'b2b-socialmedia',
	B2bReferral = 'b2b-referral',
	B2bHeadhunter = 'b2b-headhunter',
	DigitalContent = 'digital-content',
	Moreinfo = 'moreinfo',
	Testweb = 'testweb',
	Testwebadwords = 'testwebadwords',
	Testwebfacebook = 'testwebfacebook',
	Corona = 'corona',
	Corona2 = 'corona-2',
	Freeapponly = 'freeapponly',
	Anonym = 'anonym',
	Adwords = 'adwords',
	Facebook = 'facebook',
	Mon = 'mon',
	Optin = 'optin',
	Optout = 'optout',
	Tup = 'tup',
	Pwa = 'pwa',
	Partner = 'partner',
	True = 'true'
}
