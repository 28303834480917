// enables the plugin and component for the respective app, needs to be done in all apps

import CookieBannerService from '@hokify/shared-components-nuxt3/lib/plugins/cookie-banner/cookieBannerService';
import CookieBannerContainer from '@hokify/shared-components-nuxt3/lib/plugins/cookie-banner/CookieBannerContainer.vue';

export default defineNuxtPlugin({
	name: 'cookie-banner',
	setup(nuxtApp) {
		nuxtApp.provide('cookieBanner', CookieBannerService);
		nuxtApp.vueApp.component('CookieBannerContainer', CookieBannerContainer);
	}
});
