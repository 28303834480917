<template>
	<main class="pb-8">
		<VideoUpload :video-tracking-category="videoTrackingCategory" />
	</main>
</template>
<script lang="ts">
import type { VideoCategory } from '@hokify/tracking-nuxt3/types';
import { defineComponent } from 'vue';
import type { PropType } from 'vue';
import VideoUpload from '@/components/user/profile/VideoUpload.vue';
import { definePageMeta } from '#imports';

export default defineComponent({
	setup() {
		definePageMeta({
			layout: 'default',
			middleware: ['auth']
		});
	},
	components: { VideoUpload },
	props: {
		videoTrackingCategory: { type: String as PropType<VideoCategory>, default: 'user-profile' }
	}
});
</script>
