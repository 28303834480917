<template>
	<NuxtLayout class="main flex flex-col h-full">
		<MainSetup
			v-model="blockPageForUser"
			:page-ref="$refs && $refs.page"
			@compute-css-headers="cssHeaders = computeCSSHeaders()"
		/>
		<NuxtPage v-if="!blockPageForUser" ref="page" :class="{ hidden: insidePageIsOpen }" />
		<Spinner v-else />
	</NuxtLayout>
</template>

<script lang="ts" name="UserMain" setup>
import { computed, ref } from 'vue';
import { insidePages } from '@hokify/shared-components-nuxt3/lib/plugins/insidepage/insidePageService';
import MainSetup from '@/components/MainSetup.vue';

import { useHeadSafe } from '#imports';

const cssHeaders = ref<string[]>(computeCSSHeaders());
const blockPageForUser = ref(!!tryUseNuxtApp()?.$loginStore?.authenticationRequired);

useHeadSafe({
	bodyAttrs: {
		class: cssHeaders.value.join(' ')
	},
	htmlAttrs: {
		lang: 'de'
	}
});

const insidePageIsOpen = computed(() => !!insidePages.pages?.length);

function computeCSSHeaders() {
	const headers: string[] = [];
	headers.push(tryUseNuxtApp()?.$isMobile?.any ? 'mobile' : 'desktop');

	return headers;
}
</script>
