<template>
	<div
		ref="tooltip-container"
		class="relative group inline-block z-10 hover:z-30"
		@mouseover="handleToolTipPosition"
		@touchstart="toggleToolTip"
	>
		<div
			ref="tooltip-text"
			class="max-w-max w-[288px] absolute shadow-md text-left"
			:class="[
				openTooltip ? 'block' : 'hidden',
				stylingClasses,
				{ 'group-hover:block': !$isMobile.any && !dummy },
				windowTop ? 'rounded-bl-none' : 'rounded-tl-none',
				smallToolTip ? 'p-2 text-sm rounded-lg' : 'p-4 rounded-xl'
			]"
			:style="{
				transform: 'translateX(' + tooltipTransform + 'px)',
				left: tooltipLeft,
				right: tooltipRight,
				top: toolTipTop,
				bottom: toolTipBottom
			}"
		>
			<slot>{{ text }}</slot>
		</div>
		<div ref="tooltip-icon" class="inline-block" @click="handleClick">
			<slot name="icon">
				<HokIcon
					class="inline"
					pointer
					:size="size"
					:name="icon"
					:color="color"
					:vertical-align="verticalAlign"
				/>
			</slot>
		</div>
		<!-- on mobile, show info on click on icon - profile complete or not -->
		<HokModal width="390px" adaptive click-to-close :name="`user-tool-tip-${myId}`" class="z-30">
			<h3 v-if="headline" class="text-center">{{ headline }}</h3>
			<p class="mb-0">{{ text }}</p>
		</HokModal>
	</div>
</template>
<script lang="ts">
import { defineComponent } from 'vue';
import HokIcon from './HokIcon.vue';

export default defineComponent({
	components: { HokIcon },
	emits: ['click', 'tooltip-toggled'],
	data() {
		const toolTipBottom = 'auto' as string | 0 | 'auto';
		const toolTipTop = 'auto' as string | 0 | 'auto';
		const tooltipRight = 'auto' as string | 0 | 'auto';
		const tooltipLeft = 0 as string | 0 | 'auto';

		return {
			tooltipLeft,
			tooltipRight,
			toolTipTop,
			toolTipBottom,
			tooltipTransform: 0,
			openTooltip: false,
			myId: Math.random()
		};
	},
	computed: {
		stylingClasses(): string[] {
			let stylingClasses;
			switch (this.styling) {
				case 'company':
					stylingClasses = ['bg-color-main-business', 'text-color-white'];
					break;
				case 'neutral':
					stylingClasses = ['bg-color-grey-lightest', 'text-color-text'];
					break;
				case 'blue':
					stylingClasses = ['bg-color-blue-grey', 'text-color-white'];
					break;
				case 'user':
				default:
					stylingClasses = ['bg-color-main', 'text-color-white'];
					break;
			}
			return stylingClasses;
		},
		tooltipContainer() {
			return this.$refs['tooltip-container'] as any;
		},
		tooltipText() {
			return this.$refs['tooltip-text'] as any;
		},
		tooltipIcon() {
			return this.$refs['tooltip-icon'] as any;
		}
	},
	mounted() {
		// we need to make sure to close the tooltip when user clicks somewhere else on the page
		window.addEventListener('touchstart', () => {
			this.openTooltip = false;
		});
	},
	beforeUnmount() {
		window.removeEventListener('touchstart', () => {
			this.openTooltip = false;
		});
	},
	methods: {
		handleToolTipPosition() {
			if (!this.dummy) {
				// REFERENCE: https://medium.com/carwow-product-engineering/building-a-simple-tooltip-component-that-never-goes-off-screen-c7039dcab5f9
				const screenPadding = 16; // 1rem

				const placeholderRect = this.tooltipContainer.getBoundingClientRect();
				const dropdownRect = this.tooltipText.getBoundingClientRect();
				const iconRect = this.tooltipIcon.getBoundingClientRect();

				const dropdownRightX = dropdownRect.x + dropdownRect.width;
				const placeholderRightX = placeholderRect.x + placeholderRect.width;

				if (this.windowTop) {
					this.toolTipBottom = `${iconRect.height}px`;
				} else {
					this.toolTipTop = `${iconRect.height}px`;
				}

				if (dropdownRect.x + iconRect.width < 0) {
					// CASE 1: Tooltip Text overflows left window boundaries -> offset via transform to the right
					this.tooltipLeft = 0;
					this.tooltipRight = 'auto';
					this.tooltipTransform = -placeholderRect.x + screenPadding;
				} else if (dropdownRightX + iconRect.width > window.outerWidth) {
					// CASE 2: Tooltip Text overflows right window boundaries -> offset via transform to the left
					this.tooltipLeft = 'auto';
					this.tooltipRight = 0;
					this.tooltipTransform = window.outerWidth - placeholderRightX - screenPadding;
				} else {
					// CASE 3: No overflow -> reset transform if any
					this.tooltipLeft = `${iconRect.width}px`;
					this.tooltipRight = 'auto';
					this.tooltipTransform = 0;
				}
				this.$emit('tooltip-toggled');
			}
		},
		toggleToolTip() {
			if (!this.openTooltip && !this.$isMobile.any) {
				this.openTooltip = true;
				this.handleToolTipPosition();
			} else {
				this.openTooltip = false;
			}
		},
		handleClick() {
			// only emit 'click' event if there is a custom eventhandler in parent **component**
			if (this.customHandler) {
				this.$emit('click');
			} else if (this.$isMobile.any && !this.customHandler) {
				this.$modal.show(`user-tool-tip-${this.myId}`);
			}
		}
	},
	props: {
		isCompanyPage: { type: Boolean },
		windowTop: { type: Boolean },
		customHandler: { type: Boolean },
		text: { type: String },
		dummy: { type: Boolean, default: false },
		headline: { type: String, default: 'Information' },
		icon: { type: String, default: 'icon:info' },
		pointer: { type: Boolean, default: () => false },
		size: {
			type: Number,
			default: 4
		},
		color: {
			type: String,
			default: 'default'
		},
		verticalAlign: {
			type: String,
			default: 'align-middle'
		},
		styling: {
			type: String,
			default: 'user',
			validator: (value: string) => ['user', 'company', 'neutral', 'blue'].includes(value)
		},
		smallToolTip: { type: Boolean, default: false }
	}
});
</script>
