<template>
	<HokModal
		:adaptive="!!$isMobile.any"
		:width="$isMobile.any ? '95%' : '350px'"
		:show-close-button="false"
		:click-to-close="false"
		name="invalidVersion"
		transition="scale"
		@vue:mounted="$modal.show('invalidVersion')"
	>
		<div class="flex flex-wrap justify-center text-center">
			<NuxtImg src="/shared-components-nuxt3/svgs/hokify/logo-hy-b2c.svg" />
			<h3 class="mt-4">Neue hokify Version verfügbar!</h3>
			<p>Es ist eine neue Version verfügbar!</p>

			<HokButton fullwidth="mobile" class="mb-4" color="main" @click="loadNewVersion">
				Jetzt updaten
			</HokButton>
			<small class="mt-3">Deine Version: {{ version }}</small>
		</div>
	</HokModal>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
	name: 'InvalidVersion',
	data() {
		const {
			public: { version }
		} = useRuntimeConfig();
		const device = process.env.cordova || 'pwa';

		return {
			device,
			version
		};
	},
	methods: {
		loadNewVersion() {
			// use same logic like AskForAppRating
			if (this.device === 'pwa') {
				window.location.reload();
			} else {
				const storeLink =
					this.device === 'android'
						? 'https://play.google.com/store/apps/details?id=com.yfabrik.hocknfinder'
						: 'https://itunes.apple.com/app/id1069568033';

				window.open(storeLink, '_blank');
			}
		}
	}
});
</script>
