<template>
	<div
		:data-sitekey="siteKey || $recaptcha.siteKey"
		:data-size="computedDataSize"
		:data-theme="dataTheme"
		:data-badge="dataBadge"
		:data-tabindex="dataTabindex"
		data-callback="recaptchaSuccessCallback"
		data-expired-callback="recaptchaExpiredCallback"
		data-error-callback="recaptchaErrorCallback"
		class="g-recaptcha"
	/>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
	props: {
		siteKey: {
			type: String,
			default: ''
		},

		dataTheme: {
			default: 'light',
			type: String,

			validator: value => ['dark', 'light'].includes(value)
		},

		dataSize: {
			default: null,
			type: String,

			validator: value => ['compact', 'normal', 'invisible'].includes(value)
		},

		dataBadge: {
			default: 'bottomright',
			type: String,

			validator: value => ['bottomright', 'bottomleft', 'inline'].includes(value)
		},

		dataTabindex: {
			default: 0,
			type: Number
		}
	},

	async mounted() {
		this.$recaptcha.on('recaptcha-error', this.onError);
		this.$recaptcha.on('recaptcha-success', this.onSuccess);
		this.$recaptcha.on('recaptcha-expired', this.onExpired);

		await this.$recaptcha.init();
	},

	computed: {
		computedDataSize() {
			return this.dataSize || this.$recaptcha.size || 'normal';
		}
	},

	methods: {
		onError(message) {
			return this.$emit('error', message);
		},

		onSuccess(token) {
			return this.$emit('success', token);
		},

		onExpired() {
			return this.$emit('expired');
		}
	},
	emits: ['error', 'success', 'expired']
});
</script>
