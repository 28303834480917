import unhead_IhZy2RRazi from "/home/runner/work/hokify/hokify/node_modules/.pnpm/nuxt@3.15.1_@types+node@20.17.23_eslint@8.57.1_sass-embedded@1.85.1_tsx@4.19.3_typescript@5.7.3/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_utIUVgOTCx from "/home/runner/work/hokify/hokify/node_modules/.pnpm/nuxt@3.15.1_@types+node@20.17.23_eslint@8.57.1_sass-embedded@1.85.1_tsx@4.19.3_typescript@5.7.3/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_TpDEvXpEvc from "/home/runner/work/hokify/hokify/node_modules/.pnpm/nuxt@3.15.1_@types+node@20.17.23_eslint@8.57.1_sass-embedded@1.85.1_tsx@4.19.3_typescript@5.7.3/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_nbYT3bfxk2 from "/home/runner/work/hokify/hokify/node_modules/.pnpm/nuxt@3.15.1_@types+node@20.17.23_eslint@8.57.1_sass-embedded@1.85.1_tsx@4.19.3_typescript@5.7.3/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_WdF2NT5jIc from "/home/runner/work/hokify/hokify/node_modules/.pnpm/nuxt@3.15.1_@types+node@20.17.23_eslint@8.57.1_sass-embedded@1.85.1_tsx@4.19.3_typescript@5.7.3/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_T1ZNwQcDM2 from "/home/runner/work/hokify/hokify/node_modules/.pnpm/nuxt@3.15.1_@types+node@20.17.23_eslint@8.57.1_sass-embedded@1.85.1_tsx@4.19.3_typescript@5.7.3/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_C5ZPXhg6To from "/home/runner/work/hokify/hokify/node_modules/.pnpm/@pinia+nuxt@0.5.5_typescript@5.7.3_vue@3.5.13_typescript@5.7.3_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/home/runner/work/hokify/hokify/apps/user-app/.nuxt/components.plugin.mjs";
import prefetch_client_TTMJUcuiq0 from "/home/runner/work/hokify/hokify/node_modules/.pnpm/nuxt@3.15.1_@types+node@20.17.23_eslint@8.57.1_sass-embedded@1.85.1_tsx@4.19.3_typescript@5.7.3/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import pwa_icons_plugin_LnrhIJeMG7 from "/home/runner/work/hokify/hokify/apps/user-app/.nuxt/pwa-icons-plugin.ts";
import pwa_client_nMVhy8QRUi from "/home/runner/work/hokify/hokify/node_modules/.pnpm/@vite-pwa+nuxt@0.10.6/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.js";
import plugin_qHh34zTdzM from "/home/runner/work/hokify/hokify/node_modules/.pnpm/@nuxt+icon@1.10.3_vue@3.5.13_typescript@5.7.3_/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import plugin_9V0Q4shcst from "/home/runner/work/hokify/hokify/node_modules/.pnpm/@nuxt+image@1.7.0/node_modules/@nuxt/image/dist/runtime/plugin.mjs";
import link_browser_client_XHVU0xFWCp from "/home/runner/work/hokify/hokify/libs-frontend/pwa-core-nuxt3/plugins/link-browser.client.ts";
import error_handler_zkIzDYbB7v from "/home/runner/work/hokify/hokify/libs-frontend/pwa-core-nuxt3/plugins/error-handler.ts";
import tracking_client_7CDHqDWcDn from "/home/runner/work/hokify/hokify/libs-frontend/tracking-nuxt3/tracking.client.ts";
import _01_provide_nuxt_in_pinia_c835ceTUhY from "/home/runner/work/hokify/hokify/apps/user-app/src/plugins/01.provide-nuxt-in-pinia.ts";
import _02_inject_stores_56V9pntk2J from "/home/runner/work/hokify/hokify/apps/user-app/src/plugins/02.inject-stores.ts";
import _03_hok_fetch_1535M70paU from "/home/runner/work/hokify/hokify/apps/user-app/src/plugins/03.hok-fetch.ts";
import _04_login_pLAZBkb7PU from "/home/runner/work/hokify/hokify/apps/user-app/src/plugins/04.login.ts";
import _05_ismobile_ML7dkTnV7W from "/home/runner/work/hokify/hokify/apps/user-app/src/plugins/05.ismobile.ts";
import _06_sentry_client_1yu9yTJais from "/home/runner/work/hokify/hokify/apps/user-app/src/plugins/06.sentry.client.ts";
import _07_modalPlugin_Kkm82xqpuq from "/home/runner/work/hokify/hokify/apps/user-app/src/plugins/07.modalPlugin.ts";
import _08_insidePagePlugin_ra5CnxFvOI from "/home/runner/work/hokify/hokify/apps/user-app/src/plugins/08.insidePagePlugin.ts";
import _09_recaptcha_client_FS21cPd9Gv from "/home/runner/work/hokify/hokify/apps/user-app/src/plugins/09.recaptcha.client.ts";
import _10_snackbar_client_ZC0h55sYQf from "/home/runner/work/hokify/hokify/apps/user-app/src/plugins/10.snackbar.client.ts";
import _11_cookieBannerPlugin_Y3wfnypztp from "/home/runner/work/hokify/hokify/apps/user-app/src/plugins/11.cookieBannerPlugin.ts";
import _12_deep_link_router_EDhY0tFuP1 from "/home/runner/work/hokify/hokify/apps/user-app/src/plugins/12.deep-link-router.ts";
import _12_hj_client_W77SKYld0Y from "/home/runner/work/hokify/hokify/apps/user-app/src/plugins/12.hj.client.ts";
import _99_pwa_client_qRyxjCODYg from "/home/runner/work/hokify/hokify/apps/user-app/src/plugins/99.pwa.client.ts";
import gmap_vue_qIISoQSIav from "/home/runner/work/hokify/hokify/apps/user-app/src/plugins/gmap-vue.ts";
export default [
  unhead_IhZy2RRazi,
  router_utIUVgOTCx,
  payload_client_TpDEvXpEvc,
  navigation_repaint_client_nbYT3bfxk2,
  check_outdated_build_client_WdF2NT5jIc,
  chunk_reload_client_T1ZNwQcDM2,
  plugin_vue3_C5ZPXhg6To,
  components_plugin_KR1HBZs4kY,
  prefetch_client_TTMJUcuiq0,
  pwa_icons_plugin_LnrhIJeMG7,
  pwa_client_nMVhy8QRUi,
  plugin_qHh34zTdzM,
  plugin_9V0Q4shcst,
  link_browser_client_XHVU0xFWCp,
  error_handler_zkIzDYbB7v,
  tracking_client_7CDHqDWcDn,
  _01_provide_nuxt_in_pinia_c835ceTUhY,
  _02_inject_stores_56V9pntk2J,
  _03_hok_fetch_1535M70paU,
  _04_login_pLAZBkb7PU,
  _05_ismobile_ML7dkTnV7W,
  _06_sentry_client_1yu9yTJais,
  _07_modalPlugin_Kkm82xqpuq,
  _08_insidePagePlugin_ra5CnxFvOI,
  _09_recaptcha_client_FS21cPd9Gv,
  _10_snackbar_client_ZC0h55sYQf,
  _11_cookieBannerPlugin_Y3wfnypztp,
  _12_deep_link_router_EDhY0tFuP1,
  _12_hj_client_W77SKYld0Y,
  _99_pwa_client_qRyxjCODYg,
  gmap_vue_qIISoQSIav
]