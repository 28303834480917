import { defineStore } from 'pinia';

export const useLocationStore = defineStore('location', {
	state: () => ({}),
	actions: {
		async resolveAddress({ address, region }) {
			try {
				return await this.$nuxt.$hokFetch(
					`/app-api/utils/resolve-address?address=${address}${region ? `?region=${region}` : ''}`
				);
			} catch {
				throw new Error('could not resolve address');
			}
		},
		resolveGeoCoords({ long, lat }) {
			return this.$nuxt.$hokFetch(`/api/resolve-geo?location=${long},${lat}`).then(data => {
				if (data.location) {
					return data.location;
				}
				throw new Error('no geo location found');
			});
		}
	}
});
