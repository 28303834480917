import { ReCaptcha, HokReCaptcha } from '@hokify/shared-components-nuxt3/lib/plugins/hok-recaptcha';

export default defineNuxtPlugin(() => {
	const {
		public: { recaptcha: config }
	} = useRuntimeConfig();

	return {
		provide: {
			recaptcha: new ReCaptcha(config),
			hokReCaptcha: HokReCaptcha
		}
	};
});
