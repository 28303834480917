<template>
	<MyIcon :class="dynamicClasses" />
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { Icon } from '#components';

const props = withDefaults(
	defineProps<{
		name?: string;
		color?:
			| 'blue'
			| 'yellow'
			| 'main'
			| 'main-business'
			| 'purple'
			| 'grey'
			| 'white'
			| 'blue-grey'
			| 'text'
			| 'grey-light'
			| 'grey-medium'
			| 'coral';
		pointer?: boolean;
		verticalAlign?:
			| 'align-middle'
			| 'align-baseline'
			| 'align-top'
			| 'align-bottom'
			| 'align-text-top'
			| 'align-text-bottom';
		size?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 10 | 12 | 14 | 16 | 20 | 24 | 32 | 40 | 48 | 56 | 64;
	}>(),
	{
		name: 'icon:alert',
		color: 'text',
		pointer: false,
		verticalAlign: 'align-middle',
		size: 6
	}
);

// eslint-disable-next-line no-undef
const MyIcon = h(Icon, { name: props.name });

const dynamicClasses = computed(() => {
	const tempClasses: string[] = [];

	tempClasses.push(props.verticalAlign);

	if (props.pointer) {
		tempClasses.push('cursor-pointer');
	}

	switch (props.color) {
		case 'blue':
			tempClasses.push('text-color-blue');
			break;
		case 'yellow':
			tempClasses.push('text-color-yellow');
			break;
		case 'main':
			tempClasses.push('text-color-main');
			break;
		case 'main-business':
			tempClasses.push('text-color-main-business');
			break;
		case 'purple':
			tempClasses.push('text-color-purple');
			break;
		case 'grey':
			tempClasses.push('text-color-grey');
			break;
		case 'white':
			tempClasses.push('text-color-white');
			break;
		case 'blue-grey':
			tempClasses.push('text-color-blue-grey');
			break;
		case 'text':
			tempClasses.push('text-color-text');
			break;
		case 'grey-light':
			tempClasses.push('text-color-grey-light');
			break;
		case 'grey-medium':
			tempClasses.push('text-color-grey-medium');
			break;
		case 'coral':
			tempClasses.push('text-color-coral');
			break;
		default:
		// nothing
	}

	switch (props.size) {
		case 0:
			tempClasses.push('w-0 h-0');
			break;
		case 1:
			tempClasses.push('w-1 h-1');
			break;
		case 2:
			tempClasses.push('w-2 h-2');
			break;
		case 3:
			tempClasses.push('w-3 h-3');
			break;
		case 4:
			tempClasses.push('w-4 h-4');
			break;
		case 5:
			tempClasses.push('w-5 h-5');
			break;
		case 6:
			tempClasses.push('w-6 h-6');
			break;
		case 7:
			tempClasses.push('w-7 h-7');
			break;
		case 8:
			tempClasses.push('w-8 h-8');
			break;
		case 10:
			tempClasses.push('w-10 h-10');
			break;
		case 12:
			tempClasses.push('w-12 h-12');
			break;
		case 14:
			tempClasses.push('w-14 h-14');
			break;
		case 16:
			tempClasses.push('w-16 h-16');
			break;
		case 20:
			tempClasses.push('w-20 h-20');
			break;
		case 24:
			tempClasses.push('w-24 h-24');
			break;
		case 32:
			tempClasses.push('w-32 h-32');
			break;
		case 40:
			tempClasses.push('w-40 h-40');
			break;
		case 48:
			tempClasses.push('w-48 h-48');
			break;
		case 56:
			tempClasses.push('w-56 h-56');
			break;
		case 64:
			tempClasses.push('w-64 h-64');
			break;
		default:
		// nothing
	}

	return tempClasses;
});
</script>

<!--
TODO: check if some of this code might still be useful

		iconKey() {
			return this.icon + this.size + this.color + this.verticalAlign + this.pointer;
		},
		myclass() {
			const myclass: string[] = [
				// import.meta.server && this.$vnode.data?.class, // for ssr
				// import.meta.server && this.$vnode.data?.staticClass, // for ssr
				'fill-current',
				'stroke-current',
				'icon',
				this.icon.replace(/-\d$/, '')
			];
		}
	},
	methods: {
		// do we want to access shared-components instead?
		getAssetSrc(name: string) {
			const modulePathToUse = `svgs/icons/${name}.svg`;
			// https://vitejs.dev/guide/features#glob-import
			const modules = import.meta.glob('/shared-components-nuxt3/svgs/icons/*', { eager: true }) as Record<
				string,
				{ default: string }
			>;
			const moduleEntry = Object.entries(modules).find(([modulePath, _module]) =>
				modulePath.includes(modulePathToUse)
			);
			const [_modulePath, module] = moduleEntry!;
			return module.default;
		},
		hashCode(s) {
			if (!s) {
				return '';
			}
			let h = 0;
			for (let i = 0; i < s.length; i += 1) {
				// eslint-disable-next-line no-bitwise
				h = (Math.imul(31, h) + s.charCodeAt(i)) | 0;
			}

			return h.toString(16);
		}
	},
-->
