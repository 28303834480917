import { io, Socket } from 'socket.io-client';
import { newDeferredPromise } from '@hokify/shared-components-nuxt3/lib/helpers/promise';
import type { IAPILoginUser } from '@hokify/common';
import { IUserAppRuntimeConfig } from '@hokify/nuxt-injected-interface';

let mySocket: Socket | undefined;

export function connectSocket(env: IUserAppRuntimeConfig['public']['env'], websocketHost?: string) {
	if (!mySocket) {
		if (!websocketHost) {
			throw new Error('no websocketHost set');
		}
		mySocket = io(websocketHost, { path: '/hok-stream' });
	}

	const connected = newDeferredPromise();

	if (mySocket.connected) {
		connected.resolve();
	} else {
		mySocket.on('connect', () => {
			connected.resolve();
		});
	}

	mySocket.on('join', data => {
		if (env !== 'production') {
			console.log('socket joined: ', data);
		}
	});

	return {
		login: async (
			user: IAPILoginUser,
			handlers: {
				onNotification: (msg) => Promise<void> | void;
				onExperienceSummarySuggestion: (msg) => Promise<void> | void;
				onJobDescriptionSuggestion: (msg) => Promise<void> | void;
			}
		) => {
			if (!mySocket) {
				throw new Error('socket not initialized / already destroyed');
			}
			// wait for the socket to be connected
			await connected.promise;

			mySocket.emit('join', {
				userid: user._id,
				key: user?.localAPI?.token
			});

			mySocket.on('notification', rawData => {
				handlers.onNotification(JSON.parse(rawData));
			});

			mySocket.on('experience-summary', rawData => {
				handlers.onExperienceSummarySuggestion(JSON.parse(rawData));
			});

			mySocket.on('job-description-suggestion', rawData => {
				handlers.onJobDescriptionSuggestion(JSON.parse(rawData));
			});
		},
		logout: async () => {
			if (!mySocket) {
				throw new Error('socket not initialized / already destroyed');
			}
			// wait for the socket to be connected
			await connected.promise;

			const closeResult = mySocket?.close();
			mySocket = undefined;
			if (env !== 'production') {
				console.log('socket closed: ', closeResult);
			}
		},
		// this function is currenly only used in admin-app, idea is that all apps use one socket.ts file together, therefore keeping it here for now
		join: async <EventData>(
			user: IAPILoginUser,
			room: string,
			eventType: string,
			onEvent: (data: EventData) => Promise<void> | void
		) => {
			// wait for the socket to be connected
			await connected.promise;

			const authKeyForSocket = user?.localAPI?.token;
			const userId = user._id;
			mySocket?.emit('join', {
				userid: userId,
				key: authKeyForSocket,
				room
			});

			mySocket?.on(eventType, async rawData => {
				onEvent(JSON.parse(rawData));
			});
		}
	};
}
