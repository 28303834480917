<template>
	<main class="flex flex-col">
		<div class="scroll-container flex-1 pb-8">
			<section class="container max-w-5xl">
				<CvFails v-if="user" :user="user" />
				<CvProfileEditMode
					:show-documents="showDocuments"
					:standalone="standalone"
					:scroll-to-section="scrollToSection"
					@set-active-sourcing="$emit('set-active-sourcing')"
				/>
			</section>
		</div>
		<div v-if="user && user.testerGroup !== 'A'" class="shadow p-4 z-10">
			<div class="mx-auto max-w-dinA4 text-center">
				<HokButton fullwidth="mobile" color="main" @click="save"> Speichern </HokButton>
			</div>
		</div>
	</main>
</template>

<script lang="ts">
import CvFails from '@hokify/pwa-core-nuxt3/components/CvFails.vue';
import type { IAPILoginUser } from '@hokify/common';
import { defineComponent } from 'vue';
import { mapStores } from 'pinia';
import CvProfileEditMode from '@/components/user/hokifyCvEdit/CvProfileEditMode.vue';
import { useUserProfileStore } from '@/stores/user-profile';
import { definePageMeta } from '#imports';

export default defineComponent({
	setup() {
		definePageMeta({
			layout: 'user',
			middleware: ['auth']
		});
	},
	components: { CvFails, CvProfileEditMode },
	emits: ['set-active-sourcing', 'done'],
	computed: {
		user(): IAPILoginUser | undefined {
			return this.userProfileStore.obj;
		},
		...mapStores(useUserProfileStore)
	},
	methods: {
		save() {
			this.$emit('done');
			if (this.$page) {
				this.$page.goBack();
			}
		}
	},
	props: {
		showDocuments: { type: Boolean, default: true },
		standalone: { type: Boolean, default: true },
		scrollToSection: { type: String, default: '' }
	}
});
</script>
