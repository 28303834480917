/* eslint-disable @typescript-eslint/naming-convention */
function provideNuxtContext() {
	return { $nuxt: tryUseNuxtApp() };
}

export default defineNuxtPlugin({
	name: 'provide-nuxt-in-pinia',
	setup() {
		const pinia = usePinia();
		pinia.use(markRaw(provideNuxtContext));
	}
});

declare module 'pinia' {
	interface PiniaCustomProperties {
		$nuxt: NuxtApp;
	}
}
