<template>
	<main>
		<div class="mx-2 sm:mx-auto sm:w-full max-w-3xl">
			<Spinner v-if="loading" />
			<NotificationsComponent
				v-else
				:company-mode="false"
				:on-refresh="refresh"
				:all-notifications="notificationsStore.allNotifications"
				@call-deep-link-router="
					$deepLinkRouter.handleUrl(
						$event.route,
						undefined,
						undefined,
						undefined,
						$event.notificationId
					)
				"
				@click-notification-list-item="$trackUserEvent?.('click_notification_list_item', $event)"
				@disable-notification="$trackUserEvent?.('disable_notification', $event)"
				@open-modal="openModal($event)"
				@notification-seen="isFirstNotification($event)"
			/>
		</div>
		<ContextMenu
			v-if="showMenu"
			:placement="$isMobile.any ? 'left-start' : 'right-start'"
			:selector="selector ? selector : ''"
			:show-context-menu="showMenu"
		>
			<div
				v-if="notificationType.startsWith('user')"
				class="flex items-center cursor-pointer"
				@click="disableNotification()"
			>
				<HokIcon name="icon:mute" :size="8" class="inline mr-8" />
				<p class="inline font-bold mb-0">Mitteilungsart deaktivieren</p>
			</div>
			<div
				v-else-if="notificationType.startsWith('jobalarm')"
				class="cursor-pointer"
				@click="editJobalarms"
			>
				<HokIcon
					name="icon:edit"
					:size="6"
					class="inline mr-8"
					vertical-align="align-text-bottom"
				/>
				<p class="inline font-bold mb-0">Jobalarme verwalten</p>
			</div>
		</ContextMenu>
	</main>
</template>

<script lang="ts">
import NotificationsComponent from '@hokify/shared-components-nuxt3/lib/components/NotificationsComponent.vue';
import type { APINotificationResult } from '@hokify/shared-components-nuxt3/lib/types/notificationTypes';
import ContextMenu from '@hokify/shared-components-nuxt3/lib/components/ContextMenu.vue';
import { defineComponent } from 'vue';
import { mapStores } from 'pinia';
import { useNotificationsStore } from '@/stores/notifications';
import { useUiStateStore } from '@/stores/uistate';
import { definePageMeta, useSeoMeta } from '#imports';

export default defineComponent({
	setup() {
		definePageMeta({
			layout: 'user',
			middleware: ['auth']
		});

		useSeoMeta({
			title: 'Mitteilungen | hokify'
		});
	},
	components: { NotificationsComponent, ContextMenu },
	data() {
		const selector = undefined as string | undefined;
		const showMenu = false;
		const loading = false;
		const notificationType = '';

		return { loading, notificationType, showMenu, selector };
	},
	async mounted() {
		this.loading = true;
		try {
			await this.notificationsStore.loadNotifications();
		} catch (err: any) {
			this.$nuxt.$errorHandler(err);
		} finally {
			this.loading = false;
		}
	},
	methods: {
		async refresh() {
			await this.notificationsStore.loadNotifications();
		},
		openModal($event) {
			this.notificationType = $event.type;
			this.showMenu = !this.showMenu;
			this.selector = `#menu-${$event.id}`;
		},
		async disableNotification() {
			try {
				await this.notificationsStore.disableNotificationType(this.notificationType);
				this.$snack.success({
					text: 'Mitteilungsart erfolgreich deaktiviert'
				});
			} catch (err) {
				this.$nuxt.$errorHandler(
					err,
					'Beim Deaktivieren dieser Mitteilungsart ist ein Fehler aufgetreten. Bitte versuche es später noch einmal.'
				);
			} finally {
				this.$modal.hide('disableNotificationModal');
			}
		},
		editJobalarms() {
			this.$router.push({ path: '/pwa/joblist/jobalarm#jobalarm' });
		},
		isFirstNotification(outboxId: string) {
			if (
				outboxId === (this.notificationsStore.notifications as APINotificationResult[])[0].outboxId
			) {
				this.uiStateStore.updateUnseenNotifications(false);
			}
		}
	},
	computed: {
		...mapStores(useNotificationsStore, useUiStateStore)
	}
});
</script>
