// further details: https://nuxt.com/docs/guide/recipes/custom-usefetch
import { NotAuthenticatedError } from '@hokify/pwa-core-nuxt3/errors/NotAuthenticatedError';
import { lsTest } from '@hokify/shared-components-nuxt3/lib/helpers/localstorage';

function setHeader(headers, key, value) {
	if (Array.isArray(headers)) {
		headers.push([key, value]);
	} else if (headers instanceof Headers) {
		headers.set(key, value);
	} else {
		headers[key] = value;
	}
}

/**
 * Tries to retrieve the error code from fetch errors and any other obscure error structure we use
 * in our codebase
 */
function getErrorCode(error: unknown): string | undefined {
	if (typeof error !== 'object' || error === null) {
		return undefined;
	}

	if ('code' in error && typeof error.code === 'string') {
		return error.code;
	}

	if (
		'data' in error &&
		typeof error.data === 'object' &&
		error.data !== null &&
		'code' in error.data &&
		typeof error.data.code === 'string'
	) {
		return error.data.code;
	}

	if (
		'response' in error &&
		typeof error.response === 'object' &&
		error.response !== null &&
		'data' in error.response &&
		typeof error.response.data === 'object' &&
		error.response.data !== null &&
		'code' in error.response.data &&
		typeof error.response.data.code === 'string'
	) {
		return error.response.data.code;
	}

	return undefined;
}

function isHokFetchResponseError(error: unknown): error is HokFetchResponseError {
	if (
		typeof error === 'object' &&
		error !== null &&
		'response' in error &&
		typeof error.response === 'object' &&
		error.response !== null
	) {
		return (
			'status' in error.response &&
			typeof error.response?.status === 'number' &&
			'data' in error.response &&
			typeof error.response?.data === 'object' &&
			error.response.data !== null &&
			'code' in error.response.data &&
			typeof error.response?.data?.code === 'string'
		);
	}
	return false;
}

function isHokFetchRequestError(error: unknown): error is HokFetchRequestError {
	if (typeof error === 'object' && error !== null) {
		const errorObject = error as HokFetchRequestError;
		return (
			typeof errorObject.name === 'string' &&
			typeof errorObject.message === 'string' &&
			typeof errorObject.status === 'number' &&
			typeof errorObject.statusCode === 'number' &&
			typeof errorObject.request === 'string'
		);
	}
	return false;
}

async function getCookie(nuxtApp: NuxtApp | null, cookieName: string) {
	try {
		return nuxtApp?.runWithContext(() => useCookie(cookieName).value);
	} catch (error) {
		console.error('Error getting session token', error);
	}
	return nuxtApp?.$loginStore?.sessionIdCookie || false;
}

export default defineNuxtPlugin({
	name: 'hok-fetch',
	dependsOn: ['inject-stores'],
	async setup() {
		const {
			public: { development, loginCookie }
		} = useRuntimeConfig();
		const nuxtApp = tryUseNuxtApp() as NuxtApp | null;

		if (!nuxtApp) {
			console.error('nuxtApp is not available in hok-fetch plugin');
			return {};
		}

		const customBaseUrl = import.meta.client && lsTest() && localStorage.getItem('baseURL');
		if (customBaseUrl) {
			nuxtApp.$config.public.API_HOST_BROWSER = customBaseUrl;
			nuxtApp.$config.public.API_HOST_SERVER = customBaseUrl;
		}

		const hokFetch = $fetch.create({
			headers: {
				Accept: 'application/json'
			},
			credentials: 'include',
			async onRequest({ options }) {
				const sessionIdCookie = await getCookie(nuxtApp, loginCookie);
				const headers = options.headers || {};
				// set default timeout
				if (import.meta.server) {
					options.timeout = 10000; // 10 seconds timeout, for server side this is more than enough
				} else {
					options.timeout = 0; // no timeout on client side (do not set timeouts due to long uploads)
				}

				// ensure it is undefined by default
				if (options.headers) {
					delete options.headers['x-session-token'];
				}

				if (sessionIdCookie) {
					setHeader(headers, 'x-session-token', sessionIdCookie);
				}

				if (import.meta.server) {
					// for server side request, we add a no-limiter key for disabling the rate limiter
					setHeader(headers, 'x-no-limiter-key', 'Ajd8823ha8ssf23asfjil023.a');
				}

				if (import.meta.server && nuxtApp?.$loginStore?.loggedIn) {
					// on server side requests, set cache control to private if the user is logged in
					// we used to do this in login plugin but it's not possible to set headers after being sent to the client
					setHeader(headers, 'Cache-Control', 'private');
				}
			},
			async onResponse({ request, response }) {
				const sessionToken =
					response._data?.sessionToken ||
					response.headers?.['x-session-token'] ||
					(await getCookie(nuxtApp, loginCookie));

				if (sessionToken) {
					const maxAge = sessionToken === false ? -1 : undefined;
					await nuxtApp?.$loginStore?.setSessionIdCookie({ sessionToken, maxAge });

					if (response._data?.token && response._data?.tokenID) {
						const reAuthTokens = {
							tokenID: response._data.tokenID,
							token: response._data.token
						};

						nuxtApp?.$loginStore?.setReAuthData({
							reAuthTokens
						});
					}
				}
				console.log('[fetch response]', request, response.status);
			},
			async onResponseError({ request, response, options }) {
				const sentry = nuxtApp?.$sentry;
				// const sentry = undefined;
				// const config = error.response?.config || error.config;
				const code = response.status;

				nuxtApp?.$userRootStore?.increaseGlobalNetworkErrors();
				if (development && import.meta.client) {
					console.log('FETCH GLOBAL ERROR HANDLER', {
						code,
						request,
						responseUrl: response.url,
						storeGlobalNetworkErrors: nuxtApp?.$userRootStore?.globalNetworkErrors
					});
				} else if (code === 500) {
					console.log('SERVER ERROR', response.body);
					if (sentry) {
						sentry.withScope(scope => {
							scope.setExtra('data', response.body);
							scope.setExtra('status', response.status);
							scope.setExtra('config', {
								url: options.baseURL,
								method: options.method,
								headers: options.headers,
								params: options.params
							});
							sentry.captureException(response.body);
						});
					}
				}

				if (
					code === 401 &&
					nuxtApp?.$loginStore?.loggedIn &&
					options.baseURL?.includes('hokify.com') &&
					(response.url?.includes('/api/') || response.url?.includes('/app-api/')) // only try to re-login if login failed against hokify API
				) {
					if (import.meta.client && nuxtApp?.$page?.event) {
						// show this dialog if sessioncookie has expired and user has been logged out in different tab
						// therefore reset current session -> set user object and loggedIn state to null
						await nuxtApp.$userProfileStore?.setUser({
							user: null,
							versionOkay: null,
							bouncedEmails: null
						});
						nuxtApp?.$modal?.show('dialog', {
							title: 'Erneuter Login erforderlich!',
							text: 'Um fortzufahren, ist ein erneuter Login notwendig.',
							buttons: [
								{
									title: 'OK',
									link: `${nuxtApp.$config.public.baseURL}/login`,
									closedialog: true
								}
							]
						});
					} else {
						console.warn('fetch call - cannot show dialog');
					}

					throw new NotAuthenticatedError(`${response.url} returned 401 error`);
				}
			}
		});

		return {
			provide: {
				hokFetch: async (url: string, options = {}) => {
					const baseURL = await nuxtApp.runWithContext(
						() => useRuntimeConfig().public.API_HOST_BROWSER
					);
					return hokFetch(url, {
						baseURL,
						...options
					});
				},
				getErrorCode,
				isHokFetchResponseError,
				isHokFetchRequestError
			}
		};
	}
});
