<template>
	<div class="calendar relative">
		<Datepicker
			:id="id"
			ref="calendar"
			:class="myClasses"
			:value="date"
			:disabled-dates="myDisabledDates"
			:initial-view="initialView"
			:format="format"
			:language="language || 'de'"
			:minimum-view="minimumView"
			:disabled="disabled"
			:inline="inline"
			:open-date="openDate"
			:typeable="!$isMobile.any && typeable"
			:parse-typed-date="parseTypedDate"
			class="input input--sae mb-4"
			input-class="input__field input__field--sae input"
			@input="input"
			@cleared="$emit('cleared')"
			@changed-month="$emit('changed-month', $event)"
			@changed-year="$emit('changed-year', $event)"
			@changed-decade="$emit('changed-decade', $event)"
			@selected="selected"
			@opened="onOpened"
			@closed="onClosed"
		>
			<template #beforeDateInput>
				<label v-if="$slots.default" :for="id" class="input__label input__label--sae">
					<span class="input__label-content input__label-content--sae"> <slot /></span>
				</label>
			</template>
		</Datepicker>
	</div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { isValid } from '../helpers/datehelpers/is-valid';
import { format as formatHelper } from '../helpers/datehelpers/format';
import Datepicker from './HokDatePicker/Datepicker.vue';
import { getAsDate } from '../helpers/datehelpers/get-as-date';

const openCalendars: HTMLElement[] = [];

function hasInit(obj: any): obj is { init(): void } {
	return typeof obj.init === 'function';
}

function hasClose(obj: any): obj is { close(): void } {
	return typeof obj.close === 'function';
}

function isDatepicker(obj: any): obj is HTMLElement {
	return obj instanceof HTMLElement;
}

export default defineComponent({
	name: 'HokDatePicker',
	components: { Datepicker },
	emits: [
		'closed',
		'selected',
		'opened',
		'click',
		'input',
		'cleared',
		'changed-month',
		'changed-year',
		'changed-decade'
	],
	data() {
		const classes: string[] = [];
		const date = '' as Date | string;

		return {
			date,
			focused: false,
			hasValue: false,
			classes
		};
	},
	computed: {
		myClasses() {
			const classes: string[] = [];
			if (this.focused || this.hasValue) {
				classes.push('input--filled');
			}
			if (this.focused) {
				classes.push('input--active');
			}
			this.classes.forEach(c => classes.push(c));
			return classes;
		},
		myDisabledDates() {
			if (this.disabledDates) {
				return {
					from:
						typeof this.disabledDates.from === 'string'
							? new Date(this.disabledDates.from)
							: this.disabledDates.from,
					to:
						typeof this.disabledDates.to === 'string'
							? new Date(this.disabledDates.to)
							: this.disabledDates.to
				};
			}

			const defaultPastDate = new Date();
			defaultPastDate.setFullYear(defaultPastDate.getFullYear() - 100);

			const defaultFutureDate = new Date();
			defaultFutureDate.setFullYear(defaultFutureDate.getFullYear() + 10);

			const disabled: {
				to: boolean | string | Date | null;
				from: boolean | string | Date | null;
			} = {
				to: defaultPastDate,
				from: defaultFutureDate
			};
			if (this.disabledTo) {
				disabled.to =
					typeof this.disabledTo === 'string' ? new Date(this.disabledTo) : this.disabledTo;
			}

			if (this.disabledFrom) {
				disabled.from =
					typeof this.disabledFrom === 'string' ? new Date(this.disabledFrom) : this.disabledFrom;
			}
			return disabled;
		}
	},
	created() {
		if (this.id === 'hokdatepicker') {
			console.warn('HokDatePicker without explicit id', this);
		}

		this.setDate(this.value);
	},
	methods: {
		setDate(date) {
			if (date && typeof date === 'string') {
				const format = this.getDateFormat();
				if (isValid(date)) {
					this.date = getAsDate(date);
				} else {
					this.date = getAsDate(date, format);
				}
			} else if (date && date instanceof Date) {
				this.date = date;
			}

			if (this.date && !isValid(this.date)) {
				console.warn('cannot parse date', date, this.getDateFormat());
			}

			this.hasValue = !!(this.date && this.date instanceof Date && !Number.isNaN(this.date as any)
				? this.date
				: null);
		},
		onClosed() {
			this.focused = false;
			this.$emit('closed');
		},
		parseTypedDate(input: any) {
			const format = this.getDateFormat();

			return getAsDate(input, format);
		},
		reinit() {
			if (hasInit(this.$refs.calendar)) {
				this.$refs.calendar.init();
			}
		},
		getDateFormat() {
			let format;
			switch (this.minimumView) {
				case 'year':
					format = 'yyyy';
					break;
				case 'month':
					format = 'MM.yyyy';
					break;
				default:
					format = 'dd.MM.yyyy';
					break;
			}
			return format;
		},
		selected(date: any) {
			if (!date) return;
			const format = this.getDateFormat();
			const formattedDate = formatHelper(format, date);
			this.hasValue = true;
			this.$emit('selected', date, formattedDate);
		},
		onOpened() {
			this.focused = true;
			this.$emit('opened');
			this.$emit('click');

			if (this.$refs.calendar) {
				// close all others
				openCalendars.forEach(c => {
					if (c !== (this.$refs as any).calendar && hasClose(c)) {
						c.close();
					}
				});

				if (
					openCalendars.some(cal => {
						// check if we have this already in open calendars  array
						if (cal === (this.$refs as any).calendar) {
							return true;
						}
						return false;
					}) &&
					isDatepicker(this.$refs.calendar)
				) {
					openCalendars.push(this.$refs.calendar);
				}
			}
		},
		input(...args) {
			if (!this.typeable || !args || !args[0]) {
				this.focused = false;
				if (hasClose(this.$refs.calendar)) {
					this.$refs.calendar.close();
				}
			}

			if (!this.inline) {
				openCalendars.some((cal, index) => {
					if (cal === (this.$refs as any).calendar) {
						openCalendars.splice(index, 1);
						return true;
					}
					return false;
				});
			}

			// only emmit non null values
			if (args && args[0]) {
				this.hasValue = true;
				this.$emit('input', ...args);
			}
		},
		onValueChange(newValue) {
			this.setDate(newValue);
		}
	},
	props: {
		styling: { type: String, default: '' },
		value: { type: [String, Date], default: null },
		inline: { type: Boolean, default: false },
		typeable: { type: Boolean, default: true },
		disabled: { type: Boolean, default: false },
		disabledDates: { type: Object, default: undefined },
		disabledTo: { type: [Boolean, Date, String], default: false },
		disabledFrom: { type: [Boolean, Date, String], default: false },
		initialView: { type: String, default: 'year' },
		language: { type: String, default: '' },
		format: { type: [Function, String], default: 'dd.MM.yyyy' },
		minimumView: { type: String, default: 'day' },
		id: { type: String, default: 'hokdatepicker', required: true },
		openDate: { type: Date, default: () => new Date() }
	},
	watch: {
		value: [
			{
				handler: 'onValueChange'
			}
		]
	}
});
</script>

<style lang="scss" scoped>
.calendar {
	// this whole v-deep block is copied from input.scss due to @import is deprecated
	// TODO: find a better way to use the scss from input.scss inside v-deep for .calender class of HokDatepicker
	::v-deep() {
		button,
		input,
		optgroup,
		select,
		textarea {
			font-family: inherit;
		}

		.input {
			position: relative;
			z-index: 1;
			display: inline-block;
			::placeholder {
				color: $color-grey-medium;
			}
		}

		.input__field {
			position: relative;
			display: block;
			float: right;
			padding: 12px;
			width: 100%;
			border: none;
			border-radius: 0;
			color: $color-grey-medium;
			-webkit-appearance: none; /* for box shadows to show on iOS */
			&.reset-padding {
				padding: 0;
			}
		}

		.input__field:focus {
			outline: none;
		}

		.input__label {
			display: inline-block;
			float: right;
			padding: 0 1em;
			width: 40%;
			font-size: $font-sm;
			-webkit-font-smoothing: antialiased;
			-moz-osx-font-smoothing: grayscale;
			-webkit-touch-callout: none;
			user-select: none;
			&:not(.styling-white) {
				color: $color-grey-medium;
				font-weight: bold;
			}
			&.styling-white {
				color: $color-white;
				font-weight: normal;
			}
		}

		.input__label-content {
			position: absolute;
			display: block;
			margin-left: 9px;
			z-index: 2;

			&:not(.styling-white) {
				background: $color-white;
			}
			&.styling-white {
				background: transparent;
			}
		}

		.input--filled {
			.input__field {
				border: 1px solid;
				&:not(.styling-white) {
					border-color: $color-blue-grey;
					color: $color-blue-grey;
				}
				&.styling-white {
					border-color: $color-white;
					color: $color-white;
				}
			}

			.input__label-content {
				margin-top: -9px;
				&:not(.styling-white) {
					color: $color-blue-grey;
				}
				&.styling-white {
					background-color: $color-white;
					color: $color-business;
				}
			}
		}

		/* Sae */
		.input--sae {
			text-align: left;
			overflow: hidden;
			max-width: 400px;
			width: 100%;
			display: block;
			padding-top: 1rem;
			&.pt-0 {
				padding-top: 0;
				.input__field {
					padding: 0 2px 0 2px;
				}
			}
			&.border-none {
				.input__field--sae {
					border: none;
				}
			}
		}
		.input__field--sae {
			line-height: 1.5;
			background: transparent;
			width: 100%;
			font-weight: 500;
			font-size: $font-base;
			border-radius: 3px;
			transition: border 300ms ease;
			&:not(.styling-white) {
				color: $color-blue-grey;
				border: 1px solid $color-grey-light;
			}
			&.styling-white {
				border: 1px solid $color-white;
			}
		}

		.input__label--sae {
			position: absolute;
			width: 100%;
			text-align: left;
			padding: 0;
			pointer-events: none;
		}

		.input__label-content--sae {
			padding: 0 5px;
			transform-origin: 0 0;
			transform: translate3d(0, 15px, 0);
			transition:
				transform 300ms ease,
				color 300ms ease;
			font-size: $font-base;
		}

		.input__field--sae:focus + .input__label--sae .input__label-content--sae,
		.input--filled .input__label-content--sae {
			transform: translate3d(0, 0, 0) scale3d(0.7, 0.7, 1);
		}

		.input__field--sae:focus + .input__label--sae::after,
		.input--active .input__label--sae::after {
			transform: translate3d(0, 0, 0);
		}

		.input--active .input__field--sae {
			&:not(.styling-white) {
				border-color: $color-main;
			}
			&.styling-white {
				border-color: $color-white;
			}
		}

		.input--active .input__label-content--sae {
			&:not(.styling-white) {
				color: $color-main;
			}
			&.styling-white {
				color: $color-business;
				background-color: $color-white;
			}
		}

		.typeform-input {
			padding-right: 2.5rem;
		}

		//TODO: extract this red into tailwind
		.has-error {
			.input__field {
				border-color: #e74c3c;
			}
			.input__label {
				color: #e74c3c;
			}
		}

		.required {
			&:not(.styling-white) {
				color: #e74c3c;
			}
			&.styling-white {
				color: $color-white;
			}
		}
	}

	::v-deep(.rtl) {
		direction: rtl;
	}

	::v-deep(.vdp-datepicker) {
		overflow: visible;
		display: inline-block;
		position: relative;
		text-align: left;
		color: $color-text;
		max-width: 400px;

		input[type='text'] {
			// border: 1px solid $color-blue-grey;
			// border-radius: 3px;
			width: 100%;
			color: $color-blue-grey;
			padding: 0.8rem;
		}

		.vdp-datepicker__calendar {
			background-color: transparent;
			//margin-top: -2px;
			width: auto;
			position: relative;
		}
	}

	::v-deep(.vdp-datepicker *) {
		box-sizing: border-box;
	}

	::v-deep(.vdp-datepicker__calendar) {
		// position: absolute;
		z-index: 100;
		background: #fff;
		width: 300px;
		border-width: 1px;
		border-color: $color-grey-light;
		border-radius: 3px;
		border-style: none solid solid solid;
		transform: translate3d(0, 0, 0);
	}

	::v-deep(.input--active) {
		.vdp-datepicker__calendar {
			border-color: #0fb1af;
		}
	}

	::v-deep(.vdp-datepicker__calendar header) {
		display: block;
		line-height: 40px;
	}

	::v-deep(.vdp-datepicker__calendar header span) {
		display: inline-block;
		text-align: center;
		width: 71.42857142857143%;
		float: left;
	}

	::v-deep(.vdp-datepicker__calendar header .next),
	::v-deep(.vdp-datepicker__calendar header .prev) {
		width: 14.285714285714286%;
		float: left;
		text-indent: -10000px;
		position: relative;
	}

	::v-deep(.vdp-datepicker__calendar header .next:after),
	::v-deep(.vdp-datepicker__calendar header .prev:after) {
		content: '';
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translateX(-50%) translateY(-50%);
		border: 6px solid transparent;
	}

	::v-deep(.vdp-datepicker__calendar header .prev:after) {
		border-right: 10px solid #000;
		margin-left: -5px;
	}

	::v-deep(.vdp-datepicker__calendar header .prev.disabled:after) {
		border-right: 10px solid #ddd;
	}
	::v-deep(.vdp-datepicker__calendar header .next:after) {
		border-left: 10px solid #000;
		margin-left: 5px;
	}

	::v-deep(.vdp-datepicker__calendar header .next.disabled:after) {
		border-left: 10px solid #ddd;
	}
	::v-deep(.vdp-datepicker__calendar header .next:not(.disabled)),
	::v-deep(.vdp-datepicker__calendar header .prev:not(.disabled)),
	::v-deep(.vdp-datepicker__calendar header .prev:not(.disabled)) {
		cursor: pointer;
	}

	::v-deep(.vdp-datepicker__calendar header .next:not(.disabled):hover),
	::v-deep(.vdp-datepicker__calendar header .prev:not(.disabled):hover),
	::v-deep(.vdp-datepicker__calendar header .up:not(.disabled):hover) {
		background: #eee;
	}

	::v-deep(.vdp-datepicker__calendar .disabled) {
		color: #ddd;
		cursor: default;
	}

	::v-deep(.vdp-datepicker__calendar .flex-rtl) {
		display: flex;
		width: inherit;
		flex-wrap: wrap;
	}

	::v-deep(.vdp-datepicker__calendar .cell) {
		display: inline-block;
		padding: 0 5px;
		width: 14.285714285714286%;
		height: 40px;
		line-height: 40px;
		text-align: center;
		vertical-align: middle;
		border: 1px solid transparent;
	}

	::v-deep(.vdp-datepicker__calendar .cell:not(.blank):not(.disabled).day),
	::v-deep(.vdp-datepicker__calendar .cell:not(.blank):not(.disabled).month),
	::v-deep(.vdp-datepicker__calendar .cell:not(.blank):not(.disabled).year) {
		cursor: pointer;
	}

	::v-deep(.vdp-datepicker__calendar .cell:not(.blank):not(.disabled).day:hover),
	::v-deep(.vdp-datepicker__calendar .cell:not(.blank):not(.disabled).month:hover),
	::v-deep(.vdp-datepicker__calendar .cell:not(.blank):not(.disabled).year:hover) {
		border: 1px solid #0fb1af;
	}

	::v-deep(.vdp-datepicker__calendar .cell.selected) {
		background: #0fb1af;
		color: #ffffff;
	}

	::v-deep(.vdp-datepicker__calendar .cell.selected:hover),
	::v-deep(.vdp-datepicker__calendar .cell.selected.highlighted) {
		background: #0fb1af;
	}

	::v-deep(.vdp-datepicker__calendar .cell.highlighted) {
		background: #cae5ed;
	}

	::v-deep(.vdp-datepicker__calendar .cell.highlighted.disabled) {
		color: #a3a3a3;
	}

	::v-deep(.vdp-datepicker__calendar .cell.grey) {
		color: #888;
	}

	::v-deep(.vdp-datepicker__calendar .cell.grey:hover) {
		background: inherit;
	}

	::v-deep(.vdp-datepicker__calendar .cell.day-header) {
		font-size: 75%;
		white-space: nowrap;
		cursor: inherit;
	}

	::v-deep(.vdp-datepicker__calendar .cell.day-header:hover) {
		background: inherit;
	}

	::v-deep(.vdp-datepicker__calendar .month),
	::v-deep(.vdp-datepicker__calendar .year) {
		width: 33.333%;
	}

	::v-deep(.vdp-datepicker__calendar-button),
	::v-deep(.vdp-datepicker__clear-button) {
		cursor: pointer;
		font-style: normal;
	}

	::v-deep(.vdp-datepicker__calendar-button.disabled),
	::v-deep(.vdp-datepicker__clear-button.disabled) {
		color: #999;
		cursor: default;
	}

	::v-deep(.text-xxs) {
		font-size: 10px;
	}

	::v-deep(.datepicker--label) {
		top: 9px;
		color: $color-blue-grey;
	}

	::v-deep(.datepicker--input) {
		background-color: transparent;
	}
}
</style>
