import { markRaw, defineAsyncComponent } from 'vue';

export default defineNuxtPlugin(() => {
	const nuxt = useNuxtApp();

	const openLink = async function openLinkBrowserFct(
		title: string,
		url: string,
		name?: string,
		downloadable = url.includes('hokify.com') && url.includes('.pdf') && nuxt.$isMobile.phone // default true on mobile, false on desktop
	) {
		if (process.env.cordova && window.SafariViewController) {
			window.SafariViewController.show({
				url,
				hidden: false,
				animated: true,
				transition: 'curl',
				enterReaderModeIfAvailable: false,
				tintColor: '#009896',
				barColor: '#009896',
				controlTintColor: '#ffffff'
			});
			return false;
		}

		const previewMode = downloadable && nuxt.$isMobile.phone;
		const {
			public: { API_HOST_BROWSER }
		} = useRuntimeConfig();

		const previewUrl = previewMode
			? `${
					API_HOST_BROWSER || 'https://test.hokify.com'
				}/documentpreview/${encodeURIComponent(url)}.png?height=${window.screen.height}`
			: url;

		const LinkBrowser = markRaw(
			defineAsyncComponent(
				() => import('@hokify/shared-components-nuxt3/lib/components/LinkBrowser.vue')
			)
		);

		const DownloadLink = markRaw(
			defineAsyncComponent(() => import('../components/DownloadLink.vue'))
		);

		await nuxt.$page?.push(
			LinkBrowser,
			{
				url: previewUrl,
				previewMode
			},
			{
				modalHeading: title,
				mode: 'modal',
				name: name || 'link-browser',
				pageTitleAfter:
					(downloadable && {
						component: DownloadLink,
						props: { url }
					}) ||
					undefined
			}
		);
	};

	return {
		provide: {
			openLink
		}
	};
});
