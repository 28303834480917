<template>
	<main>
		<section v-if="article" class="container max-w-3xl">
			<div class="sm:mt-6 mb-12 sm:mb-24">
				<div class="py-2 md:px-4">
					<h1 v-if="article.title" class="mb-2">
						{{ article.title }}
					</h1>
					<div class="flex items-center smaller mb-5">
						<div v-if="article.author && article.author.images.small" class="mb-3 sm:mb-0">
							<NuxtImg
								:src="article.author.images.small"
								class="object-cover w-12 h-12 mr-2 rounded-full"
							/>
						</div>
						<p class="mb-0">
							<span v-if="article.author && article.author.displayName">
								von {{ article.author.displayName }} </span
							><br />
							<span v-if="article.date">Aktualisiert: {{ formatDateDMY(article.updatedAt) }}</span>
						</p>
					</div>
					<NuxtImg
						v-if="article.images"
						:src="article.images.medium"
						:alt="article.imageAlt"
						class="object-cover w-full mb-4 sm:mb-8 aspect-16/10 rounded-lg"
					/>
					<article class="content">
						<client-only>
							<EditorContent :editor="editor" />
							<template #placeholder>
								<div v-html="article.content" />
							</template>
						</client-only>
					</article>
					<div class="flex justify-center">
						<span v-if="$isMobile.any" :class="navigatorAvailable ? [] : ['hidden']">
							<HokButton color="main" @click="shareJob(article.title, article.webUrl)">
								Teilen
							</HokButton>
						</span>
					</div>
				</div>
			</div>
		</section>
		<Magazine
			v-if="articles.length"
			class="border-t border-color-grey-light pt-4"
			:articles="articles"
			:magazine-title="'Weiterführende Artikel'"
			@go-to-article="goToArticle"
		/>
	</main>
</template>

<script lang="ts">
import { format } from '@hokify/shared-components-nuxt3/lib/helpers/datehelpers/format';
import { Editor, EditorContent } from '@tiptap/vue-3';
import type { IAPILoginUser } from '@hokify/common';
import type { IAPIArticle, IAPIArticleTeaser } from '@hokify/magazine-interface';
import Video from '@hokify/shared-components-nuxt3/lib/components/Article/VideoExtension';
import Fallback from '@hokify/shared-components-nuxt3/lib/components/Article/FallbackExtension';
import Document from '@tiptap/extension-document';
import Text from '@tiptap/extension-text';
import type { AnyExtension } from '@tiptap/core';
import Magazine from '@hokify/shared-components-nuxt3/lib/components/Magazine.vue';
import { mapStores } from 'pinia';
import { EventBus } from '@hokify/shared-components-nuxt3/lib/eventbus';
import { defineNuxtComponent, definePageMeta } from '#imports';
import { useArticlesStore } from '@/stores/articles';
import { useUserProfileStore } from '@/stores/user-profile';

export default defineNuxtComponent({
	name: 'ArticlePage',
	setup() {
		definePageMeta({
			layout: 'user',
			path: '/pwa/article/:slug?'
		});
	},
	components: {
		EditorContent,
		Magazine
	},
	data() {
		const articles: IAPIArticleTeaser[] = [];
		const article = null as IAPIArticle | null;
		const editor = undefined as undefined | Editor;
		const err = false as {} | boolean;
		const navigatorAvailable = false;

		return { editor, navigatorAvailable, err, article, articles, EventBus };
	},
	computed: {
		user(): IAPILoginUser | undefined {
			return this.userProfileStore.obj;
		},
		...mapStores(useArticlesStore, useUserProfileStore)
	},
	props: {
		alias: { type: String, default: '' }
	},
	async mounted() {
		try {
			const aliasFromSlug =
				typeof this.$route.params.slug === 'string' ? this.$route.params.slug : ''; // via redirect from deeplink router we get the alias straight from slug
			const identifier = this.alias || aliasFromSlug;
			if (identifier) {
				this.article = await this.articlesStore.getArticle(decodeURI(identifier));
			}
		} catch (err: any) {
			this.$nuxt.$errorHandler(err);
		}

		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		this.editor = new Editor({
			content: this.article?.content,
			extensions: [Document, Text, Video as AnyExtension, Fallback as AnyExtension],
			editable: false
		});

		if (import.meta.client) {
			if (typeof navigator !== 'undefined') {
				this.navigatorAvailable = (navigator as any).share;
			}
		}
		this.collectArticles();
	},
	beforeUnmount() {
		this.editor?.destroy();
	},
	methods: {
		formatDateDMY(dateIn) {
			if (!dateIn) {
				return '';
			}

			return format('dd.MM.yyyy', dateIn);
		},
		shareJob(title, webUrl) {
			(navigator as any)
				.share({
					title: 'Schau dir folgenden Artikel von hokify an:',
					text: title,
					url: webUrl
				})
				.catch(err => this.$nuxt.$errorHandler(err));
		},
		async collectArticles() {
			try {
				if (this.article?.category?.identifier) {
					const result = await this.articlesStore.getArticles(this.article.category.identifier, 7);

					const sameArticle = result.find(item => item.alias === this.article?.alias);

					if (sameArticle) {
						this.articles = result.filter(item => item.alias !== this.article?.alias);
					} else {
						this.articles = result.splice(-1);
					}
				}
			} catch (err) {
				this.$nuxt.$errorHandler(err);
			}
		},

		goToArticle(article: IAPIArticleTeaser) {
			this.EventBus.$emit('go-to-article', article);
		}
	}
});
</script>
<!--eslint-disable-next-line vue-scoped-css/require-scoped vue-scoped-css/enforce-style-type-->
<style src="@hokify/shared-components-nuxt3/assets/styles/article.scss" lang="scss" />
