import { EventBus } from '@hokify/shared-components-nuxt3/lib/eventbus';

const fbSignInParams = {
	scope: ['email'],
	return_scopes: true
};

const facebookSDKAvailable = (window: any): window is { facebookConnectPlugin: any } =>
	!!(
		process.env.cordova &&
		typeof window.facebookConnectPlugin !== 'undefined' &&
		window.facebookConnectPlugin
	);

declare global {
	interface Window {
		fbAsyncInit: any;
		FB: any;
	}
}

export const initFBSDK = (cb?) => {
	if (
		process.server || // only on client
		window.FB || // already intialized
		process.env.cordova //   not needed because we are a cordova build
	) {
		if (cb) {
			cb();
		}
		console.info('initFBSDK already called or not needed');
		return;
	}

	function onSDKInit() {
		window.FB.init({
			appId: '1031146320229277',
			xfbml: true, // needs to be true in order for fb comment widget to work
			version: 'v6.0'
		});

		EventBus.$on('logged-out', () => {
			window.FB.logout();
		});

		if (cb) {
			cb();
		}
		EventBus.$emit('fb-sdk-ready');
	}

	// eslint-disable-next-line func-names
	(function (d, s, id) {
		const fjs = d.getElementsByTagName(s)[0];
		if (d.getElementById(id)) {
			return;
		}
		const js = d.createElement(s) as HTMLScriptElement;
		js.id = id;

		js.onload = () => setTimeout(onSDKInit, 300);

		js.src = 'https://connect.facebook.net/de_AT/sdk.js';
		if (fjs.parentNode) {
			fjs.parentNode.insertBefore(js, fjs);
		}
	})(document, 'script', 'facebook-jssdk');
};

export const FbLogin = async () =>
	new Promise((resolve, reject) => {
		if (facebookSDKAvailable(window)) {
			window.facebookConnectPlugin.login(
				fbSignInParams.scope,
				response => {
					resolve(response);
				},
				err => {
					reject(err);
				}
			);
		} else {
			console.log('initFBSDK');
			initFBSDK(() => {
				window.FB.login(response => {
					if (response.authResponse) {
						resolve(response);
					} else {
						reject(response);
					}
				}, fbSignInParams);
			});
		}
	});
