<template>
	<HokModal
		:adaptive="!!$isMobile.any"
		:width="$isMobile.any ? '95%' : '350px'"
		:show-close-button="false"
		:click-to-close="false"
		name="accountDeletion"
		transition="scale"
		@vue:mounted="$modal.show('accountDeletion')"
	>
		<div class="flex flex-wrap justify-center text-center">
			<h3 class="mt-4">Dein Account wird gelöscht</h3>
			<p>
				Du hast die Löschung deines Accounts beantragt. Wenn du dein Konto doch behalten möchtest,
				klicke auf "Konto behalten".
			</p>
			<HokButton fullwidth="always" class="mb-4" @click="$emit('cancel-delete-profile')">
				Konto behalten
			</HokButton>
			<HokButton
				:color="mode === 'company' ? 'business' : 'main'"
				fullwidth="always"
				is-text
				@click="$emit('logout-only')"
			>
				Abmelden
			</HokButton>
		</div>
	</HokModal>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
	name: 'AccountDeletionModal',
	components: {},
	props: {
		mode: {
			type: String,
			required: false,
			validator: (value: string) => ['user', 'company'].includes(value)
		}
	},
	emits: ['cancel-delete-profile', 'logout-only']
});
</script>
