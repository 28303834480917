<template>
	<HokModal
		v-if="!featureAdvertisementShown"
		name="feature-advertisement"
		:width="$isMobile.any ? '95%' : '500px'"
		:min-height="500"
		adaptive
		class="rounded-t-md"
		:lazy="lazy"
		:click-to-close="clickToClose"
		@closed="handleCloseModal"
	>
		<NuxtImg
			v-if="features.slides && features.slides.length === 1"
			:src="features.slides[0].image"
			class="aspect-16/10 mb-5 rounded-t-md"
			width="500"
			height="313"
		/>

		<HokCarousel
			v-else-if="features.slides.length > 1"
			:auto-play="false"
			:items-to-show="1"
			:items="
				features.slides.map(s => ({
					component: 'img',
					props: {
						src: $img(s.image),
						class: 'w-full aspect-16/10 rounded-t-md'
					}
				}))
			"
			:show-navigation="!$isMobile.any"
			@slide="handleSlide"
		>
		</HokCarousel>
		<div class="text-center px-8 pb-8 pt-3">
			<div v-for="(slide, slideNumber) in features.slides" :key="slideNumber">
				<div>
					<h3 v-if="slide.headline && currentSlide === slideNumber">
						{{ slide.headline }}
					</h3>
					<p v-if="slide.body && currentSlide === slideNumber" class="mb-6">{{ slide.body }}</p>
					<div
						v-if="slide.buttons && currentSlide === slideNumber"
						:class="{
							'flex justify-center space-x-5': !$isMobile.any
						}"
					>
						<HokButton
							v-for="(button, index) in slide.buttons"
							:key="button.text + index"
							:fullwidth="features.buttons.length > 1 ? 'always' : false"
							:outline="button.outline"
							:color="usedApp === 'company' ? 'business' : 'main'"
							:to="button.url"
							:class="{ 'mb-2': $isMobile.any }"
							@click="clickFeature(button)"
							>{{ button.text }}</HokButton
						>
					</div>
				</div>
			</div>
			<h3 v-if="features.headline" class="mb-2">{{ features.headline }}</h3>
			<p v-if="features.body" class="mb-6">{{ features.body }}</p>
			<div
				v-if="features.buttons"
				:class="{
					'flex justify-center space-x-5': !$isMobile.any
				}"
			>
				<HokButton
					v-for="button in features.buttons"
					:key="button.text"
					:fullwidth="!$isMobile.any && features.buttons.length > 1 ? 'always' : 'mobile'"
					:outline="button.outline"
					:color="usedApp === 'company' ? 'business' : 'main'"
					:to="button.url"
					:class="{ 'mb-2': $isMobile.any }"
					@click="clickFeature(button)"
					>{{ button.text }}</HokButton
				>
			</div>
		</div>
	</HokModal>
</template>

<script lang="ts">
import type {
	IAPIFeatureAdvertisementSimple,
	IAPIFeatureAdvertisementExtended
} from '@hokify/common';
import type { PropType } from 'vue';
import { defineComponent } from 'vue';
import HokCarousel from './HokCarousel.vue';
import { lsTest } from '../helpers/localstorage';

export default defineComponent({
	name: 'FeatureAdvertisement',
	components: { HokCarousel },
	emits: ['closed-feature-ad'],
	data() {
		return {
			currentSlide: 0
		};
	},
	computed: {
		featureAdvertisementShown(): boolean {
			return (
				lsTest() &&
				localStorage.getItem(`advertisementShown-${this.features?._id}`) === this.features._id
			);
		}
	},
	methods: {
		handleCloseModal() {
			if (lsTest()) {
				localStorage.setItem(`advertisementShown-${this.features?._id}`, this.features?._id);
			}
			this.trackAdClick();
			this.$modal.hide('feature-advertisement');
			this.$emit('closed-feature-ad');
		},
		handleSlide(payload: { currentSlide: number }) {
			this.currentSlide = payload.currentSlide;
		},
		clickFeature(button: { text: string; outline: boolean; url?: string }) {
			// if no url is specified the button is used to close the modal
			if (!button.url) {
				this.handleCloseModal();
			} else {
				if (lsTest()) {
					localStorage.setItem(`advertisementShown-${this.features?._id}`, this.features?._id);
				}
				this.trackAdClick(button.url);
			}
		},
		trackAdClick(url?: string) {
			this.$trackGenericEvent?.('click_feature_ad', {
				featureId: this.features?._id,
				pageElement: this.features?.mainUrl,
				url: url || 'closed'
			});
		}
	},
	props: {
		features: {
			type: Object as PropType<IAPIFeatureAdvertisementSimple | IAPIFeatureAdvertisementExtended>,
			required: true
		},
		usedApp: { type: String, default: 'user' },
		lazy: { type: Number, default: 1 },
		clickToClose: { type: Boolean, default: false }
	}
});
</script>

<style lang="scss" scoped>
::v-deep(.v--modal-background-click > .v--modal-box) {
	@apply p-0;
}

::v-deep(.v--modal-box > span) {
	@apply z-50;
}

::v-deep(.v--modal-box > span > svg) {
	@apply w-8 h-8 m-3 bg-color-white rounded-full p-2 opacity-40;
}

h3 {
	@apply mb-2 font-bold text-color-text;
}
</style>
