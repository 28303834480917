<template>
	<main class="flex flex-col">
		<div class="overflow-y-auto flex-1">
			<section class="mx-auto max-w-dinA4">
				<div class="container">
					<CvFails v-if="user" :user="user" />
				</div>
				<div :class="{ 'shadow-lg rounded-lg mt-4': !$isMobile.any }">
					<CvProfile :user="user" styling="v1" @open-link="openLink" />
				</div>
			</section>
		</div>
		<div class="shadow p-4 z-10">
			<div class="mx-auto max-w-dinA4 text-center">
				<HokButton fullwidth="mobile" color="main" @click="goBack">
					Zurück zur Profilübersicht
				</HokButton>
			</div>
		</div>
	</main>
</template>

<script lang="ts">
import CvProfile from '@hokify/hokifycv-components-nuxt3/lib/components/CvProfileWithLayout.vue';
import CvFails from '@hokify/pwa-core-nuxt3/components/CvFails.vue';
import type { IAPILoginUser } from '@hokify/common';
import { defineComponent } from 'vue';
import { mapStores } from 'pinia';
import { useUserProfileStore } from '@/stores/user-profile';
import { definePageMeta } from '#imports';

export default defineComponent({
	setup() {
		definePageMeta({
			layout: 'user',
			middleware: ['auth']
		});
	},
	components: {
		CvFails,
		CvProfile
	},
	emits: ['done'],
	computed: {
		user(): IAPILoginUser | undefined {
			return this.userProfileStore.obj;
		},
		...mapStores(useUserProfileStore)
	},
	methods: {
		openLink(obj) {
			this.$openLink.call(this, obj.title, obj.url, 'media-detail');
		},
		goBack() {
			this.$emit('done');
			if (this.$page) {
				this.$page.goBack();
			}
		}
	}
});
</script>
