
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace('')
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as _91companyId_93NTsPDrihAZMeta } from "/home/runner/work/hokify/hokify/apps/user-app/src/pages/apply-company/[companyId].vue?macro=true";
import { default as _91jobNr_93nIAIbC0sEGMeta } from "/home/runner/work/hokify/hokify/apps/user-app/src/pages/apply/[jobNr].vue?macro=true";
import { default as ShowTextB5boHF0B4QMeta } from "/home/runner/work/hokify/hokify/apps/user-app/src/pages/apply/ShowText.vue?macro=true";
import { default as HokifyCVQVGpPGqT7sMeta } from "/home/runner/work/hokify/hokify/apps/user-app/src/pages/HokifyCV.vue?macro=true";
import { default as indexUMC2u23XPAMeta } from "/home/runner/work/hokify/hokify/apps/user-app/src/pages/index.vue?macro=true";
import { default as _91jobNrOrJobIdOrCompanyId_93kJTI9oZSARMeta } from "/home/runner/work/hokify/hokify/apps/user-app/src/pages/pwa/[jobNrOrJobIdOrCompanyId].vue?macro=true";
import { default as _91_46_46_46slug_93q5xeUK0oyCMeta } from "/home/runner/work/hokify/hokify/apps/user-app/src/pages/pwa/article/[...slug].vue?macro=true";
import { default as _91companyId_93udZTpAOBrwMeta } from "/home/runner/work/hokify/hokify/apps/user-app/src/pages/pwa/c/[companyId].vue?macro=true";
import { default as change_45versionM122W5FDQRMeta } from "/home/runner/work/hokify/hokify/apps/user-app/src/pages/pwa/change-version.vue?macro=true";
import { default as _91jobNr_93p7Q3D0u6OfMeta } from "/home/runner/work/hokify/hokify/apps/user-app/src/pages/pwa/job/[jobNr].vue?macro=true";
import { default as jobfiltertOqhPu0ebEMeta } from "/home/runner/work/hokify/hokify/apps/user-app/src/pages/pwa/jobfilter.vue?macro=true";
import { default as _91tab_93NeZTpZ1diSMeta } from "/home/runner/work/hokify/hokify/apps/user-app/src/pages/pwa/joblist/[tab].vue?macro=true";
import { default as loginIaxudQCBnGMeta } from "/home/runner/work/hokify/hokify/apps/user-app/src/pages/pwa/login.vue?macro=true";
import { default as logoutPSsewew5BbMeta } from "/home/runner/work/hokify/hokify/apps/user-app/src/pages/pwa/logout.vue?macro=true";
import { default as _91id_93I7qE3tTN6WMeta } from "/home/runner/work/hokify/hokify/apps/user-app/src/pages/pwa/match/[id].vue?macro=true";
import { default as notificationssymf77Fo1XMeta } from "/home/runner/work/hokify/hokify/apps/user-app/src/pages/pwa/notifications.vue?macro=true";
import { default as _91_91stage_93_93TULVZCebx6Meta } from "/home/runner/work/hokify/hokify/apps/user-app/src/pages/pwa/onboarding/[[stage]].vue?macro=true";
import { default as passwordDLPcwWcGseMeta } from "/home/runner/work/hokify/hokify/apps/user-app/src/pages/pwa/password.vue?macro=true";
import { default as picUploadPageAben3u6nG1Meta } from "/home/runner/work/hokify/hokify/apps/user-app/src/pages/pwa/picUploadPage.vue?macro=true";
import { default as _91tab_93qA5KHMR1FfMeta } from "/home/runner/work/hokify/hokify/apps/user-app/src/pages/pwa/profile/[tab].vue?macro=true";
import { default as ChangeCvDesignPageSEicnACsIPMeta } from "/home/runner/work/hokify/hokify/apps/user-app/src/pages/pwa/profile/ChangeCvDesignPage.vue?macro=true";
import { default as DeactivatedNotificationsPageqZ4I6KY1lrMeta } from "/home/runner/work/hokify/hokify/apps/user-app/src/pages/pwa/profile/DeactivatedNotificationsPage.vue?macro=true";
import { default as DeleteAccountActionPagekOVWkRQRFeMeta } from "/home/runner/work/hokify/hokify/apps/user-app/src/pages/pwa/profile/DeleteAccountActionPage.vue?macro=true";
import { default as DeleteAccountOverviewPage2K7nM7LQlIMeta } from "/home/runner/work/hokify/hokify/apps/user-app/src/pages/pwa/profile/DeleteAccountOverviewPage.vue?macro=true";
import { default as editCvGeneralDataULNR6mCZZZMeta } from "/home/runner/work/hokify/hokify/apps/user-app/src/pages/pwa/profile/editCvGeneralData.vue?macro=true";
import { default as GrossNetCalculatorPage99wsC0CbScMeta } from "/home/runner/work/hokify/hokify/apps/user-app/src/pages/pwa/profile/GrossNetCalculatorPage.vue?macro=true";
import { default as ProfileEditModePageu2w6pTbYQSMeta } from "/home/runner/work/hokify/hokify/apps/user-app/src/pages/pwa/profile/ProfileEditModePage.vue?macro=true";
import { default as ProfilePage5aQgh3p2mvMeta } from "/home/runner/work/hokify/hokify/apps/user-app/src/pages/pwa/profile/ProfilePage.vue?macro=true";
import { default as QuizPage5tYcKFxknkMeta } from "/home/runner/work/hokify/hokify/apps/user-app/src/pages/pwa/profile/QuizPage.vue?macro=true";
import { default as QuickCv55FJcOlpYeMeta } from "/home/runner/work/hokify/hokify/apps/user-app/src/pages/pwa/QuickCv.vue?macro=true";
import { default as _91_91substage_93_9338cUlQ0y1BMeta } from "/home/runner/work/hokify/hokify/apps/user-app/src/pages/pwa/review-profile/[stage]/[[substage]].vue?macro=true";
import { default as index9Vr6r8X6TeMeta } from "/home/runner/work/hokify/hokify/apps/user-app/src/pages/pwa/review-profile/index.vue?macro=true";
import { default as secretFeatureConfigYldxAMG1NnMeta } from "/home/runner/work/hokify/hokify/apps/user-app/src/pages/pwa/secretFeatureConfig.vue?macro=true";
import { default as videoUploadPagerAhOv7mIzwMeta } from "/home/runner/work/hokify/hokify/apps/user-app/src/pages/pwa/videoUploadPage.vue?macro=true";
import { default as _91docType_93G9grEBDZhVMeta } from "/home/runner/work/hokify/hokify/apps/user-app/src/pages/pwa/webupload/[docType].vue?macro=true";
export default [
  {
    name: "apply-company-companyId",
    path: "/apply-company/:companyId?",
    meta: _91companyId_93NTsPDrihAZMeta || {},
    alias: ["/apply-company-fb/:companyId?"],
    component: () => import("/home/runner/work/hokify/hokify/apps/user-app/src/pages/apply-company/[companyId].vue")
  },
  {
    name: "apply-jobNr",
    path: "/apply/:jobNr?",
    meta: _91jobNr_93nIAIbC0sEGMeta || {},
    alias: ["/apply-fb/:jobNr?"],
    component: () => import("/home/runner/work/hokify/hokify/apps/user-app/src/pages/apply/[jobNr].vue")
  },
  {
    name: "apply-ShowText",
    path: "/apply/ShowText",
    component: () => import("/home/runner/work/hokify/hokify/apps/user-app/src/pages/apply/ShowText.vue")
  },
  {
    name: "HokifyCV",
    path: "/hokifycv",
    meta: HokifyCVQVGpPGqT7sMeta || {},
    component: () => import("/home/runner/work/hokify/hokify/apps/user-app/src/pages/HokifyCV.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/runner/work/hokify/hokify/apps/user-app/src/pages/index.vue")
  },
  {
    name: "pwa-jobNrOrJobIdOrCompanyId",
    path: "/pwa/:jobNrOrJobIdOrCompanyId?",
    meta: _91jobNrOrJobIdOrCompanyId_93kJTI9oZSARMeta || {},
    component: () => import("/home/runner/work/hokify/hokify/apps/user-app/src/pages/pwa/[jobNrOrJobIdOrCompanyId].vue")
  },
  {
    name: "pwa-article-slug",
    path: "/pwa/article/:slug?",
    meta: _91_46_46_46slug_93q5xeUK0oyCMeta || {},
    component: () => import("/home/runner/work/hokify/hokify/apps/user-app/src/pages/pwa/article/[...slug].vue")
  },
  {
    name: "pwa-c-companyId",
    path: "/pwa/c/:companyId?",
    meta: _91companyId_93udZTpAOBrwMeta || {},
    component: () => import("/home/runner/work/hokify/hokify/apps/user-app/src/pages/pwa/c/[companyId].vue")
  },
  {
    name: "pwa-change-version",
    path: "/pwa/change-version",
    component: () => import("/home/runner/work/hokify/hokify/apps/user-app/src/pages/pwa/change-version.vue")
  },
  {
    name: "pwa-job-jobNr",
    path: "/pwa/job/:jobNr?",
    meta: _91jobNr_93p7Q3D0u6OfMeta || {},
    component: () => import("/home/runner/work/hokify/hokify/apps/user-app/src/pages/pwa/job/[jobNr].vue")
  },
  {
    name: "pwa-jobfilter",
    path: "/pwa/jobfilter",
    meta: jobfiltertOqhPu0ebEMeta || {},
    component: () => import("/home/runner/work/hokify/hokify/apps/user-app/src/pages/pwa/jobfilter.vue")
  },
  {
    name: "pwa-joblist-tab",
    path: "/pwa/joblist/:tab?",
    meta: _91tab_93NeZTpZ1diSMeta || {},
    component: () => import("/home/runner/work/hokify/hokify/apps/user-app/src/pages/pwa/joblist/[tab].vue")
  },
  {
    name: "pwa-login",
    path: "/pwa/login",
    component: () => import("/home/runner/work/hokify/hokify/apps/user-app/src/pages/pwa/login.vue")
  },
  {
    name: "pwa-logout",
    path: "/pwa/logout",
    component: () => import("/home/runner/work/hokify/hokify/apps/user-app/src/pages/pwa/logout.vue")
  },
  {
    name: "pwa-match-id",
    path: "/pwa/match/:id?",
    meta: _91id_93I7qE3tTN6WMeta || {},
    component: () => import("/home/runner/work/hokify/hokify/apps/user-app/src/pages/pwa/match/[id].vue")
  },
  {
    name: "pwa-notifications",
    path: "/pwa/notifications",
    meta: notificationssymf77Fo1XMeta || {},
    component: () => import("/home/runner/work/hokify/hokify/apps/user-app/src/pages/pwa/notifications.vue")
  },
  {
    name: "pwa-onboarding-stage",
    path: "/pwa/onboarding/:stage?",
    meta: _91_91stage_93_93TULVZCebx6Meta || {},
    component: () => import("/home/runner/work/hokify/hokify/apps/user-app/src/pages/pwa/onboarding/[[stage]].vue")
  },
  {
    name: "pwa-password",
    path: "/pwa/password",
    component: () => import("/home/runner/work/hokify/hokify/apps/user-app/src/pages/pwa/password.vue")
  },
  {
    name: "pwa-picUploadPage",
    path: "/pwa/picUploadPage",
    meta: picUploadPageAben3u6nG1Meta || {},
    component: () => import("/home/runner/work/hokify/hokify/apps/user-app/src/pages/pwa/picUploadPage.vue")
  },
  {
    name: "pwa-profile-tab",
    path: "/pwa/profile/:tab?",
    meta: _91tab_93qA5KHMR1FfMeta || {},
    component: () => import("/home/runner/work/hokify/hokify/apps/user-app/src/pages/pwa/profile/[tab].vue")
  },
  {
    name: "pwa-profile-ChangeCvDesignPage",
    path: "/pwa/profile/ChangeCvDesignPage",
    meta: ChangeCvDesignPageSEicnACsIPMeta || {},
    component: () => import("/home/runner/work/hokify/hokify/apps/user-app/src/pages/pwa/profile/ChangeCvDesignPage.vue")
  },
  {
    name: "pwa-profile-DeactivatedNotificationsPage",
    path: "/pwa/profile/DeactivatedNotificationsPage",
    meta: DeactivatedNotificationsPageqZ4I6KY1lrMeta || {},
    component: () => import("/home/runner/work/hokify/hokify/apps/user-app/src/pages/pwa/profile/DeactivatedNotificationsPage.vue")
  },
  {
    name: "pwa-profile-DeleteAccountActionPage",
    path: "/pwa/profile/DeleteAccountActionPage",
    component: () => import("/home/runner/work/hokify/hokify/apps/user-app/src/pages/pwa/profile/DeleteAccountActionPage.vue")
  },
  {
    name: "pwa-profile-DeleteAccountOverviewPage",
    path: "/pwa/profile/DeleteAccountOverviewPage",
    component: () => import("/home/runner/work/hokify/hokify/apps/user-app/src/pages/pwa/profile/DeleteAccountOverviewPage.vue")
  },
  {
    name: "pwa-profile-editCvGeneralData",
    path: "/pwa/profile/editCvGeneralData",
    meta: editCvGeneralDataULNR6mCZZZMeta || {},
    component: () => import("/home/runner/work/hokify/hokify/apps/user-app/src/pages/pwa/profile/editCvGeneralData.vue")
  },
  {
    name: "pwa-profile-GrossNetCalculatorPage",
    path: "/brutto-netto-rechner",
    meta: GrossNetCalculatorPage99wsC0CbScMeta || {},
    component: () => import("/home/runner/work/hokify/hokify/apps/user-app/src/pages/pwa/profile/GrossNetCalculatorPage.vue")
  },
  {
    name: "pwa-profile-ProfileEditModePage",
    path: "/pwa/profile/ProfileEditModePage",
    meta: ProfileEditModePageu2w6pTbYQSMeta || {},
    component: () => import("/home/runner/work/hokify/hokify/apps/user-app/src/pages/pwa/profile/ProfileEditModePage.vue")
  },
  {
    name: "pwa-profile-ProfilePage",
    path: "/pwa/profile/ProfilePage",
    meta: ProfilePage5aQgh3p2mvMeta || {},
    component: () => import("/home/runner/work/hokify/hokify/apps/user-app/src/pages/pwa/profile/ProfilePage.vue")
  },
  {
    name: "pwa-profile-QuizPage",
    path: "/pwa/profile/QuizPage",
    meta: QuizPage5tYcKFxknkMeta || {},
    component: () => import("/home/runner/work/hokify/hokify/apps/user-app/src/pages/pwa/profile/QuizPage.vue")
  },
  {
    name: "pwa-QuickCv",
    path: "/pwa/QuickCv",
    meta: QuickCv55FJcOlpYeMeta || {},
    component: () => import("/home/runner/work/hokify/hokify/apps/user-app/src/pages/pwa/QuickCv.vue")
  },
  {
    name: "pwa-review-profile-stage-substage",
    path: "/pwa/review-profile/:stage()/:substage?",
    meta: _91_91substage_93_9338cUlQ0y1BMeta || {},
    component: () => import("/home/runner/work/hokify/hokify/apps/user-app/src/pages/pwa/review-profile/[stage]/[[substage]].vue")
  },
  {
    name: "ReviewProfileOverview",
    path: "/pwa/review-profile",
    meta: index9Vr6r8X6TeMeta || {},
    component: () => import("/home/runner/work/hokify/hokify/apps/user-app/src/pages/pwa/review-profile/index.vue")
  },
  {
    name: "pwa-secretFeatureConfig",
    path: "/pwa/secretFeatureConfig",
    meta: secretFeatureConfigYldxAMG1NnMeta || {},
    component: () => import("/home/runner/work/hokify/hokify/apps/user-app/src/pages/pwa/secretFeatureConfig.vue")
  },
  {
    name: "pwa-videoUploadPage",
    path: "/pwa/videoUploadPage",
    meta: videoUploadPagerAhOv7mIzwMeta || {},
    component: () => import("/home/runner/work/hokify/hokify/apps/user-app/src/pages/pwa/videoUploadPage.vue")
  },
  {
    name: "pwa-webupload-docType",
    path: "/pwa/webupload/:docType?",
    meta: _91docType_93G9grEBDZhVMeta || {},
    component: () => import("/home/runner/work/hokify/hokify/apps/user-app/src/pages/pwa/webupload/[docType].vue")
  }
]