<template>
	<!-- this class is needed for CvGeneralData.focusBirthday to work !-->
	<main class="inside-page-scroll-container">
		<section class="container">
			<div class="mt-3 md:mt-8 pb-4">
				<h4 class="text-color-main">{{ percentDone }}% erledigt</h4>

				<!-- EXPERIENCE -->
				<div v-if="currentStage === STAGE.Experience && usesHokifyCv" key="stage-experience">
					<ReviewExperience :current-stage-value="STAGE.Experience" />
				</div>

				<!-- DuplicateExperience -->
				<div
					v-if="currentStage === STAGE.DuplicateExperience && usesHokifyCv"
					key="stage-duplicateexperience"
				>
					<ReviewExperience :current-stage-value="STAGE.DuplicateExperience" />
				</div>

				<!-- ContentExperience -->
				<div
					v-if="currentStage === STAGE.ContentExperience && usesHokifyCv"
					key="stage-contentexperience"
				>
					<ReviewExperience :current-stage-value="STAGE.ContentExperience" />
				</div>

				<!-- EDUCATION -->
				<div v-else-if="currentStage === STAGE.Education && usesHokifyCv" key="stage-education">
					<ReviewEducation :current-stage-value="STAGE.Education" />
				</div>

				<!-- DuplicateEducation -->
				<div
					v-else-if="currentStage === STAGE.DuplicateEducation && usesHokifyCv"
					key="stage-duplicateeducation"
				>
					<ReviewEducation :current-stage-value="STAGE.DuplicateEducation" />
				</div>

				<!-- ContentEducation -->
				<div
					v-else-if="currentStage === STAGE.ContentEducation && usesHokifyCv"
					key="stage-ContentEducation"
				>
					<ReviewEducation :current-stage-value="STAGE.ContentEducation" />
				</div>

				<!-- PHOTO -->
				<div v-else-if="currentStage === STAGE.Photo" key="stage-photo">
					<section class="mb-8 sm:p-5 sm:shadow-lg sm:rounded-lg overflow-hidden">
						<h2 class="mb-1">
							{{ errorCodes.failedCvCriteria[currentStage] }}
						</h2>
						<p>
							{{ errorCodes.failedCvCriteria.description[currentStage] }}
						</p>
						<PicUpload :show-save-button="false" always-show-empty-state class="-mx-4" />
					</section>
				</div>

				<!-- GeneralData -->
				<div v-else-if="currentStage === STAGE.GeneralData" key="stage-general">
					<section class="mb-8 sm:p-5 sm:shadow-lg sm:rounded-lg overflow-hidden">
						<h2 class="mb-1">
							{{ errorCodes.failedCvCriteria[currentStage] }}
						</h2>
						<p>
							{{ errorCodes.failedCvCriteria.description[currentStage] }}
						</p>

						<h2 class="smaller pt-4">Allgemeine Daten</h2>
						<div class="flex flex-wrap">
							<div class="w-full md:w-1/2">
								<CvGeneralData
									ref="cvGeneralDataFirstname"
									:display-fields="['firstname', 'lastname', 'birthday', 'nationality']"
									check-for-work-permit
									autofocus="firstname"
									:class="{ 'mb-36': !$isMobile.any }"
								/>
							</div>
							<div class="w-full md:w-1/2">
								<CvGeneralData
									ref="cvGeneralDataResidence"
									:display-fields="['residence']"
									:class="{ 'mb-36': !$isMobile.any }"
								/>
							</div>
						</div>
					</section>
				</div>

				<!-- ContactData -->
				<div v-else-if="currentStage === STAGE.ContactData" key="stage-contact-data">
					<section class="mb-8 sm:p-5 sm:shadow-lg sm:rounded-lg overflow-hidden">
						<h2 class="mb-1">
							{{ errorCodes.failedCvCriteria[currentStage] }}
						</h2>
						<p>
							{{ errorCodes.failedCvCriteria.description[currentStage] }}
						</p>
						<h2 class="smaller pt-4">Kontaktdaten</h2>
						<div class="mx-4 pb-6">
							<CvGeneralData
								ref="cvGeneralDataPhone"
								:display-fields="['phone', 'email']"
								autofocus="phone"
							/>
						</div>
					</section>
				</div>

				<!-- CV Upload -->
				<div
					v-else-if="
						!usesHokifyCv &&
						(currentStage === STAGE.NoCV ||
							currentStage === STAGE.BadFormat ||
							currentStage === STAGE.BadPhotoCv ||
							currentStage === STAGE.TwistedCV ||
							currentStage === STAGE.Experience ||
							currentStage === STAGE.Education ||
							currentStage === STAGE.DuplicateExperience ||
							currentStage === STAGE.DuplicateEducation ||
							currentStage === STAGE.OutdatedCv ||
							currentStage === STAGE.CvGap ||
							currentStage === STAGE.ContentExperience ||
							currentStage === STAGE.ContentEducation ||
							currentStage === STAGE.MissingExperienceEducation ||
							currentStage === STAGE.WrongLanguage)
					"
					key="stage-cv"
				>
					<section class="sm:p-5 sm:shadow-lg sm:rounded-lg overflow-hidden">
						<h2 class="mb-1">
							{{ errorCodes.failedCvCriteria[currentStage] }}
						</h2>
						<p>
							{{ errorCodes.failedCvCriteria.description[currentStage] }}
						</p>
						<h2 class="smaller">Lebenslauf</h2>
						<ShowUploadedCv />
					</section>
				</div>

				<!-- OutdatedCv -->
				<div v-else-if="currentStage === STAGE.OutdatedCv && usesHokifyCv" key="stage-outdatedcv">
					<ReviewExperience :current-stage-value="STAGE.OutdatedCv" />
					<ReviewEducation :current-stage-value="STAGE.OutdatedCv" :show-header="false" />
				</div>

				<!-- CvGap -->
				<div v-else-if="currentStage === STAGE.CvGap && usesHokifyCv" key="stage-cvgap">
					<ReviewExperience :current-stage-value="STAGE.CvGap" />
					<ReviewEducation :current-stage-value="STAGE.CvGap" :show-header="false" />
				</div>

				<!-- MissingExperienceEducation -->
				<div
					v-else-if="currentStage === STAGE.MissingExperienceEducation && usesHokifyCv"
					key="stage-missingexperienceeducation"
				>
					<ReviewExperience :current-stage-value="STAGE.MissingExperienceEducation" />
					<ReviewEducation
						:current-stage-value="STAGE.MissingExperienceEducation"
						:show-header="false"
					/>
				</div>

				<!-- WrongLanguage -->
				<div
					v-else-if="currentStage === STAGE.WrongLanguage && usesHokifyCv"
					key="stage-wronglanguage"
				>
					<ReviewExperience :current-stage-value="STAGE.WrongLanguage" />
					<ReviewEducation :current-stage-value="STAGE.WrongLanguage" :show-header="false" />
				</div>

				<!-- Workpermit -->
				<div v-else-if="currentStage === STAGE.Workpermit" key="stage-workpermit">
					<UploadWorkPermit :current-stage-value="STAGE.Workpermit" />
				</div>

				<!-- videos are not working at the moment with nuxt 3 - will need re-implementation next quarter -->
				<!-- ProfilevideoBadQuality -->
				<!--				<div
					v-else-if="currentStage === STAGE.ProfilevideoBadQuality"
					key="stage-profilevideobadquality"
				>
					<section class="mb-16 sm:p-5 sm:shadow-lg sm:rounded-lg overflow-hidden">
						<h2 class="mb-1">
							{{ errorCodes.failedCvCriteria[currentStage] }}
						</h2>
						<p>
							{{ errorCodes.failedCvCriteria.description[currentStage] }}
						</p>
						<VideoUpload always-show-empty-state video-tracking-category="review-profile" />
					</section>
				</div>-->

				<!-- ProfilevideoBadContent -->
				<!--				<div
					v-else-if="currentStage === STAGE.ProfilevideoBadContent"
					key="stage-profilevideobadcontent"
				>
					<section class="mb-8 sm:p-5 sm:shadow-lg sm:rounded-lg overflow-hidden">
						<h2 class="mb-1">
							{{ errorCodes.failedCvCriteria[currentStage] }}
						</h2>
						<p>
							{{ errorCodes.failedCvCriteria.description[currentStage] }}
						</p>
						<VideoUpload video-tracking-category="review-profile" />
					</section>
				</div>-->

				<!-- Reviewed Document -->
				<div
					v-else-if="currentStage === 'rejecteddocument'"
					:key="`stage-rejecteddocument-${currentSubStage}`"
				>
					<UploadDocument
						:current-stage-value="currentStage"
						:document-name="documentTypes[currentSubStage]"
						:document-type="currentSubStage"
					/>
				</div>

				<Spinner v-if="loading" />
				<ButtonRound
					v-else
					:styling="valid || needsExplicitSaving ? 'b2c' : 'disabled'"
					@click="nextStage"
				/>
			</div>
		</section>
	</main>
</template>

<script lang="ts">
import ButtonRound from '@hokify/shared-components-nuxt3/lib/components/ButtonRound.vue';
import CvGeneralData from '@hokify/shared-components-nuxt3/lib/components/CvGeneralData.vue';
import type { IAPILoginUser } from '@hokify/common';
import { errorCodes } from '@hokify/shared-components-nuxt3/lib/data/errorCodes';
import { mapStores } from 'pinia';
// import VideoUpload from '@/components/user/profile/VideoUpload.vue';
import PicUpload from '@/components/user/profile/PicUpload.vue';
import { useMatchesStore } from '@/stores/matches';
import { useUserProfileStore } from '@/stores/user-profile';
import { useUserReviewStore } from '@/stores/user-review';
import { useRoute, defineNuxtComponent, definePageMeta } from '#imports';
import { useValuesStore } from '@/stores/values';
import UploadWorkPermit from '@/components/user/review-profile/UploadWorkPermit.vue';
import ReviewEducation from '@/components/user/review-profile/ReviewEducation.vue';
import UploadDocument from '@/components/user/review-profile/UploadDocument.vue';
import ReviewExperience from '@/components/user/review-profile/ReviewExperience.vue';
import ShowUploadedCv from '@/components/user/hokifyCvEdit/ShowUploadedCv.vue';

enum FailedCvCriteriaStatic {
	Education = 'education',
	Experience = 'experience',
	Photo = 'photo',
	NoCV = 'nocv',
	BadPhotoCv = 'badphotocv',
	TwistedCV = 'twistedcv',
	BadFormat = 'badformat',
	GeneralData = 'generaldata',
	ContactData = 'contactdata',
	OutdatedCv = 'outdatedcv',
	CvGap = 'cvgap',
	Workpermit = 'workpermit',
	DuplicateExperience = 'duplicateexperience',
	DuplicateEducation = 'duplicateeducation',
	// ProfilevideoBadQuality = 'profilevideobadquality',
	// ProfilevideoBadContent = 'profilevideobadcontent',
	WrongLanguage = 'wronglanguage',
	ContentExperience = 'contentexperience',
	ContentEducation = 'contenteducation',
	MissingExperienceEducation = 'missingexperienceeducation'
}

type FailedCvCriteria = FailedCvCriteriaStatic | `rejecteddocument-${string}`;

function hasSave(cvGeneralData: any): cvGeneralData is { save(): Promise<boolean> } {
	return cvGeneralData && typeof cvGeneralData.save === 'function';
}

export default defineNuxtComponent({
	setup() {
		definePageMeta({
			layout: 'user',
			middleware: ['auth']
		});
	},
	transition: {
		name: 'fade',
		mode: 'out-in'
	},
	components: {
		// VideoUpload,
		PicUpload,
		UploadWorkPermit,
		ReviewEducation,
		ReviewExperience,
		ButtonRound,
		ShowUploadedCv,
		CvGeneralData,
		UploadDocument
	},
	async asyncData({ $pinia }) {
		const { params } = useRoute();

		const currentStage = params.stage as FailedCvCriteriaStatic | 'rejecteddocument';
		const currentSubStage = params.substage;

		const valuesStore = useValuesStore($pinia);

		const documentTypeArray = await valuesStore.getUserDocumenttypes();
		const documentTypes = documentTypeArray.reduce((acc, curr) => {
			acc[`${curr.type}:${curr.key}`] = curr.name;
			return acc;
		}, {});

		return {
			documentTypes,
			currentStage,
			currentSubStage
		};
	},
	data() {
		const currentStage = undefined as undefined | FailedCvCriteriaStatic | 'rejecteddocument';
		const currentSubStage = undefined as string | undefined;
		const loading = false;
		const noChanges = false;
		const STAGE = { ...FailedCvCriteriaStatic, REJECTED_DOCUMENT: 'rejecteddocument' };

		return {
			currentStage,
			currentSubStage,
			loading,
			noChanges,
			STAGE,
			errorCodes,
			documentTypes: {}
		};
	},
	computed: {
		user(): IAPILoginUser | undefined {
			return this.userProfileStore.obj;
		},
		percentDone() {
			return this.userReviewStore.percentDone;
		},
		needsExplicitSaving(): boolean {
			return (
				this.currentStage === this.STAGE.GeneralData || this.currentStage === this.STAGE.ContactData
			);
		},
		stage(): FailedCvCriteria {
			return `${this.currentStage}${this.currentSubStage ? `-${this.currentSubStage}` : ''}` as FailedCvCriteria;
		},
		valid() {
			// check if currentStage is still in userObjCvFails
			return this.stage && !this.userObjCvFails.includes(this.stage);
		},
		userObjCvFails(): FailedCvCriteria[] {
			return (this.userProfileStore.obj?.reviewResult?.failedCvCriteria ||
				[]) as FailedCvCriteria[];
		},
		errorName() {
			const errorCode = this.currentStage && this.errorCodes.failedCvCriteria[this.currentStage];
			if (this.currentStage !== 'rejecteddocument') {
				return errorCode;
			}

			if (this.currentSubStage) {
				return errorCode?.replace(
					'{{documentName}}',
					this.documentTypes[this.currentSubStage] || this.currentSubStage || ''
				);
			}

			return errorCode;
		},
		usesHokifyCv() {
			let myCv;
			this.userProfileStore.obj?.extras?.some(extra => {
				if (extra?.type?.toLowerCase() === 'cv') {
					myCv = extra;
					return true;
				}
				return false;
			});

			return myCv?.details === 'hokify';
		},
		...mapStores(useMatchesStore, useUserProfileStore, useUserReviewStore)
	},
	created() {
		this.userReviewStore.computeCvFails();

		// if stage is unknown, go to overview
		const stageArray = Object.values(this.STAGE);
		if (!stageArray.includes(this.currentStage)) {
			console.log('INVALID STAGE', this.currentStage);
			this.$router.push('/pwa/review-profile');
		}
	},
	async mounted() {
		try {
			// fill store with matches
			await this.matchesStore.getSaved();
		} catch (err) {
			this.$nuxt.$errorHandler(err);
		}
	},
	methods: {
		async nextStage() {
			this.loading = true;

			// if it is a cvGeneralData, call save method
			if ((this.$refs && this.$refs.cvGeneralDataFirstname) || this.$refs.cvGeneralDataResidence) {
				if (hasSave(this.$refs.cvGeneralDataFirstname)) {
					await this.$refs.cvGeneralDataFirstname.save();
				}
				if (hasSave(this.$refs.cvGeneralDataResidence)) {
					await this.$refs.cvGeneralDataResidence.save();
				}
			} else if (this.$refs && this.$refs.cvGeneralDataPhone) {
				if (hasSave(this.$refs.cvGeneralDataPhone)) {
					await this.$refs.cvGeneralDataPhone.save();
				}
			}

			// only go to nextStage, if fail is not in userObjCvFails array anymore
			if (this.stage && !this.userObjCvFails.includes(this.stage)) {
				this.noChanges = false;

				// track end of current stage
				this.$trackUserEvent?.('user_review_profile_questions_finish', {
					questionType: this.currentStage
				});

				// if there is a next stage, open it - if not, go to overview again
				if (this.userObjCvFails?.length) {
					// track start of first stage
					this.$trackUserEvent?.('user_review_profile_questions_start', {
						questionType: this.userObjCvFails[0]
					});
					this.$router.push(`/pwa/review-profile/${this.userObjCvFails[0].replace('-', '/')}`);
				} else {
					this.$router.push('/pwa/review-profile');
				}
			} else {
				this.noChanges = true;
				this.loading = false;
				this.$snack.danger({
					text: `${this.errorName}`
				});
			}
		},
		async doUseHokifyCv() {
			try {
				await this.userProfileStore.useHokifyCv();
			} catch (err: any) {
				if (
					this.$isHokFetchResponseError(err) &&
					err.response?.data.code === 'INCOMPLETE_HOKIFY_CV'
				) {
					this.$snack.danger({
						text: 'Um fortzufahren muss dein Lebenslauf vollständiger sein!'
					});
					return;
				}
				this.$snack.danger({
					text: 'Es ist ein Fehler aufgetreten, beim Versuch den hokify Lebenslauf zu verwenden.'
				});
				this.$nuxt.$errorHandler(
					err,
					'Es ist ein Fehler aufgetreten, beim Versuch den hokify Lebenslauf zu verwenden.'
				);
			}
		},
		onCvFailsChanged() {
			this.noChanges = false;
			this.userReviewStore.calcPercent();
		}
	},
	watch: {
		userObjCvFails: [
			{
				handler: 'onCvFailsChanged'
			}
		]
	}
});
</script>
