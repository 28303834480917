import { defineStore } from 'pinia';
import type {
	APIMatchRelation,
	APIObjectType,
	APITypeObjectId,
	IAPIJobForUser,
	IAPIMatchForJobSeeker
} from '@hokify/common';
import type { IJobSeekerTrackingOptions } from '@hokify/tracking-nuxt3/types';
import { getCookie } from '@hokify/shared-components-nuxt3/lib/helpers/cookie';
import { getGoogleAnalyticsClientId } from '@hokify/tracking-nuxt3/tracking.client';
import { useMatchesStore } from './matches';
import { useUserProfileStore } from './user-profile';

interface IApiMatchActionResponse {
	matchObj?: IAPIMatchForJobSeeker;
	action: 'contact' | 'applied' | 'pending' | 'saved' | 'discard' | 'needreview';
	matchId?: APITypeObjectId<APIObjectType.Match>;
	userObjUpdate?: Record<string, unknown>;
}

export type ListType =
	| {
			type: 'matchrelation';
			identifier: string;
			relation: APIMatchRelation;
	  }
	| {
			type: 'question';
			identifier: string;
			question: string;
			questionId: APITypeObjectId<APIObjectType.Question>;
	  }
	| {
			type: 'info';
			identifier: string;
			title: string;
			text: string;
	  };

export interface IRelationsState {
	discarded: any[];
	searchresults: {
		totalJobs: number;
		list: ListType[];
	};
	suggestionsCurrentlyLoading: Promise<any> | false;
	fullyLoaded: { [jobNrOrId: string]: IAPIJobForUser }; // used to store fully loaded jobs
	lastIdentifier: string | undefined;
	numberOrId: { idOrNr: string; audienceId?: string } | undefined;
}

export const useRelationsStore = defineStore('relations', {
	state: (): IRelationsState => ({
		discarded: [],
		searchresults: {
			totalJobs: 0,
			list: []
		},
		suggestionsCurrentlyLoading: false,
		fullyLoaded: {}, // used to store fully loaded relations
		lastIdentifier: undefined,
		numberOrId: undefined
	}),
	actions: {
		async getJob({ jobNr, force = false }): Promise<IAPIJobForUser> {
			if (!force && this.fullyLoaded[jobNr]) {
				return this.fullyLoaded[jobNr];
			}
			const job = await this.$nuxt.$hokFetch<IAPIJobForUser>(
				`/app-api/jobseeker/job/byNr/${jobNr}`
			);

			this.saveFullyLoaded(job);
			return job;
		},

		async getJobById(jobId) {
			if (this.fullyLoaded[jobId]) {
				return this.fullyLoaded[jobId];
			}
			const job = await this.$nuxt.$hokFetch(`/app-api/jobseeker/job/byId/${jobId}`);

			this.saveFullyLoaded(job);
			return job;
		},

		async loadMoreCards(
			reset?: boolean,
			suggestionsDiversity = false,
			useJobRecommendation = false
		) {
			if (reset) {
				this.setSearchresults({ totalJobs: 0, list: [] });
			}

			const lastIdentifier = this.lastIdentifier || this.searchresults?.list[0]?.identifier;

			const params: Record<string, unknown> = { suggestionsDiversity, useJobRecommendation };

			if (!reset) {
				params.lastIdentifier = lastIdentifier;
			}

			const currentyLoading = this.$nuxt.$hokFetch('/app-api/jobseeker/search/suggestions', {
				params
			});

			this.setSuggestionsCurrentlyLoading(currentyLoading);

			const suggestions = await currentyLoading;
			if (suggestions) {
				this.appendSearchresults(suggestions);
			}

			this.setSuggestionsCurrentlyLoading(false);

			return this.searchresults;
		},

		undoDiscardRelation() {
			const lastDiscardedRelation =
				this.discarded && this.discarded.length > 0 && this.discarded[this.discarded.length - 1];
			if (!lastDiscardedRelation) {
				return false;
			}

			// call match-action API if lastDiscardedRelation is a matchrelation
			if (
				lastDiscardedRelation &&
				lastDiscardedRelation?.type &&
				lastDiscardedRelation?.type === 'matchrelation'
			) {
				return this.$nuxt
					.$hokFetch('/app-api/jobseeker/match-action/undo', {
						method: 'POST',
						body: {
							relation: lastDiscardedRelation.relation.type,
							relationId: lastDiscardedRelation.relation.obj._id
						}
					})
					.then(() => {
						this.undoRelationCard(lastDiscardedRelation);
						return lastDiscardedRelation;
					});
			}
			// only undo on client for all other card types
			this.undoRelationCard(lastDiscardedRelation);
			return lastDiscardedRelation;
		},

		loadJobCards(suggestionsDiversity = false, useJobRecommendation = false) {
			if (!(this.searchresults.list.length > 4) && !this.suggestionsCurrentlyLoading) {
				return this.loadMoreCards(false, suggestionsDiversity, useJobRecommendation);
			}
		},

		resetDiscarded() {
			return this.$nuxt
				.$hokFetch('/app-api/jobseeker/search/reset', { method: 'POST', body: {} })
				.then(() => {
					this.resetlastIdentifier();
					this.loadMoreCards();
				});
		},

		async discardRelation(discardedRelation) {
			// only call discard API if type is matchrelation
			if (discardedRelation?.type === 'matchrelation') {
				const cookies = import.meta.client && window.document.cookie;
				const utm = (cookies && getCookie('utm', cookies)) || undefined;

				return this.$nuxt
					.$hokFetch('/app-api/jobseeker/match-action/discard', {
						method: 'POST',
						body: {
							relation: discardedRelation.relation.type,
							relationId: discardedRelation.relation.obj._id,
							audienceId: discardedRelation.relation.audienceId,
							utm,
							gaCID: await getGoogleAnalyticsClientId(),
							fbp: (cookies && getCookie('_fbp', cookies)) || undefined,
							fbc: (cookies && getCookie('_fbc', cookies)) || undefined
						}
					})
					.then(() => {
						this.discardRelationCard(discardedRelation);
					})
					.catch(err => {
						this.discardRelationCard(discardedRelation); // still throw it away for now
						throw err;
					});
			}
			// discard all other cards on client
			this.discardRelationCard(discardedRelation);
		},

		async applyToRelation({
			relation,
			acceptedPrivacyApplication
		}: {
			relation: APIMatchRelation;
			acceptedPrivacyApplication?: boolean;
		}): Promise<IApiMatchActionResponse> {
			const cookies = import.meta.client && window.document.cookie;
			const utm = (cookies && getCookie('utm', cookies)) || undefined;
			const matchesStore = useMatchesStore();

			const result = await this.$nuxt.$hokFetch<IApiMatchActionResponse>(
				'/app-api/jobseeker/match-action/apply',
				{
					method: 'POST',
					body: {
						privacy_user_application: acceptedPrivacyApplication,
						relation: relation.type,
						relationId: relation.obj._id,
						utm,
						gaCID: await getGoogleAnalyticsClientId(),
						fbp: (cookies && getCookie('_fbp', cookies)) || undefined,
						fbc: (cookies && getCookie('_fbc', cookies)) || undefined
					}
				}
			);

			if (!result) {
				throw new Error('no result');
			}

			const { matchObj, userObjUpdate } = result;

			if (userObjUpdate) {
				const userProfileStore = useUserProfileStore();
				userProfileStore.updateElements(userObjUpdate);
			}

			// save fullyLoadedMatch in store
			this.discardRelationCard({
				relation,
				identifier: `matchrelation:${relation.type}:${relation.obj._id}`
			});
			matchesStore.saveMatch(matchObj);
			return result;
		},

		async saveRelation(relation) {
			const cookies = import.meta.client && window.document.cookie;
			const utm = (cookies && getCookie('utm', cookies)) || undefined;
			const result = await this.$nuxt.$hokFetch<IApiMatchActionResponse>(
				'/app-api/jobseeker/match-action/save',
				{
					method: 'POST',
					body: {
						relation: relation.type,
						relationId: relation.obj._id,
						audienceId: relation.audienceId,
						utm,
						gaCID: await getGoogleAnalyticsClientId(),
						fbp: (cookies && getCookie('_fbp', cookies)) || undefined,
						fbc: (cookies && getCookie('_fbc', cookies)) || undefined
					}
				}
			);

			if (!result) throw new Error('no result');

			const { matchObj } = result;
			// save in store
			this.discardRelationCard(relation);
			if (matchObj) {
				const matchesStore = useMatchesStore();
				matchesStore.saveMatch(matchObj);
			}
		},

		async jobSeekerTracking(body: IJobSeekerTrackingOptions) {
			await this.$nuxt.$hokFetch('app-api/jobseeker/tracking/track-action', {
				method: 'POST',
				body
			});
		},

		async jobSuggestions(suggestionsDiversity = false) {
			const params: Record<string, unknown> = {
				limit: 3,
				fallbackOnNoFilter: true,
				jobsOnly: true,
				suggestionsDiversity
			};

			return this.$nuxt.$hokFetch('/app-api/jobseeker/search/suggestions', { params });
		},
		saveFullyLoaded(job) {
			this.fullyLoaded[job._id] = job; // lookup by _id
			this.fullyLoaded[job.jobNr] = job; // lookup by jobNr
		},
		resetlastIdentifier() {
			this.lastIdentifier = undefined;
		},
		setSearchresults(suggestions) {
			this.searchresults = suggestions;
		},
		setSuggestionsCurrentlyLoading(currentyLoading) {
			this.suggestionsCurrentlyLoading = currentyLoading;
		},
		appendSearchresults(suggestions) {
			if (suggestions.totalJobs) {
				this.searchresults.totalJobs = suggestions.totalJobs;
			}

			suggestions.list.forEach(suggestion => {
				// only add suggestions, which are not in store yet
				if (!this.searchresults.list.some(sugg => sugg.identifier === suggestion.identifier)) {
					this.searchresults.list.unshift(suggestion);
				}
				this.lastIdentifier = suggestion.identifier;
			});
		},
		discardRelationCard(discardedRelation) {
			const removedFromStack = this.searchresults.list.some((searchResult, index) => {
				if (searchResult?.identifier === discardedRelation?.identifier) {
					this.searchresults.list.splice(index, 1);
					return true;
				}
				return false;
			});

			if (removedFromStack) {
				this.discarded.push(discardedRelation);
			}
		},
		undoRelationCard(discardedRelation) {
			// remove from discarded
			this.discarded.some((disc, index) => {
				if (disc && disc.identifier && disc.identifier === discardedRelation.identifier) {
					this.discarded.splice(index, 1);
					return true;
				}
				return false;
			});

			// if not already in list, add it
			if (
				!this.searchresults.list.some(
					searchResult => searchResult.identifier === discardedRelation.identifier
				)
			) {
				this.searchresults.list.push(discardedRelation);
			}
		},
		updateNumberOrId(newNumberOrId) {
			this.numberOrId = newNumberOrId;
		}
	}
});
