<template>
	<main>
		<PicUpload @pic-uploaded="EventBus.$emit('pic-uploaded')" />
	</main>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { EventBus } from '@hokify/shared-components-nuxt3/lib/eventbus';
import PicUpload from '@/components/user/profile/PicUpload.vue';
import { definePageMeta } from '#imports';

export default defineComponent({
	setup() {
		definePageMeta({
			layout: 'user',
			middleware: ['auth']
		});
	},
	components: { PicUpload },
	data() {
		return {
			EventBus
		};
	}
});
</script>
