import { defineStore } from 'pinia';
import type {
	APIMatchRelation,
	IAPIMatchForJobSeeker,
	IAPIMatchForJobSeekerListEntry,
	IInterviewQuestion,
	LikeUser
} from '@hokify/common';

interface IState {
	all: {
		match: IAPIMatchForJobSeekerListEntry | IAPIMatchForJobSeeker;
		fullyLoaded: boolean;
	}[];
}

let loadedApplied: IAPIMatchForJobSeekerListEntry[] | undefined;
let loadedSaved: IAPIMatchForJobSeekerListEntry[] | undefined;
let loadedActivesourced: IAPIMatchForJobSeekerListEntry[] | undefined;

function isFullyLoadedMatchObj(
	obj: any
): obj is { match: IAPIMatchForJobSeeker; fullyLoaded: boolean } {
	return obj.fullyLoaded;
}

export const useMatchesStore = defineStore('matches', {
	state: (): IState => ({
		all: []
	}),
	getters: {
		getAll(state) {
			return state.all
				.filter(obj => !!obj.match) // filter out invalid entries
				.map(obj => obj.match);
		},
		getStatusOfRelation(state) {
			return (relation: APIMatchRelation) => {
				let status: LikeUser | undefined;
				state.all.some(m => {
					if (m?.match?.relation?.obj?._id === relation?.obj?._id) {
						status = m.match.likeuser;
						return true;
					}
					return false;
				});

				return status;
			};
		},
		getMatchByRelation(
			state
		): (
			relation: APIMatchRelation
		) => IAPIMatchForJobSeekerListEntry | IAPIMatchForJobSeeker | undefined {
			return relation =>
				state.all.find(obj => obj?.match?.relation?.obj?._id === relation?.obj?._id)?.match;
		}
	},
	actions: {
		async getUserMatch(matchId: string): Promise<IAPIMatchForJobSeeker | undefined> {
			// if match is already in store, return it
			const fullyLoadedMatch = this.all.find(m => {
				if (m?.match?._id?.toString?.() === matchId.toString() && isFullyLoadedMatchObj(m)) {
					return true;
				}
				return false;
			});
			if (fullyLoadedMatch) {
				return fullyLoadedMatch.match as IAPIMatchForJobSeeker;
			}

			// if not, get store from API & save it into store
			const matchResult = await this.$nuxt.$hokFetch<IAPIMatchForJobSeeker>(
				`/app-api/jobseeker/match/byId?matchId=${matchId}`
			);
			if (matchResult) {
				const matchFromApi = {
					match: matchResult,
					fullyLoaded: true
				};
				this.saveMatch(matchFromApi);
			}
			return matchResult;
		},
		async getUserMatchByRelation({
			relation,
			force = false
		}): Promise<IAPIMatchForJobSeeker | undefined> {
			if (!force) {
				// if match is already in store, return it
				const fullyLoadedMatch = this.all.find(
					m =>
						!!(
							m.match?._id?.toString() === relation?.obj?._id.toString() && isFullyLoadedMatchObj(m)
						)
				);

				if (fullyLoadedMatch) {
					return fullyLoadedMatch.match as IAPIMatchForJobSeeker;
				}
			}

			// get store from API & save it into store
			const matchResult = await this.$nuxt.$hokFetch<IAPIMatchForJobSeeker | undefined>(
				`/app-api/jobseeker/match/byRelation?relation=${relation?.type}&relationId=${relation?.obj?._id}`
			);

			if (matchResult) {
				const matchFromApi = {
					match: matchResult,
					fullyLoaded: true
				};

				this.saveMatch(matchFromApi);
			}

			return matchResult;
		},
		async getApplied(forceReload = false) {
			// if API call was already made and result is available, return it
			if (loadedApplied && !forceReload) {
				return loadedApplied;
			}

			// if not, call API and save result into store
			const result = await this.$nuxt.$hokFetch<IAPIMatchForJobSeekerListEntry[]>(
				'/app-api/jobseeker/applications'
			);
			loadedApplied = result;

			if (result?.length) {
				this.saveListOfMatches(result);
			}

			return result;
		},
		async getSaved(forceReload = false) {
			// if API call was already made and result is available, return it
			if (loadedSaved && !forceReload) {
				return loadedSaved;
			}

			// if not, call API and save result into store
			const result = await this.$nuxt.$hokFetch<IAPIMatchForJobSeekerListEntry[]>(
				'/app-api/jobseeker/saved'
			);
			loadedSaved = result;

			if (result?.length) {
				this.saveListOfMatches(result);
			}

			return result;
		},
		async deleteMatch({ match }) {
			const result = await this.$nuxt.$hokFetch(`/app-api/jobseeker/match/${match._id}`, {
				method: 'DELETE'
			});

			this.removeMatch(match);

			return result;
		},
		async getActivesourced(forceReload = false) {
			// if API call was already made and result is available, return it
			if (loadedActivesourced && !forceReload) {
				return loadedActivesourced;
			}

			// if not, call API and save result into store
			const result = await this.$nuxt.$hokFetch<IAPIMatchForJobSeekerListEntry[]>(
				'/app-api/jobseeker/activesourced'
			);
			loadedActivesourced = result;
			if (result?.length) {
				this.saveListOfMatches(result);
			}

			return result;
		},
		setUnseenMsgsUser({ matchId, reset, count }) {
			const found = this.all.some(m => {
				if (m.match._id === matchId) {
					const index = this.all.indexOf(m);
					if (reset) {
						this.all[index].match.openMessages = 0;
					} else if (count && count === '+1') {
						this.all[index].match.openMessages = m.match.openMessages + 1;
					} else if (count && count === '-1') {
						this.all[index].match.openMessages = m.match.openMessages - 1;
					} else if (count) {
						this.all[index].match.openMessages = count;
					}
					return true;
				}
				return false;
			});
			if (!found) {
				console.warn('setUnseenMsgsUser', 'no match found to update openMessages', matchId);
			}
		},
		// these matches are NOT fullyLoaded
		saveListOfMatches(matches) {
			matches.forEach(matchObj => {
				if (!matchObj) {
					console.warn('saveListOfMatches with empty matchObj', matchObj);
					return;
				}

				const notFullyLoadedMatch = {
					match: matchObj,
					fullyLoaded: false
				};

				// if match is already in store, update it
				const found = this.all.some((m, i) => {
					if (m.match._id === matchObj._id) {
						this.all[i] = notFullyLoadedMatch;
						return true;
					}
					return false;
				});
				// if match is not in store, add it
				if (!found) {
					this.all.push(notFullyLoadedMatch);
				}
			});
		},
		// these matches are fullyLoaded
		saveMatch(matchObj) {
			if (!matchObj) {
				console.warn('saveMatch with empty matchObj', matchObj);
				return;
			}

			const fullyLoadedMatchObj = {
				match: matchObj,
				fullyLoaded: true
			};

			// if match is already in store, update it
			const found = this.all.some((m, i) => {
				if (m.match._id === matchObj._id) {
					this.all[i] = fullyLoadedMatchObj;
					return true;
				}
				return false;
			});
			// if match is not in store, add it
			if (!found) {
				// unshift new match instead of pushing, to be sure it is on top
				this.all.unshift(fullyLoadedMatchObj);
			}
		},
		removeMatch(match) {
			this.all.some((m, index) => {
				if (m.match._id === match._id) {
					this.all.splice(index, 1);
					return true;
				}
				return false;
			});
		},
		updateInterviewAnswer({
			matchObj,
			answered,
			replace
		}: {
			matchObj: IAPIMatchForJobSeeker;
			answered: IInterviewQuestion;
			replace?: boolean;
		}) {
			// find match and update it
			const found = this.all.some(m => {
				if (
					m.match.relation.type === matchObj.relation.type &&
					m.match.relation.obj._id === matchObj.relation.obj._id &&
					isFullyLoadedMatchObj(m)
				) {
					if (!m.match.interviewQuestions) {
						m.match.interviewQuestions = [];
					}

					if (
						m.match.interviewQuestions &&
						!m.match.interviewQuestions.some((iQ, i) => {
							if (iQ.baseIdentifier === answered.baseIdentifier && m.match.interviewQuestions) {
								m.match.interviewQuestions[i] = answered;
								return true;
							}
							return false;
						})
					) {
						if (replace) {
							m.match.interviewQuestions.some((q, index) => {
								const qId = q.baseIdentifier;
								const aId = answered.baseIdentifier;
								if (qId === aId && m.match.interviewQuestions) {
									m.match.interviewQuestions[index] = answered;
									return true;
								}
								return false;
							});
						} else {
							// add it to the array
							m.match.interviewQuestions.push(answered);
						}
					}

					return true;
				}
				return false;
			});

			if (!found) {
				throw new Error('match is not in store');
			}
		}
	}
});
