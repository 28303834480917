import { defineStore } from 'pinia';
import type { IAPICompany, IAPIJobPreviewForUser } from '@hokify/common';

interface ICompanyDetail {
	jobCount: number;
	previewJobs: IAPIJobPreviewForUser[];
	company: IAPICompany;
}

interface IState {
	companyDetail: {
		[companyId: string]: ICompanyDetail;
	};
}

export const useCompanyStore = defineStore('company', {
	state: (): IState => ({
		companyDetail: {}
	}),
	actions: {
		async getCompany(companyId: string, audienceId?: string): Promise<ICompanyDetail> {
			if (this.companyDetail[companyId]) {
				return this.companyDetail[companyId];
			}

			const buildQueryParams: Record<string, string> = {};
			if (audienceId) {
				buildQueryParams.audienceId = audienceId;
			}

			const queryParams = new URLSearchParams(buildQueryParams).toString();

			return this.$nuxt
				.$hokFetch(
					`/app-api/jobseeker/company/by-id/${companyId}${queryParams ? `?${queryParams}` : ''}`
				)
				.then(companyDetail => {
					this.saveCompanyDetail(companyDetail);
					return { jobCount: Number(companyDetail.jobCount) || 0, ...companyDetail };
				});
		},
		saveCompanyDetail(companyDetail: ICompanyDetail) {
			this.companyDetail[companyDetail.company._id] = companyDetail;
		}
	}
});
