<template>
	<div>
		<div
			:class="`${
				companyMode ? 'bg-color-main-business' : 'bg-color-main'
			} relative onboarding-wrapper text-center ${
				(step && steps && !fullwidth) || userOnboarding ? 'steps' : ''
			}`"
		>
			<div v-if="(step && steps && !fullwidth) || userOnboarding" class="relative">
				<NuxtImg src="/shared-components-nuxt3/svgs/onboarding/wave.svg" class="steps-mobile" />
				<span
					class="goback absolute left-1 top-4 cursor-pointer"
					style="padding: 5px"
					@click="goBack"
				>
					<HokIcon name="icon:arrow-left" color="white" :size="3" />
				</span>
				<span v-if="step && steps && !fullwidth" class="progress">
					<span class="current-progress">{{ step }}</span
					>/{{ steps }}
				</span>
			</div>
			<NuxtImg
				v-else
				src="/shared-components-nuxt3/svgs/hokify/logo-brand-white.svg"
				alt="hokify"
				class="h-20 w-auto mx-auto pt-4"
				:class="{ 'cursor-pointer': redirectOnLogoClick }"
				@click="redirectOnLogoClick ? $emit('click-logo') : undefined"
			/>
			<NuxtImg v-if="imagePath" :src="imagePath" class="header-center-image" />
			<slot />
		</div>
		<div
			:class="{
				'header-wave': true,
				'hidden md:block': (step && steps && !fullwidth) || userOnboarding,
				'company-header-wave': companyMode
			}"
			:style="{
				backgroundImage: companyMode
					? `url('${$img('/shared-components-nuxt3/svgs/onboarding/wave_header_company.svg')}')`
					: `url('${$img('/shared-components-nuxt3/svgs/onboarding/wave_header.svg')}')`
			}"
		/>
	</div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
	name: 'HeaderOnboarding',
	emits: ['click-logo', 'go-back'],
	methods: {
		goBack() {
			this.$emit('go-back');
		}
	},
	props: {
		fullwidth: { type: Boolean, default: false },
		companyMode: { type: Boolean, default: false },
		redirectOnLogoClick: { type: Boolean, default: false },
		step: { type: Number, default: undefined },
		steps: { type: Number, default: undefined },
		imagePath: { type: String, default: undefined },
		userOnboarding: { type: Boolean, default: false }
	}
});
</script>

<style lang="scss" scoped>
.onboarding-wrapper {
	min-height: 100px;
	@media (min-width: $md) {
		min-height: 60px;
	}
}

.header-wave {
	height: 60px;
	background-position: top center;
	background-size: auto;
	background-repeat: no-repeat;
	margin-bottom: 2rem;
	width: 100vw; //this produces scroll bars in onboarding, but is needed - otherwise login screen is totally broken
}

.progress {
	position: absolute;
	font-size: $font-lg;
	color: #ffffff;
	z-index: 1;
	top: 0.5rem;
	left: 2rem;
	font-weight: normal;

	.current-progress {
		font-size: $font-xl3;
		font-weight: bold;
	}
}

@media (max-width: $md) {
	.steps-desktop {
		display: none;
	}
	.steps-mobile {
		display: block;
		height: 100px;
	}
	.onboarding-wrapper.steps {
		background-color: #ffffff;
	}
	.header-center-image {
		margin-top: -5rem;
	}
}

// simon says we do need double navigation!
/*.goback {
	display: none;
}*/

@media (min-width: $md) {
	.insidepage {
		.goback {
			display: none;
		}

		.progress {
			top: 0.8rem;
			left: 5rem;
		}
	}
	.steps-desktop {
		display: block;
	}
	.steps-mobile {
		display: none;
	}
	.onboarding-wrapper.steps {
		background-color: #0fb1af;
	}
	.header-center-image {
		margin-bottom: -2.5rem;
		margin-top: 1.25rem;
	}
}
</style>
