import routerOptions0 from "/home/runner/work/hokify/hokify/node_modules/.pnpm/nuxt@3.15.1_@types+node@20.17.23_eslint@8.57.1_sass-embedded@1.85.1_tsx@4.19.3_typescript@5.7.3/node_modules/nuxt/dist/pages/runtime/router.options.js";
import routerOptions1 from "/home/runner/work/hokify/hokify/apps/user-app/src/app/router.options.ts";
const configRouterOptions = {
  hashMode: false,
  scrollBehaviorType: "auto"
}
export const hashMode = routerOptions0.hashMode ?? false
export default {
...configRouterOptions,
...routerOptions0,
...routerOptions1,
}