import { defineStore } from 'pinia';
import { useUserProfileStore } from './user-profile';

export const useUserProfileExtraStore = defineStore('userProfileExtra', {
	state: () => ({}),
	actions: {
		async delExtra(extraId): Promise<{
			userUpdate: Record<string, unknown>;
		}> {
			const deleteObj = {
				path: 'extras',
				_id: extraId
			};

			if (!deleteObj._id) {
				throw new Error('delExtra: i need an extraId');
			}

			const update = await this.$nuxt.$hokFetch(`/app-api/profile-extra/${extraId}`, {
				method: 'DELETE'
			});

			if (update?.userUpdate) {
				const userProfileStore = useUserProfileStore();
				userProfileStore.updateElements(update.userUpdate);
			}

			return update;
		},
		async uploadExtra({
			postData
		}: {
			postData: any;
			onUploadProgress?: (evt: { loaded: number; total?: number }) => void;
		}) {
			const profileExtra = await this.$nuxt.$hokFetch('/app-api/updateProfileExtra', {
				method: 'POST',
				body: postData
			});
			const { extra, userUpdate } = profileExtra;
			if (userUpdate) {
				const userProfileStore = useUserProfileStore();
				userProfileStore.updateElements(userUpdate);
			}

			if (extra) {
				this.updateExtra({ details: false, ...extra }); // remove details, because of updating the hokify-cv
			}
			return profileExtra;
		},
		updateExtra(update) {
			const userProfileStore = useUserProfileStore();
			const user = userProfileStore.obj;

			let foundExtra = false;
			if (user && user.extras && user.extras.length > 0) {
				foundExtra = user.extras.some(extra => {
					if (update._id === extra._id) {
						foundExtra = { ...extra, ...update };
						const updateObj = {
							path: 'extras',
							_id: extra._id,
							update: foundExtra
						};
						// search and modify
						userProfileStore.updateElement(updateObj);
						return true;
					}
					return false;
				});
			}

			if (!foundExtra) {
				const addObj = {
					path: 'extras',
					obj: update
				};

				// add
				userProfileStore.addElement(addObj);
				return true;
			}
		},
		async sendFileuploadMail(data) {
			return this.$nuxt.$hokFetch('/api/send-fileupload-mail', { method: 'POST', body: data });
		},
		async uploadVideo({ url, file }: { url: string; file: Blob }): Promise<void> {
			await this.$nuxt.$hokFetch(url, {
				method: 'PUT',
				body: file,
				headers: { 'Content-Type': 'multipart/form-data' }
			});
		},
		async sendParsingUserFeedback(rating: number) {
			try {
				const result = await this.$nuxt.$hokFetch('/app-api/jobseeker/cv/userReview', {
					method: 'POST',
					body: { userRating: rating }
				});
				return result;
			} catch (error: any) {
				console.error('send parsing user feedback failed', error);
				throw error.response?.data || error.response || error;
			}
		},
		async checkCvParsingEnabled() {
			try {
				const result = await this.$nuxt.$hokFetch<{ enabled: boolean }>(
					'/app-api/cv-parsing-enabled?type=b2c'
				);
				const { enabled } = result;
				return enabled;
			} catch (error: any) {
				console.error('get cv designs failed', error);
				throw error.response?.data || error.response || error;
			}
		}
	}
});
