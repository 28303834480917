import { defineStore } from 'pinia';

export interface IUiStateState {
	isActiveSelected: boolean;
	doNotDisturbMode: boolean;
	openCalendars: [];
	unseenSavedJobs: boolean;
	unseenNotifications: boolean;
}

export const useUiStateStore = defineStore('uiState', {
	state: (): IUiStateState => ({
		isActiveSelected: false,
		doNotDisturbMode: false,
		openCalendars: [],
		unseenSavedJobs: false,
		unseenNotifications: false
	}),
	actions: {
		setDoNotDisturbMode(active) {
			this.doNotDisturbMode = !!active;
		},
		updateUnseenSavedJobs(hasNewSavedJob) {
			this.unseenSavedJobs = hasNewSavedJob;
		},
		updateUnseenNotifications(hasUnseenNotifications) {
			this.unseenNotifications = hasUnseenNotifications;
		}
	}
});
