/* eslint-disable @typescript-eslint/no-empty-interface */
/* eslint-disable @typescript-eslint/naming-convention */
import type {
	ILoginStore,
	IUserProfileStore,
	IUserRootStore,
	IRelationsStore,
	IUserJobFilterStore,
	IMessagesStore,
	IUserProfileExtraStore,
	IUserProfileGeneralStore,
	IValuesStore,
	IUserProfileExperienceStore,
	IMatchesStore,
	IInterviewStore,
	ILocationStore,
	ICompanyStore
} from '@hokify/nuxt-injected-interface/dist/stores';
import { useInterviewStore } from '../stores/interview';
import { useLoginStore } from '../stores/login';
import { useMatchesStore } from '../stores/matches';
import { useMessagesStore } from '../stores/messages';
import { useRelationsStore } from '../stores/relations';
import { useUserRootStore } from '../stores/root';
import { useUserJobFilterStore } from '../stores/user-job-filter';
import { useUserProfileStore } from '../stores/user-profile';
import { useUserProfileExperienceStore } from '../stores/user-profile-experience';
import { useUserProfileExtraStore } from '../stores/user-profile-extra';
import { useUserProfileGeneralStore } from '../stores/user-profile-general';
import { useValuesStore } from '../stores/values';
import { useLocationStore } from '../stores/location';

// We added this plugin mainly to keep existing store functionalities available in various plugins.
// Before adding any additional store operations to plugins, it might be better to rethink your approach!

/**
 * we need to inject various stores so we can make them available inside plugins
 *
 * @todo add needed types to index.ts file inside nuxt-injected-interface lib and
 *
 * @todo and if you added a new interface, add it below as a type (we then get build errors if there
 *   is a type mismatch between actual types and the "fake" types we created for plugins).
 */

export default defineNuxtPlugin({
	name: 'inject-stores',
	dependsOn: ['provide-nuxt-in-pinia'],
	setup() {
		const loginStore: ILoginStore = useLoginStore();
		const userProfileStore: IUserProfileStore = useUserProfileStore();
		const userRootStore: IUserRootStore = useUserRootStore();
		const relationsStore: IRelationsStore = useRelationsStore();
		const userJobFilterStore: IUserJobFilterStore = useUserJobFilterStore();
		const messagesStore: IMessagesStore = useMessagesStore();
		const userProfileExtraStore: IUserProfileExtraStore = useUserProfileExtraStore();
		const userProfileGeneralStore: IUserProfileGeneralStore = useUserProfileGeneralStore();
		const valuesStore: IValuesStore = useValuesStore();
		const userProfileExperienceStore: IUserProfileExperienceStore = useUserProfileExperienceStore();
		const matchesStore: IMatchesStore = useMatchesStore();
		const interviewStore: IInterviewStore = useInterviewStore();
		const locationStore: ILocationStore = useLocationStore();
		const companyStore: ICompanyStore = useCompanyStore();

		return {
			provide: {
				loginStore,
				userProfileStore,
				userRootStore,
				relationsStore,
				userJobFilterStore,
				messagesStore,
				userProfileExtraStore,
				userProfileGeneralStore,
				valuesStore,
				userProfileExperienceStore,
				matchesStore,
				interviewStore,
				locationStore,
				companyStore
			}
		};
	}
});
