import * as PathToRegexp from 'path-to-regexp';
import { EventBus } from '@hokify/shared-components-nuxt3/lib/eventbus';
import type {
	ISnackMessage,
	IVueSnack
} from '@hokify/shared-components-nuxt3/lib/plugins/snackbar/type';
import { nativeLinkOpener } from '@hokify/shared-components-nuxt3/lib/helpers/nativeLinkOpener';
import { setTrackingSource } from '@hokify/tracking-nuxt3/tracking.client';
import { lsTest } from '@hokify/shared-components-nuxt3/lib/helpers/localstorage';
import {
	APIObjectType,
	APITypeObjectId,
	IAPIJobForUser,
	IAPIMatchForJobSeeker
} from '@hokify/common';
import { defineAsyncComponent, markRaw } from 'vue';
import { isNotAuthenticatedError, NotAuthenticatedError } from '../errors/NotAuthenticatedError';

type DefaultParamsType = { [key: string]: string };
interface ICompiledRoute {
	route: PathToRegexp.MatchFunction<DefaultParamsType>;
	method: DeepLinkMethod;
}

const checkForObjectIdRegExp = /^[0-9a-fA-F]{24}$/;

type DeepLinkMethod = (
	params: DefaultParamsType,
	query: URLSearchParams | undefined,
	redirect: (param: {
		path: string;
		query?: any;
		permanently?: boolean;
		snack?: { type: keyof IVueSnack; options: ISnackMessage };
	}) => void,
	noStoreOperations: boolean, // TODO remove at the end of user app rewrite if we're certain this can be kicked out!
	address: string,
	store?: any // TODO remove once all apps are rewritten to pinia and moved to nuxt 3
) => boolean | Promise<boolean>;

type Apps = 'generic' | 'b2b' | 'b2c';

/**
 * all supported deep links
 */
const deepLinkRoutes: {
	route: PathToRegexp.Path;
	app: Apps;
	method: DeepLinkMethod;
}[] = [
	// GENERIC ROUTES
	{
		route: '/a/:alias+',
		app: 'generic',
		method(this: DeepLinkRouter, params, query, redirect, _noStoreOperations, _address) {
			const fullAlias = params.alias.toString().replace(',', '/');

			if (this.baseURL) {
				redirect({
					path: `${this.baseURL}/article/${fullAlias}`,
					query
				});
			}
			return true;
		}
	},
	{
		route: '/restore-session/:sessionToken',
		app: 'generic',
		async method(this: DeepLinkRouter, params, query, _redirect, noStoreOperations, fullAddress) {
			const link = (query && query.get('redirect')) || undefined;

			await this.nuxt.$loginStore?.setSessionIdCookie({
				sessionToken: decodeURIComponent(params.sessionToken)
			});
			await this.nuxt.$loginStore?.resumeSession();

			let linkHandled;
			if (link) {
				linkHandled = await this.handleUrl(link, false, noStoreOperations, fullAddress);
			} else if (this.mode === 'b2b') {
				linkHandled = await this.handleUrl('/pwa/company', false, noStoreOperations, fullAddress);
			} else {
				linkHandled = await this.handleUrl('/pwa', false, noStoreOperations, fullAddress);
			}

			return linkHandled || false;
		}
	},
	{
		route: ['/app/login/', '/company/login', '/user/login'],
		app: 'generic',
		method(this: DeepLinkRouter, _params, query, redirect, _noStoreOperations, _fullAddress) {
			if (this.baseURL) {
				redirect({ path: `${this.baseURL}/login`, query });
			}
			return true;
		}
	},
	{
		/** /app/seamless-login is legacy, remove after 1.11.2021 */
		route: ['/seamless-login/:loginId/:loginToken', '/app/seamless-login/:loginId/:loginToken'],
		app: 'generic',
		method(this: DeepLinkRouter, params, query, _redirect, noStoreOperations, fullAddress) {
			// hokify://seamless-login/asdf/asdf
			const link = (query && query.get('redirect')) || undefined;

			if (
				this.nuxt.$loginStore?.loggedIn &&
				this.nuxt.$userProfileStore?.obj?._id !== params.loginId
			) {
				if (this.nuxt.$snack && import.meta.client) {
					this.nuxt.$snack.show({
						text:
							this.mode === 'b2b'
								? 'Möchten Sie den User wechseln?'
								: 'Möchtest du den User wechseln?',
						button: 'Ja, wechseln!',
						action: async () => {
							await this.loginSeamless(
								params.loginId,
								params.loginToken,
								link,
								noStoreOperations,
								fullAddress
							);
						}
					});
				}
				// return false, means we have not handled it, but processed it
				return false;
			}

			return this.loginSeamless(
				params.loginId,
				params.loginToken,
				link,
				noStoreOperations,
				fullAddress
			);
		}
	},
	{
		route: '/verify/:userid/:code',
		app: 'generic',
		async method(this: DeepLinkRouter, params, query, _redirect, noStoreOperations, fullAddress) {
			const link = query && query.get('redirect');

			// only processed client-side
			if (noStoreOperations) {
				return false;
			}

			const result = await this.nuxt.$loginStore?.verify(params.code);

			if (this.nuxt.$snack) {
				if (!result?.accountVerified) {
					this.nuxt.$snack.danger({
						text: 'Account konnte nicht bestätigt werden. Bitte versuch es erneut.',
						action: undefined
					});
				} else {
					this.nuxt.$snack.success({
						text: 'Account erfolgreich bestätigt.',
						action: undefined
					});
				}
			}

			// here we check wether the redirect from the query params can be resolved as well
			let linkHandled;
			if (link) {
				linkHandled = await this.handleUrl(link, false, noStoreOperations, fullAddress);
			} else if (this.mode === 'b2b') {
				linkHandled = await this.handleUrl('/pwa/company', false, noStoreOperations, fullAddress);
			} else {
				linkHandled = await this.handleUrl('/pwa', false, noStoreOperations, fullAddress);
			}

			return linkHandled || false;
		}
	},
	{
		route: '/password/:userId/:nonce',
		app: 'generic',
		async method(this: DeepLinkRouter, params, query, redirect, _noStoreOperations, _fullAddress) {
			const { nonce, userId } = params;

			const extendedQuery = query ?? new URLSearchParams();

			if (nonce && userId) {
				extendedQuery.append('userId', userId);
				extendedQuery.append('nonce', nonce);
			}

			redirect({
				path: `${this.baseURL}/password`,
				query: extendedQuery
			});

			return true;
		}
	},

	// USER ROUTES
	{
		route: ['/', '/app', '/pwa', '/user', '/app/user', '/getapp'],
		app: 'b2c',
		async method(this: DeepLinkRouter, _params, query, redirect, _noStoreOperations, _fullAddress) {
			try {
				if (this.baseURL) {
					await redirect({ path: this.baseURL, query });
				}
			} catch (err: any) {
				// ignore redirect errors
				console.warn('ignore redirect error', err);
			}
			return true;
		}
	},
	{
		route: '/saved',
		app: 'b2c',
		method(this: DeepLinkRouter, _params, query, redirect, _noStoreOperations, _fullAddress) {
			if (this.baseURL) {
				redirect({ path: `${this.baseURL}/joblist/saved`, query });
			}
			return true;
		}
	},
	{
		route: '/joblist',
		app: 'b2c',
		method(this: DeepLinkRouter, _params, query, redirect, _noStoreOperations, _fullAddress) {
			if (this.baseURL) {
				redirect({ path: `${this.baseURL}/joblist`, query });
			}
			return true;
		}
	},
	{
		route: '/review-profile',
		app: 'b2c',
		async method(this: DeepLinkRouter, _params, query, redirect, _noStoreOperations, _fullAddress) {
			// ensure updated userUpdate
			await this.ensureUserObjectUpdate();

			if (this.baseURL) {
				redirect({ path: `${this.baseURL}/review-profile`, query });
			}
			return true;
		}
	},
	{
		route: '/user-profile',
		app: 'b2c',
		method(this: DeepLinkRouter, _params, query, redirect, _noStoreOperations, _fullAddress) {
			if (this.baseURL) {
				redirect({ path: `${this.baseURL}/profile`, query });
			}
			return true;
		}
	},
	{
		route: ['/user-settings', '/notification-settings'],
		app: 'b2c',
		method(this: DeepLinkRouter, _params, query, redirect, _noStoreOperations, _fullAddress) {
			if (this.baseURL) {
				redirect({ path: `${this.baseURL}/profile/settings`, query });
			}
			return true;
		}
	},
	{
		route: ['/user-magazine'],
		app: 'b2c',
		method(this: DeepLinkRouter, _params, query, redirect, _noStoreOperations, _fullAddress) {
			if (this.baseURL) {
				redirect({ path: `${this.baseURL}/profile/magazine`, query });
			}
			return true;
		}
	},
	{
		route: '/my-cv',
		app: 'b2c',
		method(this: DeepLinkRouter, _params, query, redirect, _noStoreOperations, _fullAddress) {
			redirect({ path: '/hokifycv', query });
			return true;
		}
	},
	{
		route: '/notifications',
		app: 'b2c',
		method(this: DeepLinkRouter, _params, query, redirect, _noStoreOperations, _fullAddress) {
			redirect({ path: `${this.baseURL}/notifications`, query });
			return true;
		}
	},
	{
		route: '/webupload/:docType',
		app: 'b2c',
		method(this: DeepLinkRouter, params, query, redirect, _noStoreOperations, _fullAddress) {
			if (this.baseURL) {
				redirect({
					path: `${this.baseURL}/webupload/${params.docType}`,
					query
				});
			}
			return true;
		}
	},
	{
		route: '/match/:matchId',
		app: 'b2c',
		async method(this: DeepLinkRouter, params, query, redirect, _noStoreOperations, _fullAddress) {
			// if you want to set showTab to the company instead you would have to use the id which is 'company-scroll-container' in this case
			const extendedquery = query ?? new URLSearchParams();
			extendedquery.append('showTab', 'job');

			// never overrule the showTab if it is set explicitly
			if (!extendedquery.has('showTab')) {
				const match: IAPIMatchForJobSeeker | undefined =
					await this.nuxt.$matchesStore?.getUserMatch(params.matchId);

				// used the same condition as in the file JobSeekerMatchListItem.vue
				if (
					match?.likeuser === 'true' &&
					(match?.actionToken === 'done' || match?.actionToken === 'cancel')
				) {
					extendedquery.set('showTab', 'chat');
				} else {
					extendedquery.set('showTab', 'job');
				}
			}

			if (this.baseURL) {
				redirect({
					path: `${this.baseURL}/match/${params.matchId}`,
					query: extendedquery
				});
			}
			return true;
		}
	},
	{
		route: '/job/:jobNr/:uref?',
		app: 'b2c',
		method(this: DeepLinkRouter, params, query, redirect, _noStoreOperations, _fullAddress) {
			const permanently = !!params.uref;
			if (this.baseURL) {
				redirect({
					path: `${this.baseURL}/job/${params.jobNr}`,
					query,
					permanently
				});
			}
			return true;
		}
	},
	{
		route: '/apply/:jobNr',
		app: 'b2c',
		method(this: DeepLinkRouter, params, query, redirect, _noStoreOperations, _fullAddress) {
			redirect({ path: `/apply/${params.jobNr}`, query });
			return true;
		}
	},
	{
		route: '/jobalarm/:jobalarmId',
		app: 'b2c',
		method(this: DeepLinkRouter, _params, query, redirect, _noStoreOperations, _fullAddress) {
			redirect({ path: `/pwa/joblist/jobalarm`, query });
			return true;
		}
	},
	{
		route: '/apply-company/:companyId',
		app: 'b2c',
		method(this: DeepLinkRouter, params, query, redirect, _noStoreOperations, _fullAddress) {
			redirect({ path: `/apply-company/${params.companyId}`, query });
			return true;
		}
	},
	{
		route: '/save/:jobNr',
		app: 'b2c',
		async method(this: DeepLinkRouter, params, query, redirect, _noStoreOperations, _fullAddress) {
			const { jobNr } = params;

			let job: IAPIJobForUser | undefined;

			let saved = true;

			try {
				job = await this.nuxt.$relationsStore?.getJob({ jobNr });
			} catch (err) {
				if (this.nuxt?.$errorHandler) {
					this.nuxt.$errorHandler(err, 'Job konnte nicht gefunden werden.');
				} else {
					console.error('Job konnte nicht gefunden werden.', err);
				}

				saved = false;
			}

			try {
				await this.nuxt.$relationsStore?.saveRelation({
					type: 'job',
					obj: job
				});
			} catch (err) {
				if (this.nuxt?.$errorHandler) {
					this.nuxt.$errorHandler(err, 'Job konnte nicht gespeichert werden.');
				} else {
					console.error('Job konnte nicht gespeichert werden.', err);
				}

				saved = false;
			}

			const options: ISnackMessage = {
				text:
					job && saved
						? `Der Job ${job.name} wurde gespeichert.`
						: 'Job konnte nicht gespeichert werden.',
				button: job ? 'Jetzt Bewerben' : undefined,
				action: async () => {
					if (!job) {
						return;
					}

					try {
						// rollup seems to always check import links even if we don't use them within a specific app.
						// defining a page name here prevents pre-transform error and works according
						// to their dynamic import limitations:
						// https://github.com/rollup/plugins/tree/master/packages/dynamic-import-vars#limitations
						const pageName = this.mode === 'b2c' ? 'jobNr' : '';
						if (pageName) {
							const applyPage = markRaw(
								defineAsyncComponent(() => import(`~/pages/apply/[${pageName}].vue`))
							);
							await this.nuxt.$page?.push(
								applyPage,
								{
									jobNr: job.jobNr,
									job
								},
								{
									pageTitle: job.name,
									route: 'apply-jobNr',
									done: () => {
										this.nuxt.$relationsStore?.updateNumberOrId(undefined);
										this.nuxt.$page?.goBack();
									}
								}
							);
							this.nuxt.$relationsStore?.updateNumberOrId({ idOrNr: `${job.jobNr}` });
						}
					} catch (err) {
						this.nuxt?.$errorHandler(err, 'Jobseite konnte nicht geöffnet werden.');
					}
				}
			};

			if (this.baseURL) {
				redirect({
					path: `${this.baseURL}/joblist/saved`,
					query,
					snack: {
						type: job && saved ? 'success' : 'danger',
						options
					}
				});
			}
			return true;
		}
	},
	{
		route: '/save-company/:companyId',
		app: 'b2c',
		async method(this: DeepLinkRouter, params, query, redirect, _noStoreOperations, _fullAddress) {
			const { companyId } = params;
			const res = await this.nuxt.$companyStore.getCompany(companyId);
			if (!res) {
				return false;
			}
			const { company } = res;

			// only try to save company if it allows unsolicited applications (Initiativbewerbung)
			if (company.allowUnsolicitedApplications) {
				await this.nuxt.$relationsStore?.saveRelation({
					type: 'company',
					obj: company
				});
			}

			// rollup seems to always check import links even if we don't use them within a specific app.
			// defining a page name here prevents pre-transform error and works according
			// to their dynamic import limitations:
			// https://github.com/rollup/plugins/tree/master/packages/dynamic-import-vars#limitations
			const pageName = this.mode === 'b2c' ? 'companyId' : '';
			if (this.baseURL && pageName) {
				const options = {
					text: `Die Firma ${company.name} wurde gespeichert.`,
					button: 'Firma Ansehen',
					action: async () => {
						try {
							const companyDetail = markRaw(
								defineAsyncComponent(() => import(`~/pages/pwa/c/[${pageName}].vue`))
							);
							await this.nuxt.$page?.push(
								companyDetail,
								{
									companyId
								},
								{
									pageTitle: `Firma ${company.name}`,
									done: () => {
										this.nuxt.$relationsStore?.updateNumberOrId(undefined);
										this.nuxt.$page?.goBack();
									}
								}
							);
							this.nuxt.$relationsStore?.updateNumberOrId({ idOrNr: company._id });
						} catch (err) {
							this.nuxt?.$errorHandler(err, 'Firmenseite konnte nicht geöffnet werden.');
						}
					}
				};
				redirect({
					path: `${this.baseURL}/joblist/saved`,
					query,
					snack: { type: 'success', options }
				});
			}

			return true;
		}
	},
	{
		route: ['/discard/:jobNr', '/discard-suggestion/:jobNr'],
		app: 'b2c',
		async method(this: DeepLinkRouter, params, query, redirect, noStoreOperations, _fullAddress) {
			// noStoreOperations is true for SSR, because we modify the store here and do redirects,
			// it is safer to do this only on client
			const returnValue = !noStoreOperations;

			const { jobNr } = params;

			const job = await this.nuxt.$relationsStore?.getJob({ jobNr });
			await this.nuxt.$relationsStore?.discardRelation({
				type: 'matchrelation',
				identifier: '',
				relation: {
					type: 'job',
					obj: job
				}
			});

			try {
				if (this.baseURL) {
					redirect({
						path: this.baseURL,
						query,
						snack: {
							type: 'show',
							options: {
								text: 'Der gewählte Job wurde verworfen'
							}
						}
					});
				}
			} catch (err: any) {
				// ignore redirect errors
				console.warn('ignore redirect error', err);
			}
			return returnValue;
		}
	},
	{
		route: ['/jobs/m/:term/:location', '/jobs/k/:term', '/jobs/l/:location'],
		app: 'b2c',
		async method(this: DeepLinkRouter, _params, query, redirect, noStoreOperations, fullAddress) {
			// noStoreOperations is true for SSR, because we modify the store here and do redirects,
			// it is safer to do this only on client
			const returnValue = !noStoreOperations;

			const urlParts = new URL(fullAddress, 'https://hokify.com');

			const hostnameParts = urlParts.hostname.split('.');
			const region = hostnameParts[hostnameParts.length - 1]?.toLowerCase();

			await this.nuxt.$userJobFilterStore?.setFilter({
				region,
				setByUrl: urlParts.pathname
			});

			try {
				if (this.baseURL) {
					redirect({
						path: this.baseURL,
						query,
						snack: {
							type: 'show',
							options: {
								text: 'Der Filter wurde erfolgreich angewendet.'
							}
						}
					});
				}
			} catch (err: any) {
				// ignore redirect errors
				console.warn('ignore redirect error', err);
			}
			return returnValue;
		}
	},
	{
		// this calls the "apply-action", that tries to apply directly to a job, which lets users enter the chat straight
		// away when they were active sourced. in case of an error we take them to the regular apply process.
		// if everything is fine, we redirect to the match detail.
		route: ['/reverse-apply/:jobId'],
		app: 'b2c',
		async method(this: DeepLinkRouter, params, query, redirect, noStoreOperations, _fullAddress) {
			// only processed client-side
			if (noStoreOperations) {
				return false;
			}

			if (!this.nuxt.$loginStore?.loggedIn) {
				throw new NotAuthenticatedError();
			}

			let job;

			try {
				job = await this.nuxt.$relationsStore?.getJobById(params.jobId);
			} catch {
				redirect({
					path: '/pwa',
					query
				});
			}

			try {
				// check if job is ats job
				if (job.activeSourcingApplicationTarget === 'application') {
					redirect({
						path: `/apply/${job.jobNr}`,
						query
					});
				} else {
					const response: {
						matchObj?: IAPIMatchForJobSeeker;
						action: 'contact' | 'applied' | 'pending' | 'saved' | 'discard' | 'needreview';
						matchId?: APITypeObjectId<APIObjectType.Match>;
						userObjUpdate?: Record<string, unknown>;
					} = await this.nuxt.$relationsStore?.applyToRelation({
						relation: { type: 'job', obj: job }
					});

					const extendedquery = query ?? new URLSearchParams();
					extendedquery.append('reverseApply', 'accepted-deeplink');
					redirect({
						path: `/pwa/match/${response?.matchId}`,
						query: extendedquery
					});
				}
			} catch {
				redirect({
					path: `/pwa/job/${job.jobNr}`,
					query
				});
			}

			return true;
		}
	},

	{
		route: '/more-info/:jobNr',
		app: 'b2c',
		method(this: DeepLinkRouter, params, query, redirect, _noStoreOperations, _fullAddress) {
			const extendedquery = query ?? new URLSearchParams();
			extendedquery.append('moreInfo', 'true');
			if (this.baseURL) {
				redirect({
					path: `${this.baseURL}/job/${params.jobNr}`,
					query: extendedquery
				});
			}
			return true;
		}
	},

	// COMPANY ROUTES
	{
		route: ['/company', '/app/company', '/pwa/company'],
		app: 'b2b',
		method(this: DeepLinkRouter, _params, query, redirect, _noStoreOperations, _fullAddress) {
			try {
				if (this.baseURL) {
					redirect({ path: this.baseURL, query });
				}
			} catch (err: any) {
				// ignore redirect errors
				console.warn('ignore redirect error', err);
			}

			return true;
		}
	},
	{
		route: '/organization',
		app: 'b2b',
		method(this: DeepLinkRouter, _params, query, redirect, _noStoreOperations, _fullAddress) {
			redirect({ path: '/pwa/company/organization', query });

			return true;
		}
	},
	{
		route: '/organization/:organizationIdOrCompanyId',
		app: 'b2b',
		method(this: DeepLinkRouter, params, query, redirect, _noStoreOperations, _fullAddress) {
			const { organizationIdOrCompanyId } = params;

			const extendedQuery = query ?? new URLSearchParams();

			// set a query parameter to open edit page for either organization or company as an insidepage
			if (organizationIdOrCompanyId) {
				extendedQuery.append('oId', organizationIdOrCompanyId);
			}

			redirect({ path: '/pwa/company/organization', query: extendedQuery });
			return true;
		}
	},
	{
		route: '/organization/:organizationId/permissions',
		app: 'b2b',
		method(this: DeepLinkRouter, params, query, redirect, _noStoreOperations, _fullAddress) {
			const { organizationId } = params;

			const extendedQuery = query ?? new URLSearchParams();

			// set a query parameter to open organization edit page as an insidepage
			if (organizationId) {
				extendedQuery.append('oId', organizationId);
			}

			redirect({ path: '/pwa/company/organization', query: extendedQuery });
			return true;
		}
	},
	{
		route: '/demo',
		app: 'b2b',
		method(this: DeepLinkRouter, _params, query, redirect, _noStoreOperations, _fullAddress) {
			redirect({ path: '/pwa/company/jobs', query });

			return true;
		}
	},
	{
		route: ['/addjob', '/addjob/:priceIdORCoupon/:coupon?'],
		app: 'b2b',
		async method(
			this: DeepLinkRouter,
			params,
			query,
			redirect,
			noStoreOperations,
			_fullAddress,
			store
		) {
			// noStoreOperations is true for SSR, because we modify the store here and do redirects,
			// it is safer to do this only on client
			const returnValue = !noStoreOperations;

			if (!this.nuxt.$loginStore?.loggedIn) {
				throw new NotAuthenticatedError();
			}

			if (
				params.priceIdORCoupon &&
				params.priceIdORCoupon.length === 24 &&
				checkForObjectIdRegExp.test(params.priceIdORCoupon)
			) {
				// 1. Get Package and add to store
				const purchasedPackages = await store.dispatch('company/payment/getPurchasedPackages');

				const selectedPackage = purchasedPackages.find(p => p._id === params.priceIdORCoupon);
				if (!selectedPackage) {
					console.error(`Purchased package not found: ${params.priceIdORCoupon}`);

					store.dispatch('company/job/createEmptyJobFromTemplate', {});
					redirect({
						path: '/pwa/company/job/0/edit',
						query,
						snack: {
							type: 'danger',
							options: {
								text: `Das gesuchte Paket (${params.priceIdORCoupon}) wurde nicht gefunden.`
							}
						}
					});

					return returnValue;
				}

				// 2. ShoppinCart Options > set Coupon Code
				if (params.coupon) {
					store.commit('company/payment/updateShoppingCartOptions', {
						coupon: params.coupon
					});
				}

				// 3. create new job
				store.dispatch('company/job/createEmptyJobFromTemplate', {
					purchasedPackageId: selectedPackage._id, // formerly known as priceId
					buyablePackage: selectedPackage.package.buyablePackage // formerly known as featureSet
				});

				// 4. redirect to Edit-Job section
				redirect({
					path: '/pwa/company/job/0/edit',
					query,
					snack: params.coupon
						? {
								type: 'success',
								options: { text: `Gutscheincode "${params.coupon}" hinzugefügt.` }
							}
						: undefined
				});
			} else if (params.priceIdORCoupon) {
				// COUPON
				// 1. ShoppinCart Options > set Coupon Code
				store.commit('company/payment/updateShoppingCartOptions', {
					coupon: params.priceIdORCoupon
				});

				// 2. redirect to pricelist
				await redirect({
					path: '/pwa/company/pricelist',
					query,
					// 3. display msg
					snack: {
						type: 'success',
						options: {
							text: `Gutscheincode "${params.priceIdORCoupon}" hinzugefügt - Wählen Sie nun ein Paket oder Inserat aus.`
						}
					}
				});
			} else {
				// just redirect to pricelist
				store.dispatch('company/job/createEmptyJobFromTemplate', {});
				redirect({
					path: '/pwa/company/job/0/edit',
					query
				});
			}

			return returnValue;
		}
	},
	{
		route: '/pricelist',
		app: 'b2b',
		method(this: DeepLinkRouter, _params, query, redirect, _fullAddress) {
			// use location
			if (this.baseURL) {
				redirect({ path: `${this.baseURL}/pricelist`, query });
			}
			return true;
		}
	},
	{
		route: '/addcredit/:priceIdORCoupon?/:couponORContingent?',
		app: 'b2b',
		async method(
			this: DeepLinkRouter,
			params,
			query,
			redirect,
			noStoreOperations,
			_fullAddress,
			store
		) {
			// noStoreOperations is true for SSR, because we modify the store here and do redirects,
			// it is safer to do this only on client
			const returnValue = !noStoreOperations;

			if (!store.state.login.loggedIn) {
				throw new NotAuthenticatedError();
			}

			// 1. link to checkout flow with preselected package and contingent
			if (
				params.priceIdORCoupon &&
				params.priceIdORCoupon.length === 24 &&
				checkForObjectIdRegExp.test(params.priceIdORCoupon)
			) {
				const variantId = params.priceIdORCoupon;
				const amount =
					params.couponORContingent && /^\d+$/.test(params.couponORContingent)
						? params.couponORContingent
						: 1;

				redirect({
					path: `/pwa/company/checkout/${variantId}/${amount}`,
					query
				});
			}

			// 2. else either add coupon or just redirect to empty checkout
			else {
				const coupon = params?.priceIdORCoupon || '';
				const extendedquery = query ?? new URLSearchParams();
				extendedquery.append('couponCode', coupon);
				redirect({
					path: `/pwa/company/checkout`,
					query: extendedquery
				});
			}

			return returnValue;
		}
	},
	{
		route: '/app/company/:jobNr',
		app: 'b2b',
		method(this: DeepLinkRouter, params, query, redirect, _noStoreOperations, _fullAddress) {
			if (this.baseURL) {
				redirect({
					path: `${this.baseURL}/job/${params.jobNr}`,
					query
				});
			}
			return true;
		}
	},
	{
		route: '/app/company/:jobNr/edit',
		app: 'b2b',
		method(this: DeepLinkRouter, params, query, redirect, _noStoreOperations, _fullAddress) {
			if (this.baseURL) {
				redirect({
					path: `${this.baseURL}/job/${params.jobNr}/edit`,
					query
				});
			}
			return true;
		}
	},
	{
		route: '/candidate/:jobId/:matchId',
		app: 'b2b',
		async method(this: DeepLinkRouter, params, query, redirect, _noStoreOperations, _fullAddress) {
			const { jobId } = params;
			const { matchId } = params;

			const extendedQuery = query ?? new URLSearchParams();

			if (jobId && matchId) {
				extendedQuery.append('showTab', 'match');
				extendedQuery.append('jId', jobId);
				extendedQuery.append('mId', matchId);
			}

			if (this.baseURL) {
				redirect({
					path: `${this.baseURL}/job/${jobId}`,
					query: extendedQuery
				});
			}
			return true;
		}
	},
	{
		route: '/candidate-company/:companyId/:matchId',
		app: 'b2b',
		async method(this: DeepLinkRouter, params, query, redirect, _noStoreOperations, _fullAddress) {
			const { companyId } = params;
			const { matchId } = params;

			const extendedQuery = query ?? new URLSearchParams();

			if (companyId && matchId) {
				extendedQuery.append('cId', companyId);
				extendedQuery.append('mId', matchId);
			}

			if (this.baseURL) {
				redirect({
					path: `${this.baseURL}/organization`,
					query: extendedQuery
				});
			}
			return true;
		}
	},
	{
		route: '/company-referral',
		app: 'b2b',
		method(this: DeepLinkRouter, _params, query, redirect, _noStoreOperations, _fullAddress) {
			// referral page is deprecated so now just redirect to start page
			if (this.baseURL) {
				redirect({ path: `${this.baseURL}/`, query });
			}
			return true;
		}
	},
	{
		route: '/company-privacy',
		app: 'b2b',
		method(this: DeepLinkRouter, _params, query, redirect, _noStoreOperations, _fullAddress) {
			if (this.baseURL) {
				redirect({ path: `${this.baseURL}/settings/privacy`, query });
			}
			return true;
		}
	},
	{
		route: '/candidates/:jobId',
		app: 'b2b',
		method(this: DeepLinkRouter, params, query, redirect, _noStoreOperations, _fullAddress) {
			const extendedquery = query ?? new URLSearchParams();
			extendedquery.append('showTab', 'activesourcing');
			if (this.baseURL) {
				redirect({
					path: `${this.baseURL}/job/${params.jobId}`,
					query: extendedquery
				});
			}
			return true;
		}
	},
	{
		route: '/company-profile',
		app: 'b2b',
		method(this: DeepLinkRouter, _params, query, redirect, _noStoreOperations, _fullAddress) {
			if (this.baseURL) {
				redirect({ path: `${this.baseURL}/settings`, query });
			}
			return true;
		}
	},
	{
		route: '/upgradejob/:jobId/:priceIdRCoupon?/:coupon?',
		app: 'b2b',
		async method(this: DeepLinkRouter, _params, query, redirect, _noStoreOperations, _fullAddress) {
			// just-in-case fallback (deeplink not used in mails anymore)
			if (this.baseURL) {
				redirect({ path: `${this.baseURL}/dashboard`, query });
			}
			return true;
		}
	},
	{
		route: '/jobclaim/:code/:jobNr',
		app: 'b2b',
		async method(
			this: DeepLinkRouter,
			params,
			query,
			redirect,
			noStoreOperations,
			_fullAddress,
			store
		) {
			// noStoreOperations is true for SSR, because we modify the store here and do redirects,
			// it is safer to do this only on client
			const returnValue = !noStoreOperations;

			if (!store.state.login.loggedIn) {
				throw new NotAuthenticatedError();
			}

			await store.dispatch('company/job/claimJob', {
				code: params.code,
				jobNr: params.jobNr
			});
			if (this.baseURL) {
				redirect({
					path: `${this.baseURL}/jobs`,
					query,
					snack: {
						type: 'success',
						options: {
							text: 'Job wurde erfolgreich hinzugefügt'
						}
					}
				});
			}

			return returnValue;
		}
	}
];

const preCompiledRoutes: {
	generic: ICompiledRoute[];
	b2b: ICompiledRoute[];
	b2c: ICompiledRoute[];
} = {
	generic: [],
	b2b: [],
	b2c: []
};

const allPreCompiledRoutes: ICompiledRoute[] = [];

// compile regexes and push it to "preCompiledRoutes".
// performance hint: do this not in the contrscutor, but in the "global" space,
// therefore this is not procssed each time the constrcutor is called.
deepLinkRoutes.forEach(route => {
	try {
		const compiledRoute: ICompiledRoute = {
			route: PathToRegexp.match(route.route),
			method: route.method
		};
		preCompiledRoutes[route.app].push(compiledRoute);
		allPreCompiledRoutes.push(compiledRoute);
	} catch (err: any) {
		if (import.meta.client) {
			console.log('cannot parse deeplink route', route.route);
		}
		throw err;
	}
});

export class DeepLinkRouter {
	constructor(
		public readonly nuxt: NuxtApp,
		public route: ReturnType<typeof useRoute>,
		private routes: {
			generic: { route: PathToRegexp.MatchFunction<DefaultParamsType>; method: DeepLinkMethod }[];
			b2b: { route: PathToRegexp.MatchFunction<DefaultParamsType>; method: DeepLinkMethod }[];
			b2c: { route: PathToRegexp.MatchFunction<DefaultParamsType>; method: DeepLinkMethod }[];
		} = preCompiledRoutes
	) {}

	get baseURL(): string | undefined {
		return this.nuxt.$config?.public?.baseURL;
	}

	get mode(): string | undefined {
		return this.nuxt.$config?.public?.mode;
	}

	public isValidDomain(host) {
		return (
			host === 'hokify.com' ||
			host === 'hokify.at' ||
			host === 'hokify.de' ||
			host === 'hokify.ch' ||
			host === 'm58q7.app.goo.gl' ||
			host === '67ra.adj.st'
		);
	}

	// check if universal link
	public isUniversalLink = funcIsUniversalLink;

	private handleDeepLinkLoginError(
		redirect: (options: { path: string; query?: any }) => void,
		err: any,
		address: string,
		companyMode: boolean,
		query: any
	) {
		if (isNotAuthenticatedError(err)) {
			// save deeplink for login
			this.nuxt.$userRootStore?.doProcessDeeplinkAfterLogin(address);

			if (companyMode) {
				redirect({ path: '/pwa/company/login', query });
				if (this.nuxt.$snack && import.meta.client) {
					this.nuxt.$snack.show({
						text: `Bitte loggen Sie Sich ein um fortzufahren.`
					});
				}
			} else {
				redirect({ path: '/pwa/login', query });
				if (this.nuxt.$snack && import.meta.client) {
					this.nuxt.$snack.show({
						text: `Einloggen um fortzufahren.`
					});
				}
			}
			return true;
		}
		return false;
	}

	public async handleUrl(
		address: string,
		// eslint-disable-next-line default-param-last
		logErrors = true,
		// eslint-disable-next-line default-param-last
		noStoreOperations = false,
		originalAddress?: string,
		notificationId?: string
	) {
		if (notificationId) {
			// do not await this, as it should not block the user from further interaction
			this.nuxt.$userRootStore?.trackPushOpen(notificationId);
		}

		let pathname: string;
		let query: URLSearchParams | undefined;
		try {
			({ query, pathname } = this.getPathName(address));
		} catch (err: any) {
			console.error('cannot parse deep link url', err, err.stack);
			pathname = address;
		}

		if (!pathname) {
			console.error('no pathname for deep link url');
			return undefined;
		}

		let handled: boolean | undefined;

		try {
			/**
			 * set up GA paremeters
			 */
			try {
				if (query) {
					// if one query paramter is occuring more than once, combine them with a ";"
					const source = query.has('utm_source') && query.getAll('utm_source').join(';');
					const medium = query.has('utm_medium') && query.getAll('utm_medium').join(';');
					if (source && medium) {
						const cookie = await this.nuxt.runWithContext(() => useRequestHeader('cookie'));

						setTrackingSource(
							source,
							medium,
							(query.has('utm_campaign') && query.getAll('utm_campaign').join(';')) || undefined,
							(query.has('utm_content') && query.getAll('utm_content').join(';')) || undefined,
							(query.has('utm_keyword') && query.getAll('utm_keyword').join(';')) || undefined,
							cookie ? { headers: { cookie } } : undefined
						);
					}
				}
			} catch (err: any) {
				console.warn('setting up tracking source failed', err);
			}

			/**
			 * CHECK IF WERE ARE IN CORRECT APP
			 *
			 * if deeplink is for b2b and we are on b2c side, redirect to b2b if deeplink is for b2c and
			 * we are on b2b side, redirect to b2c
			 */
			const otherApp = this.mode === 'b2b' ? 'b2c' : 'b2b';
			const isOtherAppLink = await this.checkDeepLinks(otherApp, address, pathname, query);

			if (isOtherAppLink !== undefined) {
				const otherAppPath =
					otherApp === 'b2b'
						? this.nuxt.$config.public.pathToB2BApp
						: this.nuxt.$config.public.pathToB2CApp;
				const otherAppUrlAdditions = (originalAddress || address).replace(/^hokify:\/\//, '/');
				// go to other app
				const otherAppUrl = this.nuxt ? `${otherAppPath}${otherAppUrlAdditions}` : '';

				if (import.meta.client) {
					// ensure it's a real redirect, otherwise nuxt does a in page navigation
					window.location.href = otherAppUrl.toString();
				} else {
					return this.nuxt.runWithContext(() =>
						navigateTo({ path: otherAppUrl.toString() }, { external: true })
					);
				}
			}

			/**
			 * PROCESS Deeplinks
			 */
			// process generic deeplinks
			handled = await this.processDeepLinks('generic', address, pathname, query, noStoreOperations);

			// process app specific deeplinks
			if (handled === undefined) {
				handled = await this.processDeepLinks(
					this.mode as Apps,
					address,
					pathname,
					query,
					noStoreOperations
				);
			}

			/**
			 * no deeplink found
			 */
			if (handled === undefined) {
				// if no match is found and protocol is valid, open given URL in new tab
				if (import.meta.client) {
					console.log('no case matches!', address);
				}

				const urlParts = new URL(address, 'https://hokify.com');
				if (
					address &&
					import.meta.client &&
					process.env.cordova &&
					urlParts &&
					(urlParts.protocol === 'https:' ||
						urlParts.protocol === 'http:' ||
						urlParts.protocol === 'hokify:')
				) {
					const openUrl = urlParts.href.replace(
						'hokify://',
						(window.location &&
							window.location.hostname &&
							`https://${window.location.hostname}/`) ||
							'https://hokify.com/'
					);

					nativeLinkOpener(openUrl);
				} else {
					throw new Error(`unknown deeplink: ${address}`);
				}
			}
		} catch (err: any) {
			if (
				this.handleDeepLinkLoginError(
					async (options: { path: string; query?: any }) =>
						this.nuxt.runWithContext(() => navigateTo(options)),
					err,
					address,
					this.mode === 'b2b',
					query
				)
			) {
				// if handleDeepLinkLoginError returns true it is already redirected
				return true;
			}

			const $sentry = this.nuxt?.$sentry;
			if ($sentry && logErrors) {
				$sentry.withScope(scope => {
					scope.setExtra('address', address);
					scope.setLevel('error');
					$sentry.captureException(err);
				});
			}

			if (this.nuxt.$snack && import.meta.client) {
				this.nuxt.$snack.danger({
					text: 'Es ist ein Fehler aufgetreten beim Verarbeiten eines Links.'
				});
			}
			if (import.meta.client) {
				console.error('error processing deeplink', address, err);
			}
		}

		return handled;
	}

	getPathName = (address: string) => funcGetPathName(this.nuxt, address);

	myRedirectFct(options: {
		path: string;
		query?: URLSearchParams;
		permanently?: boolean;
		snack?: { type: keyof IVueSnack; options: ISnackMessage };
	}) {
		if (options.path && this.route?.path && options.path.split('?')[0] === this.route.path) {
			// check if path is the same, in case throw an error
			// this is necessary for error.vue deeplink processing, e.g. /a/non-existing-article
			// will call the right route already, with a non existing article,
			// but will call the error page and retriggers the deeplink handler
			// this is no longer valid for the nuxt3 error handling, will leave it here for now
			// throw new Error(`cannot redirect to same page: ${this.route.path}`);
		}

		if (options.snack) {
			this.nuxt.$snack[options.snack.type](options.snack.options);
		}

		const redirectCode = options.permanently ? 301 : 302;

		let parsedQuery: any = options.query;
		if (options.query?.entries) {
			parsedQuery = Array.from(options.query.entries() as Iterable<[string, string]>).reduce(
				(query, [key, value]) => {
					query[key] = value;
					return query;
				},
				{}
			);
		}
		return this.nuxt.runWithContext(() => {
			navigateTo({ path: `${options.path}`, query: parsedQuery }, { redirectCode });
		});
	}

	// does not redirect, and no store actions
	async checkDeepLinks(
		app: Apps,
		address: string,
		pathname: string,
		query?: URLSearchParams
	): Promise<boolean | undefined> {
		try {
			let result;
			this.routes[app].some(route => {
				const match = route.route(pathname);
				if (match) {
					result = route.method.call(this, match.params, query, () => {}, true, address);
				}
				return !!match;
			});

			if (await result) {
				return result;
			}
		} catch (err: any) {
			console.error(
				`checkDeepLinks failed for ${app} (pathname: ${pathname}, query: ${query}`,
				err
			);
			return false;
		}
		return undefined;
	}

	async processDeepLinks(
		app: Apps,
		address: string,
		pathname: string,
		query?: URLSearchParams,
		noStoreOperations = false
	): Promise<boolean | undefined> {
		try {
			let result;
			const routeMatched = this.routes[app].some(route => {
				const match = route.route(pathname);
				if (match) {
					result = route.method.call(
						this,
						match.params,
						query,
						async options => this.myRedirectFct(options),
						noStoreOperations,
						address,
						this.nuxt.$snack
					);
				}
				return !!match;
			});

			if (routeMatched) {
				return await result;
			}
		} catch (err: any) {
			console.error(
				`processDeepLinks failed for ${app} (pathname: ${pathname}, query: ${query}, noStoreOperations: ${noStoreOperations})`,
				err
			);
			throw err;
		}
		return undefined;
	}

	async loginSeamless(
		loginId: string,
		loginToken: string,
		link?: string,
		noStoreOperations?: boolean,
		fullAddress?: string
	) {
		let loginResult;
		try {
			loginResult = await this.nuxt.$loginStore?.seamlessLogin({
				loginToken,
				loginId
			});
		} catch (err: any) {
			console.error(err);
		}

		if (this.nuxt.$snack && import.meta.client) {
			if (!loginResult) {
				this.nuxt.$snack.danger({
					text: 'Etwas ist beim Login schief gelaufen.'
				});
			} else {
				this.nuxt.$snack.success({
					text: `Erfolgreich eingeloggt als ${this.nuxt.$userProfileStore?.obj?.displayName}.`
				});
			}
		} else if (!loginResult && import.meta.client) {
			console.log('login failed', loginResult);
		}

		let linkHandled;
		if (link) {
			linkHandled = await this.handleUrl(link, false, noStoreOperations, fullAddress);
		} else if (this.mode === 'b2b') {
			linkHandled = await this.handleUrl('/pwa/company', false, noStoreOperations, fullAddress);
		} else {
			linkHandled = await this.handleUrl('/pwa', false, noStoreOperations, fullAddress);
		}

		return linkHandled || false;
	}

	async ensureUserObjectUpdate() {
		const reAuthData = import.meta.client && lsTest() && localStorage.getItem('reAuthData');

		const parsedLocalStorage = reAuthData && JSON.parse(reAuthData);
		if (parsedLocalStorage) {
			const { tokenID, token } = parsedLocalStorage;

			try {
				await this.nuxt.$loginStore?.doLogin({
					method: 'token-login',
					parameters: {
						apiUser: tokenID,
						token
					}
				});
			} catch (err) {
				// do Login may fail here
				const {
					public: { development }
				} = useRuntimeConfig();
				if (development) {
					console.log('pwaReAuth login failed', import.meta.client && err);
				}
			}
		}
	}
}

function funcIsUniversalLink(protocol) {
	return protocol === 'hokify:';
}

function funcIsValidDomain(host) {
	return (
		host === 'hokify.com' ||
		host === 'hokify.at' ||
		host === 'hokify.de' ||
		host === 'hokify.ch' ||
		host === 'm58q7.app.goo.gl' ||
		host === '67ra.adj.st'
	);
}

function funcGetPathName(
	nuxt: NuxtApp,
	address: string
): { pathname: string; query?: URLSearchParams } {
	const urlParts = new URL(address, 'https://hokify.com');
	const env = nuxt.$config?.public?.env;

	let pathname;
	if (env !== 'production' && urlParts && urlParts.host) {
		// remove "test.hokify." prefix of e.g. "test.hokify.com" or "test.hokify.at"
		urlParts.host = urlParts.host.replace('test.hokify.', 'hokify.');
	}

	// if host is valid
	if (funcIsValidDomain(urlParts.host)) {
		// with domain, e.g. https://hokify.at/job/1234
		({ pathname } = urlParts);
		if (!pathname) {
			pathname = '/';
		}
	} else if (funcIsUniversalLink(urlParts.protocol)) {
		// app link, e.g. hokify://job/1234
		if (!urlParts.host && urlParts.pathname && urlParts.pathname.startsWith('//')) {
			pathname = `/${urlParts.pathname.slice(2) || ''}`;
		} else {
			pathname = `/${urlParts.host}${urlParts.pathname || ''}`;
		}
	} else if (!urlParts.host && address[0] === '/' && urlParts.pathname) {
		// no domain, e.g. /job/1234
		pathname = `${urlParts.pathname}`;
	}

	if (pathname && pathname.length > 1 && pathname[pathname.length - 1] === '/') {
		// 	if length > 1, strip last "/" if there is any
		pathname = pathname.substr(0, pathname.length - 1);
	}

	return {
		pathname,
		query: (urlParts.search && new URLSearchParams(urlParts.search)) || undefined
	};
}

export function isDeepLinkRoute(nuxt: NuxtApp, fullPath: string): boolean {
	try {
		const { pathname } = funcGetPathName(nuxt, fullPath);
		return allPreCompiledRoutes.some(route => route.route(pathname));
	} catch (err) {
		// no need to log error here as it would throw on non-deeplink urls
		return false;
	}
}

export const deepLinkRouterPlugin = defineNuxtPlugin({
	async setup() {
		const nuxt = tryUseNuxtApp();
		const route = useRoute();

		if (!nuxt) {
			console.warn('no nuxt app found - Deep link router not initialized');
			return {};
		}

		addRouteMiddleware(
			'deep-link-checker',
			async (to, from) => {
				const isDeepLink = isDeepLinkRoute(nuxt, to.fullPath);

				const isSamePath = to.fullPath === from.fullPath;

				if (isDeepLink && nuxt.$deepLinkRouter && !isSamePath) {
					await nuxt.$deepLinkRouter?.handleUrl(to.fullPath);
				}

				return true;
			},
			{ global: true }
		);

		const deepLinkRouter = new DeepLinkRouter(nuxt, route);

		if (import.meta.client) {
			EventBus.$on('logged-in', async () => {
				if (nuxt.$userRootStore?.processDeeplinkAfterLogin) {
					console.log(
						're-processing deeplink url after login',
						nuxt.$userRootStore?.processDeeplinkAfterLogin
					);
					const deeplink = nuxt.$userRootStore?.processDeeplinkAfterLogin;
					const handled = deeplink ? await deepLinkRouter.handleUrl(deeplink) : false;
					if (handled) {
						nuxt.$userRootStore?.doProcessDeeplinkAfterLogin('');
					}
				}
			});
		}

		return {
			provide: { deepLinkRouter }
		};
	}
});
