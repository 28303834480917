<template>
	<div
		:class="[isInfoBox ? 'info-box' : 'error-box', { 'styling-white': stylingWhite }]"
		class="flex items-center p-2 mb-8"
	>
		<HokIcon
			v-if="$isMobile.any || !$slots.title"
			:name="name"
			:size="7"
			:color="color"
			class="inline mr-2"
			style="stroke: transparent"
		/>
		<div class="flex-1 flex flex-col" :class="{ 'justify-center items-center': !$isMobile.any }">
			<h3
				v-if="$slots.title"
				:class="{ 'font-normal': !$slots.default, 'styling-white': stylingWhite }"
				class="smaller mb-2"
			>
				<HokIcon
					v-if="!$isMobile.any"
					:name="name"
					:size="7"
					:color="color"
					class="inline"
					style="stroke: transparent"
				/>
				<slot name="title"></slot>
			</h3>
			<p v-if="$slots.default" class="mb-0 text-sm" :class="{ 'styling-white': stylingWhite }">
				<slot />
			</p>
			<slot name="action" />
		</div>
	</div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import HokIcon from './HokIcon.vue';

export default defineComponent({
	components: { HokIcon },
	data() {
		const color = this.stylingWhite ? 'white' : 'purple';
		const name = 'icon:alert';
		const isInfoBox = false;

		return {
			isInfoBox,
			name,
			color
		};
	},
	props: {
		type: { type: String, default: '' },
		stylingWhite: { type: Boolean, default: false } // used for a box in business green, where purple would be barely visible
	}
});
</script>

<style lang="scss" scoped>
.error-box {
	border-radius: 0.5rem;
	border: 1px solid;

	&:not(.styling-white) {
		border-color: $color-purple;
	}
	&.styling-white {
		border-color: $color-white;
	}

	h3,
	p {
		&:not(.styling-white) {
			color: $color-purple;
		}
		&.styling-white {
			color: $color-white;
		}
	}
}
</style>
