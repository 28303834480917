<template>
	<div
		v-show="showYearView"
		:class="[calendarClass, 'vdp-datepicker__calendar']"
		:style="calendarStyle"
		@mousedown.prevent
	>
		<slot name="beforeCalendarHeader"></slot>
		<header>
			<span :class="{ disabled: isLeftNavDisabled }" class="prev" @click="previousDecade()"
				>&lt;</span
			>
			<span>{{ getPageDecade }}</span>
			<span :class="{ disabled: isRightNavDisabled }" class="next" @click="nextDecade()">&gt;</span>
		</header>
		<span
			v-for="year in years"
			:key="year.timestamp"
			:class="{ selected: year.isSelected, disabled: year.isDisabled }"
			class="cell year"
			@click.stop="selectYear(year)"
			>{{ year.year }}</span
		>
	</div>
</template>
<script>
import { makeDateUtils, langYearSuffix } from './DateUtils';

export default {
	props: {
		showYearView: Boolean,
		selectedDate: Date,
		pageDate: Date,
		pageTimestamp: Number,
		disabledDates: Object,
		highlighted: Object,
		calendarClass: [String, Object, Array],
		calendarStyle: Object,
		language: String,
		allowedToShowView: Function,
		useUtc: Boolean
	},
	emits: ['select-year', 'changed-decade'],
	watch: {
		language(newLanguage) {
			this.utils = makeDateUtils(this.useUtc, newLanguage);
		},
		useUtc(newUtc) {
			this.utils = makeDateUtils(newUtc, this.language);
		}
	},
	computed: {
		years() {
			const d = this.pageDate;

			const years = [];
			// set up a new date object to the beginning of the current 'page'
			const dObj = this.useUtc
				? new Date(
						Date.UTC(Math.floor(d.getUTCFullYear() / 10) * 10, d.getUTCMonth(), d.getUTCDate())
					)
				: new Date(
						Math.floor(d.getFullYear() / 10) * 10,
						d.getMonth(),
						d.getDate(),
						d.getHours(),
						d.getMinutes()
					);

			for (let i = 0; i < 10; i++) {
				years.push({
					year: this.utils.getFullYear(dObj),
					timestamp: dObj.getTime(),
					isSelected: this.isSelectedYear(dObj),
					isDisabled: this.isDisabledYear(dObj)
				});
				this.utils.setFullYear(dObj, this.utils.getFullYear(dObj) + 1);
			}
			return years;
		},
		/**
		 * @returns {String}
		 */
		getPageDecade() {
			const decadeStart = Math.floor(this.utils.getFullYear(this.pageDate) / 10) * 10;
			const decadeEnd = decadeStart + 9;
			const yearSuffix = langYearSuffix[this.language] || '';
			return `${decadeStart} - ${decadeEnd}${yearSuffix}`;
		},
		/**
		 * Is the left hand navigation button disabled?
		 *
		 * @returns {Boolean}
		 */
		isLeftNavDisabled() {
			return this.isPreviousDecadeDisabled(this.pageTimestamp);
		},
		/**
		 * Is the right hand navigation button disabled?
		 *
		 * @returns {Boolean}
		 */
		isRightNavDisabled() {
			return this.isNextDecadeDisabled(this.pageTimestamp);
		}
	},
	data() {
		const constructedDateUtils = makeDateUtils(this.useUtc, this.language);
		return {
			utils: constructedDateUtils
		};
	},
	methods: {
		// eslint-disable-next-line consistent-return
		selectYear(year) {
			if (year.isDisabled) {
				return false;
			}
			this.$emit('select-year', year);
		},
		changeYear(incrementBy) {
			const date = this.pageDate;
			this.utils.setFullYear(date, this.utils.getFullYear(date) + incrementBy);
			this.$emit('changed-decade', date);
		},
		// eslint-disable-next-line consistent-return
		previousDecade() {
			if (this.isPreviousDecadeDisabled()) {
				return false;
			}
			this.changeYear(-10);
		},
		isPreviousDecadeDisabled() {
			if (!this.disabledDates || !this.disabledDates.to) {
				return false;
			}
			const disabledYear = this.utils.getFullYear(this.disabledDates.to);
			const lastYearInPreviousPage =
				Math.floor(this.utils.getFullYear(this.pageDate) / 10) * 10 - 1;
			return disabledYear > lastYearInPreviousPage;
		},
		// eslint-disable-next-line consistent-return
		nextDecade() {
			if (this.isNextDecadeDisabled()) {
				return false;
			}
			this.changeYear(10);
		},
		isNextDecadeDisabled() {
			if (!this.disabledDates || !this.disabledDates.from) {
				return false;
			}
			const disabledYear = this.utils.getFullYear(this.disabledDates.from);
			const firstYearInNextPage = Math.ceil(this.utils.getFullYear(this.pageDate) / 10) * 10;
			return disabledYear < firstYearInNextPage;
		},

		/**
		 * Whether the selected date is in this year
		 *
		 * @param {Date}
		 * @returns {Boolean}
		 */
		isSelectedYear(date) {
			return (
				this.selectedDate &&
				this.utils.getFullYear(this.selectedDate) === this.utils.getFullYear(date)
			);
		},
		/**
		 * Whether a year is disabled
		 *
		 * @param {Date}
		 * @returns {Boolean}
		 */
		isDisabledYear(date) {
			let disabledDates = false;
			if (typeof this.disabledDates === 'undefined' || !this.disabledDates) {
				return false;
			}

			if (typeof this.disabledDates.to !== 'undefined' && this.disabledDates.to) {
				if (this.utils.getFullYear(date) < this.utils.getFullYear(this.disabledDates.to)) {
					disabledDates = true;
				}
			}
			if (typeof this.disabledDates.from !== 'undefined' && this.disabledDates.from) {
				if (this.utils.getFullYear(date) > this.utils.getFullYear(this.disabledDates.from)) {
					disabledDates = true;
				}
			}

			if (
				typeof this.disabledDates.customPredictor === 'function' &&
				this.disabledDates.customPredictor(date)
			) {
				disabledDates = true;
			}

			return disabledDates;
		}
	}
};
// eslint-disable-next-line
</script>
