<template>
	<div :class="['hokify-spinner-container', fixed ? 'fixed' : '', size ? size : '']">
		<div class="cssload-fond">
			<div class="cssload-container-general">
				<div class="cssload-internal">
					<div class="cssload-ballcolor cssload-ball_1"></div>
				</div>
				<div class="cssload-internal">
					<div class="cssload-ballcolor cssload-ball_2"></div>
				</div>
				<div class="cssload-internal">
					<div class="cssload-ballcolor cssload-ball_3"></div>
				</div>
				<div class="cssload-internal">
					<div class="cssload-ballcolor cssload-ball_4"></div>
				</div>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
	name: 'Spinner',
	props: {
		size: {
			type: String,
			default: 'medium',
			validator: (value: string) => ['small', 'medium'].includes(value)
		},
		fixed: { type: Boolean, default: true }
	}
});
</script>

<style lang="scss" scoped>
.hokify-spinner-container {
	position: relative;
	z-index: 999998; // should always be on top (except below payment provider)
	margin: auto;
}

.cssload-fond {
	position: relative;
	margin: auto;
}

.cssload-ball_1,
.cssload-ball_2,
.cssload-ball_3,
.cssload-ball_4 {
	position: absolute;
}

.cssload-container-general {
	animation: cssload-animball_two 2.05s infinite;
	width: 121px;
	height: 121px;
}

.cssload-internal {
	width: 121px;
	height: 121px;
	position: absolute;
}

.cssload-ballcolor {
	width: 55px;
	height: 55px;
	border-radius: 50%;
}

.cssload-ball_1 {
	background-color: rgb(15, 177, 174);
	top: 0;
	left: 0;
}

.cssload-ball_2 {
	background-color: rgb(178, 227, 227);
	top: 0;
	left: 66px;
}

.cssload-ball_3 {
	background-color: rgb(178, 227, 227);
	top: 66px;
	left: 0;
}

.cssload-ball_4 {
	background-color: rgb(15, 177, 174);
	top: 66px;
	left: 66px;
}

@keyframes cssload-animball_two {
	0% {
		transform: rotate(0deg) scale(1);
	}
	50% {
		transform: rotate(360deg) scale(1.3);
	}
	100% {
		transform: rotate(720deg) scale(1);
	}
}
.small {
	.cssload-ball_1,
	.cssload-ball_2,
	.cssload-ball_3,
	.cssload-ball_4 {
		animation: cssload-animball_one_small 2.05s infinite ease;
	}

	&.hokify-spinner-container.fixed {
		border-radius: 5px;
		width: 30px;
		height: 30px;
		position: fixed;
		top: 30%;
		left: 50%;
		margin: -15px;
		background-color: transparent;
		text-align: center;
	}

	&.hokify-spinner-container {
		width: 30px;
		height: 30px;
	}

	.cssload-container-general {
		width: 30px;
		height: 30px;
	}

	.cssload-internal {
		width: 30px;
		height: 30px;
	}

	.cssload-ballcolor {
		width: 13px;
		height: 13px;
	}

	.cssload-ball_2 {
		left: 16px;
	}

	.cssload-ball_3 {
		top: 16px;
	}

	.cssload-ball_4 {
		top: 16px;
		left: 16px;
	}

	@keyframes cssload-animball_one_small {
		50% {
			top: 8px;
			left: 8px;
			position: absolute;
			opacity: 0.5;
		}
		100% {
			position: absolute;
		}
	}
}

.medium {
	.cssload-ball_1,
	.cssload-ball_2,
	.cssload-ball_3,
	.cssload-ball_4 {
		animation: cssload-animball_one 2.05s infinite ease;
	}

	&.hokify-spinner-container.fixed {
		border-radius: 5px;
		width: 120px;
		height: 120px;
		position: fixed;
		top: 30%;
		left: 50%;
		margin: -60px;
		background-color: transparent;
		text-align: center;
	}

	&.hokify-spinner-container {
		width: 120px;
		height: 120px;
	}

	.cssload-container-general {
		width: 121px;
		height: 121px;
	}

	.cssload-internal {
		width: 121px;
		height: 121px;
	}

	.cssload-ballcolor {
		width: 55px;
		height: 55px;
	}

	.cssload-ball_2 {
		left: 66px;
	}

	.cssload-ball_3 {
		top: 66px;
	}

	.cssload-ball_4 {
		top: 66px;
		left: 66px;
	}

	@keyframes cssload-animball_one {
		50% {
			top: 33px;
			left: 33px;
			position: absolute;
			opacity: 0.5;
		}
		100% {
			position: absolute;
		}
	}
}
</style>
