import { Hotjar } from '@hokify/shared-components-nuxt3/lib/plugins/hotjar/hotjar';
import { EventBus } from '@hokify/shared-components-nuxt3/lib/eventbus';

export default defineNuxtPlugin(nuxtApp => {
	EventBus.$on('accepted-tracking', parsedCookie => {
		// all apps share the same hotjar ID
		const id = nuxtApp.$config.public.hotjarId;

		if (!id) {
			return;
		}

		const hotjar = new Hotjar(id, nuxtApp.$config.public.env);
		const hotjarScript = document.getElementById('hotjar');
		const userHasAcceptedStatisticsCookies = !!parsedCookie?.includes('stats');
		if (userHasAcceptedStatisticsCookies && !hotjarScript) {
			// if user has consented to statistics tracking, initialize hotjar script
			hotjar.handleHotjarImport();
		} else if (!userHasAcceptedStatisticsCookies && hotjarScript) {
			// if user has denied statistics tracking, make sure to delete any prevalent hotjar script
			hotjar.unloadHotjar();
		}
	});
});
