<template>
	<client-only>
		<Transition :name="animation" appear>
			<div :class="classes" class="container flex flex-col">
				<slot />
			</div>
		</Transition>
	</client-only>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
	name: 'ContentContainer',
	computed: {
		classes() {
			return {
				'sm:max-w-sm': this.size === 'tiny',
				'sm:max-w-lg': this.size === 'small',
				'md:max-w-2xl': this.size === 'medium',
				'lg:max-w-5xl': this.size === 'large',
				'xl:max-w-7xl': this.size === 'huge'
			};
		}
	},
	props: {
		size: {
			type: String,
			default: 'small',
			validator: (value: string) => ['tiny', 'small', 'medium', 'large', 'huge'].includes(value)
		},
		animation: { type: String, default: 'fade' }
	}
});
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
	transition: opacity 300ms;
}

.fade-enter,
.fade-leave-to {
	opacity: 0;
}

@keyframes bounceInUp {
	from,
	60%,
	75%,
	90%,
	to {
		-webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
		animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
	}

	from {
		opacity: 0;
		-webkit-transform: translate3d(0, 3000px, 0);
		transform: translate3d(0, 3000px, 0);
	}

	60% {
		opacity: 1;
		-webkit-transform: translate3d(0, -20px, 0);
		transform: translate3d(0, -20px, 0);
	}

	75% {
		-webkit-transform: translate3d(0, 10px, 0);
		transform: translate3d(0, 10px, 0);
	}

	90% {
		-webkit-transform: translate3d(0, -5px, 0);
		transform: translate3d(0, -5px, 0);
	}

	to {
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}
}

.bounceInUp-enter-active {
	animation: bounceInUp 0.5s;
}
</style>
