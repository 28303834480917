import { lsTest } from '@hokify/shared-components-nuxt3/lib/helpers/localstorage';
import { getCookie } from '@hokify/shared-components-nuxt3/lib/helpers/cookie';
import { EventBus } from '@hokify/shared-components-nuxt3/lib/eventbus';

let clientResumedSession = false;
let clientLoginInitialized = false;

async function getCookieSafe(nuxtApp: NuxtApp, key): Promise<any | undefined> {
	try {
		return (await nuxtApp?.runWithContext(() => useCookie(key).value)) ?? undefined;
	} catch (err) {
		console.warn('Error getting cookie. Fallback to old cookie getters', err);

		if (import.meta.server) {
			const headers = await nuxtApp?.runWithContext(() => useRequestHeaders(['cookie']));
			if ('cookie' in headers) {
				return getCookie(key, headers.cookie);
			}
		}

		if (import.meta.client) {
			return getCookie(key, window.document.cookie);
		}
	}
}

/**
 * Set the session token from the cookie (server & client), if available
 *
 * @param nuxtApp nuxt app instance
 */
async function setSessionToken(nuxtApp: NuxtApp): Promise<void> {
	const sessionToken =
		nuxtApp.$config.public.loginCookie &&
		(await getCookieSafe(nuxtApp, nuxtApp.$config.public.loginCookie));

	// check local cookies, if they are present and nothing is in the store yet, use them
	if (sessionToken && !nuxtApp.$loginStore.sessionIdCookie) {
		if (nuxtApp.$config.public.development) {
			console.log('using sessionToken from cookie (login)', sessionToken, typeof sessionToken);
		}
		await nuxtApp?.$loginStore?.setSessionIdCookie({ sessionToken });
	}
}

/**
 * Get reAuthData from cookie or localStorage
 *
 * @returns reAuthData from cookie or localStorage
 */
async function getReAuthData(
	nuxtApp: NuxtApp
): Promise<{ tokenID: string; token: string } | undefined> {
	const pwaReAuth = await getCookieSafe(nuxtApp, 'pwaReAuth');

	if (pwaReAuth?.tokenID && pwaReAuth?.token) {
		return pwaReAuth;
	}

	if (import.meta.client && !pwaReAuth) {
		try {
			const localStoragePwaReAuth = (lsTest() && localStorage.getItem('reAuthData')) || undefined;
			const parsedCookie = JSON.parse(localStoragePwaReAuth ?? '{}');
			if (parsedCookie.tokenID && parsedCookie.token) {
				const { tokenID, token } = parsedCookie;
				if (nuxtApp.$config.public.development) {
					console.log('tokenID', tokenID, token);
				}
				return { tokenID, token };
			}
		} catch (err) {
			console.error('failed to parse reAuthData', err);
		}
	}
}

async function doLogin(nuxtApp: NuxtApp) {
	const reAuthData = await getReAuthData(nuxtApp);

	await setSessionToken(nuxtApp);

	// if we have a sessionIdCookie or we are on the client try login via session
	if (
		(nuxtApp?.$loginStore?.sessionIdCookie || (!clientResumedSession && import.meta.client)) &&
		!nuxtApp?.$loginStore?.loggedIn
	) {
		if (import.meta.client) {
			clientResumedSession = true;
		}
		if (nuxtApp.$config.public.development) {
			console.log(import.meta.server ? 'SERVER' : 'CLIENT', 'resumeSession');
		}

		try {
			await nuxtApp?.$loginStore?.resumeSession();
		} catch (err) {
			// resumeSession may fail
			if (nuxtApp.$config.public.development) {
				console.log('session login failed', import.meta.client && err);
			}
		}
	}

	// on server&client try login via reauth cookie
	if (!nuxtApp?.$loginStore?.loggedIn && reAuthData) {
		if (nuxtApp.$config.public.development) {
			console.log(import.meta.server ? 'SERVER' : 'CLIENT', 'cookieReAuth');
		}
		const { tokenID, token } = reAuthData;
		if (nuxtApp.$config.public.development) {
			console.log('tokenID', tokenID, token);
		}

		try {
			await nuxtApp?.$loginStore?.doLogin({
				method: 'token-login',
				parameters: {
					apiUser: tokenID,
					token
				}
			});
		} catch (err) {
			// do Login may fail here
			if (nuxtApp.$config.public.development) {
				console.log('pwaReAuth login failed', import.meta.client && err);
			}
		}
	}

	if (import.meta.client) {
		if (nuxtApp?.$loginStore?.loggedIn) {
			if (!clientLoginInitialized) {
				clientLoginInitialized = true;
				EventBus.$emit('logged-in', {
					user: nuxtApp?.$userProfileStore?.obj,
					versionOkay: nuxtApp?.$userProfileStore?.versionOkay,
					bouncedEmails: nuxtApp?.$userProfileStore?.bouncedEmails
				});
			}
		} else {
			nuxtApp?.$loginStore?.setLoginState(false);
		}
	}
}

const loginPlugin: ReturnType<typeof defineNuxtPlugin> = defineNuxtPlugin({
	async setup() {
		const nuxtApp = tryUseNuxtApp();
		if (!nuxtApp) {
			return;
		}

		try {
			if (import.meta.server) {
				await doLogin(nuxtApp);
			}

			if (import.meta.client) {
				// on client, do not wait till this process is done
				doLogin(nuxtApp).catch(err => {
					const sentry = nuxtApp?.$sentry;
					if (sentry) {
						sentry.captureError(err);
					}
				});
			}
		} catch (err) {
			console.error('loginPlugin error', err);
		}
	}
});

export default loginPlugin;
