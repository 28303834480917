<template>
	<div>
		<client-only>
			<!-- Google ReCaptcha -->
			<ReCaptchaWidgetContainer />

			<template v-if="whenIdle">
				<template v-if="pageRef">
					<!-- inside page root -->
					<InsidePageRoot @compute-css-headers="$emit('compute-css-headers')" />
				</template>

				<!-- CookieBanner -->
				<CookieBannerContainer has-interacted mode="user" />

				<!-- feature advertisement modal -->
				<FeatureAdvertisement
					v-if="featuresOnTab && loggedIn"
					:lazy="0"
					:features="featuresOnTab"
				/>
				<FeatureAdvertisement
					v-else-if="featuresOnRoute && loggedIn && !selectedTab"
					:lazy="0"
					:features="featuresOnRoute"
				/>
			</template>

			<div v-else data-attr="idle-placeholder"></div>
		</client-only>

		<AccountDeletionModal
			v-if="hasPendingDeletion"
			mode="user"
			@cancel-delete-profile="doCancelDeleteProfile"
			@logout-only="logoutOnly"
		/>

		<!-- contact data invalid -->
		<ContactDataInvalid
			v-else-if="showInvalidContactDataB2C"
			:contact-data-invalid="showInvalidContactDataB2C"
		/>

		<!-- invalid version -->
		<InvalidVersion v-else-if="showInvalidVersion" />

		<!-- DSGVO -->
		<HokModal
			v-else-if="showDSGVO"
			ref="user-privacy"
			:classes="['v--modal']"
			:adaptive="true"
			:show-close-button="false"
			name="hokify-user-privacy"
			@vue:mounted="$modal.show('hokify-user-privacy')"
		>
			<div>
				<h3 class="text-center">Datenschutzerklärung für Jobsuchende</h3>
				<div>
					<HokCheckbox
						id="privacy_user_register"
						v-model="acceptedPrivacy"
						required
						name="privacy_user_register"
						class="text-sm"
					>
						hokify darf meine Daten speichern, um den vollen Funktionsumfang zu gewährleisten.
						<template #more>
							<HokLink class="link-dsgvo underline" to="/privacy#user-register" target="_blank">
								Datenschutzerklärung & Widerrufsrecht
							</HokLink>
						</template>
					</HokCheckbox>
				</div>

				<ErrorBox v-if="privacyError">
					<template #title> Zustimmung notwendig </template>
					Bitte bestätige die Datenschutzerklärung und das Widerrufsrecht um fortzufahren.
				</ErrorBox>

				<HokButton fullwidth="always" class="mb-4" color="main" @click="checkUserPrivacy">
					Weiter
				</HokButton>
			</div>
		</HokModal>
	</div>
</template>

<script lang="ts">
import { EventBus } from '@hokify/shared-components-nuxt3/lib/eventbus';
import { PrivacyType } from '@hokify/shared-components-nuxt3/lib/types/privacyType';
import type {
	IAPIFeatureAdvertisementSimple,
	IAPIFeatureAdvertisementExtended,
	IAPIMailBounceRelation,
	IAPILoginUser
} from '@hokify/common';
import { lsTest } from '@hokify/shared-components-nuxt3/lib/helpers/localstorage';
import InvalidVersion from '@hokify/pwa-core-nuxt3/components/login/InvalidVersion.vue';
import { defineComponent } from 'vue';
import type { ComponentPublicInstance, PropType } from 'vue';
// import type { MetaInfo } from 'vue-meta';
import { mapStores } from 'pinia';
import ReCaptchaWidgetContainer from '@hokify/shared-components-nuxt3/lib/plugins/hok-recaptcha/ReCaptchaWidgetContainer.vue';
import CookieBannerContainer from '@hokify/shared-components-nuxt3/lib/plugins/cookie-banner/CookieBannerContainer.vue';
import ContactDataInvalid from '@hokify/pwa-core-nuxt3/components/login/ContactDataInvalid.vue';
import InsidePageRoot from '@hokify/shared-components-nuxt3/lib/plugins/insidepage/InsidePageRoot.vue';
import FeatureAdvertisement from '@hokify/shared-components-nuxt3/lib/components/FeatureAdvertisement.vue';
import HokCheckbox from '@hokify/shared-components-nuxt3/lib/components/HokCheckbox.vue';
import ErrorBox from '@hokify/shared-components-nuxt3/lib/components/ErrorBox.vue';
import AccountDeletionModal from '@hokify/pwa-core-nuxt3/components/AccountDeletionModal.vue';
import { insidePages } from '@hokify/shared-components-nuxt3/lib/plugins/insidepage/insidePageService';
import { useUserProfileStore } from '@/stores/user-profile';
import { useLoginStore } from '@/stores/login';
import { useFeatureAdvertisementStore } from '@/stores/featureadvertisement';

export function isAPILoginUser(obj: any): obj is IAPILoginUser {
	return !!(obj && obj._id);
}

type RequestIdleCallbackDeadline = {
	readonly didTimeout: boolean;
	timeRemaining: () => number;
};

function hasRequestIdleCallback(window: any): window is {
	requestIdleCallback: (
		callback: (deadline: RequestIdleCallbackDeadline) => void,
		opts?: {
			timeout: number;
		}
	) => any;
} {
	return 'requestIdleCallback' in window;
}

const missingAuthenticationError = {
	message: 'Login notwendig',
	statusCode: 401,
	fatal: true
};

export default defineComponent({
	name: 'MainSetup',
	emits: ['compute-css-headers', 'update:modelValue'],
	components: {
		AccountDeletionModal,
		ContactDataInvalid,
		InvalidVersion,
		InsidePageRoot,
		FeatureAdvertisement,
		HokCheckbox,
		ErrorBox,
		ReCaptchaWidgetContainer,
		CookieBannerContainer
	},
	data() {
		const featuresOnTab = undefined as
			| IAPIFeatureAdvertisementSimple
			| IAPIFeatureAdvertisementExtended
			| undefined;
		const selectedTab = undefined as string | undefined;
		const headerCSS: string[] = [];
		const whenIdle = false;
		const privacyError = false;
		const showDSGVO = false;
		const {
			public: { mode }
		} = useRuntimeConfig();

		return {
			headerCSS,
			whenIdle,
			privacyError,
			showDSGVO,
			selectedTab,
			featuresOnTab,
			featuresOnTabTimeout: undefined as NodeJS.Timeout | undefined,
			EventBus,
			mode
		};
	},
	computed: {
		loggedIn() {
			return this.loginStore.loggedIn;
		},
		authenticationRequired() {
			return this.loginStore.authenticationRequired;
		},
		blockPageForUser: {
			get() {
				return this.modelValue;
			},
			set(value: boolean) {
				this.$emit('update:modelValue', value);
			}
		},
		user(): IAPILoginUser | undefined {
			return this.userProfileStore.obj;
		},
		versionOkay(): boolean | null {
			return this.userProfileStore.versionOkay;
		},
		bouncedEmails(): IAPIMailBounceRelation[] {
			return this.userProfileStore.bouncedEmails;
		},
		acceptedPrivacy: {
			get() {
				if (!this.privacy || this.privacy.user_register === undefined) {
					this.userProfileStore.updatePrivacy({ user_register: false });
				}

				return this.privacy && this.privacy.user_register;
			},
			set(value) {
				this.userProfileStore.updatePrivacy({ user_register: value });
				this.privacyError = !value;
			}
		},
		currentRoute(): string {
			return this.$route.path;
		},
		featuresOnRoute():
			| IAPIFeatureAdvertisementSimple
			| IAPIFeatureAdvertisementExtended
			| undefined {
			return this.featureAdvertisementStore.features.find(
				feat =>
					feat.mainUrl === this.currentRoute &&
					(!feat.testerGroup ||
						(isAPILoginUser(this.user) &&
							feat.testerGroup === this.userProfileStore.obj?.testerGroup))
			);
		},
		privacy():
			| false
			| {
					user_register?: boolean;
					user_application?: boolean;
					user_active_sourcing?: boolean;
					company_register?: boolean;
					company_posting?: boolean;
					company_application?: boolean;
					company_club?: boolean;
					company_digital_content?: boolean;
			  } {
			return isAPILoginUser(this.user) && this.user.privacy;
		},
		showInvalidVersion() {
			return this.loggedIn && this.versionOkay === false;
		},
		showInvalidContactData(): IAPIMailBounceRelation | false {
			if (
				!this.$route.path.match(/\/logout|login/) &&
				this.loggedIn &&
				this.bouncedEmails &&
				this.bouncedEmails.length &&
				this.bouncedEmails[0]?.bounce?.email
			) {
				return this.bouncedEmails[0]; // only return one email per visit to not spam user with popups
			}
			return false;
		},
		showInvalidContactDataB2C(): IAPIMailBounceRelation | false {
			if (
				this.mode === 'b2c' &&
				this.showInvalidContactData &&
				this.showInvalidContactData.type === 'user'
			) {
				return this.showInvalidContactData;
			}
			return false;
		},
		hasPendingDeletion() {
			return this.loggedIn && isAPILoginUser(this.user) && !!this.user.pendingDeletion;
		},
		tabAdvertisementShown(): boolean {
			return (
				lsTest() &&
				localStorage.getItem(`advertisementShown-${this.featuresOnTab?._id}`) ===
					this.featuresOnTab?._id
			);
		},
		insidePageIsOpen(): boolean {
			return !!insidePages.pages?.length;
		},
		...mapStores(useLoginStore, useFeatureAdvertisementStore, useUserProfileStore)
	},
	created() {
		this.checkUserObjectState();
	},
	async beforeMount() {
		if (lsTest() && localStorage.getItem('first-start') === null) {
			if (this.mode === 'b2c') {
				this.$trackUserEvent?.('first_start', {});
			}
			localStorage.setItem('first-start', 'true');
		}

		if (hasRequestIdleCallback(window)) {
			window.requestIdleCallback(
				() => {
					this.whenIdle = true;
				},
				{ timeout: 10000 }
			);
		} else {
			this.whenIdle = true;
		}
	},
	async mounted() {
		await this.checkAuthentication(this.authenticationRequired);

		this.EventBus.$on('select-tab', selected => {
			this.selectedTab = selected;
			this.featuresOnTab = this.featureAdvertisementStore.features.find(
				feat =>
					feat.mainUrl === this.selectedTab &&
					(!feat.testerGroup ||
						(isAPILoginUser(this.user) &&
							feat.testerGroup === this.userProfileStore.obj?.testerGroup))
			);
			if (this.featuresOnTab && !this.tabAdvertisementShown) {
				setTimeout(() => {
					this.$modal.show('feature-advertisement');
				}, 1500);
			}
		});

		if (this.featureAdvertisementStore.features.length === 0) {
			try {
				await this.featureAdvertisementStore.loadFeatureAdvertisement('B2C');
			} catch (err: any) {
				this.$nuxt.$errorHandler(err);
			}
		}
	},
	beforeUnmount() {
		this.EventBus.$off('reload-page');
	},
	methods: {
		async checkAuthentication(authenticationRequired: boolean) {
			const error = useError();
			if (authenticationRequired) {
				// Another error is already shown
				if (error.value) {
					return;
				}

				if (!this.userProfileStore?.obj || !this.loginStore.loggedIn) {
					// User is on a page that requires authentication and still not logged in
					this.blockPageForUser = true;
					throw createError(missingAuthenticationError);
				}
			}

			if (
				error.value?.statusCode === missingAuthenticationError.statusCode &&
				error.value?.message === missingAuthenticationError.message
			) {
				// Clear the error if the user is logged in
				await clearError();
			}

			this.$nextTick(() => {
				// Enable rendering of the page content
				this.blockPageForUser = false;
			});
		},
		checkUserObjectState() {
			if (
				this.loggedIn &&
				isAPILoginUser(this.user) &&
				(!this.user.privacy || !this.user.privacy.user_register)
			) {
				this.showDSGVO = true;
			}
		},
		checkUserPrivacy() {
			if (!this.acceptedPrivacy) {
				this.privacyError = true;
			} else {
				this.userProfileStore.acceptPrivacy(PrivacyType.UserRegister);
				this.$modal.hide('hokify-user-privacy');
				this.showDSGVO = false;
			}
		},
		async logoutOnly() {
			this.$modal.hide('accountDeletion');
			try {
				await this.loginStore.logout();
			} catch (err) {
				this.$nuxt.$errorHandler(err);
			}
			this.$trackGenericEvent?.('user_deletion_logout', {});
		},
		async doCancelDeleteProfile() {
			try {
				await this.userProfileStore.cancelDeleteProfile();
				this.userProfileStore.updateElements({ pendingDeletion: undefined });
				this.$modal.hide('accountDeletion');
				this.$snack.success({ text: 'Die Accountlöschung wurde abgebrochen.' });
				this.$trackUserEvent?.('user_deletion_reactivate', {});
			} catch (err) {
				this.$nuxt.$errorHandler(err, 'Abbrechen der Accountlöschung nicht möglich.');
			}
		},
		onRouteChange() {
			if (this.featuresOnTabTimeout) {
				clearTimeout(this.featuresOnTabTimeout);
			}
			this.featuresOnTab = undefined;
			this.selectedTab = undefined;
			this.featuresOnTabTimeout = setTimeout(() => {
				if (this?.featuresOnRoute && this?.$modal?.show) {
					this.$modal.show('feature-advertisement');
				}
			}, 1500);
		},
		loggedInStateChangedWatcher(loggedIn) {
			if (loggedIn) {
				this.checkUserObjectState();
			}
		}
	},
	props: {
		modelValue: { type: Boolean, default: false },
		pageRef: { type: Object as PropType<ComponentPublicInstance | null>, default: null }
	},
	watch: {
		'$route.path': [
			{
				handler: 'onRouteChange'
			}
		],
		loggedIn: [
			{
				handler: 'loggedInStateChangedWatcher'
			}
		],
		authenticationRequired: [
			{
				handler: 'checkAuthentication'
			}
		]
	}
});
</script>
