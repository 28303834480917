<template>
	<div :class="wrapperClasses" class="leading-tight">
		<input
			:id="id"
			:name="name"
			:disabled="disabled"
			:value="value"
			:checked="overwriteChecked !== null ? overwriteChecked : isActive"
			type="radio"
			class="cursor-pointer"
			:class="radioClasses"
			@change="onChange"
		/>
		<label :for="id" :class="{ custom: $slots.custom }" class="cursor-pointer">
			<slot name="custom" />
			<slot />
		</label>
	</div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { EventBus } from '../eventbus';

export default defineComponent({
	name: 'HokRadio',
	emits: ['change'],
	data() {
		return {
			isActive: false,
			EventBus
		};
	},
	computed: {
		wrapperClasses() {
			return {
				'opacity-40': this.disabled,
				'inline-block': this.inline,
				'flex items-start space-x-2 mb-3': this.styling !== 'none'
			};
		},
		radioClasses() {
			return {
				'accent-color-text min-w-[18px] h-[18px] border border-color-text':
					this.styling === 'default',
				'appearance-none accent-color-white min-w-[18px] min-h-[18px] bg-transparent checked:w-4 checked:h-4 ring-1 ring-color-white rounded-full checked:bg-color-white checked:border-4 checked:border-color-main-business':
					this.styling === 'white',
				'appearance-none': this.styling === 'none'
			};
		}
	},
	mounted() {
		(this.$parent as any).register(this);
	},
	beforeUnmount() {
		(this.$parent as any).unregister(this);
	},
	methods: {
		onChange() {
			if (this.disabled) {
				return;
			}

			if (!this.isActive) {
				this.EventBus.$emit(`change-${this.id}`, this.value);
				this.isActive = true;
			}
		}
	},
	props: {
		id: { type: String, required: true },
		name: { type: String, default: undefined },
		disabled: { type: Boolean, default: false },
		inline: { type: Boolean, default: true },
		value: { type: [Boolean, String, Number] },
		overwriteChecked: { type: [Boolean, null], default: null },
		styling: {
			type: String,
			default: 'default',
			validator: (value: string) => ['default', 'white', 'none'].includes(value)
		}
	}
});
</script>
