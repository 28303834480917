<template>
	<span
		v-if="invisible && to"
		:id="id"
		:class="buttonClasses"
		role="link"
		@click="openInvisibleLink"
	>
		<slot />
	</span>
	<HokLink
		v-else-if="to"
		:id="id"
		:to="to"
		:link-type="linkType"
		:name="name"
		:value="value"
		:class="buttonClasses"
		:target="target"
	>
		<slot />
	</HokLink>
	<button
		v-else
		:id="id"
		:name="name"
		:value="value"
		:type="submit ? 'submit' : 'button'"
		:class="buttonClasses"
		:disabled="disabled"
		@click="click"
		@touchstart="$emit('touchstart', $event)"
		@touchend="$emit('touchend', $event)"
	>
		<slot />
	</button>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import HokLink from './HokLink.vue';

export default defineComponent({
	name: 'HokButton',
	components: { HokLink },
	emits: ['click', 'touchstart', 'touchend'],
	data() {
		return {
			processing: false
		};
	},
	computed: {
		buttonClasses() {
			const buttonClasses = ['inline-flex rounded-full justify-center items-center'];

			if ((this.fullwidth === 'mobile' && this.$isMobile.phone) || this.fullwidth === 'always') {
				buttonClasses.push('w-full');
			}

			if (this.disabled) {
				if (this.outline) {
					buttonClasses.push('border-color-grey-light');
					buttonClasses.push('text-color-grey-light');
				} else if (!this.isText) {
					buttonClasses.push('bg-color-grey-light');
					buttonClasses.push('border-color-grey-light');
					buttonClasses.push('text-color-white');
				}
				buttonClasses.push('cursor-not-allowed');
			} else {
				buttonClasses.push('cursor-pointer');
			}
			// if button should be a text button
			if (this.isText) {
				buttonClasses.push('border-0');
				buttonClasses.push('bg-transparent');
				buttonClasses.push(this.fontWeigthClass ? this.fontWeigthClass : 'font-bold');
				if (this.disabled) {
					buttonClasses.push('text-color-grey');
				} else {
					switch (this.color) {
						case 'main':
							buttonClasses.push('text-color-main');
							break;
						case 'blue':
							buttonClasses.push('text-color-blue');
							break;
						case 'yellow':
							buttonClasses.push('text-color-yellow');
							break;
						case 'purple':
							buttonClasses.push('text-color-purple');
							break;
						case 'business':
							buttonClasses.push('text-color-main-business');
							break;
						case 'grey':
							buttonClasses.push('text-color-grey-light');
							break;
						case 'blue-grey':
							buttonClasses.push('text-color-blue-grey');
							break;
						case 'white':
							buttonClasses.push('text-color-white');
							break;
						case 'coral':
							buttonClasses.push('text-color-coral');
							break;
						case 'text':
							buttonClasses.push('text-color-text');
							break;
						case 'grey-medium':
							buttonClasses.push('text-color-grey-medium');
							break;
						default:
						// Nothing
					}
				}
				if (this.underline) {
					buttonClasses.push('underline');
				}
			} else {
				// Set default styling for button
				buttonClasses.push('border');
				switch (this.size) {
					case 'small':
						buttonClasses.push('px-3');
						buttonClasses.push('py-1');
						buttonClasses.push('leading-tight');
						buttonClasses.push(this.fontWeigthClass ? this.fontWeigthClass : 'font-normal');
						break;
					case 'medium':
						buttonClasses.push('px-8');
						buttonClasses.push('py-2');
						buttonClasses.push(this.fontWeigthClass ? this.fontWeigthClass : 'font-bold');
						break;
					case 'big':
						buttonClasses.push('px-12');
						buttonClasses.push('py-3');
						buttonClasses.push(this.fontWeigthClass ? this.fontWeigthClass : 'font-bold');
						break;
					default:
					// nothing!
				}
				// if outline active and not disabled only set border- and text-color
				if (this.outline && !this.disabled) {
					// buttonClasses.push('outline');
					buttonClasses.push('bg-color-transparent');
					switch (this.color) {
						case 'main':
							buttonClasses.push('border-color-main');
							buttonClasses.push('text-color-main');
							buttonClasses.push('hover:bg-color-main');
							buttonClasses.push('hover:text-color-white');
							break;
						case 'blue':
							buttonClasses.push('border-color-blue');
							buttonClasses.push('text-color-blue');
							buttonClasses.push('hover:bg-color-blue');
							buttonClasses.push('hover:text-color-white');
							break;
						case 'yellow':
							buttonClasses.push('border-color-yellow');
							buttonClasses.push('text-color-yellow');
							buttonClasses.push('hover:bg-color-yellow');
							buttonClasses.push('hover:text-color-white');
							break;
						case 'purple':
							buttonClasses.push('border-color-purple');
							buttonClasses.push('text-color-purple');
							buttonClasses.push('hover:bg-color-purple');
							buttonClasses.push('hover:text-color-white');
							break;
						case 'business':
							buttonClasses.push('border-color-main-business');
							buttonClasses.push('text-color-main-business');
							buttonClasses.push('hover:bg-color-main-business');
							buttonClasses.push('hover:text-color-white');
							break;
						case 'coral':
							buttonClasses.push('border-color-coral');
							buttonClasses.push('text-color-coral');
							buttonClasses.push('hover:bg-color-coral');
							buttonClasses.push('hover:text-color-white');
							break;
						case 'grey':
							buttonClasses.push('border-color-grey-light');
							buttonClasses.push('text-color-grey-light');
							break;
						case 'blue-grey':
							buttonClasses.push('border-color-blue-grey');
							buttonClasses.push('text-color-blue-grey');
							break;
						case 'white':
							buttonClasses.push('border-color-white');
							buttonClasses.push('text-color-white');
							buttonClasses.push('hover:bg-color-white');
							buttonClasses.push('hover:text-color-blue-grey');
							break;
						case 'text':
							buttonClasses.push('border-color-text');
							buttonClasses.push('text-color-text');
							break;
						default:
						// Nothing
					}
				} else if (!this.disabled) {
					// set button styling if not disabled and not outline
					// bg-color, border-color, text-color, and their respective hover states will be set
					switch (this.color) {
						case 'main':
							buttonClasses.push('bg-color-main');
							buttonClasses.push('border-color-main');
							buttonClasses.push('hover:border-color-main-hover');
							buttonClasses.push('hover:bg-color-main-hover');
							buttonClasses.push('text-color-white');
							break;
						case 'blue':
							buttonClasses.push('bg-color-blue');
							buttonClasses.push('border-color-blue');
							buttonClasses.push('hover:border-color-blue-hover');
							buttonClasses.push('hover:bg-color-blue-hover');
							buttonClasses.push('text-color-white');
							break;
						case 'yellow':
							buttonClasses.push('bg-color-yellow');
							buttonClasses.push('border-color-yellow');
							buttonClasses.push('hover:border-color-yellow-hover');
							buttonClasses.push('hover:bg-color-yellow-hover');
							buttonClasses.push('text-color-white');
							break;
						case 'purple':
							buttonClasses.push('bg-color-purple');
							buttonClasses.push('border-color-purple');
							buttonClasses.push('hover:border-color-purple-hover');
							buttonClasses.push('hover:bg-color-purple-hover');
							buttonClasses.push('text-color-white');
							break;
						case 'business':
							buttonClasses.push('bg-color-main-business');
							buttonClasses.push('border-color-main-business');
							buttonClasses.push('hover:border-color-main-business-hover');
							buttonClasses.push('hover:bg-color-main-business-hover');
							buttonClasses.push('text-color-white');
							break;
						case 'coral':
							buttonClasses.push('bg-color-coral');
							buttonClasses.push('border-color-coral');
							buttonClasses.push('hover:border-color-coral-hover');
							buttonClasses.push('hover:bg-color-coral-hover');
							buttonClasses.push('text-color-white');
							break;
						case 'grey':
							buttonClasses.push('bg-color-grey-light');
							buttonClasses.push('border-color-grey-light');
							buttonClasses.push('text-color-white');
							break;
						case 'blue-grey':
							buttonClasses.push('bg-color-blue-grey');
							buttonClasses.push('border-color-blue-grey');
							buttonClasses.push('text-color-white');
							break;
						case 'white':
							buttonClasses.push('bg-color-white');
							buttonClasses.push('border-color-white');
							buttonClasses.push('hover:border-color-white-hover');
							buttonClasses.push('hover:bg-color-white-hover');
							buttonClasses.push('text-color-main-business');
							break;
						case 'white-text':
							buttonClasses.push('bg-color-white');
							buttonClasses.push('border-color-white');
							buttonClasses.push('hover:border-color-white-hover');
							buttonClasses.push('hover:bg-color-white-hover');
							buttonClasses.push('text-color-text');
							break;
						case 'white-main':
							buttonClasses.push('bg-color-white');
							buttonClasses.push('border-color-main');
							buttonClasses.push('text-color-main');
							buttonClasses.push('hover:text-color-main');
							buttonClasses.push('hover:bg-color-white');
							break;
						default:
						// Nothing
					}
				}
			}

			if (this.to && this.positionSet) {
				buttonClasses.push('relative');
			}
			return buttonClasses;
		},
		fontWeigthClass(): string | false {
			if (this.fontWeight)
				switch (this.fontWeight) {
					case 'normal':
						return 'font-normal';
					case 'light':
						return 'font-light';
					case 'bold':
					default:
						return 'font-bold';
				}
			else return false;
		}
	},
	methods: {
		click($event) {
			if (this.disabled) return; // do net emit click event, if button is disabled
			if (!this.processing) {
				this.processing = true;
				this.$emit('click', $event);
				setTimeout(() => {
					this.processing = false;
				}, 1000); // allow another click only after 1 second pause
			}
		},
		openInvisibleLink() {
			window.location.href = this.to;
		}
	},
	props: {
		id: { type: String },
		name: { type: String },
		linkType: { type: String, required: false },
		value: { type: String, default: undefined },
		fullwidth: {
			type: [String, Boolean],
			default: false,
			validator: (value: string | boolean) => [false, 'mobile', 'always'].includes(value)
		},
		disabled: { type: Boolean, default: false },
		submit: { type: Boolean, default: false },
		isText: { type: Boolean, default: false },
		color: {
			type: String,
			default: 'blue',
			validator: (value: string) =>
				[
					'blue',
					'yellow',
					'main',
					'purple',
					'business',
					'grey',
					'blue-grey',
					'white',
					'white-text',
					'coral',
					'text',
					'grey-medium',
					'white-main'
				].includes(value)
		},
		size: {
			default: 'medium',
			validator: (value: string) => ['small', 'medium', 'big'].includes(value),
			type: String
		},
		fontWeight: {
			default: false,
			validator: (value: string | boolean) => ['light', 'normal', 'bold', false].includes(value),
			type: [String, Boolean]
		},
		to: { type: String, default: undefined },
		outline: { type: Boolean, default: false },
		underline: { type: Boolean, default: false },
		invisible: { type: Boolean, default: false },
		positionSet: { type: Boolean, default: true },
		target: { type: String, required: false }
	}
});
</script>

<style lang="scss" scoped>
button:not(.bg-transparent),
a:not(.bg-transparent) {
	text-decoration: none;

	&:hover {
		transition: background-color 600ms ease;
	}

	svg {
		fill: #ffffff;
	}

	&:active {
		box-shadow:
			0 0 10px white inset,
			0 1px 0 whitesmoke;
	}
}
</style>
