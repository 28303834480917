// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { getSentryClient } from '@hokify/shared-components-nuxt3/lib/plugins/sentry/client';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { type ISentryConfig } from '@hokify/shared-components-nuxt3/lib/plugins/sentry/ISentryConfig';

export default defineNuxtPlugin({
	name: 'sentry',
	async setup(nuxtApp) {
		const {
			public: { sentry: config }
		} = useRuntimeConfig();

		const { sentry } = await getSentryClient(config as ISentryConfig, { nuxtApp, onNuxtReady });
		return {
			provide: { sentry }
		};
	}
});
