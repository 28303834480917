import { defineStore } from 'pinia';
import type { IAPILoginUser, IAPIMailBounceRelation } from '@hokify/common';
import { EventBus } from '@hokify/shared-components-nuxt3/lib/eventbus';
import { completeObj } from '@hokify/pwa-core-nuxt3/helpers/user';
import { PrivacyType } from '@hokify/shared-components-nuxt3/lib/types/privacyType';
import { lsTest } from '@hokify/shared-components-nuxt3/lib/helpers/localstorage';
// eslint-disable-next-line import/no-cycle
import { useLoginStore } from './login';
import { useMessagesStore } from './messages';
import { setPropByPath } from '~/helpers/updateElements';

export interface IUserProfileState {
	obj?: IAPILoginUser;
	versionOkay: boolean | null;
	bouncedEmails: IAPIMailBounceRelation[];
	cvParsingDone: { done: boolean; parsingDate: Date | undefined };
	cvParsingError: { error: string | undefined; notCvErrorSeen?: boolean | undefined } | undefined;
}

export function isAPILoginUser(obj: any): obj is IAPILoginUser {
	return obj && obj._id;
}

export const useUserProfileStore = defineStore('userProfile', {
	state: (): IUserProfileState => ({
		obj: undefined,
		versionOkay: null,
		bouncedEmails: [],
		cvParsingDone: { done: false, parsingDate: undefined },
		cvParsingError: { error: '', notCvErrorSeen: undefined }
	}),
	actions: {
		async saveSetting({ type, value }) {
			const postData = {};
			postData[type] = value;

			return this.$nuxt
				.$hokFetch('/app-api/settings', { method: 'PUT', body: postData })
				.then(({ userUpdate }) => {
					if (userUpdate) {
						this.updateElements(userUpdate);
					}

					return true;
				});
		},
		async acceptPrivacy(type: PrivacyType) {
			return this.$nuxt
				.$hokFetch('/app-api/acceptPrivacy', { method: 'PUT', body: { privacy: type } })
				.then(success => {
					if (success) {
						this.updatePrivacy({ [type]: true });
					}

					return true;
				})
				.catch(err => {
					throw (err.response && err.response.data) || err.response || err;
				});
		},
		async sendVerificationCode({
			entryUrl,
			recaptchaToken,
			recaptchaVersion,
			verificationType
		}: {
			entryUrl: string;
			recaptchaToken: string;
			recaptchaVersion: 'v2' | 'v3';
			verificationType?: string | true;
		}): Promise<{
			email?: boolean;
			sms?: boolean;
		}> {
			const buildQueryParams: Record<string, any> = { entryUrl, recaptchaToken, recaptchaVersion };
			if (verificationType) {
				buildQueryParams.verificationType = verificationType;
			}

			const queryParams = new URLSearchParams(buildQueryParams).toString();
			try {
				return this.$nuxt.$hokFetch(
					`/app-api/sendVerificationCode${queryParams ? `?${queryParams}` : ''}`
				);
			} catch (err: any) {
				throw (err.response && err.response.data) || err.response || err;
			}
		},
		async setUser({ user, versionOkay, bouncedEmails }) {
			const loginStore = useLoginStore();
			const messagesStore = useMessagesStore();
			this.obj = completeObj(user, 'user');
			this.versionOkay = versionOkay;
			this.bouncedEmails = bouncedEmails;

			if (user && lsTest()) {
				localStorage.setItem('mode', user.mode);
			}

			if (user && user._id) {
				loginStore.setLoginState(true);
				// process hooks
				if (import.meta.client) {
					EventBus.$emit('logged-in', { user, versionOkay, bouncedEmails });
				}
				await messagesStore.updateOStats();
			} else {
				loginStore.setLoginState(false);
			}
		},
		async useHokifyCv(payload?: Record<string, unknown>) {
			const { design, color, lineSpacing, disableProfilePic } =
				payload && typeof payload === 'object'
					? payload
					: {
							design: undefined,
							color: undefined,
							lineSpacing: undefined,
							disableProfilePic: undefined
						};
			const update = await this.$nuxt.$hokFetch('/app-api/jobseeker/cv/use-hokify-cv', {
				method: 'POST',
				body: {
					design,
					color,
					lineSpacing,
					disableProfilePic
				}
			});
			if (update.userUpdate) {
				this.updateElements(update.userUpdate);
			}
			return update;
		},
		updateElements(update) {
			// update userObj
			this.setUpdatedElements(update);

			// make sure loggedIn is set to true
			if (isAPILoginUser(this.obj)) {
				const loginStore = useLoginStore();
				loginStore.setLoginState(true);
			}
		},
		getAnswers() {
			try {
				return this.$nuxt.$hokFetch('/app-api/answers');
			} catch (err: any) {
				throw err.response?.data || err.response || err;
			}
		},
		answerQuestion({ decision, questionId }) {
			try {
				return this.$nuxt.$hokFetch('/app-api/answer', {
					method: 'POST',
					body: { decision, questionId }
				});
			} catch (err: any) {
				throw err.response?.data || err.response || err;
			}
		},
		async deleteProfile(feedbackMessage?: string) {
			return this.$nuxt.$hokFetch('/app-api/profile', {
				method: 'DELETE',
				data: { feedbackMessage: feedbackMessage || undefined }
			});
		},
		cancelDeleteProfile() {
			return this.$nuxt.$hokFetch('/app-api/cancel-delete-profile', { method: 'POST' });
		},
		async setSelfClassification(payload: {
			rootField: string;
			subFields: string[];
			value: string;
		}) {
			return this.$nuxt.$hokFetch('app-api/self-classification', {
				method: 'POST',
				body: payload
			});
		},
		resetInvalidEmail(): void {
			this.bouncedEmails = [];
		},
		addElement(addObj): void {
			let obj = addObj.path.split('.').reduce((o, i) => o[i], this.obj);
			if (!obj) {
				// add empty array on this
				const rootPath = addObj.path.split('.');
				const lastPath = rootPath.pop();

				const objRoot = rootPath.reduce((o, i) => o[i], this.obj);
				// set empty array
				objRoot[lastPath] = [];
				obj = objRoot[lastPath];
			}

			if (Array.isArray(obj)) {
				obj.push(addObj.obj);
				return;
			}

			console.warn(`path ${addObj.path} is not an array, canceling add`);
		},
		setUpdatedElements(userUpdate): void {
			Object.keys(userUpdate).forEach(path => {
				setPropByPath(this.obj, path, userUpdate[path]);
			});
		},
		updateElement(updateObj): void {
			if (Object.keys(updateObj.update).length === 0) {
				console.warn('skipping empty updatePath update', updateObj);
				return;
			}

			const obj = updateObj.path.split('.').reduce((o, i) => o[i], this.obj);

			if (Array.isArray(obj)) {
				// update existing entry
				if (updateObj._id !== undefined || updateObj.index !== undefined) {
					obj.some((o, index) => {
						if (
							(updateObj.index !== undefined && updateObj.index === index) ||
							(updateObj._id !== undefined && o._id === updateObj._id)
						) {
							obj[index] = { ...o, ...updateObj.update }; // replace with new obj - so VUE detects the chagnes
							return true;
						}
						return false;
					});
					return;
				}

				// add new array entry
				obj.push(updateObj.update);
			}

			if (obj !== undefined) {
				Object.keys(updateObj.update).forEach(key => {
					obj[key] = updateObj.update[key];
				});
			}
		},
		updatePrivacy(update): void {
			Object.keys(update).forEach(key => {
				if (this.obj) {
					this.obj.privacy[key] = update[key];
				}
			});
		},
		sortCV(what): void {
			switch (what) {
				case 'education':
					if (isAPILoginUser(this.obj) && this.obj.cvInfo && this.obj.cvInfo.educations) {
						this.obj.cvInfo.educations.sort((edu1, edu2) => {
							if (
								edu1.status === 'studying' &&
								edu2.status === 'studying' &&
								edu1.startDate &&
								edu2.startDate
							) {
								const d1 = new Date(edu1.startDate);
								const d2 = new Date(edu2.startDate);
								return d2.getTime() - d1.getTime();
							}
							if (edu1.status === 'studying' || edu2.status === 'studying') {
								return edu1.status === 'studying' ? -1 : 1;
							}

							if (edu1.endDate && edu2.endDate) {
								const d1 = new Date(edu1.endDate);
								const d2 = new Date(edu2.endDate);
								if (d1.getFullYear() !== d2.getFullYear() && d1.getMonth() !== d2.getMonth()) {
									return d2.getTime() - d1.getTime();
								}
							}

							if (edu1.startDate && edu2.startDate) {
								return new Date(edu2.startDate).getTime() - new Date(edu1.startDate).getTime();
							}

							if (!edu1.startDate || !edu1.endDate) {
								return 1;
							}
							return -1;
						});
					}
					break;
				case 'experience':
					if (isAPILoginUser(this.obj) && this.obj.cvInfo && this.obj.cvInfo.experiences) {
						this.obj.cvInfo.experiences.sort((exp1, exp2) => {
							if (exp1.isCurrent && exp2.isCurrent && exp1.startDate && exp2.startDate) {
								const d1 = new Date(exp1.startDate);
								const d2 = new Date(exp2.startDate);
								return d2.getTime() - d1.getTime();
							}
							if (exp1.isCurrent || exp2.isCurrent) {
								return exp1.isCurrent ? -1 : 1;
							}

							if (exp1.endDate && exp2.endDate) {
								const d1 = new Date(exp1.endDate);
								const d2 = new Date(exp2.endDate);
								if (d1.getFullYear() !== d2.getFullYear() && d1.getMonth() !== d2.getMonth()) {
									return d2.getTime() - d1.getTime();
								}
							}

							if (exp1.startDate && exp2.startDate) {
								return new Date(exp2.startDate).getTime() - new Date(exp1.startDate).getTime();
							}

							if (!exp1.startDate || !exp1.endDate) {
								return 1;
							}
							return -1;
						});
					}
					break;
				default:
					throw new Error(`invalid sort type ${what}`);
			}
		},
		async verifyParsedCv(payload: { isVerified: boolean }): Promise<{ isVerified: boolean }> {
			try {
				const res = await this.$nuxt.$hokFetch('app-api/jobseeker/cv/set-cv-data-verified', {
					method: 'POST',
					body: payload
				});
				this.updateElements(res.userUpdate);
				return res;
			} catch (error: any) {
				throw error.response?.data || error.response || error;
			}
		},
		updateCvParsingDone(cvParsingDone) {
			this.cvParsingDone = cvParsingDone;
		},
		updateCvParsingError(cvParsingError) {
			this.cvParsingError = cvParsingError;
		}
	}
});
