import type { RouterConfig } from '@nuxt/schema';
import { insidePages } from '@hokify/shared-components-nuxt3/lib/plugins/insidepage/insidePageService';

export default {
	scrollBehavior(to, _from, savedPosition) {
		let insidePagesPosition;

		if (insidePages.pages.length === 0 && insidePages.bodyScrollOffset !== undefined) {
			insidePagesPosition = insidePages.bodyScrollOffset;
			insidePages.bodyScrollOffset = undefined;
		}

		return new Promise(resolve => {
			if (insidePagesPosition) {
				resolve({ x: 0, y: insidePagesPosition });
			} else if (savedPosition) {
				resolve(savedPosition);
			} else if (to.hash) {
				resolve({
					selector: to.hash
				});
			} else {
				resolve({ x: 0, y: 0 });
			}
		});
	}
} as RouterConfig;
