import { createGmapVuePlugin } from '@gmap-vue/v3';
import '@gmap-vue/v3/dist/style.css';
import { defineNuxtPlugin } from 'nuxt/app';

// map-id="c1577a1614be710d""
export default defineNuxtPlugin(nuxtApp => {
	const config = useRuntimeConfig();
	nuxtApp.vueApp.use(
		createGmapVuePlugin({
			dynamicLoad: true,
			load: {
				key: config.public.googleApiKey,
				libraries: 'places,visualization' // necessary for places input
			}
		})
	);
});
