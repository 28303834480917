import { defineStore } from 'pinia';

export interface IValuesState {
	cache: Record<string, unknown>;
}

function compareFct(key: string): (a: object, b: object) => number {
	return function compareFctInner(a, b): number {
		if (a[key] < b[key]) {
			return -1;
		}
		if (a[key] > b[key]) {
			return 1;
		}
		return 0;
	};
}

export const useValuesStore = defineStore('values', {
	state: (): IValuesState => ({
		cache: {}
	}),
	actions: {
		async organizationSuggestion(term: string) {
			return this.getSuggestion({
				route: 'recruiter/organization/search',
				term,
				useOldApi: false
			});
		},
		async userSchoolSuggestion(term: string) {
			return this.getSuggestion({
				route: 'user-schoolsuggestion',
				term,
				useOldApi: true
			});
		},
		async userJobCompanySuggestion(term: string) {
			return this.getSuggestion({
				route: 'jobseeker/company/search',
				term,
				useOldApi: false
			});
		},
		async ontologyJobTitleSuggestion(term: string) {
			return this.getSuggestion({
				route: 'ontology-suggestions?type=jobtitle',
				term,
				useOldApi: true
			});
		},
		async ontologyCitySuggestion(term: string) {
			return this.getSuggestion({
				route: 'ontology-suggestions?type=city',
				term,
				useOldApi: true
			});
		},
		async ontologySuggestion(term: string) {
			return this.getSuggestion({
				route: 'ontology-suggestions',
				term,
				useOldApi: true
			});
		},
		async countryListSuggestion(term: string) {
			return this.getSuggestion({
				route: 'country-list',
				term,
				useOldApi: true
			});
		},
		async getSuggestion({
			route,
			term,
			useOldApi
		}: {
			route: string;
			term: string;
			useOldApi: boolean;
		}): Promise<any[]> {
			const response = await this.$nuxt.$hokFetch(
				`${!useOldApi ? 'app-api' : 'api'}/${route}${route.includes('?') ? '&' : '?'}term=${encodeURIComponent(term)}`
			);

			if (Array.isArray(response)) {
				const filteredResponse = response.filter((obj: any) => {
					// local filtering in case we get a list of objects (legacy code)
					if (typeof obj === 'object' && obj?.name) {
						return obj.name.toLowerCase().includes(term.toLowerCase());
					}
					return true;
				});

				return filteredResponse;
			}
			if (this.$nuxt?.$sentry) {
				this.$nuxt.$sentry.captureMessage('Received suggestions are not of type Array.');
			}
			return [];
		},
		async getUserDocumenttypes() {
			return this.getFromCache({ route: '/api/user-documenttypes' });
		},
		async getJobFields() {
			return this.getFromCache({
				route: '/app-api/utils/jobfields',
				sortMode: 'jobfields'
			});
		},
		async getEducationDegree(level) {
			return this.getFromCache({
				route: `app-api/jobseeker/cv/education-degrees?level=${level}`,
				cacheid: level
			});
		},
		async getEducationSublevels(rootLevel) {
			return this.getFromCache({
				route: `/app-api/utils/education-levels?mode=jobseeker&level=${rootLevel}`,
				cacheid: rootLevel
			});
		},
		async getEducationLevelsForUser() {
			return this.getFromCache({
				route: '/app-api/utils/education-levels?mode=jobseeker'
			});
		},
		async getPredefinedCvSkills() {
			return this.getFromCache({
				route: '/api/cv-skills',
				allowGuest: true
			});
		},
		async getFromCache(payload) {
			const { route, cacheid } = payload;
			const cacheKey = cacheid ? `${route}|${cacheid}` : route;
			if (this.cache && this.cache[cacheKey]) {
				return this.cache[cacheKey];
			}

			const data = await this.$nuxt.$hokFetch(route);

			if (payload.sortMode) {
				switch (payload.sortMode) {
					case 'jobfields':
						data.sort(compareFct('name'));
						data.forEach(parent => {
							parent.children.sort(compareFct('name'));
						});
						break;
					default:
						throw new Error('unsupporetd sortBy');
				}
			}

			this.cache({
				cacheKey,
				data
			});
			return data;
		},
		cache(cache: { cacheKey: string; data: any }): void {
			this.cache[cache.cacheKey] = cache.data;
		}
	}
});
