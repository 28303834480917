<template>
	<SwitchGroup>
		<div class="flex items-center">
			<!-- wrong v-model type seems to be a headlessUI issue: https://github.com/tailwindlabs/headlessui/pull/459-->
			<Switch v-slot="{ checked }" v-model="model" as="template">
				<button
					:disabled="disabled"
					class="relative inline-flex h-[20px] w-[36px] shrink-0 rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75"
					:class="[
						checked ? `${sliderColor}` : 'bg-color-grey-light',
						disabled ? 'cursor-not-allowed' : 'cursor-pointer'
					]"
				>
					<span class="sr-only">Enable switch</span>
					<span
						:class="[
							checked ? 'translate-x-4' : 'translate-x-0',
							disabled ? 'cursor-not-allowed bg-color-white/75' : 'bg-color-white shadow-lg'
						]"
						class="pointer-events-none inline-block h-4 w-4 transform rounded-full ring-0 transition duration-200 ease-in-out"
					/>
				</button>
			</Switch>
			<SwitchLabel v-if="$slots.default" class="ml-2 cursor-pointer"><slot /></SwitchLabel>
		</div>
	</SwitchGroup>
</template>

<script setup lang="ts">
import { computed, watch } from 'vue';
import { Switch, SwitchGroup, SwitchLabel } from '@headlessui/vue';

const model = defineModel<boolean>({ required: true }); // https://vuejs.org/api/sfc-script-setup.html#definemodel
const emit = defineEmits<{
	input: [value: boolean];
}>();

export interface IHokSwitchProps {
	disabled?: boolean;
	styling?: 'main' | 'business';
}

// https://vuejs.org/api/sfc-script-setup.html#default-props-values-when-using-type-declaration
const props = withDefaults(defineProps<IHokSwitchProps>(), { disabled: false, styling: 'main' });

const sliderColor = computed(() => {
	if (props.disabled) {
		switch (props.styling) {
			case 'main':
				return 'bg-color-main/75';
			case 'business':
			default:
				return 'bg-color-main-business/75';
		}
	}
	switch (props.styling) {
		case 'main':
			return 'bg-color-main';
		case 'business':
		default:
			return 'bg-color-main-business';
	}
});

watch(model, value => {
	emit('input', value);
});
</script>
