<template>
	<HokModal
		:adaptive="!!$isMobile.any"
		:width="$isMobile.any ? '95%' : '500px'"
		:show-close-button="false"
		:click-to-close="false"
		name="contactDataInvalid"
		transition="scale"
		@vue:mounted="$modal.show('contactDataInvalid')"
	>
		<div class="text-center">
			<h2>Fehlerhafte E-Mail</h2>
			<p class="mb-3">
				Die E-Mail-Adresse in {{ mode === 'user' ? 'deinen' : 'Ihren' }} {{ emailSourceText }}:
				<b>{{ contactDataInvalid.bounce.email }}</b>
				konnte nicht erreicht werden.
				<br />Bitte
				{{ mode === 'user' ? 'überprüfe diese und gib' : 'überprüfen Sie diese und geben Sie' }}
				eine gültige<br />E-Mail-Adresse ein.
			</p>
			<form class="w-full text-center" @submit.stop.prevent="checkInvalidMail">
				<HokInput
					id="invalidcontactdata-email"
					v-model="contactDataNewEmail"
					type="text"
					name="email"
				>
					E-Mail-Adresse
				</HokInput>

				<ErrorBox v-if="contactDataInvalidError" class="max-w-sm mx-auto">
					Bitte verwende eine andere E-Mail-Adresse!
				</ErrorBox>
				<HokButton submit fullwidth="mobile" color="main"> Weiter </HokButton>
			</form>
		</div>
	</HokModal>
</template>

<script lang="ts">
import HokInput from '@hokify/shared-components-nuxt3/lib/components/HokInput.vue';
import ErrorBox from '@hokify/shared-components-nuxt3/lib/components/ErrorBox.vue';
import type { IAPIMailBounceRelation } from '@hokify/common';
import { defineComponent } from 'vue';
import type { PropType } from 'vue';

export default defineComponent({
	name: 'ContactDataInvalid',
	components: { HokInput, ErrorBox },
	emits: ['done'],
	data() {
		const contactDataNewEmail = this.contactDataInvalid.bounce.email;
		const contactDataInvalidError = false;

		return {
			contactDataNewEmail,
			contactDataInvalidError
		};
	},
	computed: {
		emailSourceText(): string {
			if (this.contactDataInvalid.type === 'organization') {
				return 'Rechnungsdaten';
			}
			return this.mode === 'company' ? 'Persönlichen Daten' : 'Profildaten';
		}
	},
	methods: {
		async checkInvalidMail() {
			if (
				!this.contactDataNewEmail ||
				this.contactDataNewEmail.length < 5 ||
				this.contactDataNewEmail === this.contactDataInvalid.bounce.email
			) {
				this.contactDataInvalidError = true;
			} else {
				try {
					const payload = {
						_id: this.contactDataInvalid.targetId,
						billingProfile: {
							invoiceEmail: this.contactDataNewEmail
						}
					};
					switch (this.contactDataInvalid.type) {
						// for type === 'organization' we change the invoice email in the billingprofile of the organization
						case 'organization':
							await this.editOrganization(payload);
							break;
						// for type === 'user' we change the general email of the user
						case 'user':
							await this.updateGeneral({ email: this.contactDataNewEmail });
							break;
						default:
							throw new Error(
								`invalid type for contactDataInvalid: ${this.contactDataInvalid.type}`
							);
					}
					this.$modal.hide('contactDataInvalid');
					this.$emit('done');
				} catch (err) {
					this.$nuxt.$errorHandler(err);
				}
			}
		},
		updateGeneral(payload) {
			return this.$nuxt?.$userProfileGeneralStore?.updateGeneral(payload);
		},
		editOrganization(payload) {
			// only for company app
			if (this.mode !== 'user') {
				return this.$nuxt?.$companyOrganizationStore?.editOrganization(payload);
			}
		}
	},
	props: {
		contactDataInvalid: { type: Object as PropType<IAPIMailBounceRelation>, required: true },
		mode: { type: String, default: 'user' }
	}
});
</script>

<style scoped lang="scss">
::v-deep(.input--sae) {
	@apply mx-auto;
}
</style>
