import { CodeError } from './CodeError';

export class NotAuthenticatedError extends CodeError {
	statusCode = 401;

	public constructor(msg = 'login required') {
		super('NOT_AUTHENTICATED', msg);
		// Set the prototype explicitly. https://github.com/Microsoft/TypeScript/issues/13965
		Object.setPrototypeOf(this, NotAuthenticatedError.prototype);
	}
}

export const isNotAuthenticatedError = err => {
	const code = parseInt(err?.response?.status, 10) || err.statusCode;
	if (
		err instanceof NotAuthenticatedError ||
		(err && err.code === 'NOT_AUTHENTICATED') ||
		(err && err.response && err.response.data && err.response.data.code === 'NOT_AUTHENTICATED') ||
		code === 401
	) {
		return true;
	}
	return false;
};
