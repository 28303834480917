<template>
	<div :class="{ 'input-group': bootstrapStyling }">
		<!-- Calendar Button -->
		<span
			v-if="calendarButton"
			:class="{ 'input-group-prepend': bootstrapStyling }"
			:style="{ 'cursor:not-allowed;': disabled }"
			class="vdp-datepicker__calendar-button"
			@click="showCalendar"
		>
			<span :class="{ 'input-group-text': bootstrapStyling }">
				<i :class="calendarButtonIcon">
					{{ calendarButtonIconContent }}
					<span v-if="!calendarButtonIcon">&hellip;</span>
				</i>
			</span>
		</span>
		<slot name="beforeDateInput"></slot>
		<!-- Input -->
		<input
			:id="id"
			:ref="refName"
			:type="inline ? 'hidden' : 'text'"
			:class="computedInputClass"
			:name="name"
			:value="formattedValue"
			:open-date="openDate"
			:placeholder="placeholder"
			:clear-button="clearButton"
			:disabled="disabled"
			:required="required"
			:readonly="!typeable"
			autocomplete="off"
			@click="showCalendar"
			@focus="showFocusCalendar"
			@keyup="keyUp"
			@blur="inputBlurred"
		/>
		<!-- Clear Button -->
		<span
			v-if="clearButton && selectedDate"
			:class="{ 'input-group-append': bootstrapStyling }"
			class="vdp-datepicker__clear-button"
			@click="clearDate()"
		>
			<span :class="{ 'input-group-text': bootstrapStyling }">
				<i :class="clearButtonIcon">
					<span v-if="!clearButtonIcon">&times;</span>
				</i>
			</span>
		</span>
		<slot name="afterDateInput"></slot>
	</div>
</template>
<script>
import { makeDateUtils } from './DateUtils';

export default {
	props: {
		selectedDate: Date,
		resetTypedDate: [Date],
		format: [String, Function],
		language: String,
		inline: Boolean,
		id: String,
		name: String,
		refName: String,
		openDate: Date,
		placeholder: String,
		inputClass: [String, Object, Array],
		clearButton: Boolean,
		clearButtonIcon: String,
		calendarButton: Boolean,
		calendarButtonIcon: String,
		calendarButtonIconContent: String,
		disabled: Boolean,
		required: Boolean,
		typeable: Boolean,
		parseTypedDate: Function,
		bootstrapStyling: Boolean,
		showCalendarOnFocus: Boolean
	},
	emits: ['show-calendar', 'typed-date', 'close-calendar', 'clear-date'],
	data() {
		const constructedDateUtils = makeDateUtils(this.useUtc, this.language);
		return {
			input: null,
			typedDate: false,
			utils: constructedDateUtils
		};
	},
	computed: {
		formattedValue() {
			if (!this.selectedDate) {
				return null;
			}
			if (this.typedDate) {
				return this.typedDate;
			}

			return typeof this.format === 'function'
				? this.format(this.selectedDate)
				: this.utils.formatDate(this.utils.parseDate(this.selectedDate, this.format), this.format);
		},

		computedInputClass() {
			if (this.bootstrapStyling) {
				if (typeof this.inputClass === 'string') {
					return [this.inputClass, 'form-control'].join(' ');
				}
				return { 'form-control': true, ...this.inputClass };
			}
			return this.inputClass;
		}
	},
	watch: {
		language(newLanguage) {
			this.utils = makeDateUtils(this.useUtc, newLanguage);
		},
		useUtc(newUtc) {
			this.utils = makeDateUtils(newUtc, this.language);
		},
		resetTypedDate() {
			this.typedDate = false;
		}
	},
	methods: {
		showCalendar() {
			// prevent to emit the event twice if we are listening focus
			if (!this.showCalendarOnFocus) {
				this.$emit('show-calendar');
			}
		},

		showFocusCalendar() {
			if (this.showCalendarOnFocus) {
				this.$emit('show-calendar', true);
			}
		},
		/**
		 * Attempt to parse a typed date
		 *
		 * @param {Event} event
		 */
		keyUp(event) {
			const code = event.keyCode ? event.keyCode : event.which;

			// close calendar if escape or enter are pressed
			if (
				[
					27, // escape
					13 // enter
				].includes(code)
			) {
				this.input.blur();
			}

			if (this.typeable) {
				const parsedDate = this.getTypedDate(this.input.value);

				// eslint-disable-next-line no-restricted-globals
				if (!isNaN(parsedDate)) {
					this.typedDate = this.input.value;
					this.$emit('typed-date', parsedDate);
				}
			}
		},
		/**
		 * nullify the typed date to defer to regular formatting called once the input is blurred
		 */
		inputBlurred() {
			// eslint-disable-next-line no-restricted-globals
			if (this.typeable && isNaN(this.getTypedDate(this.input.value))) {
				this.clearDate();
				this.input.internalValueId = null;
				this.typedDate = null;
			}

			this.$emit('close-calendar', true);
		},
		/**
		 * emit a clearDate event
		 */
		clearDate() {
			this.$emit('clear-date');
		},
		/**
		 * parse Date with regular or custom function
		 */
		getTypedDate(input) {
			const date =
				typeof this.parseTypedDate === 'function'
					? this.parseTypedDate(input)
					: this.utils.parseDate(input, typeof this.format === 'string' ? this.format : undefined);

			return date;
		}
	},
	mounted() {
		this.input = this.$el.querySelector('input');
	}
};
// eslint-disable-next-line
</script>
