<template>
	<div class="container max-w-4xl mb-8">
		<JobQuiz
			id="quiz"
			:questions="questions"
			:show-quiz="showQuiz"
			:show-results="showResults"
			:quiz-results="quizResults"
			:quiz-id="quizId"
			:shuffled-answers="shuffledAnswers"
			:yes-or-no-quiz="yesOrNoQuiz"
			:weighted-quiz="weightedQuiz"
			class="mt-10"
			@finished="finishQuiz($event)"
			@reset="resetQuiz"
		/>
	</div>
</template>
<script lang="ts">
import JobQuiz from '@hokify/shared-components-nuxt3/lib/components/JobQuiz.vue';
import { mapStores } from 'pinia';
import { defineComponent } from 'vue';
import type { PropType } from 'vue';
import { useQuizStore } from '@/stores/quiz';
import { definePageMeta } from '#imports';

export default defineComponent({
	name: 'JobInterviewTraining',
	layout: 'user',
	components: { JobQuiz },
	setup() {
		definePageMeta({
			layout: 'user',
			middleware: ['auth']
		});
	},
	data() {
		return {
			showQuiz: true,
			showResults: false
		};
	},
	computed: {
		...mapStores(useQuizStore)
	},
	methods: {
		async finishQuiz(answers?: { questionId: string; answerId: string }[]) {
			this.showQuiz = false;
			this.showResults = true;
			if (answers) {
				try {
					await this.quizStore.sendAnsweredQuestions(answers);
				} catch (err: any) {
					this.$nuxt.$errorHandler(err);
				}
			}
		},
		async resetQuiz() {
			this.showQuiz = true;
			this.showResults = false;
		}
	},
	props: {
		questions: {
			type: Array as PropType<
				{
					_id: string;
					question: string;
					options: {
						correct: boolean;
						explanation: string;
						option: string;
						totalAnswers: number;
					}[];
				}[]
			>,
			required: true
		},
		quizResults: { type: Array as PropType<{ title: string; text: string }[]>, required: true },
		quizIcon: { type: String, required: false, default: '' },
		quizId: { type: String, required: true },
		yesOrNoQuiz: { type: Boolean, default: false },
		weightedQuiz: { type: Boolean, default: false },
		shuffledAnswers: { type: Boolean, default: true }
	}
});
</script>
