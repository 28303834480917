<!-- eslint-disable vue/no-multiple-template-root -->
<template>
	<div
		v-if="wrap"
		class="flex border rounded-[3px]"
		:class="[
			focused
				? 'border-color-main'
				: `${value === '' ? 'border-color-grey-light' : 'border-color-text'}`
		]"
	>
		<slot></slot>
	</div>
	<slot v-else></slot>
</template>

<!-- TODO @lbrcs evaluate in nuxt3 branch whether this works! -->
<script lang="ts">
import { defineComponent } from 'vue';

/* This component is only used for input type password */
export default defineComponent({
	name: 'HokInputWrapper',
	props: {
		wrap: { type: Boolean, default: false },
		focused: { type: Boolean, default: false },
		value: { type: [String, Number], default: '' }
	}
});
</script>
