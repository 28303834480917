// import { EventBus } from '@hokify/shared-components-nuxt3/lib/eventbus';

declare global {
	interface Window {
		appleAsyncInit?: any;
		AppleID?: any;
		cordova?: {
			InAppBrowser: any;
			plugins: {
				firebase: any;
				SignInWithApple: any;
				idfa: any;
				permissions: any;
				iosrtc: any;
			};
			require: any;
		};
	}
}
export const initAppleSDK = (cb?) => {
	const {
		public: { development }
	} = useRuntimeConfig();
	if (
		(process as any).server || // only on client
		window.AppleID || // already intialized
		process.env.cordova //   not needed because we are a cordova build
	) {
		if (cb) {
			cb();
		}
		console.info('initAppleSDK already called or not needed');
		return;
	}
	// eslint-disable-next-line func-names
	(function (d, s, id) {
		const ajs = d.getElementsByTagName(s)[0];
		if (d.getElementById(id)) {
			return;
		}
		const js = d.createElement(s) as HTMLScriptElement;
		js.id = id;
		js.onload = () => {
			window.appleAsyncInit();
		};
		(js as any).onreadystatechange = () => {
			window.appleAsyncInit();
		};
		js.src = 'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js';
		if (ajs.parentNode) {
			ajs.parentNode.insertBefore(js, ajs);
		}
	})(document, 'script', 'applelogin-jssdk');

	window.appleAsyncInit = function onSDKInit() {
		window.AppleID.auth.init({
			clientId: 'com.hokify.applelogin',
			scope: 'name email',
			redirectURI: development ? 'https://test.hokify.at' : window.origin,
			state: '',
			nonce: '',
			usePopup: true // or false defaults to false
		});

		// AppleID hat no method called logout()
		// EventBus.$on('logged-out', () => {
		// 	window.AppleID.logout();
		// });

		if (cb) {
			cb();
		}
		// EventBus.$emit('apple-sdk-ready');
	};
};

export const AppleLogin = async () =>
	new Promise((resolve, reject) => {
		if (window.cordova?.plugins?.SignInWithApple) {
			window.cordova.plugins.SignInWithApple.signin(
				{ requestedScopes: [0, 1] },
				response => {
					resolve(response);
				},
				err => {
					reject(err);
				}
			);
		} else {
			initAppleSDK(() => {
				try {
					resolve(window.AppleID.auth.signIn());
				} catch (error: any) {
					reject(error);
				}
			});
		}
	});
