import { defineStore } from 'pinia';
import type {
	LoginActionPayload,
	SignupActionPayload
} from '@hokify/login-stack-nuxt3/lib/types/login';
import type { APIObjectType, APITypeObjectId, IAPIReAuthResult, IAppMode } from '@hokify/common';
import { genCookie, getCookie } from '@hokify/shared-components-nuxt3/lib/helpers/cookie';
import { EventBus } from '@hokify/shared-components-nuxt3/lib/eventbus';
import { lsTest } from '@hokify/shared-components-nuxt3/lib/helpers/localstorage';
import { SupportedAppType } from '@hokify/login-stack-nuxt3/lib/types/supported-app-type';
import { useUserRootStore } from './root';

// eslint-disable-next-line import/no-cycle
import { useUserProfileStore } from './user-profile';

export interface IUserLoginState {
	loggingInRunning: boolean;
	loggedIn: undefined | boolean;
	reAuthData: null;
	sessionIdCookie: false | string;
	userId: undefined | string;
	nonce: undefined | string;
	loginIdentifier: string;
	authenticationRequired: boolean;
	pwdServiceUnavailable: boolean;
	loginRedirect: string;
}

export const useLoginStore = defineStore('login', {
	state: (): IUserLoginState => ({
		loggingInRunning: false,
		loggedIn: undefined,
		reAuthData: null,
		sessionIdCookie: false,
		userId: undefined,
		nonce: undefined,
		loginIdentifier: '',
		authenticationRequired: false,
		pwdServiceUnavailable: false,
		loginRedirect: ''
	}),
	actions: {
		setAuthenticationRequired(authenticationRequired: boolean): void {
			this.authenticationRequired = authenticationRequired;
		},
		async logout() {
			const userProfileStore = useUserProfileStore();
			await this.$nuxt.$hokFetch('/app-api/logout', {
				method: 'POST'
			});
			await userProfileStore.$reset();

			if (import.meta.client) {
				const d = new Date();
				d.setTime(d.getTime() - 1000); // in the past
				const expires = `expires=${d.toUTCString()}`;
				document.cookie = `pwaReAuth=false;${expires};path=/`;

				if (lsTest()) {
					localStorage.removeItem('reAuthData');
				}
			}
			await this.setSessionIdCookie({ sessionToken: false });
			EventBus.$emit('logged-out');
			this.$reset();
		},
		async seamlessLogin({ loginId, loginToken }) {
			this.loggingInRunning = true;
			const userProfileStore = useUserProfileStore();
			const rootStore = useUserRootStore();
			const config = this.$nuxt.$config;

			try {
				const result = await this.$nuxt.$hokFetch<IAPIReAuthResult>('/app-api/seamless-login', {
					method: 'POST',
					body: {
						id: loginId,
						loginKey: loginToken,
						viaDevice: rootStore.device,
						appVersion: config?.public?.version,
						appType: config?.public?.appType as SupportedAppType
					}
				});

				this.loggingInRunning = false;

				// reauthToken is done via axios interceptor
				if (result?.loggedin) {
					const { user, versionOkay, bouncedEmails } = result;
					await userProfileStore.setUser({ user, versionOkay, bouncedEmails });
				}

				return result?.loggedin;
			} catch (err) {
				console.error('seamless login failed', err);
				this.loggingInRunning = false;
			}
		},
		async verify(code: string): Promise<Record<string, any>> {
			const userProfileStore = useUserProfileStore();
			const result = await this.$nuxt.$hokFetch<{
				userObjUpdate: Record<string, any>;
			}>('/app-api/verifyAccount', {
				method: 'POST',
				body: { code }
			});

			if (result?.userObjUpdate) {
				userProfileStore.setUpdatedElements(result.userObjUpdate);
			}

			return result.userObjUpdate;
		},
		async resumeSession(): Promise<IAPIReAuthResult | undefined> {
			this.loggingInRunning = true;

			try {
				const result = await this.resumeSessionRequest({
					// on client we try without token first
					useToken: import.meta.server
				});
				this.loggingInRunning = false;
				return result;
			} catch (err) {
				if (import.meta.client) {
					if (this.sessionIdCookie) {
						try {
							// otherwise, retry with useSessionToken true
							await this.resumeSessionRequest({
								useToken: true
							});
						} catch (e) {
							console.error('resumeSession error', e);
						}
					}
				}
				this.loggingInRunning = false;
				console.log('resumeerr', err);
			}
		},
		async resumeSessionRequest(
			{ useToken }: { useToken: boolean } = { useToken: true }
		): Promise<IAPIReAuthResult | undefined> {
			const userProfileStore = useUserProfileStore();
			const rootStore = useUserRootStore();
			const config = this.$nuxt.$config;

			try {
				const result = await this.$nuxt.$hokFetch<IAPIReAuthResult>('/app-api/resume-session', {
					method: 'POST',
					body: {
						viaDevice: rootStore.device,
						appVersion: config?.public?.version,
						appType: config?.public?.appType as SupportedAppType,
						useSessionToken: useToken,
						withCredentials: true
					}
				});

				const { loggedin, user, versionOkay, bouncedEmails } = result;
				if (loggedin) {
					await userProfileStore.setUser({
						user,
						versionOkay,
						bouncedEmails
					});
				}
				this.loggingInRunning = false;
				return result;
			} catch (err) {
				this.loggingInRunning = false;
				if (this.$nuxt.$isHokFetchRequestError(err)) {
					console.log('Error trying to resume session:', err.data.code ?? err.message);
				} else if (this.$nuxt.$isHokFetchResponseError(err)) {
					console.log(
						'Error trying to resume session:',
						err.response.data.code ?? err.response.status
					);
				}
			}
		},
		// TODO remove this once interview flow is rewritten as well
		/**
		 * @deprecated This is only used for interview anymore. For everything else: use 'setPwd'
		 *   instead.
		 */
		async resetPwd(payload: {
			loginIdentifier: string;
			recaptchaToken: string;
			recaptchaVersion: 'v2' | 'v3';
		}) {
			await this.$nuxt.$hokFetch('/app-api/reset-password', {
				method: 'POST',
				body: payload
			});
		},
		async setPwd(payload: {
			recaptchaToken: string;
			recaptchaVersion: 'v2' | 'v3';
			id: APITypeObjectId<APIObjectType.User>;
			nonce: string;
			password: string;
		}) {
			await this.$nuxt.$hokFetch('/app-api/password/set', {
				method: 'POST',
				body: { passwordCase: 'b2c', ...payload }
			});
		},
		async checkPwdLinkValidity({ nonce, id }: { nonce: string; id: string }) {
			await this.$nuxt.$hokFetch('/app-api/password/link-validity', {
				params: { nonce, id }
			});
		},
		async getNewPwdLink({
			loginIdentifier,
			appMode,
			redirect
		}: {
			loginIdentifier: string;
			appMode: IAppMode;
			redirect?: string;
		}) {
			await this.$nuxt.$hokFetch('/app-api/password/new-link', {
				method: 'POST',
				body: { loginIdentifier, appMode, redirect }
			});
		},
		async getNewNonce(userId: string) {
			const nonce: string = await this.$nuxt.$hokFetch('/app-api/password/nonce');
			if (nonce) {
				this.setUserIdAndNonce({ userId, nonce });
			}
		},
		async resendServiceUnavailableEmail({
			loginIdentifier,
			appMode,
			redirect
		}: {
			loginIdentifier: string;
			appMode: IAppMode;
			redirect: string;
		}) {
			await this.$nuxt.$hokFetch('/app-api/login/email-redirect-link', {
				method: 'POST',
				body: { loginIdentifier, appMode, redirect }
			});
		},
		async resendSignUpEmail({
			loginIdentifier,
			usercase,
			companycase
		}: {
			loginIdentifier: string;
			usercase?: string;
			companycase?: string;
		}) {
			await this.$nuxt.$hokFetch('/app-api/signup/resend-mail', {
				method: 'POST',
				body: { loginIdentifier, usercase, companycase }
			});
		},
		/**
		 * Logs in the user with the given payload
		 *
		 * @param payload The login payload
		 * @param userTriggered Whether the login was triggered by the user
		 */
		async doLogin(payload: LoginActionPayload, userTriggered = false): Promise<IAPIReAuthResult> {
			if (!userTriggered) {
				this.loggingInRunning = true;
			}
			try {
				const userProfileStore = useUserProfileStore();
				const rootStore = useUserRootStore();
				const config = this.$nuxt.$config;

				payload.parameters = {
					...payload.parameters,
					viaDevice: payload.parameters.viaDevice || rootStore.device,
					appVersion: config?.public?.version,
					appType: config?.public?.appType as SupportedAppType
				};
				const result = await this.$nuxt.$hokFetch<IAPIReAuthResult>('/app-api/login/v2', {
					method: 'POST',
					body: payload
				});

				const { loggedin, user, versionOkay, bouncedEmails } = result;

				if (loggedin) {
					await userProfileStore.setUser({ user, versionOkay, bouncedEmails });
				} else {
					throw result;
				}
				this.loggingInRunning = false;
				console.log('sucessful login');
				return result;
			} catch (err) {
				this.loggingInRunning = false;
				console.log('loginerr', err);
				throw err;
			}
		},
		async doSignUp(payload: SignupActionPayload | FormData): Promise<IAPIReAuthResult> {
			const userProfileStore = useUserProfileStore();
			const rootStore = useUserRootStore();
			if (!(payload instanceof FormData)) {
				const cookies = import.meta.client && window.document.cookie;
				const utm = (cookies && getCookie('utm', cookies)) || undefined;
				const config = this.$nuxt.$config;
				payload = {
					...payload,
					viaDevice: rootStore.device,
					appVersion: config?.public?.version,
					appType: config?.public?.appType as SupportedAppType,
					utm,
					// add region if available
					region: rootStore.topLevelDomain || undefined,
					redirect: this.loginRedirect
				};
			} else {
				payload.set('redirect', this.loginRedirect);
			}

			const result = await this.$nuxt.$hokFetch<IAPIReAuthResult>('/app-api/signup', {
				method: 'POST',
				body: payload
			});

			if (result) {
				const { loggedin, user, versionOkay, bouncedEmails } = result;
				if (loggedin) {
					await userProfileStore.setUser({ user, versionOkay, bouncedEmails });
				}
			}

			return result;
		},
		setLoginState(loginState: boolean): void {
			this.loggedIn = loginState;
		},
		async setSessionIdCookie({
			sessionToken,
			maxAge
		}: {
			sessionToken?: string | boolean;
			maxAge?: number;
		}): Promise<void> {
			let mySessionToken;
			if (sessionToken === 'false' || !sessionToken) {
				mySessionToken = false;
			} else if (typeof sessionToken === 'string') {
				mySessionToken = sessionToken;
			} else {
				console.warn('invalid sessionToken value', sessionToken);
			}
			this.sessionIdCookie = mySessionToken;

			const expires = mySessionToken === false ? -1 : undefined; // if sessionToken is false, set expire to the past, so it gets removed
			const loginCookieKey = this.$nuxt.$config?.public?.loginCookie;
			if (loginCookieKey && mySessionToken) {
				try {
					const loginCookie = await this.$nuxt.runWithContext(() =>
						useCookie(loginCookieKey, { maxAge })
					);
					loginCookie.value = mySessionToken;
					refreshCookie(loginCookieKey);
				} catch (e) {
					console.error('error setting session cookie', e);
				}
				if (import.meta.client) {
					const loginSave = genCookie(
						loginCookieKey,
						mySessionToken,
						expires,
						window.location.host
					);
					document.cookie = loginSave;
				}
			}
		},
		async setReAuthData({ reAuthTokens }): Promise<void> {
			this.reAuthData = reAuthTokens; // {id, token}
			try {
				const pwaReAuthCookie = await this.$nuxt.runWithContext(() => useCookie('pwaReAuth'));
				pwaReAuthCookie.value = JSON.stringify(reAuthTokens);
				refreshCookie('pwaReAuth');
			} catch (e) {
				console.error('error setting session cookie', e);
			}

			if (import.meta.client) {
				document.cookie = genCookie('pwaReAuth', JSON.stringify(reAuthTokens), 90);
				if (lsTest()) {
					localStorage.setItem('reAuthData', JSON.stringify(reAuthTokens));
				}
			} /* else if (process.server) {
				if (res && typeof res.header === 'function') {
					res.header('Set-Cookie', genCookie('pwaReAuth', JSON.stringify(reAuthTokens), 90));
				} else if (res && typeof res.setHeader === 'function') {
					// pass along to browser
					res.setHeader('Set-Cookie', genCookie('pwaReAuth', JSON.stringify(reAuthTokens), 90));
				} else {
					// pass along to browser
					console.error('cannot set header via SSR!');
				}
			} */
		},
		setUserIdAndNonce({ userId, nonce }: { userId: string; nonce: string }) {
			this.userId = userId;
			this.nonce = nonce;
		},
		setLoginIdentifier(loginIdentifier: string) {
			this.loginIdentifier = loginIdentifier;
		},
		setPwdServiceUnavailable(state: boolean) {
			this.pwdServiceUnavailable = state;
		},
		setLoginRedirect(loginRedirect: string) {
			this.loginRedirect = loginRedirect;
		}
	}
});
