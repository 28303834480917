<template>
	<Hooper
		ref="carousel"
		:mouse-drag="false"
		:wheel-control="false"
		:keys-control="false"
		:items-to-show="itemsToShow"
		:auto-play="autoPlay"
		:trim-white-space="true"
		:hover-pause="true"
		:play-speed="5000"
		:class="{ 'indicator-white-bg': whiteBackground }"
		class="w-full"
		v-bind="$attrs"
	>
		<Slide v-for="({ component, props }, index) in items" :key="index" :index="index">
			<component :is="component" v-bind="props" />
		</Slide>

		<template #hooper-addons>
			<Pagination v-if="showPagination" />
			<Navigation v-if="showNavigation" :class="styling" />
		</template>
	</Hooper>
</template>

<script lang="ts">
import { Hooper, Navigation, Pagination, Slide } from '@wattanx/hooper-vue3';
import { defineComponent } from 'vue';
import type { PropType } from 'vue';
import { EventBus } from '../eventbus';

export default defineComponent({
	name: 'HokCarousel',
	components: {
		Hooper,
		Navigation,
		Pagination,
		Slide
	},
	data() {
		return { EventBus };
	},
	mounted() {
		this.EventBus.$on('page-close-observed', this.pageCloseObserved);
	},
	props: {
		itemsToShow: { type: Number, default: 1 },
		items: {
			type: Array as PropType<{ component: ReturnType<typeof defineComponent>; props?: any }[]>
		},
		autoPlay: { type: Boolean, default: true },
		whiteBackground: { type: Boolean },
		showNavigation: { type: Boolean, default: false },
		showPagination: { type: Boolean, default: true },
		styling: { type: String as PropType<'default' | 'border-button'>, default: 'default' }
	},
	computed: {
		carousel() {
			return this.$refs.carousel as any;
		}
	},
	beforeUnmount() {
		this.EventBus.$off('page-close-observed', this.pageCloseObserved);
	},
	methods: {
		pageCloseObserved() {
			this.$nextTick(() => this.carousel?.update());
		}
	}
});
</script>
<style scoped src="@wattanx/hooper-vue3/css" lang="scss"></style>

<style scoped lang="scss">
// eslint-disable-next-line vue-scoped-css/no-unused-selector
.hooper {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: auto;

	::v-deep() {
		@apply relative box-border w-full;
	}

	::v-deep(*) {
		@apply box-border;
	}

	::v-deep(.hooper-slide) {
		@apply h-auto m-0 p-0 list-none shrink-0;
	}

	::v-deep(.hooper-pagination) {
		position: initial;
		transform: unset;
		@apply justify-center flex p-1;
	}

	::v-deep(.hooper-indicators) {
		@apply flex flex-wrap justify-center m-0 p-0 list-none;
		li {
			@apply m-0;
		}
	}

	::v-deep(.hooper-indicator:hover) {
		background-color: #007f7e;
	}

	::v-deep(.hooper-indicator.is-active) {
		background-color: #007f7e;
	}

	::v-deep(.hooper-indicator) {
		@apply box-content bg-clip-padding cursor-pointer;
		width: 4px;
		height: 4px;
		border-radius: 20px;
		border: 10px solid transparent;
		background-color: #efefef;
		padding: 5px;
		margin: 1px;
	}

	::v-deep(.hooper-list) {
		@apply overflow-hidden h-full w-full;
	}

	::v-deep(.hooper-track) {
		@apply flex box-border w-full p-0 m-0;
	}

	::v-deep(.hooper-sr-only) {
		@apply absolute p-0 overflow-hidden border-0;
		width: 1px;
		height: 1px;
		margin: -1px;
		clip: rect(0, 0, 0, 0);
	}

	::v-deep(.hooper-next),
	::v-deep(.hooper-prev) {
		@apply absolute bg-color-white p-1 border-0 cursor-pointer rounded-full;
		top: 50%;
		transform: translateY(-50%);
	}
	::v-deep(.hooper-next.is-disabled),
	::v-deep(.hooper-prev.is-disabled) {
		@apply opacity-30 cursor-not-allowed;
	}
	::v-deep(.hooper-next) {
		@apply right-3;
	}
	::v-deep(.hooper-prev) {
		@apply left-3;
	}
	::v-deep(.hooper-next svg),
	::v-deep(.hooper-prev svg) {
		@apply fill-color-text;
	}
	::v-deep(.hooper-navigation.is-vertical .hooper-next) {
		@apply top-auto bottom-0;
		transform: initial;
	}
	::v-deep(.hooper-navigation.is-vertical .hooper-prev) {
		@apply top-0 bottom-auto right-0 left-auto;
		transform: initial;
	}
	::v-deep(.hooper-navigation.is-rtl .default .hooper-prev) {
		@apply left-auto right-0;
	}
	::v-deep(.hooper-navigation.is-rtl .default .hooper-next) {
		@apply right-auto left-0;
	}
	::v-deep(.border-button .hooper-prev) {
		@apply right-auto left-0 h-8 w-8 border border-color-grey-medium;
	}
	::v-deep(.border-button .hooper-next) {
		@apply left-auto right-0 h-8 w-8 border border-color-grey-medium;
	}
	::v-deep(.border-button .hooper-next svg),
	::v-deep(.border-button .hooper-prev svg) {
		@apply fill-color-text h-6 w-6;
	}
	::v-deep(.hooper-navigation.is-vertical .hooper-next) {
		@apply top-auto bottom-0;
		transform: initial;
	}
}

// eslint-disable-next-line vue-scoped-css/no-unused-selector
.hooper.indicator-white-bg {
	::v-deep(.hooper-indicator) {
		background-color: #ffffff;
	}

	::v-deep(.hooper-indicator:hover) {
		background-color: #fdd408;
	}

	::v-deep(.hooper-indicator.is-active) {
		background-color: #fdd408;
	}
}
</style>
