// tslint:disable
/**
 * app-api No description provided (generated by Openapi Generator
 * https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.11.26
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech Do not edit the class manually.
 */

/**
 * @export
 * @enum {string}
 */
export enum SupportedUserCase {
	GNC = 'gnc',
	Webapply = 'webapply',
	Pwa = 'pwa',
	True = 'true'
}
