<template>
	<div>
		<NuxtLayout name="user">
			<MainSetup />

			<Spinner v-if="isLoading" :fixed="false" />
			<div v-else-if="error && error.statusCode === 401" class="bg-color-white h-full">
				<!-- LOGIN -->
				<div v-if="!loggedIn" key="stage-0" class="stage-0">
					<client-only>
						<HokLogin class="login-error" @login-result="loginResult">
							<template #headline> Login notwendig um fortzufahren. </template>
						</HokLogin>
					</client-only>
				</div>
				<div v-else-if="loggedIn && stage === 'socialLogin' && socialLogin" key="socialLogin">
					<SocialMediaData :social-login="socialLogin" @done="loginDone" />
				</div>
				<div v-else-if="loggedIn && !stage" key="loggedIn" class="text-center mt-20 mx-5">
					<div
						class="w-20 h-20 flex items-center justify-center rounded-full border-4 border-color-main mb-5 mx-auto"
					>
						<HokIcon :size="12" color="main" name="icon:checkmark-2" />
					</div>
					<div class="text-sm mb-5">
						<h2 class="mb-1">
							<template v-if="user && user.displayName">
								{{ user.displayName }}
							</template>
							<template v-else-if="user && user.general && user.general.email">
								{{ user.general.email }}
							</template>
						</h2>
						erfolgreich angemeldet
					</div>
					<HokButton fullwidth="mobile" class="mb-4" color="main" @click="loginDone(true)">
						Fortfahren </HokButton
					><br />
				</div>
			</div>
			<main v-else class="container text-center">
				<div>
					<h1 class="mt-4">
						{{ (error && error.statusCode) || '-' }}
						<template v-if="error.code">
							{{ error.code }}
						</template>
					</h1>
					<NuxtImg class="error-robot mb-4 inline" src="/pwa-core-nuxt3/img/error-robot.png" />
					<h2>
						{{ message }}
					</h2>
					<Logout v-if="error && error.statusCode === 403" class="text-center">
						<HokButton outline color="main" class="mb-4">
							Ausloggen und mit anderem User fortfahren
						</HokButton>
					</Logout>
					<HokButton v-if="customBacklink" :to="customBacklink.url" class="mb-4">
						{{ customBacklink.text || 'zurück' }}
					</HokButton>
					<HokButton v-else-if="error && error.statusCode === 404" color="main" class="mb-4" to="/">
						zurück zur Startseite
					</HokButton>
					<HokButton v-else outline class="mb-4" color="main" @click="$router.go(-1)">
						zurück
					</HokButton>
				</div>
			</main>
		</NuxtLayout>
	</div>
</template>

<script lang="ts">
import { EventBus } from '@hokify/shared-components-nuxt3/lib/eventbus';
import HokLogin from '@hokify/login-stack-nuxt3/lib/components/login/HokLogin.vue';
import SocialMediaData from '@hokify/login-stack-nuxt3/lib/components/login/SocialMediaData.vue';
import Logout from '@hokify/pwa-core-nuxt3/components/login/Logout.vue';
import type { IAPILoginUser } from '@hokify/common';
import { defineComponent } from 'vue';
import type { PropType } from 'vue';
import { mapStores } from 'pinia';
import { isDeepLinkRoute } from '@hokify/pwa-core-nuxt3/plugins/deep-link-router';
import { useUserRootStore } from '@/stores/root';
import { useUserProfileStore } from '@/stores/user-profile';
import { useLoginStore } from '@/stores/login';
import MainSetup from '@/components/MainSetup.vue';

export interface ICustomBackLink {
	url: string;
	text: string;
}

export default defineComponent({
	components: {
		HokLogin,
		SocialMediaData,
		Logout,
		MainSetup
	},
	layout: 'user',
	head() {
		return {
			bodyAttrs: {
				class: this.$isMobile.any ? 'mobile' : 'desktop'
			},
			htmlAttrs: {
				lang: 'de'
			}
		};
	},
	data() {
		const socialLogin = null as string | null;
		const stage = '';
		const basePath = '/pwa';
		const loading = isDeepLinkRoute(this.$nuxt, this.$route.fullPath);
		const user = undefined as IAPILoginUser | undefined;
		const loggedIn = false;

		return {
			loading,
			stage,
			socialLogin,
			basePath,
			user,
			loggedIn,
			EventBus
		};
	},
	computed: {
		message() {
			let title = '';
			if (this.$isHokFetchResponseError(this.error)) {
				title = 'Backend Request failed: ';
			}

			if (this.error.statusCode >= 500) {
				title = 'Serverfehler: ';
			}

			return (
				title +
				((this.error?.statusCode >= 500 &&
					(this.error.message || 'Serverfehler, bitte versuch es später noch einmal')) ||
					(this.error.response?.data?.name &&
						`${this.error.response?.data?.name} ${this.error.response?.data?.message}`) ||
					this.error.message ||
					'Error')
			);
		},
		processDeeplinkAfterLogin() {
			return this.userRootStore?.processDeeplinkAfterLogin;
		},
		hasFiltersSet(): boolean {
			return !!(
				(this.userProfileStore.obj?.jobFilter?.searchterm ||
					this.userProfileStore.obj?.jobFilter?.fieldIds?.length) &&
				this.userProfileStore.obj?.jobFilter?.location?.name
			);
		},
		isLoading() {
			return this.loading || this.loginStore.loggingInRunning;
		},
		customBacklink(): ICustomBackLink | undefined {
			if (typeof this.error !== 'object' || !this.error) {
				return undefined;
			}

			return this.error.customBacklink ?? this.error.data?.customBacklink;
		},
		...mapStores(useUserProfileStore, useUserRootStore, useLoginStore)
	},
	async mounted() {
		this.user = this.userProfileStore?.obj;
		this.loggedIn = this.loginStore.loggedIn ?? false;

		this.EventBus.$on('logged-in', this.userLoginUpdate);

		// check deeplink's on 404 (not found) errors. (CLIENT)
		if (this.$route.fullPath && this.error && this.error.statusCode === 404) {
			const path = this.$route.fullPath.replace(new RegExp(`^${this.basePath}`), '');

			if (path && path !== this.userRootStore.lastDeeplink) {
				this.userRootStore.setLastDeeplink(path);
				this.loading = true;
				let handled;
				try {
					handled = await this.$deepLinkRouter.handleUrl(path, false, import.meta.server);
				} catch (err: any) {
					console.error('deepLinkRouter failed', err);
				}

				console.log('handled:', handled);

				if (handled === false) {
					// redirect to start page
					this.$router.replace('/pwa');
				} else if (!handled) {
					// if we were not able to handle this case, reset loading spinner
					this.loading = false;
				}
			} else {
				console.log('skipping deep link router', path, this.userRootStore.lastDeeplink);
			}
		}

		if (
			(this.error && this.error.statusCode === 401) ||
			(this.error && this.error.statusCode === 404)
		) {
			this.loading = false;
		}
	},
	beforeUnmount() {
		this.EventBus.$off('logged-in', this.userLoginUpdate);
	},
	async serverPrefetch() {
		const route = this.$route;

		// check deeplink's on 404 (not found) errors. (SSR)
		if (this.error && this.error.statusCode === 404) {
			// are we in the correct app?
			try {
				this.loading = true;
				const handled = await this.$deepLinkRouter.handleUrl(
					route.fullPath.replace(new RegExp(`^${this.basePath}`), ''),
					false,
					true
				);
				if (handled === undefined) {
					// if we were not able to handle this case, reset loading spinner
					this.loading = false;
				}
			} catch (errDeeplink) {
				console.log('not a deeplink', route.fullPath, errDeeplink);
				this.loading = false;
			}
		}
	},
	methods: {
		userLoginUpdate({ user }) {
			this.user = user;
			this.loggedIn = true;
			this.loginDone();
		},
		async loginResult({ loginMethod, newUser }) {
			this.loggedIn = true;
			if (newUser) {
				switch (loginMethod) {
					case 'fb': // login via fb
					case 'google': // login via google
					case 'apple': // login via apple
					case 'linkedIn': // login via linkedIn
						this.stage = 'socialLogin';
						this.socialLogin = loginMethod;
						this.$page.scrollToTop();
						break;
					case 'signup': // new user signup
						this.$router.push('/pwa/password?signUp=true');
						break;
					case 'login': // existing user loggedin ??
					default:
						await this.loginDone();
						break;
				}
			} else {
				await this.loginDone();
			}
		},
		async loginDone(redirect?: boolean) {
			// if error code was "401 not authorized", let's reload this page or redirect
			if (!(this.error.statusCode === 401 || redirect)) {
				console.log(
					'LOGIN DONE',
					`we stay on this page, due to error code ${this.error.statusCode}`
				);
				return;
			}

			this.loading = true;
			console.log(
				'LOGIN DONE',
				!this.processDeeplinkAfterLogin
					? (redirect && 'redirecting to index') || 'refreshing current page'
					: 'waiting for deeplink handler to kick in'
			);

			// only do something if there is no deep link to process
			if (!this.processDeeplinkAfterLogin) {
				const options = this.getRedirect(redirect);
				// eslint-disable-next-line no-undef
				await clearError(options);
			}
		},
		getRedirect(redirect = false): undefined | { redirect: string } {
			if (redirect) {
				if (!this.hasFiltersSet) {
					return { redirect: '/pwa/onboarding/welcome' };
				}

				return { redirect: '/pwa' };
			}

			this.EventBus.$emit('reload-page');

			return undefined;
		},
		changeRoute() {
			this.loading = false;
		}
	},
	props: {
		error: {
			type: Object as PropType<any>,
			default: null
		}
	},
	watch: {
		$route: [
			{
				handler: 'changeRoute'
			}
		]
	}
});
</script>

<style scoped lang="scss">
.error-robot {
	height: 150px;
}

h1 {
	font-size: 5rem;
}
// eslint-disable-next-line vue-scoped-css/no-unused-selector
button {
	max-width: 320px;
}
</style>
