import { defineStore } from 'pinia';
import type {
	APIMatchRelation,
	APIObjectType,
	APITypeObjectId,
	IAPIMatchForJobSeeker,
	IAPIReAuthResult,
	IInterviewQuestion
} from '@hokify/common';
import { NoPasswordError } from '@hokify/pwa-core-nuxt3/errors/NoPasswordError';
import { InvalidResponseError } from '@hokify/pwa-core-nuxt3/errors/InvalidResponseError';
import { NoEmailError } from '@hokify/pwa-core-nuxt3/errors/NoEmailError';
import { getCookie } from '@hokify/shared-components-nuxt3/lib/helpers/cookie';
import { getGoogleAnalyticsClientId } from '@hokify/tracking-nuxt3/tracking.client';
import type { SupportedAppType } from '@hokify/login-stack-nuxt3/lib/types/supported-app-type';
import { useUserProfileStore } from './user-profile';
import { useLoginStore } from './login';
import { useRelationsStore } from './relations';
import { useMatchesStore } from './matches';

export type AnswerInterviewInput = {
	form: FormData | { name: string; value: number | string }[];
	msgId: string;
	matchObj: IAPIMatchForJobSeeker;
	onUploadProgress?: (evt) => void;
	replace?: boolean;
	resend?: boolean;
};

export interface IStartInterviewResultDTO {
	success: boolean;
	newMatchObj?: boolean;
	next?: IInterviewQuestion | boolean;
	finish?: boolean;
	answeredQuestions?: IInterviewQuestion[];
	totalQuestions?: number;
	cntOpenQuestions?: number;
	match?: IAPIMatchForJobSeeker;
	allowCvParsing: boolean;
}

export type AnswerInterviewResult = {
	success: boolean;
	answered: IInterviewQuestion;
	finish: boolean;
	next?: IInterviewQuestion;
	totalQuestions: number;
	cntOpenQuestions: number;
	userUpdate?: { [key: string]: any };
	updateInterviewQuestion?: boolean;
};

export const useInterviewStore = defineStore('interview', {
	state: (): IStartInterviewResultDTO => ({
		success: false,
		newMatchObj: undefined,
		next: undefined,
		finish: undefined,
		answeredQuestions: undefined,
		totalQuestions: undefined,
		cntOpenQuestions: undefined,
		allowCvParsing: false,
		match: undefined
	}),
	actions: {
		forgotPassword(payload: {
			loginIdentifier: string;
			recaptchaToken: string;
			recaptchaVersion: 'v2' | 'v3';
		}) {
			const loginStore = useLoginStore();
			return loginStore.resetPwd(payload);
		},
		async mergeUser(mergeData: { id: APITypeObjectId<APIObjectType.User>; hash: string }) {
			try {
				const res = await this.$nuxt.$hokFetch('/app-api/mergeAccount', {
					method: 'POST',
					body: {
						id: mergeData.id,
						hash: mergeData.hash
					}
				});

				if (res?.userUpdate) {
					const userProfileStore = useUserProfileStore();
					userProfileStore.updateElements(res.userUpdate);
				}
			} catch (error: any) {
				console.error('mergeUser failed', error);
			}
		},
		async handleDoLogin({ messages, password, user, device, matchObj, uref, utm }) {
			// LOGIN User
			if (!password) {
				// wrong passwort
				throw new NoPasswordError('invalid input parameters for handleDoLogin');
			}
			const loginStore = useLoginStore();
			const config = this.$nuxt.$config;

			const loginResult = await loginStore.doLogin({
				method: 'local-login',
				parameters: {
					email: user,
					password,
					viaDevice: device || 'pwa',
					appVersion: config?.public?.version,
					appType: config?.public?.appType as SupportedAppType,
					usercase: uref || undefined,
					utm
				}
			});

			messages.some((m, index) => {
				if (m._id === 'userPassword') {
					messages[index].currentAnswer = '****';
					return true;
				}
				return false;
			});

			const interviewResult = await this.startInterview({ relation: matchObj.relation });

			if (!interviewResult.success) {
				throw new InvalidResponseError('invalid response from start-interview', interviewResult);
			}

			return {
				loginResult,
				interviewResult
			};
		},
		async handleApplySignUp({
			messages,
			formData,
			email,
			device,
			uref,
			utm,
			loginAuthCode,
			matchObj,
			recaptchaToken,
			recaptchaVersion,
			autoLogin
		}: {
			messages: any;
			formData: FormData;
			email?: string;
			device?: string;
			uref?: string;
			utm?: string;
			loginAuthCode?: string;
			matchObj: Partial<IAPIMatchForJobSeeker>;
			recaptchaToken: string;
			recaptchaVersion: 'v2' | 'v3';
			autoLogin?: boolean;
		}): Promise<{
			loginResult: IAPIReAuthResult;
			interviewResult: IStartInterviewResultDTO;
		}> {
			const config = this.$nuxt.$config;

			// use FormData.set instead of FormData.append because we might use the same FormData multiple times due to recaptcha
			if (email) {
				formData.set('email', email);
			}
			formData.set('viaDevice', device || 'pwa');
			formData.set('appVersion', config?.public?.version);
			formData.set('appType', config?.public?.appType);
			formData.set('recaptchaToken', recaptchaToken);
			formData.set('recaptchaVersion', recaptchaVersion);
			if (uref) {
				formData.set('usercase', uref);
			} else {
				formData.delete('uref');
			}

			if (utm) {
				formData.set('utm', utm);
			} else {
				formData.delete('utm');
			}

			if (loginAuthCode) {
				formData.set('emailCode', loginAuthCode);
			} else {
				formData.delete('emailCode');
			}

			if (autoLogin) {
				formData.set('autoLogin', 'true');
			}

			if ((!formData || !formData.has('email')) && !email) {
				// formData.has is not supported by internet explorer, therefore we pass in the "email" directly via additional property
				throw new NoEmailError('invalid input parameters for handleLogin');
			}

			const loginStore = useLoginStore();
			const loginResult = await loginStore.doSignUp(formData);

			// answer question
			messages.some((m, index) => {
				if (m._id === 'userLogin' && email) {
					messages[index].currentAnswer = email;
					return true;
				}
				return false;
			});

			const { relation } = matchObj;

			let interviewResult!: IStartInterviewResultDTO;
			if (relation) {
				interviewResult = await this.startInterview({ relation });
			}
			if (!interviewResult.success) {
				throw new InvalidResponseError('invalid response from start-interview', interviewResult);
			}
			return {
				loginResult,
				interviewResult
			};
		},

		async startInterview({
			relation,
			resetSkippedQuestions,
			audienceId
		}: {
			relation:
				| APIMatchRelation // either via regular match relation // or with an object id match relation
				| (
						| {
								type: 'company';
								obj: APITypeObjectId<APIObjectType.Company>; // company id
						  }
						| {
								type: 'job';
								obj: APITypeObjectId<APIObjectType.Job> | number; // job id or number
						  }
				  );
			resetSkippedQuestions?: boolean;
			audienceId?: APITypeObjectId<APIObjectType.CompanyAudience>;
		}): Promise<IStartInterviewResultDTO> {
			// we can start an interview either jobNr (type job), jobId (type job) or companyId (type company)
			const cookies = import.meta.client && window.document.cookie;
			const utm = (cookies && getCookie('utm', cookies)) || undefined;

			const result = await this.$nuxt.$hokFetch<IStartInterviewResultDTO>(
				'/app-api/jobseeker/interview/start',
				{
					method: 'POST',
					body: {
						relation: relation.type,
						relationId:
							(relation?.obj && typeof relation.obj === 'object' && relation.obj._id) ||
							relation?.obj,
						audienceId,
						resetSkippedQuestions,
						utm,
						gaCID: await getGoogleAnalyticsClientId(),
						fbp: (cookies && getCookie('_fbp', cookies)) || undefined,
						fbc: (cookies && getCookie('_fbc', cookies)) || undefined
					}
				}
			);

			if (!result?.success) {
				throw new Error('cannot start interview');
			}

			// const { success, match, newMatchObj } = result;
			const { match } = result;

			const relationsStore = useRelationsStore();
			// save to store
			relationsStore.discardRelationCard(relation);
			if (match) {
				const matchesStore = useMatchesStore();
				matchesStore.saveMatch(match);
			}
			// not used ATM
			/* if (success && newMatchObj) {
										commit('messages/increaseOstats', 'saved', { root: true });
								} */

			return result;
		},
		async answerInterview({
			form,
			msgId,
			matchObj,
			replace,
			resend
		}: AnswerInterviewInput): Promise<AnswerInterviewResult> {
			let generalAnswer;
			if (form instanceof FormData && matchObj) {
				generalAnswer = form;
				if (!resend) {
					generalAnswer.append('identifier', msgId);
					generalAnswer.append('relation', matchObj.relation.type);
					generalAnswer.append('relationId', matchObj.relation.obj._id);
				}
			} else if (Array.isArray(form) && matchObj) {
				console.warn('answer is not a formdata', form);
				generalAnswer = {
					identifier: msgId,
					relation: matchObj.relation.type,
					relationId: matchObj.relation.obj._id
				};
				form.forEach(a => {
					generalAnswer[a.name] = a.value;
				});
			} else {
				throw new TypeError('invalid form data');
			}

			const data = await this.$nuxt.$hokFetch('/app-api/jobseeker/interview/answer', {
				method: 'POST',
				body: generalAnswer
			});

			if (data.answered && typeof data.answered.currentAnswer === 'undefined') {
				// init with a value for reactiviness (just to be sure)
				data.answered.currentAnswer = false;
			}

			const { success, userUpdate, updateInterviewQuestion, answered } = data;

			if (success) {
				if (userUpdate) {
					const userProfileStore = useUserProfileStore();
					userProfileStore.updateElements(userUpdate);
				}

				if (updateInterviewQuestion && matchObj && answered) {
					try {
						const matchesStore = useMatchesStore();
						matchesStore.updateInterviewAnswer({
							matchObj,
							answered,
							replace
						});
					} catch (e) {
						console.warn('match could not be updated!', e);
					}
				}
			}

			return data;
		},
		answerOptions({ matchObj, qId }) {
			return this.$nuxt.$hokFetch(
				`/app-api/jobseeker/interview/answer-options?relation=${matchObj.relation.type}&relationId=${matchObj.relation.obj._id}&identifier=${qId}`
			);
		}
	}
});
