<template>
	<main>
		<ContentContainer size="large">
			<h1 class="pt-4">Secret Feature Config</h1>
			<HokCheckbox
				v-for="({ _id, enabled, description, locallyEnabled, name }, index) in featureFlags"
				:id="`featureFlag-${_id}`"
				:key="`featureFlag-${_id}`"
				:checked="locallyEnabled"
				@input="updateLocalFeatureFlag(index, $event)"
			>
				<strong>{{ name }}</strong>
				<template v-if="enabled"> (Enabled globally) </template>
				<template v-if="description"> - {{ description }}</template>
			</HokCheckbox>

			<p class="mb-2">
				TesterGroup
				<ClientOnly>
					<ToolTip
						>Attention: TesterGroup changes are only temporary/local and will be lost after
						reloading.</ToolTip
					>
				</ClientOnly>
			</p>
			<HokRadioGroup v-model="testerGroup">
				<HokRadio id="A" name="A" value="A"> A </HokRadio>
				<HokRadio id="B" name="B" value="B"> B </HokRadio>
			</HokRadioGroup>
		</ContentContainer>
	</main>
</template>

<script lang="ts">
import ContentContainer from '@hokify/shared-components-nuxt3/lib/components/ContentContainer.vue';
import { lsTest } from '@hokify/shared-components-nuxt3/lib/helpers/localstorage';
import HokCheckbox from '@hokify/shared-components-nuxt3/lib/components/HokCheckbox.vue';
import HokRadioGroup from '@hokify/shared-components-nuxt3/lib/components/HokRadioGroup.vue';
import HokRadio from '@hokify/shared-components-nuxt3/lib/components/HokRadio.vue';
import type { IAPILoginUser } from '@hokify/common';
import ToolTip from '@hokify/shared-components-nuxt3/lib/components/ToolTip.vue';
import { defineComponent } from 'vue';
import { mapStores } from 'pinia';
import type { IFeatureFlag } from '@hokify/feature-flag-interface';
import { useUserProfileStore } from '@/stores/user-profile';
import { useFeatureFlagStore } from '@/stores/feature-flag';
import { definePageMeta } from '#imports';

export default defineComponent({
	setup() {
		definePageMeta({ layout: 'user' });
	},
	components: { ContentContainer, HokCheckbox, HokRadioGroup, HokRadio, ToolTip },
	data() {
		const featureFlags: (IFeatureFlag & { locallyEnabled: boolean })[] = [];

		return { featureFlags };
	},
	computed: {
		user(): IAPILoginUser | undefined {
			return this.userProfileStore.obj;
		},
		testerGroup: {
			get() {
				return this.user?.testerGroup;
			},
			set(value) {
				this.updateUserGroup(value);

				this.$snack.success({
					text: 'Your TesterGroup was changed (temporary)'
				});
			}
		},
		...mapStores(useUserProfileStore, useFeatureFlagStore)
	},
	async mounted() {
		const featureFlagsRaw = await this.featureFlagStore.getAllFeatureFlags();
		const featureFlags = featureFlagsRaw.map(featureFlag => ({
			...featureFlag,
			locallyEnabled: featureFlag.enabled
		}));

		this.featureFlags.push(...featureFlags);
	},
	methods: {
		updateUserGroup(testerGroup) {
			this.userProfileStore.updateElements({ testerGroup });
		},
		updateLocalFeatureFlag(index: number, value: boolean) {
			this.featureFlags[index].locallyEnabled = value;
			if (lsTest()) {
				localStorage.setItem(this.featureFlags[index].name, value.toString());
			}
		}
	}
});
</script>
