import { CodeError } from './CodeError';

export class InvalidResponseError extends CodeError {
	public constructor(
		msg: string,
		public additionalInfo?: any
	) {
		super('INVALID_RESPONSE', msg);
		// Set the prototype explicitly. https://github.com/Microsoft/TypeScript/issues/13965
		Object.setPrototypeOf(this, InvalidResponseError.prototype);
	}
}
