<template>
	<main>
		<DeactivatedNotifications @end-go-to="EventBus.$emit('end-go-to')" />
	</main>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { EventBus } from '@hokify/shared-components-nuxt3/lib/eventbus';
import DeactivatedNotifications from '@/components/user/profile/DeactivatedNotifications.vue';
import { definePageMeta, useSeoMeta } from '#imports';

export default defineComponent({
	emits: ['end-go-to'],
	setup() {
		definePageMeta({
			layout: 'user',
			middleware: ['auth']
		});
		useSeoMeta({
			title: 'Deaktivierte Mitteilungen bearbeiten | hokify'
		});
	},
	data() {
		return {
			EventBus
		};
	},
	components: { DeactivatedNotifications }
});
</script>
