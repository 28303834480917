<template>
	<div :class="{ 'container max-w-4xl': !$isMobile.any }">
		<GrossNetCalculator
			ref="gncCalucatur"
			:gnc-calculation="gncCalculation"
			:period="period"
			is-pwa
			:remuneration="remuneration"
			@update-remuneration="updateRemuneration($event)"
			@update-period="updatePeriod($event)"
			@go-to-jobsearch="openJobsearch"
			@send-gnc="calcGnc"
		/>
	</div>
</template>
<script lang="ts">
import GrossNetCalculator from '@hokify/shared-components-nuxt3/lib/components/GrossNetCalculator.vue';
import type { IAPIGrossNetCalculation, GNCPeriod } from '@hokify/common';
import { defineComponent, nextTick } from 'vue';
import type { ComponentPublicInstance } from 'vue';
import { mapStores } from 'pinia';
import { useGncStore } from '@/stores/gnc';
import { definePageMeta } from '#imports';

function isHTMLElement(element: any): element is HTMLElement {
	return element && element instanceof HTMLElement;
}

function hasVueElement(vue: any): vue is ComponentPublicInstance {
	return vue && !!vue.$el;
}

export default defineComponent({
	name: 'GncPage',
	layout: 'user',
	components: { GrossNetCalculator },
	setup() {
		definePageMeta({
			layout: 'user',
			path: '/brutto-netto-rechner',
			middleware: ['auth']
		});
	},
	data() {
		const remuneration = undefined as number | undefined;
		const period: GNCPeriod = 3;
		const gncCalculation = undefined as IAPIGrossNetCalculation | undefined;

		return {
			gncCalculation,
			period,
			remuneration
		};
	},
	computed: {
		...mapStores(useGncStore)
	},
	methods: {
		async calcGnc(gnc, scroll?: any) {
			try {
				this.gncCalculation = await this.gncStore.sendGnc({ ...gnc, locationUrl: 'lp' });
				if (scroll) {
					nextTick(() => {
						const gncCalculator = this.$refs.gncCalucatur;
						if (hasVueElement(gncCalculator)) {
							const element = gncCalculator.$refs?.gncResultsRef;
							if (isHTMLElement(element)) {
								element.scrollIntoView({ behavior: 'smooth', block: 'start' });
							}
						}
					});
				}
			} catch (err: any) {
				this.$nuxt.$errorHandler(err);
			}
		},
		openJobsearch() {
			this.$router.push('/pwa');
			this.$trackUserEvent?.('click_redirect', { url: '/pwa' });
		},
		updateRemuneration(updatedRemuneration) {
			this.remuneration = updatedRemuneration;
		},
		updatePeriod(updatedPeriod) {
			this.period = updatedPeriod;
		}
	}
});
</script>
