import { defineStore } from 'pinia';
import { lsTest } from '@hokify/shared-components-nuxt3/lib/helpers/localstorage';
import { useUserProfileStore } from './user-profile';

export interface IUserJobFilterState {
	possibleResults: number;
}

export const useUserJobFilterStore = defineStore('userJobFilter', {
	state: (): IUserJobFilterState => ({
		possibleResults: 0
	}),
	actions: {
		setFilter(payload) {
			if (!payload) {
				payload = {};
			}

			// temporary to test new jobsearch
			payload.experimental = lsTest() && !!localStorage.getItem('experimentalJobSearch');

			return this.$nuxt
				.$hokFetch('/app-api/jobseeker/search/jobfilter', { method: 'POST', body: payload })
				.then(res => {
					const { jobFilter } = res;
					const userProfileStore = useUserProfileStore();
					userProfileStore.updateElements({ jobFilter });
					return res;
				});
		},
		getPossibleFilter(payload) {
			if (!payload) {
				payload = {};
			}

			return this.$nuxt
				.$hokFetch('/app-api/jobseeker/search/possible-filters', { method: 'POST', body: payload })
				.then(res => {
					const { possibleResults } = res;
					this.setPossibleResults(possibleResults);
					return res;
				});
		},
		setPossibleResults(possibleResults) {
			this.possibleResults = possibleResults;
		}
	}
});
