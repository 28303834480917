<template>
	<div class="quickCv container" :class="stage === 'design' ? 'max-w-6xl' : 'max-w-md'">
		<QuickEducation
			v-if="stage === 'education'"
			class="mt-4 pb-8"
			@change-stage="changeStage($event)"
		/>
		<QuickExperience
			v-else-if="stage === 'experience'"
			class="mt-4"
			@change-stage="changeStage($event)"
		/>
		<QuickSkills v-else-if="stage === 'skills'" class="mt-4" @change-stage="changeStage($event)" />
		<QuickCvSelector v-else-if="stage === 'design'" class="mt-4" />
	</div>
</template>

<script lang="ts">
import type { IAPILoginUser } from '@hokify/common';
import { defineComponent } from 'vue';
import { mapStores } from 'pinia';
import QuickEducation from '@/components/user/quickCv/quickEducation.vue';
import QuickExperience from '@/components/user/quickCv/quickExperience.vue';
import QuickSkills from '@/components/user/quickCv/quickSkills.vue';
import QuickCvSelector from '@/components/user/quickCv/quickCvSelector.vue';
import { useUserProfileStore } from '@/stores/user-profile';
import { definePageMeta } from '#imports';

export default defineComponent({
	setup() {
		definePageMeta({
			layout: 'user',
			middleware: ['auth']
		});
	},
	components: { QuickCvSelector, QuickSkills, QuickExperience, QuickEducation },
	data() {
		const stage = 'education' as 'education' | 'experience' | 'skills' | 'design';

		return {
			stage
		};
	},
	computed: {
		user(): IAPILoginUser | undefined {
			return this.userProfileStore.obj;
		},
		...mapStores(useUserProfileStore)
	},
	async mounted() {
		const completeEducations = this.userProfileStore.obj?.cvInfo?.educations?.filter(
			education => education.sublevel
		);

		if (completeEducations?.length) {
			if (this.userProfileStore.obj?.cvInfo?.experiences?.length) {
				this.stage = 'skills';
			} else {
				this.stage = 'experience';
			}
		}
		this.$trackUserEvent?.('cv_question_start', { stage: this.stage });
	},
	methods: {
		changeStage(stage) {
			this.$trackUserEvent?.('cv_question_completed', { stage: this.stage });
			this.stage = stage;
		}
	},
	props: {
		hideProgressBar: { type: Boolean, default: false }
	}
});
</script>
<style lang="scss" scoped>
.quickCv {
	::v-deep(.input--sae) {
		padding-top: 0.35rem;
	}
	::v-deep(.calendar .input--sae) {
		padding-top: 0.35rem;
	}
}
</style>
