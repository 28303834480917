<template>
	<div class="h-full bg-color-white pt-4 flex flex-col justify-center items-center">
		<div
			v-if="internalStage === 'overview'"
			class="w-full flex-1 px-6 sm:px-8 overflow-y-auto sm:pt-2"
		>
			<div class="flex items-center mb-4">
				<NuxtImg
					alt="hokify Logo"
					src="/shared-components-nuxt3/svgs/hokify/logo-hy-b2c.svg"
					class="h-12 mr-4"
				/>
				<h1 class="text-color-text text-base sm:text-xl mb-0">Wir verwenden Cookies.</h1>
			</div>
			<div class="flex justify-between">
				<p class="font-bold text-sm sm:text-base sm:mb-4">
					Wir benötigen deine Zustimmung, damit wir und unsere Partner Cookies und andere
					Technologien einsetzen können.
				</p>
				<NuxtImg alt="Cookie" src="/cookie.gif" class="h-12 ml-4 sm:mr-6" />
			</div>
			<p class="text-sm sm:text-base">
				Cookies sind kleine Text-Dateien die auf deinem Rechner beziehungsweise einem mobilen
				Endgerät heruntergeladen werden. Manche Cookies sind essentiell während andere für externe
				Inhalte auf der hokify Plattform, Marketing oder Statistik Zwecke dienen. Mit dem Button OK
				stimmst du allen Cookies (jederzeit widerruflich) zu. Unter Einstellungen kann man die
				Cookie Einstellungen individualisieren und über Notwendige Cookies nur die unbedingt für das
				Service notwendigen Cookies nutzen.
			</p>
		</div>
		<div v-else class="w-full flex-1 flex flex-col overflow-hidden">
			<div class="flex justify-between items-center px-4">
				<h1 class="text-color-text text-base mb-0">Privatsphären-Einstellung</h1>
				<HokIcon
					name="icon:close-slim"
					color="grey"
					:size="4"
					pointer
					@click="internalStage = 'overview'"
				/>
			</div>
			<Tabs
				ref="cookieTabs"
				name="cookieTabs"
				:align-center="!$isMobile.phone"
				class="fullwidth2tabsonphone"
				@changed="changeTabId"
			>
				<Tab id="category" name="Kategorien" class="px-4 flex-1 pb-12">
					<div v-for="category in categories" :key="category.id">
						<div class="border border-color-grey-light p-3 mt-4">
							<div class="flex justify-between items-center mb-2">
								<div class="flex items-center">
									<h2 class="text-color-text text-base font-bold mb-0 inline">
										{{ category.title }}
									</h2>
									<HokIcon
										v-if="category.id === 'essential'"
										name="icon:lock"
										:size="5"
										color="grey"
										class="inline"
									/>
								</div>
								<HokSwitch
									:id="`${category.id}-switch`"
									:ref="category.id"
									v-model="trackerCategories[category.id]"
									:disabled="category.id === 'essential'"
									styling="main"
									class="scroll-mt-6"
								/>
							</div>
							<p class="mb-0">
								{{ category.text }}
							</p>
						</div>
						<div class="border-r border-b border-l border-color-grey-light px-4 py-2">
							<div
								class="flex justify-between items-center cursor-pointer"
								@click="toggleItem(category.id)"
							>
								<h3 class="text-color-text text-base mb-0 leading-loose py-1">Zur Übersicht</h3>
								<HokIcon
									:class="{ 'rotate-90': accordionCategories[category.id] }"
									:size="5"
									color="main"
									name="icon:arrow-right"
									class="transition-transform duration-300"
								/>
							</div>
							<TransitionExpand>
								<div v-if="accordionCategories[category.id]" class="mt-2">
									<div
										v-for="tracker in category.trackers"
										:key="tracker.id"
										class="flex items-center py-1 cursor-pointer"
										@click="goTo(tracker.id)"
									>
										<HokIcon name="icon:info" color="grey-light" class="inline mr-2" />
										<p class="leading-loose mb-0">{{ tracker.name }}</p>
									</div>
								</div>
							</TransitionExpand>
						</div>
					</div>
				</Tab>
				<Tab id="information" name="Informationen" class="px-4 flex-1">
					<LazyCookieBannerInformation
						v-if="currentTabId === 'information'"
						ref="cookieBannerInformation"
					/>
				</Tab>
			</Tabs>
		</div>
		<div class="w-full pt-6 px-6 sm:px-8 shadow">
			<div v-if="internalStage === 'overview'" :class="{ 'px-12': !$isMobile.phone }">
				<HokButton
					fullwidth="always"
					class="mb-4"
					:color="mode === 'company' ? 'business' : 'main'"
					@click="acceptAll"
					>OK</HokButton
				>
				<HokButton
					fullwidth="always"
					outline
					:color="mode === 'company' ? 'business' : 'main'"
					class="mb-4"
					@click="acceptEssential"
				>
					Notwendige Dienste
				</HokButton>
				<HokButton
					:color="mode === 'company' ? 'business' : 'main'"
					fullwidth="always"
					is-text
					class="mb-4"
					@click="internalStage = 'config'"
					>Konfigurieren</HokButton
				>
			</div>
			<div v-else :class="{ 'px-12': !$isMobile.phone }">
				<HokButton
					fullwidth="always"
					class="mb-4"
					:color="mode === 'company' ? 'business' : 'main'"
					@click="acceptAll"
					>Alles akzeptieren</HokButton
				>
				<HokButton
					fullwidth="always"
					outline
					:color="mode === 'company' ? 'business' : 'main'"
					class="mb-6"
					@click="`${currentTabId === 'category' ? saveConfig() : selectTab('category')}`"
				>
					{{ currentTabId === 'category' ? 'Konfiguration übernehmen' : 'Konfigurieren' }}
				</HokButton>
			</div>
			<div class="flex justify-between mb-4">
				<HokLink to="/privacy" target="_blank" link-type="internal" class="text-color-grey text-xs"
					>Datenschutz</HokLink
				>
				<HokLink to="/imprint" target="_blank" link-type="internal" class="text-color-grey text-xs"
					>Impressum</HokLink
				>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import type { ComponentPublicInstance } from 'vue';
import { lsTest } from '../../helpers/localstorage';
import TransitionExpand from '../../components/TransitionExpand.vue';
import Tab from '../../components/Tab.vue';
import HokSwitch from '../../components/HokSwitch.vue';
import Tabs from '../../components/Tabs.vue';
import LazyCookieBannerInformation from './CookieBannerInformation.vue';

function hasSelectTab(tab: any): tab is { selectTab(tab: string): void } {
	return tab && typeof tab.selectTab === 'function';
}

function hasToggleItem(obj: any): obj is { toggleItem(id: string): void } {
	return obj && typeof obj.toggleItem === 'function';
}

function isHTMLElement(element: any): element is HTMLElement {
	return element && element instanceof HTMLElement;
}

function hasVueElement(vue: any): vue is ComponentPublicInstance {
	return vue && !!vue.$el;
}

export default defineComponent({
	name: 'CookieBanner',
	components: {
		LazyCookieBannerInformation,
		Tab,
		Tabs,
		HokSwitch,
		TransitionExpand
	},
	emits: ['close'],
	data() {
		const accordionCategories: {
			essential: boolean;
			externalYoutube: boolean;
			ads: boolean;
			stats: boolean;
		} = {
			essential: false,
			externalYoutube: false,
			ads: false,
			stats: false
		};
		const currentTabId = 'category' as 'category' | 'information';
		let externalYoutube = false;
		let ads = false;
		let stats = false;

		const acceptedCookie = lsTest() && localStorage.getItem('accept-cookies');
		if (acceptedCookie && acceptedCookie.startsWith('[')) {
			const parsedCookie = JSON.parse(acceptedCookie);
			if (parsedCookie.includes('externalYoutube')) {
				externalYoutube = true;
			}
			if (parsedCookie.includes('ads')) {
				ads = true;
			}
			if (parsedCookie.includes('stats')) {
				stats = true;
			}
		}
		return {
			trackerCategories: {
				essential: true,
				externalYoutube,
				ads,
				stats
			},
			currentTabId,
			internalStage: this.stage,
			accordionCategories,
			categories: [
				{
					id: 'essential',
					title: 'Essenziell',
					text: 'Diese Technologien sind unbedingt notwendig um die angeforderten Services auf unserer Website zu erbringen.',
					trackers: [
						{ name: 'Google Maps', id: 'gm' },
						{ name: 'Google Tag Manager', id: 'gtm' },
						{ name: 'Hubspot', id: 'hubspot' },
						{ name: 'Google reCaptcha', id: 'recaptcha' }
					]
				},
				{
					id: 'externalYoutube',
					title: 'Anzeige externer Inhalte',
					text: 'Diese Technologien sind notwendig um gewisse externe Inhalte auf unserer Plattform anzuzeigen (z.B.: YouTube Videos)',
					trackers: [{ name: 'YouTube', id: 'youtube' }]
				},
				{
					id: 'ads',
					title: 'Personalisierte Anzeigen und Marketing',
					text: 'Diese Technologien werden verwendet um relevantere Anzeigen zu schalten, die Performance der Anzeigen zu messen und unsere Drittpartner können damit die Werbung für dich personalisieren.',
					trackers: [
						{ name: 'Facebook Pixel', id: 'facebook' },
						{ name: 'Google Advertising', id: 'gadv' },
						{ name: 'TikTok Advertising', id: 'tiktok' },
						{ name: 'Snap Pixel', id: 'snap' },
						{ name: 'Linkedin Tag', id: 'linkedin' },
						{ name: 'Bing Ads UET Tag', id: 'bing' },
						{ name: 'Reddit Ads Conversion Tracking', id: 'reddit' },
						{ name: 'Criteo Tag', id: 'criteo' },
						{ name: 'RTB House', id: 'rtbhouse' }
					]
				},
				{
					id: 'stats',
					title: 'Statistik',
					text: 'Diese Technologien werden verwendet um Informationen über die Nutzung unserer Plattform zu erhalten um das Nutzererlebnis zu verbessern.',
					trackers: [
						{ name: 'Google Analytics', id: 'ga' },
						{ name: 'Hotjar', id: 'hotj' },
						{ name: 'Adjust', id: 'adjust' }
					]
				}
			]
		};
	},
	mounted() {
		this.$trackGenericEvent?.('cookie_banner_viewed', {});

		if (this.stage === 'config') {
			this.$nextTick(() => {
				const element = this.$refs.externalYoutube;
				if (element && hasVueElement(element?.[0])) {
					element[0].$el.scrollIntoView({ behavior: 'smooth', block: 'start' });
				}
			});
			setTimeout(() => {
				this.trackerCategories.externalYoutube = true;
			}, 500); // consider this as an animation
		}
	},
	methods: {
		acceptAll() {
			if (lsTest()) {
				localStorage.setItem(
					'accept-cookies',
					JSON.stringify(['essential', 'externalYoutube', 'ads', 'stats'])
				);
			}

			this.$trackGenericEvent?.('cookie_banner_accepted', {});
			this.$emit('close');
		},
		acceptEssential() {
			if (lsTest()) {
				localStorage.setItem('accept-cookies', JSON.stringify(['essential']));
			}
			this.$emit('close');
		},
		saveConfig() {
			if (lsTest()) {
				const allowed: string[] = [];

				Object.entries(this.trackerCategories).forEach(([key, value]) => {
					if (value) {
						allowed.push(key);
					}
				});

				localStorage.setItem('accept-cookies', JSON.stringify(allowed));
			}
			this.$emit('close');
		},
		changeTabId(newTab) {
			this.currentTabId = newTab?.tab?.id || newTab;
		},
		toggleItem(id: string) {
			this.categories.some(category => {
				if (category.id === id) {
					this.accordionCategories[id] = !this.accordionCategories[id];
					return true;
				}
				return false;
			});
		},
		goTo(trackerId) {
			this.currentTabId = 'information';
			this.selectTab('information');

			// wait for CookieBannerInformation & thirdParty.json to be loaded
			// TODO: wait for an emit from the mounted lifecycle inside the tab
			setTimeout(() => {
				// wait for tab to be changed
				this.$nextTick(() => {
					const { cookieBannerInformation } = this.$refs;
					if (hasVueElement(cookieBannerInformation)) {
						const element = cookieBannerInformation.$refs[trackerId];
						if (element && isHTMLElement(element?.[0])) {
							element[0].scrollIntoView({ behavior: 'smooth', block: 'start' });
						}
					}
				});
				if (hasToggleItem(this.$refs.cookieBannerInformation)) {
					this.$refs.cookieBannerInformation.toggleItem(trackerId);
				}
			}, 500);
		},
		selectTab(tab) {
			if (hasSelectTab(this.$refs.cookieTabs)) {
				this.$refs.cookieTabs.selectTab(tab);
				this.changeTabId(tab);
			}
		}
	},
	props: {
		stage: {
			type: String,
			default: 'overview',
			validator: (value: string) => ['overview', 'config'].includes(value)
		},
		mode: {
			type: String,
			required: false,
			validator: (value: string) => ['user', 'company'].includes(value)
		}
	}
});
</script>
