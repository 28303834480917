<template>
	<main class="flex flex-col relative">
		<Spinner v-if="showLoading" />
		<section class="scroll-container flex-1 pb-8">
			<div class="container max-w-5xl">
				<CvFails v-if="user" :user="user" />
				<CvProfileEditMode class="mt-6" :show-documents="showDocuments" :is-profile="false" />
			</div>
		</section>
		<div class="shadow p-4 z-10 sticky bottom-0 bg-color-white">
			<div class="m-auto max-w-dinA4 flex justify-center">
				<HokButton v-if="reviewCv" color="main" fullwidth="mobile" @click="verifyCvParsing">
					Daten bestätigen
				</HokButton>
				<HokButton v-else fullwidth="mobile" color="main" @click="openChangeCvDesign">
					Vorschau & Speichern
				</HokButton>
			</div>
		</div>
	</main>
</template>

<script lang="ts">
import CvFails from '@hokify/pwa-core-nuxt3/components/CvFails.vue';
import type { IAPILoginUser } from '@hokify/common';
import { mapStores } from 'pinia';
import { markRaw, defineAsyncComponent } from 'vue';
import CvProfileEditMode from '@/components/user/hokifyCvEdit/CvProfileEditMode.vue';
import { useUserProfileStore } from '@/stores/user-profile';
import { useLoginStore } from '@/stores/login';
import { defineNuxtComponent, useRoute, definePageMeta, useSeoMeta, useNuxtApp } from '#imports';

export function isAPILoginUser(obj: any): obj is IAPILoginUser {
	return obj && obj._id;
}

export default defineNuxtComponent({
	setup() {
		definePageMeta({
			layout: 'user',
			middleware: ['auth'],
			path: '/hokifycv'
		});
		const nuxt = useNuxtApp();
		const title = 'Lebenslauf | hokify';
		const description =
			'Kostenlos in wenigen Minuten deinen Lebenslauf erstellen, auch direkt am Smartphone, unkompliziert und in wenigen Minunten. Fertiger Lebenslauf kann als PDF heruntergeladen oder direkt auf hokify verwendet werden.';

		useSeoMeta({
			title,
			description,
			ogTitle: title,
			ogDescription: description,
			robots: 'index, follow',
			ogUrl: `${nuxt.$userRootStore.host}/hokifycv`
		});
	},
	components: {
		CvProfileEditMode,
		CvFails
	},
	async asyncData() {
		const { query } = useRoute();

		const reviewCv = query.reviewcv;

		return { reviewCv };
	},
	data() {
		const reviewCv = false;
		return {
			showLoading: false,
			reviewCv
		};
	},
	computed: {
		user(): IAPILoginUser | undefined {
			return this.userProfileStore.obj;
		},
		loggedIn(): boolean | undefined {
			return this.loginStore.loggedIn;
		},
		...mapStores(useUserProfileStore, useLoginStore)
	},
	created() {
		if (this.loggedIn) {
			this.showLoading = false;
			this.checkLoginState();
		} else if (this.loggedIn === null) {
			// show loading screen
			this.showLoading = true;
		}
	},
	methods: {
		async openChangeCvDesign() {
			const changeCvDesignPageComponent = markRaw(
				defineAsyncComponent(() => import('@/pages/pwa/profile/ChangeCvDesignPage.vue'))
			);
			try {
				await this.$page.push(
					changeCvDesignPageComponent,
					{},
					{
						mode: 'modal',
						name: 'changeCvDesign',
						done: () => {
							this.$router.push('/pwa/profile');
						}
					}
				);
			} catch (err) {
				this.$nuxt.$errorHandler(err);
			}
		},
		checkLoginState(loginDone?: any) {
			if (loginDone) {
				this.showLoading = false;
			}
		},
		loggedInChanged() {
			this.checkLoginState(true);
		},
		async verifyCvParsing() {
			try {
				await this.userProfileStore.verifyParsedCv({ isVerified: true });
				this.$router.push({
					path: '/pwa'
				});
			} catch (err) {
				this.$nuxt.$errorHandler(err);
				this.$snack.danger({
					text: 'Profildaten konnten nicht bestätigt werden.',
					offset: 'bottom-20'
				});
			}
		}
	},
	props: {
		hideProgressBar: { type: Boolean, default: false },
		showDocuments: { type: Boolean, default: true }
	},
	watch: {
		loggedIn: [
			{
				handler: 'loggedInChanged'
			}
		]
	}
});
</script>
