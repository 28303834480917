<template>
	<Transition :name="`slide-${direction}`">
		<section
			:id="computedId"
			:key="computedId"
			:aria-hidden="!isActive || isDisabled"
			class="w-full relative shrink-0"
			:class="[
				{ hidden: !isActive || isDisabled },
				noScroll ? 'overflow-y-hidden' : 'overflow-y-auto'
			]"
			role="tabpanel"
		>
			<slot />
		</section>
	</Transition>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import Tabs from './Tabs.vue';

export default defineComponent({
	name: 'Tab',
	data() {
		const isVisible: boolean = this.initialVisible;
		return {
			isVisible
		};
	},
	computed: {
		isActive: {
			// should be || this.initialActive which is set in Tabs, but seems to not work because of the inject
			get() {
				return (this.$parent as unknown as typeof Tabs)?.activeTabHash === this.hash || false;
			},
			set(newTab) {
				return newTab;
			}
		},
		direction(): string | undefined {
			return (this.$parent as unknown as typeof Tabs)?.direction || undefined;
		},
		computedId(): string {
			return this.id ? this.id : this.name.toLowerCase().replace(/ /g, '-');
		},
		hash(): string {
			if (this.isDisabled) {
				return '';
			}

			return this.computedId;
		}
	},
	props: {
		id: { type: String },
		name: { type: String, required: true },
		prefix: { type: String, default: '' },
		suffix: { type: String, default: '' },
		url: { type: String, default: undefined },
		isDisabled: { type: Boolean, default: false },
		noScroll: { type: Boolean, default: false },
		notificationBadge: { type: Boolean, default: false },
		textBadge: { type: Boolean, default: false },
		infoBadge: { type: Boolean, default: false },
		scrollToTop: { type: Boolean, default: true },
		badge: { type: [Number, String], default: '' },
		addCheckmark: { type: Boolean, default: false },
		initialActive: { type: Boolean, default: false },
		initialVisible: { type: Boolean, default: true }
	}
});
</script>

<style lang="scss" scoped>
.slide-left-leave-active,
.slide-left-enter-active,
.slide-right-leave-active,
.slide-right-enter-active {
	transition: 300ms;
}

.slide-left-leave-to {
	transform: translate(0, 0);
}

.slide-left-enter {
	transform: translate(-100%, 0);
}

.slide-right-leave {
	transform: translate(0, 0);
}

.slide-right-leave-to,
.slide-right-enter-to {
	transform: translate(-100%, 0);
}
</style>
