<template>
	<div class="radio-group relative" role="radiogroup">
		<slot />
	</div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import HokRadio from './HokRadio.vue';
import { EventBus } from '../eventbus';

export default defineComponent({
	name: 'HokRadioGroup',
	emits: ['update:modelValue'],
	data() {
		const radios: (typeof HokRadio)[] = [];
		const internalValue: string | number | boolean = this.modelValue;

		return {
			internalValue,
			radios,
			EventBus
		};
	},
	methods: {
		onRadioChange(value) {
			this.internalValue = value;
			this.setActiveRadio();
			this.$emit('update:modelValue', this.internalValue);
		},
		register(radio) {
			radio.isActive = this.internalValue === radio.value;
			this.EventBus.$on(`change-${radio.id}`, this.onRadioChange);
			this.radios.push(radio);
		},
		unregister(radio) {
			this.EventBus.$off(`change-${radio.id}`, this.onRadioChange);

			const index = this.radios.findIndex(r => r === radio);

			if (index > -1) {
				this.radios.splice(index, 1);
			}
		},
		setActiveRadio() {
			this.radios.forEach(radio => {
				radio.isActive = this.internalValue === radio.value;
			});
		},
		onValueChanged(newVal) {
			this.internalValue = newVal;
			this.setActiveRadio();
		}
	},
	props: {
		modelValue: { type: [String, Number, Boolean], default: null }
	},
	watch: {
		modelValue: [
			{
				handler: 'onValueChanged'
			}
		]
	}
});
</script>
