import { defineStore } from 'pinia';
import { lsTest } from '@hokify/shared-components-nuxt3/lib/helpers/localstorage';
import { SupportedDevices } from '@hokify/login-stack-nuxt3/lib/types/supported-devices';

export interface IUserRootState {
	topLevelDomain?: string;
	host: string;

	locale: string;
	localeMessages?: string[];
	device: SupportedDevices;
	back: boolean;
	globalNetworkErrors: number;
	addToHomeScreen: boolean;
	setupPushNotification: boolean;

	processDeeplinkAfterLogin: string | undefined;
	lastDeeplink: string;
}

interface IPushObj {
	deviceId: string;
	token: string;
	device: string;
	endpoint?: string;
	keys?: {
		p256dh: string;
		auth: string;
	};
}

function guid() {
	function s4() {
		return Math.floor((1 + Math.random()) * 0x10000)
			.toString(16)
			.substring(1);
	}

	return `${s4() + s4()}-${s4()}-${s4()}-${s4()}-${s4()}${s4()}${s4()}`;
}

export const useUserRootStore = defineStore('userRoot', {
	state: (): IUserRootState => ({
		locale: 'de',
		localeMessages: undefined,
		device: (process.env.cordova as SupportedDevices) || 'pwa',
		back: false,
		globalNetworkErrors: 0,
		addToHomeScreen: false,
		setupPushNotification: false,
		lastDeeplink: '',
		processDeeplinkAfterLogin: undefined,
		topLevelDomain: undefined,
		host: ''
	}),
	actions: {
		trackPushOpen(outboxId) {
			return this.$nuxt.$hokFetch<void>('/app-api/notification/track-open', {
				method: 'POST',
				body: {
					outboxId,
					device: this.device
				}
			});
		},
		async refreshPushOnServer(subscriptionObject) {
			let deviceId;
			if (lsTest()) {
				deviceId = localStorage.getItem('deviceId');
			}
			if (!deviceId) {
				deviceId = guid();
				if (lsTest()) {
					localStorage.setItem('deviceId', deviceId);
				}
			}

			const pushObj: IPushObj = {
				deviceId,
				token: subscriptionObject.registrationId,
				device: this.device
			};

			if (subscriptionObject.endpoint) {
				pushObj.endpoint = subscriptionObject.endpoint;
			}

			if (subscriptionObject.keys) {
				pushObj.keys = {
					p256dh: subscriptionObject.keys.p256dh,
					auth: subscriptionObject.keys.auth
				};
			}

			await this.$nuxt.$hokFetch('/app-api/notification/register-push', {
				method: 'POST',
				body: pushObj
			});
		},
		setBack(val) {
			this.back = val;
		},
		setTopLevelDomain(topLevelDomain) {
			this.topLevelDomain = topLevelDomain;
		},
		setHost(host) {
			this.host = host;
		},
		setLastDeeplink(deeplink) {
			this.lastDeeplink = deeplink;
		},
		doProcessDeeplinkAfterLogin(deeplink) {
			this.processDeeplinkAfterLogin = deeplink;
		},
		setLocale(locale) {
			this.locale = locale;
		},
		setLocaleMessages(localeMessages) {
			this.localeMessages = localeMessages;
		},

		increaseGlobalNetworkErrors() {
			this.globalNetworkErrors += 1;
		},

		resetGlobalNetworkErrors() {
			this.globalNetworkErrors = 0;
		},
		doAddToHomeScreen(val) {
			this.addToHomeScreen = !!val;
		},
		doSetupPushNotification(val) {
			this.setupPushNotification = !!val;
		}
	}
});
