<script lang="ts">
import { defineComponent } from 'vue';
import ErrorBox from './ErrorBox.vue';

export default defineComponent({
	components: {},
	extends: ErrorBox,
	data() {
		const color = 'main';
		const icon = 'icon:info';
		const isInfoBox = true;

		return {
			isInfoBox,
			icon,
			color
		};
	}
});
</script>

<style lang="scss" scoped>
.info-box {
	border-radius: 0.5rem;
	border: 1px solid #0fb1af;

	h3,
	p {
		color: #0fb1af;
	}
}
</style>
