const frontendLibAssetPaths = ['/shared-components-nuxt3/', '/pwa-core-nuxt3/'];

const allowedFileTypes = ['.png', '.jpg', '.jpeg', '.webp', '.svg', '.gif'];

/**
 * Middleware to catch and replace possible image paths to frontend libs assets Since image urls
 * defined in style files are not processed by the nuxt image provider, it is necessary to catch and
 * redirect them to the correct path
 *
 * TODO: Find a solution during build time to replace img paths in style files with "publicPath"
 * prefix -> see image-provider.ts
 */
export default defineNuxtRouteMiddleware(to => {
	if (
		frontendLibAssetPaths.some(path => to.path.startsWith(path)) &&
		allowedFileTypes.some(type => to.path.toLowerCase().endsWith(type))
	) {
		const $img = useImage();
		return navigateTo($img(to.path), { replace: true });
	}

	return true;
});
