<template>
	<HokModal
		:adaptive="!!$isMobile.any"
		:width="$isMobile.any ? '95%' : '350px'"
		:click-to-close="false"
		:show-close-button="false"
		name="reCaptchaModal"
		transition="scale"
		height="auto"
		@vue:mounted="mountModal"
	>
		<Recaptcha
			v-show="showReCaptchaV2"
			id="recaptchaWidgetV2"
			:site-key="siteKey"
			class="mb-4"
			@success="sendRequest"
			@error="resetRecaptcha(true)"
			@expire="resetRecaptcha(true)"
		/>
		<h4 v-if="!showReCaptchaV2">Eingabe wird geprüft, bitte einen Moment warten...</h4>
		<div class="flex items-center">
			<HokIcon name="icon:info" color="text" class="mr-2" />
			<p class="mb-0 text-xs">
				geschützt durch reCAPTCHA<br />
				<HokLink to="https://policies.google.com/privacy">Datenschutzerklärung</HokLink> |
				<HokLink to="https://policies.google.com/terms">Nutzungsbedingungen</HokLink>
			</p>
		</div>
	</HokModal>
</template>
<script lang="ts">
import { defineComponent } from 'vue';
import HokLink from '@hokify/shared-components-nuxt3/lib/components/HokLink.vue';
import HokIcon from '@hokify/shared-components-nuxt3/lib/components/HokIcon.vue';
import Recaptcha from './Recaptcha.vue';
import { getEnvironment } from '../../helpers/environmentHelper';

export default defineComponent({
	components: {
		Recaptcha: Recaptcha as any, // weird type issue without this, seems to resolve to the wrong type definition
		HokLink: HokLink as any, // weird type issue without this, seems to resolve to the wrong type definition
		HokIcon: HokIcon as any // weird type issue without this, seems to resolve to the wrong type definition
	},
	data() {
		const recaptchaWidgetV2Id = undefined as undefined | number;
		const modalOpenTime = undefined as undefined | Date;
		const recaptchaV2PromiseResolve = undefined as undefined | ((value: string) => void);
		const bodyPointerValue = undefined as undefined | string;

		const {
			public: {
				recaptcha: { siteKeyV2Prod, siteKeyV2Test }
			}
		} = this.$nuxt.$config;

		return {
			recaptchaWidgetV2Id,
			modalOpenTime,
			recaptchaV2PromiseResolve,
			bodyPointerValue,
			siteKey:
				(getEnvironment() === 'production' ? siteKeyV2Prod : siteKeyV2Test) ||
				'6LeATfEiAAAAAJuddjHtQ5XhMtzAV1HYfB1hzSV5'
		};
	},
	computed: {
		showReCaptchaV2(): boolean {
			return typeof this.recaptchaWidgetV2Id === 'number';
		}
	},
	props: {
		showModal: { type: Boolean, default: false }
	},
	methods: {
		async onShowModalChange(show: boolean) {
			const delayTime = 3000;
			if (show) {
				this.$modal.show('reCaptchaModal');
				this.modalOpenTime = new Date();
			} else {
				const now = new Date();
				if (!this.modalOpenTime || now.getTime() >= this.modalOpenTime.getTime() + delayTime) {
					this.hideModal();
				} else {
					setTimeout(
						() => {
							this.hideModal();
						},
						this.modalOpenTime.getTime() + delayTime - now.getTime()
					);
				}
			}
		},

		hideModal() {
			this.modalOpenTime = undefined;
			this.$modal.hide('reCaptchaModal');
			this.resetRecaptcha();
			this.recaptchaWidgetV2Id = undefined;
		},

		async mountModal() {
			await this.$recaptcha.init();
			this.$emit('ready');
		},

		sendRequest(token: string) {
			if (this.$isMobile.any) {
				document.body.style.pointerEvents = this.bodyPointerValue || '';
			}
			this.recaptchaV2PromiseResolve?.(token);
		},

		resetRecaptcha(resetPointerValue = false): void {
			// reset pointerEvents value to what it was before showing the recaptcha v2
			// but only in case we still show the recaptcha modal
			// changing the pointerEvents value is necessary because on mobile we block every
			// event outside the modal but the recaptcha v2 is not inside the modal and needs to be clicked
			if (this.$isMobile.any && resetPointerValue) {
				document.body.style.pointerEvents = this.bodyPointerValue || '';
			}
			this.recaptchaV2PromiseResolve = undefined;
			if (typeof this.recaptchaWidgetV2Id === 'number') {
				this.$recaptcha.reset(this.recaptchaWidgetV2Id);
			} else {
				this.$recaptcha.reset();
			}
		},

		async getV2Token(): Promise<string> {
			if (this.$isMobile.any) {
				this.bodyPointerValue = document.body.style.pointerEvents;
				document.body.style.pointerEvents = '';
			}
			return new Promise<string>(resolve => {
				this.recaptchaV2PromiseResolve = resolve;
			});
		},

		// gets called via REF from outside
		async getRecaptchaToken({
			action,
			useRecaptchaV2
		}: {
			action: string;
			useRecaptchaV2: boolean;
		}): Promise<{ recaptchaToken: string; recaptchaVersion: 'v2' | 'v3' }> {
			if (useRecaptchaV2 && typeof this.recaptchaWidgetV2Id !== 'number') {
				this.recaptchaWidgetV2Id = await this.$recaptcha.render('recaptchaWidgetV2', {
					sitekey: this.siteKey
				});
			}

			// recaptchaWidgetV2Id must be checked against null because it will be 0 because it's the first v2 widget initialized on this page
			if (useRecaptchaV2 && typeof this.recaptchaWidgetV2Id === 'number') {
				const recaptchaToken = await this.getV2Token();

				return {
					recaptchaToken,
					recaptchaVersion: 'v2'
				};
			}

			const recaptchaToken = await this.$recaptcha.execute(action);

			return {
				recaptchaToken,
				recaptchaVersion: 'v3'
			};
		}
	},
	watch: {
		showModal: [
			{
				handler: 'onShowModalChange'
			}
		]
	},
	beforeUnmount() {
		this.resetRecaptcha();
		this.recaptchaWidgetV2Id = undefined;
	},
	emits: ['ready']
});
</script>
