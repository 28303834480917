import { defineStore } from 'pinia';
import type {
	IAPIFeatureAdvertisementExtended,
	IAPIFeatureAdvertisementSimple
} from '@hokify/common';

interface IState {
	features: (IAPIFeatureAdvertisementSimple | IAPIFeatureAdvertisementExtended)[];
}

export const useFeatureAdvertisementStore = defineStore('featureAdvertisement', {
	state: (): IState => ({
		features: []
	}),
	actions: {
		async loadFeatureAdvertisement(
			siteType: string
		): Promise<IAPIFeatureAdvertisementSimple[] | IAPIFeatureAdvertisementExtended[]> {
			try {
				const features: IAPIFeatureAdvertisementSimple[] | IAPIFeatureAdvertisementExtended[] =
					await this.$nuxt.$hokFetch(
						`/app-api/jobseeker/featureadvertisement?siteType=${siteType}`
					);
				this.saveFeatures(features);
				return features;
			} catch (error: any) {
				console.error('get featureadvertisement failed', error);
				throw error.response?.data || error.response || error;
			}
		},
		saveFeatures(features: IAPIFeatureAdvertisementSimple[] | IAPIFeatureAdvertisementExtended[]) {
			this.features = features;
		}
	}
});
