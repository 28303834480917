import { defineStore } from 'pinia';
import type {
	APIObjectType,
	APITypeObjectId,
	IAPIConversation,
	MessagingTags
} from '@hokify/common';
import { useUiStateStore } from './uistate';
// eslint-disable-next-line import/no-cycle
import { useLoginStore } from './login';
import { useMatchesStore } from './matches';

let reloadOstats;

// check if an element exists in array using a comparer function
// comparer : function(currentElement)
function inArray(array, comparer) {
	for (const element of array) {
		if (comparer(element)) {
			return true;
		}
	}
	return false;
}

// adds an element to the array if it does not already exist using a comparer
// function
function pushIfNotExist(array, element, comparer) {
	if (!inArray(array, comparer)) {
		array.push(element);
	}
}

export interface IMessagesState {
	conversations: {
		[key: string]: IAPIConversation[];
	};
	loadedMatchChatMessages: { [key: string]: Promise<any> | undefined };
	ostats: {
		saved: number; // not used atm
		applied: number; // not used atm
		unseenMsgs: number;
		matchesWithUnseenMsgs: {};
	};
}

export const useMessagesStore = defineStore('messages', {
	state: (): IMessagesState => ({
		conversations: {},
		loadedMatchChatMessages: {},
		ostats: {
			saved: 0, // not used atm
			applied: 0, // not used atm
			unseenMsgs: 0,
			matchesWithUnseenMsgs: {}
		}
	}),
	getters: {
		countUnseenMsgs(state): number {
			return state.ostats.unseenMsgs;
		},
		matchesWithUnseenMsgs(state) {
			return state.ostats.matchesWithUnseenMsgs;
		},
		countSaved(state): number {
			return state.ostats.saved;
		},
		countApplied(state): number {
			return state.ostats.applied;
		}
	},
	actions: {
		async loadConversations({
			type,
			typeId,
			markSeen
		}: {
			type: number;
			typeId: APITypeObjectId<APIObjectType.Match>;
			markSeen?: boolean;
		}) {
			const key = `${type}_${typeId}`;
			const matchId = typeId;
			const matchesStore = useMatchesStore();

			// update store and set messages to 0 and match as seen
			this.markMatchAsSeen(matchId);
			matchesStore.setUnseenMsgsUser({ matchId, reset: true, count: undefined });

			if (this.loadedMatchChatMessages[key] && !markSeen) {
				return this.loadedMatchChatMessages[key];
			}

			// this happens async!
			const load = this.$nuxt
				.$hokFetch(
					`/app-api/messaging/full-conversation?conversationTypeId=${typeId}&conversationType=${type}&forDestination=user${
						markSeen ? '&markSeen=true' : ''
					}`
				)
				.then(messages => {
					this.setChatMessages({
						type,
						typeId,
						messages
					});
					return this.conversations[key];
				})
				.catch(err => {
					console.error('loading full conversation failed', err);
				})
				.finally(() => {
					// reset loading
					this.loadChat({
						type,
						typeId,
						load: undefined
					});
				});

			this.loadChat({
				type,
				typeId,
				load
			});

			return load;
		},
		async processMessage({
			conversationId,
			conversationType,
			conversationTypeId
		}: {
			conversationId: APITypeObjectId<APIObjectType.Conversation>;
			conversationType: number;
			conversationTypeId: APITypeObjectId<APIObjectType.Match>;
		}) {
			// only case by now, but could change soon
			if (conversationType === 0) {
				await this.$nuxt
					.$hokFetch(
						`/app-api/messaging/message?conversationId=${conversationId}&conversationType=${conversationType}&conversationTypeId=${conversationTypeId}&deviceId=web`
					)
					.then(data => {
						if (data) {
							const matchId = conversationTypeId;
							const matchesStore = useMatchesStore();
							this.pushMatchChatMessage({
								type: conversationType,
								typeId: conversationTypeId,
								message: data
							});

							// if message is from company, process user side
							if (data.sender === 'company') {
								// force updating oStats
								this.updateOStats(true);

								matchesStore.setUnseenMsgsUser({ matchId, reset: false, count: '+1' });
							}
						}
					});
			}
			return false;
		},
		async updateOStats(force?: boolean) {
			const loginStore = useLoginStore();
			if (!loginStore.loggedIn) {
				return;
			}

			const timeAgo = new Date();
			timeAgo.setMinutes(timeAgo.getMinutes() - 5);

			const reloadTimePassed = !reloadOstats || new Date(reloadOstats) < timeAgo;

			if (reloadTimePassed || force) {
				reloadOstats = new Date();
				await this.$nuxt.$hokFetch('/app-api/notification/user-ostats?mode=user').then(ostats => {
					// for first load, check if there are unsaved jobs, if so, set updateUnseenSavedJobs to true
					const uiState = useUiStateStore();
					if (uiState.unseenSavedJobs === undefined && ostats.saved > 0) {
						uiState.updateUnseenSavedJobs(true);
					}
					this.saveOstats(ostats);
				});
			}
		},
		async sendMsg({
			conversationTypeId,
			conversationType,
			message,
			messagingTag
		}: {
			conversationTypeId: APITypeObjectId<APIObjectType.Match>;
			conversationType: number;
			message: string;
			messagingTag?: MessagingTags;
		}) {
			return this.$nuxt
				.$hokFetch('/app-api/messaging/message', {
					method: 'POST',
					body: {
						conversationTypeId,
						conversationType,
						sender: 'user',
						message,
						messagingTags: messagingTag ? [messagingTag] : undefined
					}
				})
				.then(messageResult => {
					this.pushMatchChatMessage({
						type: conversationType,
						typeId: conversationTypeId,
						message: messageResult
					});
				});
		},
		async getAttachmentUrl({
			conversationId,
			conversationType,
			conversationTypeId,
			attachmentIndex
		}: {
			conversationId: APITypeObjectId<APIObjectType.Conversation>;
			conversationTypeId: APITypeObjectId<APIObjectType.Match>;
			conversationType: number;
			attachmentIndex: number;
		}) {
			const res = await this.$nuxt.$hokFetch(
				`/app-api/messaging/attachment?conversationId=${conversationId}&conversationType=${conversationType}&conversationTypeId=${conversationTypeId}&attachmentIndex=${attachmentIndex}`
			);
			return res;
		},
		setChatMessages({
			type,
			typeId,
			messages
		}: {
			type: number;
			typeId: APITypeObjectId<APIObjectType.Match>;
			messages: IAPIConversation[];
		}) {
			const key = `${type}_${typeId}`;

			if (!this.conversations[key]) {
				this.conversations[key] = [];
			}
			messages.forEach(msg => {
				pushIfNotExist(this.conversations[key], msg, m => m && m._id === msg._id);
			});
		},
		loadChat({
			type,
			typeId,
			load
		}: {
			type: number;
			typeId: APITypeObjectId<APIObjectType.Match>;
			load: Promise<any> | undefined;
		}) {
			const key = `${type}_${typeId}`;
			this.loadedMatchChatMessages[key] = load;
		},
		pushMatchChatMessage({
			type,
			typeId,
			message
		}: {
			type: number;
			typeId: APITypeObjectId<APIObjectType.Match>;
			message: IAPIConversation;
		}) {
			const key = `${type}_${typeId}`;
			if (!this.conversations[key]) {
				this.conversations[key] = [];
			}
			pushIfNotExist(this.conversations[key], message, m => m && m._id === message._id);
		},
		saveOstats(update) {
			Object.keys(update).forEach(key => {
				this.ostats[key] = update[key];
			});
		},
		decreaseOstats({ ostatType, amount = 1 }) {
			if (ostatType === 'saved') {
				if (this.ostats.saved > 0) {
					this.ostats.saved -= amount;
				}
			} else if (ostatType === 'applied') {
				if (this.ostats.applied > 0) {
					this.ostats.applied -= amount;
				}
			} else if (ostatType === 'unseenMsgs') {
				if (this.ostats.unseenMsgs > 0) {
					this.ostats.unseenMsgs -= amount;
				}
			}
		},
		increaseOstats(ostatType) {
			if (ostatType === 'saved') {
				this.ostats.saved += 1;
			} else if (ostatType === 'applied') {
				this.ostats.applied += 1;
			} else if (ostatType === 'unseenMsgs') {
				this.ostats.unseenMsgs += 1;
			}
		},
		markMatchAsSeen(matchId) {
			// copy object
			const copy = {
				...this.ostats.matchesWithUnseenMsgs
			};

			Object.keys(copy).some(mId => {
				if (mId === matchId) {
					// delete property of internal
					delete copy[matchId];
					return true;
				}
				return false;
			});

			// set ostats to modified internal object, to beware reactivity
			this.ostats.matchesWithUnseenMsgs = copy;
		}
	}
});
