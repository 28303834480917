<template>
	<div class="flex flex-col">
		<Spinner v-if="loading"></Spinner>
		<ContentContainer size="small" class="px-4 mt-4 pb-24">
			<template v-if="type === 'contact'">
				<h2>Kontaktdaten</h2>
				<CvGeneralData
					ref="generaldata"
					:display-fields="['gender', 'firstname', 'lastname', 'phone', 'email']"
					autofocus="firstname"
					:verification-mode="true"
				/>
			</template>
			<template v-else>
				<h2>Persönliche Info</h2>
				<CvGeneralData
					ref="generaldata"
					:display-fields="['birthday', 'nationality', 'residence']"
				/>
			</template>
		</ContentContainer>
		<ContentContainer size="small" class="fixed bottom-0 fixed-center z-50 pointer-events-none">
			<ButtonRound :checkmark="true" @click="saveNow" />
		</ContentContainer>
	</div>
</template>

<script lang="ts">
import ContentContainer from '@hokify/shared-components-nuxt3/lib/components/ContentContainer.vue';
import ButtonRound from '@hokify/shared-components-nuxt3/lib/components/ButtonRound.vue';
import CvGeneralData from '@hokify/shared-components-nuxt3/lib/components/CvGeneralData.vue';
import { defineComponent } from 'vue';
import { EventBus } from '@hokify/shared-components-nuxt3/lib/eventbus';
import { definePageMeta, useSeoMeta } from '#imports';

function hasSave(generaldata: any): generaldata is { save(): Promise<boolean> } {
	return generaldata && typeof generaldata.save === 'function';
}

function hasIsSaveAlreadyCalled(obj: any): obj is { isSaveAlreadyCalled: boolean } {
	return obj && obj.isSaveAlreadyCalled !== undefined;
}

export default defineComponent({
	setup() {
		definePageMeta({
			layout: 'user',
			middleware: ['auth']
		});
		useSeoMeta({
			title: 'Allgemeine Daten bearbeiten | hokify'
		});
	},
	components: { ContentContainer, ButtonRound, CvGeneralData },
	data() {
		return {
			loading: false,
			EventBus
		};
	},
	mounted() {
		// reset goBackHandler
		this.$page.setGoBackHandlerForCurrentPage(() => false);
	},
	methods: {
		async saveNow() {
			try {
				this.loading = true;
				const done = hasSave(this.$refs.generaldata) && (await this.$refs.generaldata.save());
				if (done) {
					if (hasIsSaveAlreadyCalled(this.$refs.generaldata)) {
						this.$refs.generaldata.isSaveAlreadyCalled = true;
					}
					this.EventBus.$emit('end-go-to');
				}
			} catch (err: any) {
				this.$nuxt.$errorHandler(err);
			}
			this.loading = false;
		}
	},
	props: {
		type: { type: String, default: 'contact' }
	}
});
</script>
<style lang="scss" scoped>
.fixed-center {
	left: 50%;
	transform: translateX(-50%);
}
</style>
