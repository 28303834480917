<template>
	<div>
		<HokInput
			id="signup-username"
			v-model.trim="firstLogin"
			:autofocus="false"
			required
			:submit-button="submitButton"
			type="text"
			name="username"
			class="mb-4"
			question="Bitte gib deine Telefonnummer oder E-Mail-Adresse ein"
		>
			E-Mail
		</HokInput>
		<HokInput
			id="signup-user-firstname"
			v-model="firstname"
			:autofocus="false"
			required
			:submit-button="submitButton"
			type="text"
			name="firstname"
			class="mb-4"
			question="Wie lautet dein Vorname?"
		>
			Vorname
		</HokInput>
		<HokInput
			id="signup-user-lastname"
			v-model="lastname"
			:autofocus="false"
			required
			:submit-button="submitButton"
			type="text"
			name="lastname"
			class="mb-4"
			question="Wie lautet dein Familienname?"
		>
			Nachname
		</HokInput>
		<ErrorBox v-if="errorOnRequest" :fullwidth="false">
			<template v-if="errorOnRequest && errorOnRequest.title" #title>
				{{ errorOnRequest.title }}
			</template>
			<template v-if="errorOnRequest && errorOnRequest.text">
				{{ errorOnRequest.text }}
			</template>
		</ErrorBox>
		<ErrorBox v-if="error" :fullwidth="false">
			<template v-if="error && error.title" #title>
				{{ error.title }}
			</template>
			<template v-if="error && error.text">
				{{ error.text }}
			</template>
		</ErrorBox>
		<HokCheckbox
			v-if="privacy.includes('user_register')"
			id="privacy-user-register"
			v-model="user_register"
			required
			class="text-xs"
			name="privacy-user-register"
		>
			Hokify darf meine Daten speichern, um den vollen Funktionsumfang zu gewährleisten.
			<template #more>
				<HokLink to="/privacy#user-register" target="_blank" class="underline">
					Datenschutzerklärung &amp; Widerrufsrecht </HokLink
				><br />Mit dem Login werden die
				<HokLink to="/disclaimer" target="_blank" class="underline"> AGB </HokLink>
				akzeptiert.
			</template>
		</HokCheckbox>
		<HokCheckbox
			v-if="privacy.includes('user_digital_content')"
			id="privacy-user-digital-content"
			v-model="user_digital_content"
			required
			name="privacy-user-digital-content"
			class="text-xs max-w-md"
		>
			Um digitale Inhalte von hokify zu erhalten, bestätige ich, dass hokify meine Daten verarbeiten
			darf.
			<HokLink to="/privacy#company-digital-content" target="_blank" class="underline">
				Datenschutzerklärung &amp; Widerrufsrecht
			</HokLink>
		</HokCheckbox>
		<HokButton class="mb-4" color="main" fullwidth="always" @click="setupPayload">
			Account erstellen
		</HokButton>
		<HokButton outline class="mb-16" color="main" fullwidth="always" @click="$emit('go-back')">
			Zurück
		</HokButton>
	</div>
</template>

<script lang="ts">
import ErrorBox from '@hokify/shared-components-nuxt3/lib/components/ErrorBox.vue';
import HokCheckbox from '@hokify/shared-components-nuxt3/lib/components/HokCheckbox.vue';
import HokInput from '@hokify/shared-components-nuxt3/lib/components/HokInput.vue';
import { defineComponent } from 'vue';
import type { PropType } from 'vue';
import type { ILocalSignupParameters } from '../../../types/ilocal-signup-parameters';
import type { SupportedUserCase } from '../../../types/supported-user-case';
import type { ISignupPayload } from '../../../types/login';
import { LoginState } from '../../../types/enums';

export default defineComponent({
	name: 'HokSignupFormB2C',
	components: {
		ErrorBox,
		HokCheckbox,
		HokInput
	},
	emits: ['send', 'reset', 'go-back'],
	data() {
		const error = false as any;
		const {
			public: { development }
		} = useRuntimeConfig();

		return {
			error,
			firstLogin: this.currentLogin,
			firstname: '',
			lastname: '',
			user_register: false,
			user_digital_content: false,
			submitButton: { label: 'weiter' },
			development
		};
	},
	methods: {
		async setupPayload() {
			if (
				!this.firstLogin ||
				this.firstLogin.length === 0 ||
				this.firstname.length === 0 ||
				this.lastname.length === 0
			) {
				this.error = {
					title: 'Name überprüfen',
					text: 'Bitte überprüfe deinen Vorname und deinen Nachname auf Vollständigkeit!'
				};
			} else if (this.privacy.includes('user_register') && !this.user_register) {
				this.error = {
					title: 'Zustimmung notwendig',
					text: 'Bitte Datenschutz akzeptieren um fortzufahren.'
				};
			} else {
				this.error = false;

				const data: Omit<ILocalSignupParameters, 'recaptchaToken' | 'recaptchaVersion'> = {
					email: this.firstLogin,
					firstname: this.firstname,
					lastname: this.lastname,
					usercase: (this.usercase as SupportedUserCase) || undefined
				};
				if (this.privacy.includes('user_register')) {
					data.privacy_user_register = this.user_register as any;
				}
				if (this.privacy.includes('user_digital_content')) {
					data.privacy_user_digital_content = this.user_digital_content as any;
				}
				const payload: ISignupPayload = {
					state: LoginState.signup,
					data
				};
				if (this.development) {
					console.log('PAYLOAD', payload);
				}
				this.$nextTick(() => {
					this.$emit('send', payload);
				});
			}
		}
	},
	props: {
		currentLogin: { type: String, required: true },
		privacy: {
			type: Array,
			default: () => {}
		},
		usercase: { type: String, default: () => undefined },
		errorOnRequest: {
			type: Object as PropType<{ title?: string; text?: string; code?: string }>,
			default: () => {}
		}
	}
});
</script>
