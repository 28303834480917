import { IUserPrivacy } from '@hokify/common';

// we used to fetch these cases also during signup. added this helper to ensure backwards compatibility.
export const getRequiredPrivacy = (
	userCase?: string,
	companyCase?: string
): (keyof IUserPrivacy)[] => {
	const requiredPrivacy: (keyof IUserPrivacy)[] = [];
	if (companyCase) {
		switch (companyCase) {
			case 'digital-content':
				requiredPrivacy.push('company_digital_content');
				break;
			case 'moreinfo':
				break;
			case 'partner':
			case 'anonym':
			case 'mon':
			case 'optin':
			case 'optout':
			case 'tup':
			case 'freeapponly':
			case 'web-landing':
			case 'b2b-lehrling':
			case 'b2b-socialmedia':
			case 'b2b-headhunter':
			case 'b2b-referral':
			case 'testweb':
			case 'testwebadwords':
			case 'testwebfacebook':
			case 'b2b':
			case 'pwa':
			case 'demo-package':
			default:
				requiredPrivacy.push('company_register');
		}
	} else {
		// if no company case, we have a user case
		switch (userCase) {
			case 'gnc':
				requiredPrivacy.push('user_digital_content');
				break;
			default:
				requiredPrivacy.push('user_register');
		}
	}

	return requiredPrivacy;
};
