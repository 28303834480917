<template>
	<div class="bg-color-white rounded-md">
		<HeaderOnboarding v-if="!isModal && showHeader">
			<div class="pt-5">
				<div class="text-color-white text-xs mt-2">Schneller zum neuen Job</div>
			</div>
		</HeaderOnboarding>

		<ContentContainer>
			<div class="text-center" :class="{ '-mt-20': showHeader }">
				<template v-if="socialLogin === 'fb'">
					<img src="../../assets/svg/onboarding/fbloginsuccess.svg" class="socialloginsuccess" />
					<h2>Facebook Login erfolgreich!</h2>
				</template>
				<template v-else-if="socialLogin === 'google'">
					<img
						src="../../assets/svg/onboarding/googleloginsuccess.svg"
						class="socialloginsuccess"
					/>
					<h2>Google Login erfolgreich!</h2>
				</template>
				<template v-else-if="socialLogin === 'linkedIn'">
					<img
						src="../../assets/svg/onboarding/linkedinloginsuccess.svg"
						class="socialloginsuccess"
					/>
					<h2>Linkedin Login erfolgreich!</h2>
				</template>
				<template v-else-if="socialLogin === 'apple'">
					<img src="../../assets/svg/onboarding/appleloginsuccess.svg" class="socialloginsuccess" />
					<h2>Apple Login erfolgreich!</h2>
				</template>
				<template v-else> Unknown Login State? </template>
				<p>
					Bitte {{ companyMode ? 'vergewissern Sie sich' : 'vergewissere Dich' }}, dass folgende
					Daten korrekt sind:
				</p>
			</div>
			<div class="flex flex-col items-center">
				<CvGeneralData
					:display-fields="['firstname', 'lastname', 'email']"
					class="mb-5"
					autofocus="firstname"
				/>
				<HokButton
					:color="companyMode ? 'business' : 'main'"
					fullwidth="mobile"
					class="mb-5"
					@click="$emit('done')"
				>
					Daten speichern und fortfahren
				</HokButton>
			</div>
		</ContentContainer>
	</div>
</template>
<script lang="ts">
import ContentContainer from '@hokify/shared-components-nuxt3/lib/components/ContentContainer.vue';

import CvGeneralData from '@hokify/shared-components-nuxt3/lib/components/CvGeneralData.vue';
import HeaderOnboarding from '@hokify/shared-components-nuxt3/lib/components/HeaderOnboarding.vue';
import { defineComponent } from 'vue';

export default defineComponent({
	name: 'StageSocialMediaData',
	components: {
		ContentContainer,
		CvGeneralData,
		HeaderOnboarding
	},
	props: {
		socialLogin: { type: String, required: true },
		isModal: { type: Boolean, default: false },
		showHeader: { type: Boolean, default: true },
		companyMode: { type: Boolean, default: false }
	},
	emits: ['done']
});
</script>
<style scoped lang="scss">
/* eslint-disable vue-scoped-css/no-unused-selector */
.socialloginsuccess {
	height: 150px;
	margin: auto;
}

.logo {
	display: block;
	height: 40px;
	margin: 0 auto;
	width: auto;
}

.label {
	font-size: $font-sm;
	padding-top: 1.25rem;
	color: #596d83;
	display: block;
	text-align: left;
	width: 100%;
}

input {
	width: 100%;
	padding: 0.5rem 0 0.75rem;
	font-size: $font-lg;
	color: #0fb1af;
	border-bottom: 2px solid $color-grey-light;
	&:focus {
		border-bottom: 2px solid #0fb1af;
	}
}
</style>
