<template>
	<div
		v-show="showMonthView"
		:class="[calendarClass, 'vdp-datepicker__calendar']"
		:style="calendarStyle"
		@mousedown.prevent
	>
		<slot name="beforeCalendarHeader"></slot>
		<header>
			<span :class="{ disabled: isLeftNavDisabled }" class="prev" @click="previousYear()"
				>&lt;</span
			>
			<span
				:class="allowedToShowView('year') ? 'up' : ''"
				class="month__year_btn"
				@click="showYearCalendar"
				>{{ pageYearName }}</span
			>
			<span :class="{ disabled: isRightNavDisabled }" class="next" @click="nextYear()">&gt;</span>
		</header>
		<span
			v-for="month in months"
			:key="month.timestamp"
			:class="{ selected: month.isSelected, disabled: month.isDisabled }"
			class="cell month"
			@click.stop="selectMonth(month)"
			>{{ month.month }}</span
		>
	</div>
</template>
<script>
import { makeDateUtils, langYearSuffix } from './DateUtils';

export default {
	props: {
		showMonthView: Boolean,
		selectedDate: Date,
		pageDate: Date,
		pageTimestamp: Number,
		disabledDates: Object,
		calendarClass: [String, Object, Array],
		calendarStyle: Object,
		language: String,
		allowedToShowView: Function,
		useUtc: Boolean
	},
	emits: ['select-month', 'changed-year', 'show-year-calendar'],
	data() {
		const constructedDateUtils = makeDateUtils(this.useUtc, this.language);
		return {
			utils: constructedDateUtils
		};
	},
	watch: {
		language(newLanguage) {
			this.utils = makeDateUtils(this.useUtc, newLanguage);
		},
		useUtc(newUtc) {
			this.utils = makeDateUtils(newUtc, this.language);
		}
	},
	computed: {
		months() {
			const d = this.pageDate;
			const months = [];
			// set up a new date object to the beginning of the current 'page'
			const dObj = this.useUtc
				? new Date(Date.UTC(d.getUTCFullYear(), 0, d.getUTCDate()))
				: new Date(d.getFullYear(), 0, d.getDate(), d.getHours(), d.getMinutes());
			for (let i = 0; i < 12; i++) {
				months.push({
					month: this.utils.getMonthName(dObj), // , i, this.language.months),
					timestamp: dObj.getTime(),
					isSelected: this.isSelectedMonth(dObj),
					isDisabled: this.isDisabledMonth(dObj)
				});
				this.utils.setMonth(dObj, this.utils.getMonth(dObj) + 1);
			}
			return months;
		},
		/**
		 * Get year name on current page.
		 *
		 * @returns {String}
		 */
		pageYearName() {
			const yearSuffix = langYearSuffix[this.language] || '';
			return `${this.utils.getFullYear(this.pageDate)}${yearSuffix}`;
		},
		/**
		 * Is the left hand navigation disabled
		 *
		 * @returns {Boolean}
		 */
		isLeftNavDisabled() {
			return this.isPreviousYearDisabled(this.pageTimestamp);
		},
		/**
		 * Is the right hand navigation disabled
		 *
		 * @returns {Boolean}
		 */
		isRightNavDisabled() {
			return this.isNextYearDisabled(this.pageTimestamp);
		}
	},
	methods: {
		/**
		 * Emits a selectMonth event
		 *
		 * @param {Object} month
		 */
		// eslint-disable-next-line consistent-return
		selectMonth(month) {
			if (month.isDisabled) {
				return false;
			}
			this.$emit('select-month', month);
		},
		/**
		 * Changes the year up or down
		 *
		 * @param {Number} incrementBy
		 */
		changeYear(incrementBy) {
			const date = this.pageDate;
			this.utils.setFullYear(date, this.utils.getFullYear(date) + incrementBy);
			this.$emit('changed-year', date);
		},
		/**
		 * Decrements the year
		 */
		previousYear() {
			if (!this.isPreviousYearDisabled()) {
				this.changeYear(-1);
			}
		},
		/**
		 * Checks if the previous year is disabled or not
		 *
		 * @returns {Boolean}
		 */
		isPreviousYearDisabled() {
			if (!this.disabledDates || !this.disabledDates.to) {
				return false;
			}
			return this.utils.getFullYear(this.disabledDates.to) >= this.utils.getFullYear(this.pageDate);
		},
		/**
		 * Increments the year
		 */
		nextYear() {
			if (!this.isNextYearDisabled()) {
				this.changeYear(1);
			}
		},
		/**
		 * Checks if the next year is disabled or not
		 *
		 * @returns {Boolean}
		 */
		isNextYearDisabled() {
			if (!this.disabledDates || !this.disabledDates.from) {
				return false;
			}
			return (
				this.utils.getFullYear(this.disabledDates.from) <= this.utils.getFullYear(this.pageDate)
			);
		},
		/**
		 * Emits an event that shows the year calendar
		 */
		showYearCalendar() {
			this.$emit('show-year-calendar');
		},
		/**
		 * Whether the selected date is in this month
		 *
		 * @param {Date}
		 * @returns {Boolean}
		 */
		isSelectedMonth(date) {
			return (
				this.selectedDate &&
				this.utils.getFullYear(this.selectedDate) === this.utils.getFullYear(date) &&
				this.utils.getMonth(this.selectedDate) === this.utils.getMonth(date)
			);
		},
		/**
		 * Whether a month is disabled
		 *
		 * @param {Date}
		 * @returns {Boolean}
		 */
		isDisabledMonth(date) {
			let disabledDates = false;

			if (typeof this.disabledDates === 'undefined') {
				return false;
			}

			if (typeof this.disabledDates.to !== 'undefined' && this.disabledDates.to) {
				if (
					(this.utils.getMonth(date) < this.utils.getMonth(this.disabledDates.to) &&
						this.utils.getFullYear(date) <= this.utils.getFullYear(this.disabledDates.to)) ||
					this.utils.getFullYear(date) < this.utils.getFullYear(this.disabledDates.to)
				) {
					disabledDates = true;
				}
			}
			if (typeof this.disabledDates.from !== 'undefined' && this.disabledDates.from) {
				if (
					(this.utils.getMonth(date) > this.utils.getMonth(this.disabledDates.from) &&
						this.utils.getFullYear(date) >= this.utils.getFullYear(this.disabledDates.from)) ||
					this.utils.getFullYear(date) > this.utils.getFullYear(this.disabledDates.from)
				) {
					disabledDates = true;
				}
			}

			if (
				typeof this.disabledDates.customPredictor === 'function' &&
				this.disabledDates.customPredictor(date)
			) {
				disabledDates = true;
			}
			return disabledDates;
		}
	}
};
// eslint-disable-next-line
</script>
