<template>
	<div class="mt-4 pb-8">
		<div
			v-for="service in thirdParty"
			:key="service.id"
			class="border border-color-grey-light px-4 py-2 mb-4"
		>
			<div
				:ref="service.id"
				class="flex justify-between items-center cursor-pointer"
				@click="toggleItem(service.id)"
			>
				<h2 class="text-color-text text-base font-bold mb-0 inline">
					{{ service.title }}
				</h2>
				<HokIcon
					:class="{ 'rotate-90': trackerCategories[service.id] }"
					:size="5"
					color="main"
					name="icon:arrow-right"
					class="transition-transform duration-300"
				/>
			</div>
			<TransitionExpand>
				<div v-if="trackerCategories[service.id]" class="mt-4">
					<h3 class="text-color-text text-sm font-bold mb-0 inline">Kategorie:</h3>
					<p class="text-xs mb-2">{{ service.category }}</p>
					<h3 class="text-color-text text-sm font-bold mb-0 inline">Beschreibung:</h3>
					<p class="text-xs mb-2">{{ service.description }}</p>
					<h3 class="text-color-text text-sm font-bold mb-0 inline">Drittanbieter Name:</h3>
					<p class="text-xs mb-2">{{ service.fullName }}</p>
					<h3 class="text-color-text text-sm font-bold mb-0 inline">Drittanbieter Adresse:</h3>
					<p class="text-xs mb-2">{{ service.address }}</p>
					<h3 class="text-color-text text-sm font-bold mb-0 inline">Datenschutzerklärung:</h3>
					<p class="text-xs mb-2">
						<a :href="service.url" target="_blank">{{ service.url }}</a>
					</p>
					<h3 class="text-color-text text-sm font-bold mb-0 inline">Zweck:</h3>
					<p class="text-xs mb-2">{{ service.purpose }}</p>
					<h3 class="text-color-text text-sm font-bold mb-0 inline">Gesammelte Daten:</h3>
					<p class="text-xs mb-2">{{ service.savedData }}</p>
					<h3 class="text-color-text text-sm font-bold mb-0 inline">Rechtliche Grundlage:</h3>
					<p class="text-xs mb-2">{{ service.legal }}</p>
					<h3 class="text-color-text text-sm font-bold mb-0 inline">Weitergabe an Drittländer:</h3>
					<p class="text-xs mb-2">{{ service.disclosure }}</p>
				</div>
			</TransitionExpand>
		</div>
	</div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import ThirdPartyJson from '../../../assets/json/thirdParty.json'; // json isn't available in /lib
import TransitionExpand from '../../components/TransitionExpand.vue';

export default defineComponent({
	name: 'CookieBannerInformation',
	components: { TransitionExpand },
	data() {
		const trackerCategories: {
			gm: boolean;
			gtm: boolean;
			recaptcha: boolean;
			hubspot: boolean;
			youtube: boolean;
			gadv: boolean;
			tiktok: boolean;
			snap: boolean;
			linkedin: boolean;
			bing: boolean;
			reddit: boolean;
			ga: boolean;
			hotj: boolean;
			adjust: boolean;
			criteo: boolean;
			rtbhouse: boolean;
		} = {
			gm: false,
			gtm: false,
			recaptcha: false,
			hubspot: false,
			youtube: false,
			gadv: false,
			tiktok: false,
			snap: false,
			linkedin: false,
			bing: false,
			reddit: false,
			ga: false,
			hotj: false,
			adjust: false,
			criteo: false,
			rtbhouse: false
		};

		const thirdParty: {
			id: string;
			title: string;
			category: string;
			description: string;
			fullName: string;
			address: string;
			url: string;
			purpose: string;
			savedData: string;
			legal: string;
			disclosure: string;
		}[] = ThirdPartyJson;

		return {
			thirdParty,
			trackerCategories
		};
	},
	methods: {
		toggleItem(id: string) {
			this.thirdParty.some(service => {
				if (service.id === id) {
					this.trackerCategories[id] = !this.trackerCategories[id];
					return true;
				}
				return false;
			});
		}
	}
});
</script>
