<template>
	<ReCaptchaWidget
		v-if="reCaptchaRequested"
		ref="recaptcha-widget"
		:show-modal="showReCaptchaModal"
		@ready="reCaptchaWidgetReady"
	/>
</template>
<script lang="ts">
import { defineComponent } from 'vue';
import { EventBus } from '../../eventbus';
import ReCaptchaWidget from './ReCaptchaWidget.vue';

export default defineComponent({
	components: {
		ReCaptchaWidget
	},
	data() {
		const showReCaptchaModal = false;
		const reCaptchaRequested = false;
		const reCaptchaMountedPromiseResolve = undefined as undefined | ((value?: unknown) => void);

		return { showReCaptchaModal, reCaptchaRequested, reCaptchaMountedPromiseResolve };
	},
	methods: {
		async sendWithRecaptchaToken<M extends (data) => any>({
			method,
			data,
			action,
			useRecaptchaV2
		}: {
			method: M;
			data: Omit<Parameters<M>[0], 'recaptchaToken' | 'recaptchaVersion'>;
			action: 'signup' | 'resetPassword' | 'setPassword' | 'verificationCode' | 'complaintForm';
			useRecaptchaV2?: boolean;
		}): Promise<void> {
			await this.initializeRecaptchaWidget();

			this.showReCaptchaModal = true;
			const recaptchaResult = await (this.$refs['recaptcha-widget'] as any).getRecaptchaToken({
				action,
				useRecaptchaV2
			});

			try {
				if (!(data instanceof FormData)) {
					data = {
						...data,
						...recaptchaResult
					};
				} else {
					data.set('recaptchaVersion', recaptchaResult.recaptchaVersion);
					data.set('recaptchaToken', recaptchaResult.recaptchaToken);
				}

				const result = await method(data);
				this.showReCaptchaModal = false;

				EventBus.$emit('returnRecaptchaMethodCall', { result });
			} catch (err: any) {
				if (
					(err?.statusCode === 400 ||
						err?.status === 400 ||
						err?.data?.statusCode === 400 ||
						err?.code === 400) &&
					(err?.data?.code === 'RECAPTCHA_FAILED' || err?.data?.code === 'INVALID_CAPTCHA_REQUEST')
				) {
					await this.sendWithRecaptchaToken({
						method,
						data,
						action,
						useRecaptchaV2: true
					});
				} else {
					EventBus.$emit('returnRecaptchaMethodCall', { err });
					this.showReCaptchaModal = false;
				}
			}
		},

		async initializeRecaptchaWidget() {
			this.reCaptchaRequested = true;
			if (this.$refs['recaptcha-widget']) {
				return Promise.resolve();
			}

			return new Promise(resolve => {
				this.reCaptchaMountedPromiseResolve = resolve;
			});
		},

		async reCaptchaWidgetReady() {
			await this.reCaptchaMountedPromiseResolve?.();
			this.reCaptchaMountedPromiseResolve = undefined;
		}
	},

	mounted() {
		EventBus.$on('sendWithRecaptchaToken', this.sendWithRecaptchaToken);
		EventBus.$on('initializeRecaptchaWidget', this.initializeRecaptchaWidget);

		EventBus.$emit('reCaptchaWidgetContainerReady');
	},

	beforeMount() {},

	beforeUnmount() {
		EventBus.$off('sendWithRecaptchaToken', this.sendWithRecaptchaToken);
		EventBus.$off('initializeRecaptchaWidget', this.initializeRecaptchaWidget);
	}
});
</script>
