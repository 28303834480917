import { defineStore } from 'pinia';
import { APIObjectType, APITypeObjectId } from '@hokify/common';
import { useUserProfileStore } from './user-profile';

export const useUserProfileExperienceStore = defineStore('userProfileExperience', {
	state: () => ({}),
	actions: {
		async delExperience({
			experienceId,
			force,
			isAIGenerated
		}: {
			experienceId?: APITypeObjectId<APIObjectType.UserExperience>;
			force?: boolean;
			isAIGenerated?: boolean;
		}) {
			if (experienceId && !experienceId.startsWith('experience-new')) {
				try {
					const params = new URLSearchParams();
					if (force !== undefined) {
						params.set('force', force.toString());
					}
					if (isAIGenerated !== undefined) {
						params.set('isAIGenerated', isAIGenerated.toString());
					}

					const res = await this.$nuxt.$hokFetch(
						`/app-api/jobseeker/cv/experience/${experienceId}?${params.toString()}`,
						{ method: 'DELETE' }
					);

					if (res?.userUpdate) {
						const userProfileStore = useUserProfileStore();
						userProfileStore.updateElements(res.userUpdate);
					}
					return res;
				} catch (error: any) {
					console.error('delExperience failed', error);
					throw (error.response && error.response.data) || error.response || error;
				}
			}
		},
		async syncExperienceToDb(
			experience: {
				_id?: APITypeObjectId<APIObjectType.UserExperience>;
				title?: string;
				summary?: string;
				company?: string;
				fields?: APITypeObjectId<APIObjectType.JobField>[];
				startDate?: Date;
				endDate?: Date;
				isCurrent?: boolean;
				isVisible?: boolean;
				selectedAsHighlight?: boolean;
			} & { isAIGenerated?: boolean }
		) {
			try {
				const serverObj: {
					_id?: APITypeObjectId<APIObjectType.UserExperience>;
					title?: string;
					summary?: string;
					company?: string;
					fields?: APITypeObjectId<APIObjectType.JobField>[];
					startDate?: Date;
					endDate?: Date;
					isCurrent?: boolean;
					isAIGenerated?: boolean;
				} = { ...experience };

				const res = await this.$nuxt.$hokFetch('/app-api/jobseeker/cv/experience', {
					method: 'POST',
					body: serverObj
				});

				if (res?.userUpdate) {
					const userProfileStore = useUserProfileStore();
					userProfileStore.updateElements(res.userUpdate);
					userProfileStore.sortCV('experience');
				}
				return res;
			} catch (error: any) {
				console.error('syncExperienceToDb failed', error);
				throw (error.response && error.response.data) || error.response || error;
			}
		},
		async triggerExperienceSummarySuggestion(experience: {
			title?: string;
			company?: string;
			fields?: APITypeObjectId<APIObjectType.JobField>[];
		}) {
			try {
				await this.$nuxt.$hokFetch('/app-api/jobseeker/cv/experience-summary-suggestion', {
					method: 'POST',
					body: experience
				});
			} catch (error: any) {
				console.error('triggerExperienceSummarySuggestion failed', error);
				throw error.response?.data || error.response || error;
			}
		},
		async highlightExperience({ experienceId, highlight }) {
			try {
				const res = await this.$nuxt.$hokFetch('/app-api/jobseeker/cv/experience-highlight', {
					method: 'PUT',
					body: {
						experienceHighlightId: experienceId,
						highlight
					}
				});
				if (res?.userUpdate) {
					const userProfileStore = useUserProfileStore();
					userProfileStore.updateElements(res.userUpdate);
				}
				return res;
			} catch (error: any) {
				console.error('highlight experience failed', error);
				throw (error.response && error.response.data) || error.response || error;
			}
		},
		async setHasNoExperience() {
			try {
				const res = await this.$nuxt.$hokFetch('/app-api/jobseeker/cv/has-no-experience', {
					method: 'PUT'
				});
				if (res?.userUpdate) {
					const userProfileStore = useUserProfileStore();
					userProfileStore.updateElements(res.userUpdate);
				}
				return res;
			} catch (error: any) {
				console.error('setting has-no-experience failed', error);
				throw (error.response && error.response.data) || error.response || error;
			}
		}
	}
});
