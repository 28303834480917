// import { EventBus } from '@hokify/shared-components-nuxt3/lib/eventbus';

import { IUserAppRuntimeConfig } from '@hokify/nuxt-injected-interface';

const googleSignInParams = {
	client_id: '987216278712-0i170uefrjffcqnheunfjol60fa8rpn0.apps.googleusercontent.com',
	scope: 'openid email profile'
};

declare global {
	interface ICordovaPlugins {
		googleplus: any;
	}

	interface Window {
		google: any;
		googleAuthLoaded: any;
		GoogleAuth: any;

		// cordova plugin
		plugins?: ICordovaPlugins;
	}
}

let apiLoaded = false;

/**
 * A wrapper function which ensures that the google Client Library is loaded and then gives access
 * to the SDK
 *
 * @param cb A callback function that is being executed once the google Client Library has been
 *   loaded successfully
 */
export const initGoogleSDK = (cb?) => {
	if (
		(process as any).server || // only on client
		apiLoaded || // already intialized
		process.env.cordova //   not needed because we are a cordova build
	) {
		console.info('initGoogleSDK already called');
		if (cb) {
			cb(window.google);
		}
		return;
	}

	if (!apiLoaded) {
		// function that is called once script has been loaded
		window.googleAuthLoaded = () => {
			apiLoaded = true;
			if (cb) {
				cb(window.google);
			}
		};
		// attach Google Identity Service API script to DOM
		const script = window.document.createElement('script');
		script.src = 'https://accounts.google.com/gsi/client';
		script.async = true;
		script.defer = true;
		script.onload = window.googleAuthLoaded;
		window.document.head.appendChild(script);
	}
};

export const GoogleLogin = async (env: IUserAppRuntimeConfig['public']['env']) =>
	new Promise((resolve, reject) => {
		// TODO GexxOn: find replacement/solution for cordova plugin
		if (window.plugins?.googleplus) {
			if (env !== 'production') {
				console.log('GOOGLE SIGNIN PARAMS', googleSignInParams);
			}
			window.plugins.googleplus.login(
				{
					scopes: googleSignInParams.scope, // optional, space-separated list of scopes, If not included or empty, defaults to `profile` and `email`.
					webClientId: googleSignInParams.client_id // optional clientId of your Web application from Credentials settings of your project - On Android, this MUST be included to get an idToken. On iOS, it is not required.
					// offline: true // optional, but requires the webClientId - if set to true the plugin will also return a serverAuthCode, which can be used to grant offline access to a non-Google server
				},
				result => {
					if (env !== 'production') {
						console.log('GOOGLE LOGIN DEBUG INFO', result);
					}
					resolve(result);
				},
				error => {
					reject(error);
				}
			);
		} else {
			/**
			 * We use the initCodeClient method of the Google 3P Authorization to load authorization codes
			 * Reference:
			 * https://developers.google.com/identity/oauth2/web/reference/js-reference#google.accounts.oauth2.initCodeClient
			 * Additional Infos for Nuxt 3:
			 * https://github.com/yobaji/vue3-google-login/blob/master/src/plugin/utils.ts#L38
			 */
			initGoogleSDK(google => {
				google.accounts.oauth2
					.initCodeClient({
						client_id: googleSignInParams.client_id,
						scope: googleSignInParams.scope,
						ux_mode: 'popup',
						error_callback: response => {
							reject(response.type);
						},
						callback: response => {
							if (response.code) {
								// EventBus.$emit('google-sdk-ready');
								resolve(response);
							} else {
								reject(response);
							}
						}
					})
					.requestCode();
			});
		}
	});
