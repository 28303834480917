<template>
	<div>
		<span @click="doLogout">
			<slot />
		</span>
		<HokModal name="confirmLogout" :width="$isMobile.any ? '95%' : '350px'" :adaptive="true">
			<div v-if="user" class="text-center">
				<h2>Abmelden</h2>
				<p>Möchtest du dich wirklich abmelden?</p>
				<HokButton fullwidth="mobile" color="main" @click="confirmLogout"> Abmelden </HokButton>
			</div>
			<div v-else class="p-4 pt-8">
				Nicht eingeloggt.
				<nuxt-link to="/pwa/login"> Zum login </nuxt-link>
			</div>
		</HokModal>
	</div>
</template>
<script lang="ts">
import type { IAPILoginUser } from '@hokify/common';
import { PropType, defineComponent } from 'vue';

export default defineComponent({
	name: 'Logout',
	methods: {
		doLogout() {
			this.$modal.show('confirmLogout');
		},
		async confirmLogout() {
			this.$router.push('/pwa/logout');
		}
	},
	props: {
		user: { type: Object as PropType<IAPILoginUser>, default: () => {} }
	}
});
</script>
