<template>
	<!-- split mobile app and "normal" app behaviour even though they might be combined -->
	<!-- this makes sense to make changes in the future simpler and the behaviour more explicit -->
	<nuxt-link
		v-if="isCordova()"
		:id="id"
		:to="computedLink"
		:target="isExternalLink ? '_blank' : target"
		:name="name"
		:rel="computedRel"
		:class="linkClasses"
		:aria-label="ariaLabel"
		v-bind="$attrs"
		@click="startInAppBrowser($event)"
	>
		<slot />
	</nuxt-link>
	<!-- keep implicit target behaviour for now to avoid breaking changes -->
	<nuxt-link
		v-else
		:id="id"
		:to="to"
		:target="isExternalLink ? '_blank' : target"
		:name="name"
		:rel="computedRel"
		:class="linkClasses"
		:aria-label="ariaLabel"
		v-bind="$attrs"
	>
		<slot />
	</nuxt-link>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { IAPILoginUser } from '@hokify/common';
import { externalLinkHelper } from '../helpers/externalLink';
import { nativeLinkOpener } from '../helpers/nativeLinkOpener';

export default defineComponent({
	name: 'HokLink',
	data() {
		return {
			env: useRuntimeConfig().public.env
		};
	},
	computed: {
		topLevelDomain(): string | undefined {
			return this.$nuxt?.$userRootStore?.topLevelDomain;
		},
		user(): IAPILoginUser | undefined {
			return this.$nuxt?.$userProfileStore?.obj;
		},
		domainRegion(): string {
			const validRegions = ['at', 'de', 'ch'];

			// 1st: use top level domain from store
			if (this.topLevelDomain && validRegions.includes(this.topLevelDomain)) {
				return this.topLevelDomain;
			}

			// 2nd: use region from user obj
			const userObj = this.user;
			if (userObj) {
				const countryCode =
					userObj.general?.address?.countryCode?.toLowerCase() ||
					userObj.internal?.region?.toLowerCase();

				if (validRegions.includes(countryCode)) {
					return countryCode;
				}
			}

			// 3rd: fallback to .com
			return 'com';
		},
		isExternalLink(): boolean {
			if (this.linkType === 'external') {
				return true;
			}
			if (this.linkType === 'internal') {
				return false;
			}

			if (process.server) {
				return false;
			}

			const matchedRoute = this.$router.resolve(this.to);

			// avoid matching /company as a jobNr
			return !matchedRoute.matched?.length || matchedRoute.params?.jobNr === 'company';
		},
		externalLink(): string | undefined {
			return externalLinkHelper(this.env, this.to, this.target, this.domainRegion);
		},
		linkClasses(): string[] {
			switch (this.styling) {
				case 'typeform':
					return ['inline'];
				case 'inline-block':
					return ['inline-block'];
				default:
					return ['inline-flex'];
			}
		},
		computedRel(): string {
			if (this.isExternalLink) {
				const isAdjustLink = this.to.startsWith('https://67ra.adj.st');
				if (isAdjustLink) {
					return 'noopener noreferrer nofollow';
				}
				return 'noopener noreferrer';
			}
			return '';
		},
		computedLink(): string {
			if (this.isExternalLink) {
				return this.externalLink ?? this.to;
			}
			return this.to;
		}
	},
	methods: {
		isCordova(): boolean {
			return !!((process.client && window.location.protocol === 'file:') || process.env.cordova);
		},
		startInAppBrowser($event: Event) {
			if (!this.isExternalLink) {
				return;
			}

			// only on cordova
			if (this.isCordova()) {
				nativeLinkOpener(this.computedLink, '_blank', $event, 'location=yes');
			}
		}
	},
	props: {
		to: { type: String, required: true },
		id: { type: String },
		name: { type: String },
		linkType: { type: String },
		target: { type: String, default: '_self' },
		ariaLabel: { type: String },
		styling: {
			type: String,
			default: undefined,
			validator: (value: string) => ['inline-block', 'typeform'].includes(value)
		}
	}
});
</script>
