<template>
	<main class="profile-page">
		<div class="tabs">
			<Tabs
				:options="{ defaultTabHash: showTab }"
				:align-center="true"
				class="fullwidth3tabsonphone"
				name="profile"
				@changed="selectTab"
			>
				<Tab id="profile" key="profile" name="Profil" url="/pwa/profile">
					<SneakPreview
						v-if="user"
						mode="user"
						:saved-jobs="savedJobsCount"
						:job-alarms="jobAlarmsCount"
						:applied-jobs="appliedJobsCount"
						:articles="profileArticles"
						:unseen-msgs="unseenMessagesCount"
						:quiz="randomQuiz"
						:job-suggestions="jobSuggestions"
						:loading="loading"
						:store-user="user"
						@change-or-create-cv="changeOrCreateCv"
						@show-edit-mode="showEditMode($event)"
						@open-quiz="openQuiz"
						@open-gnc="openGrossNetCalculator"
						@toggle-active-sourcing="setupActiveSourcing($event)"
						@go-to-article="goToArticle"
						@edit-experience="editExperience($event)"
					/>
				</Tab>
				<Tab id="settings" key="settings" name="Einstellungen" url="/pwa/profile/settings">
					<Settings :scroll-to-job-alarm-interval="scrollToJobAlarmInterval" />
				</Tab>
				<Tab id="magazine" key="magazine" name="Karriere-Tipps" url="/pwa/profile/magazine">
					<div class="container lg:max-w-5xl px-5 pt-4">
						<h1 class="text-color-main">Die hokify Karriere-Tipps</h1>
						<p class="mb-6">
							Hier findest du Tipps für deine erfolgreiche Jobsuche, Bewerbung & Ausbildung, sowie
							hilfreiche Artikel<br v-if="!$isMobile.phone" />
							in Sachen Gehalt, Urlaub und Krankenstand.
						</p>
						<HokSelect
							:value-promise="categoryPromise"
							:value="currentCategoryFilter"
							label="Kategorie"
							choose-text="Kategorie auswählen..."
							class="mb-5"
							@input="val => setFilter(val.id, val.value)"
						/>
					</div>
					<Magazine
						v-if="articles"
						:articles="articles"
						:user-firstname="user && user.general && user.general.firstName"
						class="pb-4"
						@go-to-article="goToArticle"
						@error="error"
					/>
				</Tab>
			</Tabs>
		</div>
		<AskPrivacyForActiveSourcing :user="user" @done="setupActiveSourcing(true)" />
	</main>
</template>

<script lang="ts">
import Tabs from '@hokify/shared-components-nuxt3/lib/components/Tabs.vue';
import Tab from '@hokify/shared-components-nuxt3/lib/components/Tab.vue';
import Magazine from '@hokify/shared-components-nuxt3/lib/components/Magazine.vue';
import HokSelect from '@hokify/shared-components-nuxt3/lib/components/HokSelect.vue';
import SneakPreview from '@hokify/pwa-core-nuxt3/components/hokifycv/SneakPreview.vue';
import type {
	IAPILoginUser,
	IAPIJobAlarmSubscription,
	IAPIMatchForJobSeekerListEntry
} from '@hokify/common';
import type { IAPIArticleTeaser } from '@hokify/magazine-interface';
import { EventBus } from '@hokify/shared-components-nuxt3/lib/eventbus';
import { mapStores } from 'pinia';
import { markRaw, defineAsyncComponent } from 'vue';
import AskPrivacyForActiveSourcing from '@/components/AskPrivacyForActiveSourcing.vue';
import Settings from '@/components/user/profile/Settings.vue';
import { useArticlesStore } from '@/stores/articles';
import { useUserProfileStore } from '@/stores/user-profile';
import { useMessagesStore } from '@/stores/messages';
import { useQuizStore } from '@/stores/quiz';
import { useRelationsStore } from '@/stores/relations';
import { useUserJobAlarmStore } from '@/stores/user-job-alarm';
import { useMatchesStore } from '@/stores/matches';
import { defineNuxtComponent, definePageMeta, useRoute, useSeoMeta } from '#imports';

export default defineNuxtComponent({
	setup() {
		definePageMeta({
			layout: 'user',
			path: '/pwa/profile/:tab?',
			middleware: ['auth']
		});

		useSeoMeta({ title: 'Mein Profil | hokify' });
	},
	components: {
		SneakPreview,
		Magazine,
		HokSelect,
		Tabs,
		Tab,
		Settings,
		AskPrivacyForActiveSourcing
	},
	async asyncData({ $pinia }) {
		const { hash, query, params } = useRoute();

		const articlesStore = useArticlesStore($pinia);
		const quizStore = useQuizStore($pinia);
		const relationsStore = useRelationsStore($pinia);

		const quizzes = [
			{
				title: 'Jobwechsel-Quiz',
				text: 'Job wechseln oder nicht? ',
				button: 'Das Jobwechsel-Quiz hilft dir!',
				siteType: 'JobSwitchQuiz',
				shuffledAnswers: false,
				yesOrNoQuiz: true
			},
			{
				title: 'Vier-Tage-Woche-Quiz',
				text: 'Passt die 4 Tage Woche zu dir oder nicht? ',
				button: 'Finde es jetzt heraus!',
				siteType: 'WorkingDaysQuiz',
				shuffledAnswers: false,
				yesOrNoQuiz: true
			},
			{
				title: 'Vorstellungsgespräch-Check',
				text: 'Wie gut bist du auf dein Bewerbungsgespräch vorbereitet?',
				button: 'Mache unser Quiz!',
				siteType: 'JobInterviewTraining',
				shuffledAnswers: false,
				yesOrNoQuiz: true
			}
		];

		const randomQuiz = quizzes[Math.floor(Math.random() * quizzes.length)];
		try {
			const loadedQuiz = await quizStore.loadQuestions({
				quizType: randomQuiz.siteType
			});

			const profileArticles = await articlesStore.getArticles('jobsuche', 3);
			let category;

			if (query?.category) {
				category = query?.category;
			} else {
				category = 'user';
			}

			const articles = await articlesStore.getArticles(category, 21);
			const scrollToJobAlarmInterval = hash === '#jobAlarmInterval';
			const jobSuggestions = await relationsStore.jobSuggestions();

			return {
				showTab: params.tab ? params.tab : 'profil',
				scrollToJobAlarmInterval,
				articles,
				setActiveJobSeeker: query?.SetActiveJobSeeker === 'true',
				profileArticles,
				randomQuiz,
				questions: loadedQuiz.questions,
				quizResults: loadedQuiz.results,
				quizId: loadedQuiz._id,
				jobSuggestions: jobSuggestions.list
			};
		} catch (e) {
			console.log(e);
		}
	},
	data() {
		const jobSuggestions = undefined as IAPIMatchForJobSeekerListEntry[] | undefined;
		const jobAlarms = undefined as IAPIJobAlarmSubscription[] | undefined;
		const appliedJobs = undefined as IAPIMatchForJobSeekerListEntry[] | undefined;
		const savedRelations = undefined as IAPIMatchForJobSeekerListEntry[] | undefined;
		const quizId = undefined as string | undefined;
		const quizResults = undefined as { title: string; text: string }[] | undefined;
		const questions = undefined as
			| {
					_id: string;
					question: string;
					options: {
						correct: boolean;
						explanation: string;
						option: string;
						totalAnswers: number;
					}[];
			  }[]
			| undefined;
		const randomQuiz = undefined as
			| {
					title: string;
					text: string;
					button: string;
					siteType: string;
					shuffledAnswers: boolean;
					yesOrNoQuiz: boolean;
			  }
			| undefined;
		const profileArticles: IAPIArticleTeaser[] = [];
		const articles: IAPIArticleTeaser[] = [];
		const showTab = undefined as undefined | string;
		const scrollToJobAlarmInterval = undefined as undefined | boolean;
		const setActiveJobSeeker = undefined as undefined | string;
		const loading = false;

		return {
			scrollToJobAlarmInterval,
			showTab,
			setActiveJobSeeker,
			randomQuiz,
			questions,
			quizResults,
			quizId,
			savedRelations,
			appliedJobs,
			jobAlarms,
			jobSuggestions,
			articles,
			profileArticles,
			currentCategoryFilter: 'Neueste Artikel',
			categories: [
				{ id: 'user', value: 'Neueste Artikel' },
				{ id: 'bewerbung', value: 'Bewerbung & Lebenslauf' },
				{ id: 'jobsuche', value: 'Alles zur Jobsuche' },
				{ id: 'ausbildung-lehre', value: 'Ausbildung & Lehre' },
				{ id: 'arbeitsmarkt', value: 'Aktuelles zum Arbeitsmarkt' },
				{ id: 'arbeitsrecht', value: 'Arbeitsalltag & Arbeitsrecht' },
				{ id: 'product-updates', value: 'Neuigkeiten von Hokify' }
			],
			loading,
			EventBus
		};
	},
	beforeUnmount() {
		this.EventBus.$off('end-go-to');
		this.EventBus.$off('deleted');
		this.EventBus.$off('next-article');
	},
	computed: {
		user(): IAPILoginUser | undefined {
			return this.userProfileStore?.obj;
		},
		privacy() {
			return this.userProfileStore.obj?.privacy;
		},
		savedJobsCount(): number {
			return (
				this.savedRelations?.filter(relation => relation?.relation?.type === 'job')?.length || 0
			);
		},
		jobAlarmsCount(): number {
			return this.jobAlarms?.length || 0;
		},
		appliedJobsCount(): number {
			return this.appliedJobs?.length || 0;
		},
		unseenMessagesCount(): number {
			return Object.keys(this.messagesStore.matchesWithUnseenMsgs)?.length || 0;
		},
		...mapStores(
			useArticlesStore,
			useUserProfileStore,
			useMessagesStore,
			useMatchesStore,
			useUserJobAlarmStore
		)
	},
	async created() {
		if (this.$route.query?.category) {
			const category = this.categories.find(item => item.id === this.$route?.query?.category);
			if (category) {
				await this.setFilter(category?.id, category?.value);
			}
		}
	},
	async mounted() {
		this.loading = true;
		try {
			this.savedRelations = await this.matchesStore.getSaved(true);
			this.appliedJobs = await this.matchesStore.getApplied(true);
			this.jobAlarms = await this.userJobAlarmStore.getJobAlarmSubscriptions(true);
		} catch (err) {
			this.$nuxt.$errorHandler(
				err,
				'Es ist ein Fehler beim Laden der Daten aufgetreten. Bitte versuche es später noch einmal.'
			);
		} finally {
			this.loading = false;
		}

		if (this.setActiveJobSeeker) {
			this.setupActiveSourcing(true);
		}
	},
	methods: {
		categoryPromise() {
			return this.categories;
		},
		error(err) {
			this.$nuxt.$errorHandler(err);
		},
		async showEditMode(section?: string) {
			const editModeComponent = markRaw(
				defineAsyncComponent(() => import('@/pages/pwa/profile/ProfileEditModePage.vue'))
			);
			try {
				await this.$page.push(
					editModeComponent,
					{ scrollToSection: section },
					{
						pageTitle: 'Profil bearbeiten',
						name: 'editMode'
						/* on: {
							'set-active-sourcing': () => this.setupActiveSourcing(true)
						} */
					}
				);
			} catch (err) {
				this.$nuxt.$errorHandler(err);
			}
		},
		async goToArticle(article) {
			const articlePage = markRaw(defineAsyncComponent(() => import('../article/[...slug].vue')));
			this.EventBus.$off('go-to-article');
			try {
				await this.$page.push(
					articlePage,
					{
						alias: article.alias
					},
					{
						pageTitle: article.title
					}
				);
				this.EventBus.$on('go-to-article', (nextArticle: string) => {
					this.goToArticle(nextArticle);
				});
			} catch (err) {
				this.$nuxt.$errorHandler(err);
			}
		},
		async setFilter(id: string, value: string) {
			try {
				this.currentCategoryFilter = value;
				this.articles = await this.articlesStore.getArticles(id, 21);
			} catch (err) {
				this.$nuxt.$errorHandler(err);
			}
		},
		setupActiveSourcing(value: boolean) {
			if (value) {
				this.$trackUserEvent?.('accept_active_sourcing', {});
			} else {
				this.$trackUserEvent?.('decline_active_sourcing', {});
			}

			if (value && (!this.privacy || !this.privacy.user_active_sourcing)) {
				this.$modal.show('askPrivacyForActiveSourcing');
				return false;
			}
			this.$modal.hide('askPrivacyForActiveSourcing');

			this.userProfileStore.saveSetting({
				type: 'activeJobSearcher',
				value
			});

			if (value) {
				this.$snack.success({
					text: 'Geschafft! Dein Profil kann jetzt von Unternehmen gefunden werden.'
				});
			} else {
				this.$snack.danger({
					text: 'Dein Profil wird ab jetzt nicht mehr von Unternehmen gefunden.'
				});
			}
		},
		async changeOrCreateCv() {
			if (
				this.userProfileStore.obj?.cvInfo?.design &&
				this.userProfileStore.obj?.cvInfo?.completeness?.readyToUse
			) {
				const changeCvDesignPageComponent = markRaw(
					defineAsyncComponent(() => import('@/pages/pwa/profile/ChangeCvDesignPage.vue'))
				);
				try {
					this.$page.push(
						changeCvDesignPageComponent,
						{},
						{
							mode: 'modal',
							name: 'changeCvDesign',
							on: {
								done: () => {
									this.$page.goBack();
								}
							}
						}
					);
				} catch (err) {
					this.$nuxt.$errorHandler(err);
				}
			} else {
				await this.showEditMode();
			}
		},
		async doUseHokifyCv() {
			try {
				await this.userProfileStore.useHokifyCv();
				this.$snack.success({
					text: 'Dein hokify Lebenslauf wird jetzt für Bewerbungen benutzt.'
				});
			} catch (err) {
				if (
					this.$isHokFetchResponseError(err) &&
					err.response?.data?.code === 'INCOMPLETE_HOKIFY_CV'
				) {
					this.$snack.danger({
						text: 'Um den hokify Lebenslauf verwenden zu können, muss dieser vollständiger sein!'
					});
					return;
				}

				this.$nuxt.$errorHandler(
					err,
					'Es ist ein Fehler aufgetreten, beim Versuch den hokify Lebenslauf zu verwenden.'
				);
			}
		},
		async openQuiz() {
			if (this.questions && this.quizResults) {
				const quizPage = markRaw(
					defineAsyncComponent(() => import('@/pages/pwa/profile/QuizPage.vue'))
				);
				try {
					this.$page.push(
						quizPage,
						{
							isPwa: true,
							questions: this.questions,
							quizId: this.quizId,
							quizResults: this.quizResults,
							yesOrNoQuiz: this.randomQuiz?.yesOrNoQuiz,
							shuffledAnswers: this.randomQuiz?.shuffledAnswers
						},
						{
							pageTitle: this.randomQuiz?.title,
							name: '/hokify-quiz'
						}
					);
				} catch (err) {
					this.$nuxt.$errorHandler(err);
				}
			} else {
				this.$snack.danger({
					text: 'Es ist ein Fehler aufgetreten. Bitte lade die Seite neu!'
				});
			}
		},
		async openGrossNetCalculator() {
			const gncPage = markRaw(
				defineAsyncComponent(() => import('@/pages/pwa/profile/GrossNetCalculatorPage.vue'))
			);
			try {
				this.$page.push(
					gncPage,
					{ isPwa: true },
					{
						pageTitle: 'Brutto-Netto Rechner',
						name: 'brutto-netto-rechner'
					}
				);
			} catch (err: any) {
				this.$nuxt.$errorHandler(err);
			}
		},
		async editExperience(experience) {
			const experienceComponent = markRaw(
				defineAsyncComponent(() => import('../../../components/user/quickCv/quickExperience.vue'))
			);
			try {
				this.$page.push(
					experienceComponent,
					{
						editExperience: experience, // clone it
						profileMode: true,
						suggestionOnLoad: true
					},
					{
						pageTitle: 'Berufserfahrung bearbeiten',
						name: 'edit-experience',
						done: () => {
							this.EventBus.$off('end-go-to');
							this.EventBus.$off('deleted');
						}
					}
				);
				this.EventBus.$on('end-go-to', () => this.$page.goBack());
				this.EventBus.$on('deleted', exp => this.confirmDeleted(exp));
			} catch (err) {
				this.$nuxt.$errorHandler(err);
			}
		},
		confirmDeleted(exp) {
			this.$page.goBack();
			this.$snack.success({
				text: `${exp || 'Tätigkeit'} wurde erfolgreich gelöscht.`
			});
		},
		selectTab({ tab }) {
			this.EventBus.$emit('select-tab', tab.url);
		}
	}
});
</script>
